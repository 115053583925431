/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import { SectionItem } from '../../../app/components/ui'
import DashboardItemDate from '../../../app/components/ui/DashboardItemDate'
import { Action, ActionGroup } from '../common'

const StatusRowWrapper = styled.div`
  display: flex;
`

const dotAfter = css`
  &:after {
    color: ${th('colorBorder')};
    content: '\\2022';
    padding: 0 ${th('gridUnit')};
  }
`

const NameWrapper = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  ${props => !props.last && dotAfter}
`

const Label = styled.span`
  color: ${th('colorBorder')};
  margin-right: calc(${th('gridUnit')} / 2);
  text-transform: capitalize;

  &:after {
    content: ':';
  }
`

const RowWrapper = styled.div`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')}
    ${th('colorFurniture')};
  padding: 4px 0;
`

const Actions = styled.div`
  flex-shrink: 0;
`

const DashboardName = props => {
  const { last, name, label } = props

  return (
    name && (
      <NameWrapper last={last}>
        <Label>{label}</Label>
        {name}
      </NameWrapper>
    )
  )
}

const StatusRow = props => {
  const { submittingAuthor, date } = props

  const submittingAuthorValue = submittingAuthor
    ? `${submittingAuthor.firstName} ${submittingAuthor.lastName}`
    : ''

  return (
    <StatusRowWrapper>
      {date && (
        <DashboardItemDate
          label="Date Published"
          value={new Date(date).getTime()}
        />
      )}
      {submittingAuthor && (
        <DashboardName label="submitting author" name={submittingAuthorValue} />
      )}
    </StatusRowWrapper>
  )
}

const ManuscriptItem = item => {
  const {
    articleId,
    curatorStatus,
    entities,
    title,
    submittingAuthor,
    datePublished,
    onClickOpenEntityTable,
  } = item

  const articleLink = curatorStatus || 'Go to Article'
  const entityTable = () => onClickOpenEntityTable(entities)

  const EditorPanel = (
    <Actions>
      <ActionGroup>
        <Action to={`/article/${articleId}`}>{articleLink}</Action>
        {entities && (
          <Action onClick={entityTable}>
            Entity Table ({entities.filter(e => !e.deleted).length})
          </Action>
        )}
      </ActionGroup>
    </Actions>
  )

  return (
    <RowWrapper>
      <StatusRow date={datePublished} submittingAuthor={submittingAuthor} />
      <SectionItem rightComponent={EditorPanel} title={title} />
    </RowWrapper>
  )
}

export default ManuscriptItem
