import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '../_helpers'
import { TextEditor } from '../common'

const Wrapper = styled.div``

const Label = styled.div`
  font-weight: bold;
`

const Editor = styled(TextEditor)`
  background: ${th('colorBackground')};
  border: 0;
  cursor: default;
  margin: 0;
  padding: 0;
  width: unset;
`

const FeedbackItem = props => {
  const { decisionLetter } = props

  return (
    <Wrapper>
      <Label>Editor feedback</Label>
      <Editor readOnly value={decisionLetter} />
    </Wrapper>
  )
}

FeedbackItem.propTypes = {
  /** Editor's feedback */
  decisionLetter: PropTypes.string.isRequired,
}

FeedbackItem.defaultProps = {}

export default FeedbackItem
