/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import last from 'lodash/last'
import clone from 'lodash/clone'
import uniqBy from 'lodash/uniqBy'
import pick from 'lodash/pick'
// import capitalize from 'lodash/capitalize'

import CurrentUserContext from '../userContext'

import {
  countLinks,
  deleteLinks,
  linkHTML,
  predictEntities,
  deleteLink,
  unDeleteLink,
} from './_helpers/linking'
import { getEntities } from '../fetch/BioentityApi'
import { FULL_PREVIEW, UPDATE_ENTITIES, SAVE_FORM } from '../graphql'
import { exportManuscriptEntities } from '../fetch/exportManuscript'
import { searchAlliance } from '../fetch/AllianceApi'
import { fetchStrain, fetchTransgene, fetchAllele } from '../fetch/WBApi'
import { ncbiAccessionLookup } from '../fetch/PubMedApi'
import { ArticlePreviewModal } from '../components/ui'

import Split from '../../ui/src/split/Split'
// import LinkPanel from '../../ui/src/curationTool/LinkPanel'
import Preview from '../../ui/src/curationTool/Preview'
import { Loader } from '../../ui/src/common'
import LinkInfoModal from '../../ui/src/modals/LinkInfoModal'
import AutoLinkModal from '../../ui/src/modals/AutoLinkModal'
import LinkerDetails from '../../ui/src/curationTool/LinkerDetails'
import KwsDescriptions from '../../ui/src/modals/KwsDescriptions'
import ConfirmResetLinks from '../../ui/src/modals/ConfirmResetLinks'

const FullHeightSplit = styled(Split)`
  height: 100%;
`

const keywordSets = [
  { label: 'Worm Entities', value: 'WB Entity' },
  { label: 'Worm Curation', value: 'WB GCAT2' },
  { label: 'Fly Entities', value: 'Fly Entities' },
  { label: 'Yeast Entities', value: 'SGD_gap' },
  { label: 'Arabidopsis Entities', value: 'TAIR_GCAT' },
  { label: 'Pombe Entities', value: 'Pombe Entities' },
  { label: 'Zebrafish Entities', value: 'Zebrafish Genes' },
  { label: 'Mouse Entities', value: 'Mouse Gene' },
  { label: 'Human Entities', value: 'Hum_genes' },
  { label: 'Rat Genes', value: 'Rat Entities' },
  { label: 'Rat Strains', value: 'Rat Strains' },
  { label: 'NCBI Species', value: 'NCBI Species' },
  { label: 'Paper Classifier Linking', value: 'Paper Classifier Linking' },
  { label: 'Molecule', value: 'WB Molecule' },
  { label: 'Disease', value: 'Disease' },
  { label: 'Editorial Check', value: 'Editorial Check' },
]

const Linker = props => {
  const { id: manuscriptId } = useParams()

  const { currentUser } = useContext(CurrentUserContext)
  const isAuthor = currentUser.auth.isAuthor.includes(manuscriptId)

  const [articleValues, setArticleValues] = useState()
  const [newEntity, setNewEntity] = useState()
  const [savedEntities, setEntities] = useState([])
  const [showLinkInfoModal, setShowLinkInfoModal] = useState(false)
  const [showKwsDescriptions, setShowKwsDescriptions] = useState(false)
  const [selectedEntity, setSelectedEntity] = useState()
  const [highlightEntity, setHighlightEntity] = useState(null)
  const [autoLinkOpen, setAutoLinkOpen] = useState(true)
  const [currentStatus, setCurrentStatus] = useState({ link: 0, predict: 0 })
  const [tableUpdated, setTableUpdated] = useState(true)
  const [authorEntitiesComments, setEntitiesComments] = useState('')
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showResetLinks, setShowResetLinks] = useState(false)
  const [returnToForm, setReturn] = useState(false)

  const history = useHistory()

  const { data, loading } = useQuery(FULL_PREVIEW, {
    variables: { id: manuscriptId },
  })

  const [updateEntitiesMutation] = useMutation(UPDATE_ENTITIES, {
    refetchQueries: [{ query: FULL_PREVIEW, variables: { id: manuscriptId } }],
  })

  const [saveForm] = useMutation(SAVE_FORM, {
    refetchQueries: [{ query: FULL_PREVIEW, variables: { id: manuscriptId } }],
  })

  const saveLinks = async newArticleValues => {
    // saveEntities(savedEntities)

    const versionWithLinks = pick(newArticleValues || articleValues, [
      'id',
      'abstract',
      'patternDescription',
      'imageTitle',
      'imageCaption',
      'methods',
      'reagents',
    ])

    return saveForm({
      variables: {
        input: versionWithLinks,
      },
    })
  }

  useEffect(() => {
    if (!loading && data) {
      if (returnToForm) history.push(`/article/${manuscriptId}`)

      const existingEntities = data.manuscript.entities || []

      const latestVersion = linkHTML(
        last(data.manuscript.versions),
        existingEntities, // .filter(e => !e.deleted),
      )

      latestVersion.species = data.manuscript.species

      if (!articleValues) {
        setArticleValues(latestVersion)
      }

      const entities = countLinks(latestVersion, data.manuscript.entities)
      setEntities(entities)
      setEntitiesComments(data.manuscript.authorEntitiesComments || '')

      // setAutoLinkOpen(entities.length === 0)
    }

    document.onmouseup = () => {
      const selectionText = document.getSelection()

      if (
        selectionText &&
        selectionText.toString().length > 0 &&
        (selectionText.focusNode.parentNode.nodeName === 'P' ||
          selectionText.focusNode.parentNode.nodeName === 'I')
      ) {
        setNewEntity(selectionText.toString())
      } else if (
        selectionText &&
        selectionText.toString().length > 0 &&
        selectionText.focusNode.parentNode.nodeName === 'SUP'
      ) {
        const parent = selectionText.focusNode.parentNode.parentNode
        setNewEntity(
          `${parent.firstChild.nodeValue}<sup>${parent.lastChild.firstChild.nodeValue}</sup>`,
        )
      }
    }
  }, [loading, data])

  const updateEntities = async entities =>
    updateEntitiesMutation({
      variables: {
        manuscriptId,
        data: entities,
        authorEntitiesComments,
      },
    })

  const linkHTMLForVersion = async entities => {
    if (!entities) return null

    const newValues = linkHTML(
      articleValues,
      entities, // .filter(e => !e.deleted),
    )

    const entitiesWithCounts = countLinks(newValues, entities)

    setArticleValues(newValues)
    return entitiesWithCounts
  }

  const getEntitiesForVersion = kws => getEntities(articleValues.id, kws)

  const deleteLinksForVersion = entities => {
    let newValues = articleValues
    entities.forEach(e => {
      newValues = deleteLinks(newValues, e)
    })

    const updatedEntities = savedEntities.map(e => {
      delete e.count
      delete e.matchIsSource

      if (entities.find(entity => e.match === entity.match)) {
        e.deleted = true
      }

      return e
    })

    const updatedValues = linkHTML(newValues, updatedEntities)

    saveEntities(updatedEntities)
    setArticleValues(updatedValues)
    saveLinks(updatedValues)
  }

  const revert = async () => {
    let newValues = articleValues
    savedEntities &&
      savedEntities.forEach(e => {
        newValues = deleteLinks(newValues, e)
      })

    await saveLinks(newValues)

    saveEntities(null)
    setArticleValues(newValues)
    // return saveLinks(newValues)
    // setAutoLinkOpen(true)
  }

  const predictEntitiesForVersion = () => {
    const predictedEntities = predictEntities(articleValues)
    return predictedEntities
  }

  const deleteLinkForVersion = async id => {
    const newValues = deleteLink(articleValues, id)
    setArticleValues(newValues)
    setTableUpdated(true)
    return saveLinks(newValues)
  }

  const exportEntities = () => exportManuscriptEntities(manuscriptId)

  const onClickShowLinkInfo = (entity, id) => {
    const foundEntity = savedEntities.find(
      savedEntity => savedEntity.match === entity,
    )

    foundEntity.id = id
    setSelectedEntity(foundEntity)
    setShowLinkInfoModal(true)
    setHighlightEntity(entity)
  }

  const saveEntities = entities => {
    setEntities(entities)

    const newEntities =
      entities &&
      entities.map(entity => {
        const clonedEntity = clone(entity)
        delete clonedEntity.count
        delete clonedEntity.matchIsSource
        delete clonedEntity.id
        return clonedEntity
      })

    updateEntities(newEntities, authorEntitiesComments)
  }

  const fixGeneOntology = entitiesToFix =>
    entitiesToFix.map(entity => {
      if (entity.type === 'GENEONTOLOGY') {
        const goEntity = clone(entity)

        if (goEntity.source === 'GO_bp') {
          goEntity.type = 'GOBP'
        } else if (goEntity.source === 'GO_cc') {
          goEntity.type = 'GOCC'
        } else if (goEntity.source === 'GO_mf') {
          goEntity.type = 'GOMF'
        }

        return goEntity
      }

      return entity
    })

  const updatePredictionTable = async matchedEntities =>
    Promise.all(
      predictEntitiesForVersion()
        .filter(
          prediction =>
            matchedEntities.findIndex(
              entity => entity.match === prediction.match,
            ) === -1,
        )
        .map(async entity => {
          const clonedEntity = clone(entity)
          const { type, match, matchIsSource } = entity

          const query = type === 'PROTEIN' ? match.toLowerCase() : match

          const category = {
            GENE: 'gene',
            VARIANT: 'allele',
            PROTEIN: 'gene',
            STRAIN: 'model',
            TRANSGENE: 'allele',
          }

          const species = articleValues.species[0]

          if (
            !matchIsSource &&
            species !== 's. pombe' &&
            species !== 'arabidopsis'
          )
            await searchAlliance(query, category[type])
              .then(res => res.json())
              .then(results => {
                if (
                  results.results.length > 0 &&
                  results.results[0].name === query
                ) {
                  clonedEntity.sourceId = results.results[0].primaryKey
                    .split(':')
                    .pop()
                  clonedEntity.url = `${clonedEntity.url}${clonedEntity.sourceId}`
                }
              })

          return clonedEntity
        }),
    )

  const linkMessage =
    savedEntities && savedEntities.length > 0
      ? 'Check for new entities?'
      : 'Check the biological entities (bioentities) mentioned in your paper, such as gene names, allele names, strain names, etc. This tool will help you find typos and bioentities that were erroneously reported. This tool also predicts bioentities that do not exist in community databases yet. This step is optional but should be fairly quick, please consider participating. To get started read the instructions on the next page.'

  const autoLink = async () => {
    setCurrentStatus({ link: 1, predict: 0 })

    const kwsMap = {
      'c. elegans': 'WB Entity',
      drosophila: 'Fly Entities',
      's. cerevisiae': 'SGD_gap',
      's. pombe': 'Pombe Entities',
      arabidopsis: 'TAIR_GCAT',
      mouse: 'Mouse Genes',
      human: 'Hum_genes',
      zebrafish: 'Zebrafish Genes',
      rat: 'Rat Entities',
    }

    await getEntities(
      articleValues.id,
      kwsMap[articleValues.species[0].toLowerCase()],
    )
      .then(res => res.json())
      .then(async result => {
        const sortedEntities = result.sort((a, b) => {
          if (a.match.length < b.match.length) return 1
          if (a.match.length > b.match.length) return -1
          return 0
        })

        setCurrentStatus({ link: 2, predict: 1 })

        const fixedEntities = fixGeneOntology(sortedEntities)
        const predictions = await updatePredictionTable(fixedEntities)

        const filteredPredictions = predictions
          .filter(p => fixedEntities.findIndex(e => e.match === p.match) === -1)
          .filter(
            p =>
              !data.manuscript.entities ||
              data.manuscript.entities.findIndex(e => e.match === p.match) ===
                -1,
          )

        setCurrentStatus({ link: 2, predict: 2 })

        const combinedEntities = await linkHTMLForVersion(
          uniqBy(
            [...fixedEntities, ...savedEntities, ...filteredPredictions],
            e => e.match,
          ),
        )

        saveEntities(combinedEntities)
        setAutoLinkOpen(false)
        return saveLinks(linkHTML(articleValues, combinedEntities))
      })
  }

  const updateEntity = async newValues => {
    const clonedEntity = clone(selectedEntity)
    clonedEntity.source = newValues.source
    clonedEntity.sourceId = newValues.sourceId
    clonedEntity.url = newValues.url

    const updatedEntities = savedEntities.filter(
      entity => selectedEntity.match !== entity.match,
    )

    setSelectedEntity(clonedEntity)

    return saveEntities([clonedEntity, ...updatedEntities])
  }

  const onClickRemoveLink = async entityText => {
    const newEntities = savedEntities.map(e => {
      if (e.deleted) e.deleted = false
      return e
    })

    setEntities(newEntities)

    await updateEntities(
      newEntities.map(e => {
        delete e.count
        delete e.id
        return e
      }),
    )

    const newValues = linkHTML(
      unDeleteLink(articleValues, entityText),
      savedEntities,
    )

    setArticleValues(newValues)

    saveLinks(newValues)
  }

  const handleEntitiesComments = (name, value) => {
    setEntitiesComments(value)
    setTableUpdated(true)
  }

  const resetLinks = async () => {
    revert()
    return autoLink().then(() => setShowResetLinks(false))
  }

  const revertAndCancel = async () => {
    setReturn(true)
    await revert()
    // history.push(`/article/${manuscriptId}`)
  }

  if (loading) return <Loader />
  const { dbReferenceId } = data.manuscript

  const preview = (
    <Preview
      highlightEntity={highlightEntity}
      onClickRemoveLink={onClickRemoveLink}
      onClickShowLinkInfo={onClickShowLinkInfo}
      savedEntities={savedEntities}
      version={articleValues}
    />
  )

  const linkerDetails = (
    <LinkerDetails
      authorEntitiesComments={data.manuscript.authorEntitiesComments}
      dbReferenceId={dbReferenceId}
      deleteLinks={deleteLinksForVersion}
      exportEntities={exportEntities}
      fixGeneOntology={fixGeneOntology}
      formLink={() => history.push(`/article/${manuscriptId}`)}
      getEntities={getEntitiesForVersion}
      // getKeywordSets={getKeywordSets}
      highlightEntity={highlightEntity}
      isAuthor={isAuthor}
      keywordSets={keywordSets}
      linkHTML={linkHTMLForVersion}
      ncbiAccessionLookup={ncbiAccessionLookup}
      newEntity={newEntity}
      onClickShowKwsDescriptions={() => setShowKwsDescriptions(true)}
      predictEntities={predictEntitiesForVersion}
      revert={revert}
      savedEntities={savedEntities ? savedEntities.filter(e => !e.deleted) : []}
      saveLinks={saveLinks}
      searchAlliance={searchAlliance}
      setEntities={saveEntities}
      setEntitiesComments={handleEntitiesComments}
      setHighlightEntity={setHighlightEntity}
      setNewEntity={setNewEntity}
      setShowPreviewModal={setShowPreviewModal}
      setShowResetLinks={setShowResetLinks}
      tableUpdated={tableUpdated}
      updatePredictionTable={updatePredictionTable}
    />
  )

  return (
    <>
      <FullHeightSplit left={preview} right={linkerDetails} splitOn={60} />
      <LinkInfoModal
        deleteLink={deleteLinkForVersion}
        deleteLinks={deleteLinksForVersion}
        entity={selectedEntity}
        isAuthor={isAuthor}
        isOpen={showLinkInfoModal}
        lookupAllele={fetchAllele}
        lookupStrain={fetchStrain}
        lookupTransgene={fetchTransgene}
        onRequestClose={() => setShowLinkInfoModal(false)}
        updateEntity={updateEntity}
      />
      <AutoLinkModal
        autoLink={autoLink}
        current={currentStatus}
        isOpen={autoLinkOpen}
        linkMessage={linkMessage}
        onRequestClose={() => setAutoLinkOpen(false)}
      />
      <KwsDescriptions
        isOpen={showKwsDescriptions}
        onRequestClose={() => setShowKwsDescriptions(false)}
      />
      <ArticlePreviewModal
        // exportManuscript={exportManuscript}
        isOpen={showPreviewModal}
        onRequestClose={() => setShowPreviewModal(false)}
        values={articleValues}
      />
      <ConfirmResetLinks
        isOpen={showResetLinks}
        onConfirm={() => setShowResetLinks(false)}
        onRequestClose={() => setShowResetLinks(false)}
        resetLinks={resetLinks}
        revertAndCancel={revertAndCancel}
        showCancelButton={false}
        textSuccess="cancel"
      />
    </>
  )
}

export default Linker
