import { compose } from 'recompose'
import { withFormik } from 'formik'
import { graphql } from '@apollo/client/react/hoc'

import SIGNUP_USER from './graphql'

import Signup from './Signup'

const handleSubmit = (
  values,
  { props, setSubmitting, setErrors, setValues, setStatus, resetForm },
) =>
  props
    .signupUser({
      variables: { input: values },
    })
    .then(({ data, errors }) => {
      if (!errors) {
        resetForm()
        setStatus({ success: 'User has been created successfully!' })
      }
    })
    .catch(e => {
      if (e.graphQLErrors) {
        setStatus({ error: e.graphQLErrors[0].message })
        setSubmitting(false)
      }
    })

const enhancedFormik = withFormik({
  displayName: 'signup',
  handleSubmit,
  mapPropsToValues: props => ({
    email: props.email,
    givenNames: props.givenNames,
    password: props.password,
    surname: props.surname,
    username: props.username,
    reviewer: props.reviewer,
    species: props.species,
    researchArea: props.researchArea,
    specialty: props.specialty,
  }),
})(Signup)

export default compose(
  graphql(SIGNUP_USER, {
    name: 'signupUser',
  }),
)(enhancedFormik)
