/* eslint-disable react/prop-types */

import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

// import { withCurrentUser } from '../../../userContext'
// import { CURRENT_USER } from '../../Private'
// import { DASHBOARD_MANUSCRIPTS } from './dashboardManuscripts'

const CREATE_MANUSCRIPT = gql`
  mutation CreateManuscript($file: String) {
    createManuscript(file: $file)
  }
`

const CreateManuscriptMutation = props => {
  const { render } = props

  /* 
    Leaving without mutations for now. But this should be here and not in the 
    onClick function that runs this mutation. The problem here is that the
    mutation does not wait for all refetch queries to resolve, causing a UI
    glitch, where you can see an empty article preview before it gets updated
    to show the form.
  */
  // const refetchQueries = [
  //   {
  //     query: CURRENT_USER,
  //   },
  //   {
  //     query: DASHBOARD_MANUSCRIPTS,
  //   },
  // ]

  return (
    <Mutation mutation={CREATE_MANUSCRIPT}>
      {(createManuscript, createManuscriptResponse) =>
        render({ createManuscript, createManuscriptResponse })
      }
    </Mutation>
  )
}

export { CREATE_MANUSCRIPT }
// export default withCurrentUser(CreateManuscriptMutation)
export default CreateManuscriptMutation
