import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { H5, RibbonFeedback } from '../common'
import { grid } from '../_helpers'

const Wrapper = styled.div``

const Header = styled(H5)`
  margin: ${grid(2)} 0;
`

const UserProfileSection = props => {
  const {
    className,
    children,
    // errorMessage,
    headerText,
    successMessage,
    update,
  } = props

  return (
    <Wrapper className={className}>
      <Header>{headerText}</Header>
      <RibbonFeedback
        // errorMessage={errorMessage}
        keepSpaceOccupied={false}
        successMessage={successMessage}
      >
        {notifyRibbon => {
          const updateFn = values =>
            update(values)
              .then(() => notifyRibbon(true))
              // TODO -- refactor this so that messages come from the gql layer
              .catch(err => {
                const errorMsg =
                  err &&
                  err.graphQLErrors &&
                  err.graphQLErrors[0] &&
                  err.graphQLErrors[0].message.split(':').pop().trim()

                const messages = [
                  'Current password is incorrect',
                  'New password must be different from current password',
                ]

                let msg = 'Something went wrong!'
                if (messages.includes(errorMsg)) msg = errorMsg

                notifyRibbon(false, msg)
              })

          return children(updateFn)
        }}
      </RibbonFeedback>
    </Wrapper>
  )
}

UserProfileSection.propTypes = {
  // errorMessage: PropTypes.string,
  headerText: PropTypes.string.isRequired,
  successMessage: PropTypes.string,
  update: PropTypes.func.isRequired,
}

UserProfileSection.defaultProps = {
  // errorMessage: null,
  successMessage: null,
}

export default UserProfileSection
