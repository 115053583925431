/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import Modal from './Modal'
import ModalHeader from './ModalHeader'
import ModalFooterDialog from './ModalFooterDialog'

// Centers info message horizontally and vertically.
const Centered = styled.div`
  align-items: center;
  display: flex;
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeBase')};
  justify-content: center;
  line-height: ${th('lineHeightBase')};
  text-align: center;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${props => props.justifyText && `text-align: justify`}
`

const DialogModal = props => {
  const { children, headerText, justifyText, ...rest } = props

  const Header = <ModalHeader text={headerText} />
  const Footer = <ModalFooterDialog />

  return (
    <Modal
      footerComponent={Footer}
      headerComponent={Header}
      size="small"
      {...rest}
    >
      <Centered justifyText={justifyText}>{children}</Centered>
    </Modal>
  )
}

export default DialogModal
