import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Player } from 'video-react'
import '../../../node_modules/video-react/dist/video-react.css'

import { th } from '@pubsweet/ui-toolkit'

import { Icon } from '../common'
import Label from './Label'
import DiffArray from './DiffArray'
import { diff } from './_cssFragments'

const Line = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledIcon = styled(Icon)`
  svg {
    height: calc(${th('gridUnit')} * 2);
  }
`

const StyledDataFile = styled.div`
  margin-bottom: 1em;
  ${diff}
`

const DataFile = props => {
  const { isAdded, isRemoved, showDiff, value } = props

  const {
    description,
    name,
    resourceType,
    url,
    softwareUrl,
    softwareLicense,
    softwareVersion,
    licenseLink,
  } = value

  const isVideo = url.split('.').pop() === 'mp4'

  return (
    <StyledDataFile isAdded={isAdded} isRemoved={isRemoved} showDiff={showDiff}>
      <Line>Filename: {name}</Line>
      <Line>Description: {description}</Line>
      <Line>Resource Type: {resourceType}</Line>
      {softwareUrl && (
        <Line>
          Software URL:{' '}
          <a href={softwareUrl} rel="noopener noreferrer" target="_blank">
            {softwareUrl}
          </a>
        </Line>
      )}
      {softwareVersion && <Line>Software Version: {softwareVersion}</Line>}
      {softwareLicense && <Line>Software License: {softwareLicense}</Line>}
      {licenseLink && <Line>Licesne Link: {licenseLink}</Line>}
      <Line>
        Download:
        <a href={url} rel="noopener noreferrer" target="_blank">
          <StyledIcon>download</StyledIcon>
        </a>
      </Line>

      {isVideo && (
        <Line>
          <Player>
            <source src={url} />
          </Player>
        </Line>
      )}
    </StyledDataFile>
  )
}

const ExtendedData = props => {
  const { previousValues, showDiff, showRemoved, values } = props

  if (!values || values.length === 0) return null

  const transformedValues = values.map(value => ({ value }))

  const transformedPreviousValues = previousValues
    ? previousValues.map(value => ({ value }))
    : []

  return (
    <>
      <Label value="Extended Data" />
      <DiffArray
        component={DataFile}
        currentArray={transformedValues}
        previousArray={transformedPreviousValues}
        showDiff={showDiff}
        showRemoved={showRemoved}
      />
    </>
  )
}

DataFile.propTypes = {
  isAdded: PropTypes.bool,
  isRemoved: PropTypes.bool,
  showDiff: PropTypes.bool,
  value: PropTypes.shape({
    description: PropTypes.string,
    name: PropTypes.string,
    resourceType: PropTypes.string,
    url: PropTypes.string,
    softwareVersion: PropTypes.string,
    softwareUrl: PropTypes.string,
    softwareLicense: PropTypes.string,
    licenseLink: PropTypes.string,
  }),
}

DataFile.defaultProps = {
  isAdded: false,
  isRemoved: false,
  showDiff: false,
  value: null,
}

ExtendedData.propTypes = {
  previousValues: PropTypes.arrayOf(PropTypes.object),
  showDiff: PropTypes.bool,
  showRemoved: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.object),
}

ExtendedData.defaultProps = {
  previousValues: [],
  showDiff: false,
  showRemoved: false,
  values: [],
}

export default ExtendedData
