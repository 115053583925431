import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import keys from 'lodash/keys'
import sortBy from 'lodash/sortBy'

import { th } from '@pubsweet/ui-toolkit'

import {
  Button,
  Form,
  Loader,
  PageHeader as DefaultPageHeader,
} from '../common'

import TeamSection from './TeamSection'

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const PageHeader = styled(DefaultPageHeader)`
  margin-block-end: ${th('gridUnit')};
`

const Ribbon = styled.div`
  background: ${th('colorSuccess')};
  border-radius: 3px;
  color: ${th('colorTextReverse')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: calc(${th('gridUnit')} / 2);
  text-align: center;
  visibility: ${props => (props.hide ? 'hidden' : 'visible')};
`

const TeamManager = props => {
  const { loading, teams, users, updateGlobalTeamMembers } = props
  const [showRibbon, setShowRibbon] = useState(false)

  if (loading) return <Loader />

  const showRibbonFn = () => {
    setShowRibbon(true)
    setTimeout(() => setShowRibbon(false), 4000)
  }

  const initialValues = {}
  teams.forEach(team => {
    initialValues[team.role] = team.members
  })

  const handleSubmit = (values, formikBag) => {
    const { resetForm } = formikBag

    const data = keys(values).map(role => ({
      role,
      members: values[role],
    }))

    updateGlobalTeamMembers(data).then(() => {
      showRibbonFn()
      resetForm(values)
    })
  }

  const sortedTeams = sortBy(teams, 'role')

  return (
    <PageWrapper>
      <PageHeader>Team Manager</PageHeader>

      <Ribbon hide={!showRibbon}>
        Your teams have been successfully updated
      </Ribbon>

      <Form initialValues={initialValues} onSubmit={handleSubmit}>
        {formProps => {
          const { dirty, setFieldValue, values } = formProps

          return (
            <>
              <Button disabled={!dirty} primary type="submit">
                Save
              </Button>
              {sortedTeams.map(team => {
                const name = team.role
                const value = values[name]
                const handleChange = vals => setFieldValue(name, vals)

                return (
                  <TeamSection
                    displayName={team.name}
                    handleChange={handleChange}
                    key={team.id}
                    name={name}
                    users={users}
                    value={value}
                  />
                )
              })}
              <Button disabled={!dirty} primary type="submit">
                Save
              </Button>
            </>
          )
        }}
      </Form>
    </PageWrapper>
  )
}

TeamManager.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The global team objects to display */
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      members: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }),
  ).isRequired,
  /** Function to run on click of the save button */
  updateGlobalTeamMembers: PropTypes.func.isRequired,
  /** List of users to choose from */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

TeamManager.defaultProps = {
  loading: false,
}

export default TeamManager
