/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'

import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import Modal from './Modal'
import ModalHeader from './ModalHeader'
import { FileUpload } from '../common'

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const UploadModal = props => {
  const { onRequestClose, upload, createManuscript, loadArticle } = props

  const [uploading, setUploading] = useState(false)
  const [internalError, setInternalError] = useState(null)

  const Header = <ModalHeader text="Upload DOCX file" />

  const accept =
    '.docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document'

  const message = <p>Drag .docx file here, or click to select file</p>

  const handleUpload = (_, data) => {
    createManuscript(data.url).then(result => {
      setUploading(false)

      const manuscriptId = result.data.createManuscript

      loadArticle(manuscriptId)
    })
  }

  return (
    <Modal
      headerComponent={Header}
      onRequestClose={onRequestClose}
      size="small"
      textAccept="Upload"
      {...props}
    >
      {' '}
      {internalError && <Error>{internalError}</Error>}
      Upload .docx file created from template. Contact
      editors@micropublication.org for more information.
      <FileUpload
        accept={accept}
        message={message}
        setFieldValue={handleUpload}
        setInternalError={setInternalError}
        upload={upload}
      />
      {uploading && 'Uploading docx...'}
    </Modal>
  )
}

export default UploadModal
