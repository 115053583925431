import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Plotly from 'plotly.js-basic-dist-min'
import createPlotlyComponent from 'react-plotly.js/factory'

import { th } from '@pubsweet/ui-toolkit'

import { Loader, PageHeader, Select } from '../common'

const Plot = createPlotlyComponent(Plotly)

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const StyledPageHeader = styled(PageHeader)`
  margin-block-end: ${th('gridUnit')};
`

const LineWrapper = styled.div`
  border-bottom: 1px solid;
  display: flex;
  justify-content: space-between;
  width: 50%;
`

const Box = styled.div``

const Statistics = props => {
  const {
    loading,
    statistics,
    speciesGraph,
    yearGraph,
    categoriesGraph,
    submissionTypesGraph,
    speciesYearGraph,
  } = props

  if (loading) return <Loader />

  const now = new Date()
  const [year, setYear] = useState(now.getFullYear())

  const {
    numPublished,
    numRejected,
    numDeclined,
    numWithdrawn,
    speciesCounts,
    categoriesCounts,
    yearCounts,
    numPaid,
    numUnpaid,
    numWaived,
    averageTimeFromSubmitToAccept,
    averageTimeFromSubmitToPublish,
    averageNumChats,
  } = statistics

  const years = Object.keys(speciesGraph).map(key => ({
    value: key,
    label: key,
  }))

  const handleSelect = value => setYear(value.value)

  return (
    <>
      <PageWrapper>
        <StyledPageHeader>Statistics</StyledPageHeader>
        <LineWrapper>
          <Box>Year</Box>
          <Box>Count</Box>
        </LineWrapper>
        {yearCounts &&
          yearCounts.map(y => (
            <LineWrapper key={y.label}>
              <Box>{y.label}</Box>
              <Box>{y.value}</Box>
            </LineWrapper>
          ))}
        {numPublished && (
          <LineWrapper>
            <Box>Published</Box>
            <Box>{numPublished}</Box>
          </LineWrapper>
        )}
        {numRejected && (
          <LineWrapper>
            <Box>Rejected</Box>
            <Box>{numRejected}</Box>
          </LineWrapper>
        )}
        {numDeclined && (
          <LineWrapper>
            <Box>Declined</Box>
            <Box>{numDeclined}</Box>
          </LineWrapper>
        )}
        {numWithdrawn && (
          <LineWrapper>
            <Box>Withdrawn</Box>
            <Box>{numWithdrawn}</Box>
          </LineWrapper>
        )}
        {numPaid && (
          <LineWrapper>
            <Box>Paid</Box>
            <Box>{numPaid}</Box>
          </LineWrapper>
        )}
        {numUnpaid && (
          <LineWrapper>
            <Box>Unpaid</Box>
            <Box>{numUnpaid}</Box>
          </LineWrapper>
        )}
        {numWaived && (
          <LineWrapper>
            <Box>Waived</Box>
            <Box>{numWaived}</Box>
          </LineWrapper>
        )}
        <LineWrapper>
          <Box>Median time from submission to acceptance</Box>
          <Box>{averageTimeFromSubmitToAccept} days</Box>
        </LineWrapper>
        <LineWrapper>
          <Box>Median time from submission to publication</Box>
          <Box>{averageTimeFromSubmitToPublish} days</Box>
        </LineWrapper>
        <LineWrapper>
          <Box>Average number of chats per article</Box>
          <Box>{averageNumChats}</Box>
        </LineWrapper>
        <LineWrapper>
          <Box>&nbsp;</Box>
        </LineWrapper>
        <LineWrapper>
          <Box>Species</Box>
          <Box>Count</Box>
        </LineWrapper>
        {speciesCounts &&
          speciesCounts.map(s => (
            <LineWrapper key={s.label}>
              <Box>{s.label}</Box>
              <Box>{s.value}</Box>
            </LineWrapper>
          ))}
        <LineWrapper>
          <Box>&nbsp;</Box>
        </LineWrapper>
        <LineWrapper>
          <Box>Category</Box>
          <Box>Count</Box>
        </LineWrapper>
        {categoriesCounts &&
          categoriesCounts.map(c => (
            <LineWrapper key={c.label}>
              <Box>{c.label}</Box>
              <Box>{c.value}</Box>
            </LineWrapper>
          ))}
      </PageWrapper>
      <Plot
        data={yearGraph}
        layout={{
          width: 800,
          height: 600,
          title: 'Monthly microPub Submissions by Year',
          barmode: 'stack',
        }}
      />
      <Plot
        data={yearGraph}
        layout={{
          width: 800,
          height: 600,
          title: 'Monthly microPub Submissions by Year',
        }}
      />
      <LineWrapper>
        <Select
          onChange={handleSelect}
          options={years}
          value={{ value: year, label: year }}
        />
      </LineWrapper>
      <Plot
        data={speciesGraph[year]}
        layout={{
          width: 800,
          height: 600,
          title: 'Monthly microPub Submissions by Species',
          barmode: 'stack',
        }}
      />
      <Plot
        data={speciesGraph[year]}
        layout={{
          width: 800,
          height: 600,
          title: 'Monthly microPub Submissions by Species',
        }}
      />
      <Plot
        data={categoriesGraph}
        layout={{
          width: 800,
          height: 600,
          title: 'Categories by Year',
        }}
      />
      <Plot
        data={submissionTypesGraph}
        layout={{
          width: 800,
          height: 600,
          title: 'Submission Types by Year',
        }}
      />
      <Plot
        data={speciesYearGraph}
        layout={{
          width: 800,
          height: 600,
          title: 'Species by Year',
        }}
      />
    </>
  )
}

Statistics.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The data to display */
  statistics: PropTypes.PropTypes.shape({
    numPublished: PropTypes.string,
    numRejected: PropTypes.string,
    numDeclined: PropTypes.string,
    numWithdrawn: PropTypes.string,
    numPaid: PropTypes.string,
    numUnpaid: PropTypes.string,
    numWaived: PropTypes.string,
    speciesCounts: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    ),
    categoriesCounts: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    ),
    yearCounts: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    ),
    averageTimeFromSubmitToAccept: PropTypes.number,
    averageTimeFromSubmitToPublish: PropTypes.number,
    averageNumChats: PropTypes.number,
  }).isRequired,
  speciesGraph: PropTypes.string,
  yearGraph: PropTypes.string,
  categoriesGraph: PropTypes.string,
  submissionTypesGraph: PropTypes.string,
  speciesYearGraph: PropTypes.string,
}

Statistics.defaultProps = {
  loading: false,
  speciesGraph: '',
  yearGraph: '',
  categoriesGraph: '',
  submissionTypesGraph: '',
  speciesYearGraph: '',
}

export default Statistics
