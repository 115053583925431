import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import clone from 'lodash/clone'
import last from 'lodash/last'

import { th } from '@pubsweet/ui-toolkit'

import { Button, Icon, List, Link } from '../common'

const Line = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledIcon = styled(Icon)`
  svg {
    height: calc(${th('gridUnit')} * 2);
  }
`

const StyledDataFile = styled.div`
  margin-bottom: 1em;
`

const StyledButton = styled(Button)`
  padding-left: ${th('gridUnit')};
`

const DataFile = item => {
  const {
    description,
    doi,
    name,
    resourceType,
    url,
    uploadData,
    softwareUrl,
    softwareLicense,
    softwareVersion,
    licenseLink,
    openEntityTable,
    loading,
  } = item

  const [uploading, setUploading] = useState(false)

  const caltechDataUpload = async () => {
    setUploading(true)
    uploadData(name).then(async result => {
      // setDoi(await result.text())
      setUploading(false)
    })
  }

  const showEntitySearch = url.includes('.docx')

  return (
    <StyledDataFile>
      <Line>Filename: {name}</Line>
      <Line>Description: {description}</Line>
      <Line>Resource Type: {resourceType}</Line>
      {softwareUrl && (
        <Line>
          Software URL:{' '}
          <a href={softwareUrl} rel="noopener noreferrer" target="_blank">
            {softwareUrl}
          </a>
        </Line>
      )}
      {softwareVersion && <Line>Software Version: {softwareVersion}</Line>}
      {softwareLicense && <Line>Software License: {softwareLicense}</Line>}
      {licenseLink && <Line>License Link: {licenseLink}</Line>}
      {doi && (
        <Line>
          DOI:&nbsp;
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to={`https://doi.org/${doi}`}
          >
            {doi}
          </Link>
        </Line>
      )}

      <Line>
        Download:
        <Link
          rel="noopener noreferrer"
          target="_blank"
          title="Download file"
          to={url}
        >
          <StyledIcon>download</StyledIcon>
        </Link>
      </Line>
      {showEntitySearch && (
        <Line>
          <StyledButton
            loading={loading}
            onClick={openEntityTable}
            primary
            size="small"
          >
            Entities<StyledIcon color="white">search</StyledIcon>
          </StyledButton>
        </Line>
      )}
      <Line>
        {!doi && (
          <StyledButton
            loading={uploading}
            onClick={caltechDataUpload}
            primary
            size="small"
          >
            Upload to CaltechDATA<StyledIcon color="white">upload</StyledIcon>
          </StyledButton>
        )}
      </Line>
    </StyledDataFile>
  )
}

const ExtendedDataSection = props => {
  const { values, uploadData, openEntityTable, loading } = props

  if (!values || values.length === 0) return null

  const transformedValues = values.map(value => {
    const newValue = clone(value)
    newValue.uploadData = uploadData
    newValue.key = value.doi || value.url
    newValue.openEntityTable = () => openEntityTable(last(value.url.split('/')))
    newValue.loading = loading
    return newValue
  })

  return <List component={DataFile} items={transformedValues} />
}

ExtendedDataSection.propTypes = {
  values: PropTypes.arrayOf(PropTypes.object),
  uploadData: PropTypes.func,
  openEntityTable: PropTypes.func,
  loading: PropTypes.bool,
}

ExtendedDataSection.defaultProps = {
  values: [],
  uploadData: null,
  openEntityTable: null,
  loading: false,
}

export default ExtendedDataSection
