import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import * as yup from 'yup'

import { grid, th } from '../_helpers'
import { Button, Form, PageHeader, Response, TextField } from '../common'

const Wrapper = styled.div``

const Link = styled(RouterLink)`
  color: ${th('colorPrimary')};
`

const LinkWrapper = styled.div`
  margin-top: ${grid(2)};
  ${props => props.centered && `text-align: center;`}
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${grid(3)};

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div:first-child {
      margin-bottom: 0;
      width: 100%;
    }

    > div[role='alert'] {
      align-self: flex-start;
      height: ${grid(2)};
      margin: ${grid(1)} 0;
    }
  }
`

const Info = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  margin: ${grid(3)} 0;
`

const initialValues = {
  email: '',
}

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('This does not look like a valid email')
    .required('Email is required'),
})

const LinkToLogin = ({ centered }) => (
  <LinkWrapper centered={centered}>
    <Link to="/login">Return to login form</Link>
  </LinkWrapper>
)

LinkToLogin.propTypes = {
  centered: PropTypes.bool,
}

LinkToLogin.defaultProps = {
  centered: false,
}

const EmailSubmitForm = props => {
  const { handleSubmit, sending } = props

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formProps => {
        const { errors, handleChange, handleBlur, touched, values } = formProps

        return (
          <>
            <Info>
              Please provide us with the email address of your account, and we
              will send you an email with further instructions.
            </Info>

            <StyledTextField
              error={errors.email}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Email"
              name="email"
              placeholder="Enter your email"
              required
              touched={touched}
              value={values.email}
            />

            <Button primary type="submit">
              {sending ? 'Sending...' : 'Send'}
            </Button>

            <LinkToLogin />
          </>
        )
      }}
    </Form>
  )
}

EmailSubmitForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sending: PropTypes.bool,
}

EmailSubmitForm.defaultProps = {
  sending: false,
}

const RequestPasswordReset = props => {
  const { className, error, onEmailSubmit, recipient, sending } = props

  return (
    <Wrapper className={className}>
      <PageHeader>Request password reset</PageHeader>

      {!recipient && !error && (
        <EmailSubmitForm handleSubmit={onEmailSubmit} sending={sending} />
      )}

      {!recipient && error && (
        <Response icon="alert_circle" status="error">
          {error}
        </Response>
      )}

      {recipient && (
        <>
          <Response icon="check_circle" status="success">
            An email has been sent to{' '}
            <strong>
              <em>{recipient}</em>
            </strong>{' '}
            containing further instructions.
          </Response>

          <LinkToLogin centered />
        </>
      )}
    </Wrapper>
  )
}

RequestPasswordReset.propTypes = {
  /** Error to display */
  error: PropTypes.string,
  /** Function to run on click send */
  onEmailSubmit: PropTypes.func.isRequired,
  /** Provides the email that the notification was sent to, to display in success message */
  recipient: PropTypes.string,
  /** Controls whether the submission is in a loading state */
  sending: PropTypes.bool,
}

RequestPasswordReset.defaultProps = {
  error: null,
  recipient: null,
  sending: false,
}

export default RequestPasswordReset
