/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { Icon } from '@pubsweet/ui'
import { fadeIn, th } from '@pubsweet/ui-toolkit'

import { Loader } from '../../../ui/src/common'

const fadeInAnimation = css`
  animation: ${fadeIn} 0.5s;
`

const margin = css`
  margin-bottom: calc(${th('gridUnit')} * 3);
  margin-top: calc(${th('gridUnit')} * 25);
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Info = styled.div`
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeHeading6')};
  text-align: center;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${fadeInAnimation}
`

const LoadingText = styled(Info)`
  ${margin};
`

const Loading = styled(Loader)`
  height: unset;
  ${fadeInAnimation}
`

const StyledIcon = styled(Icon)`
  align-self: center;
  ${margin};

  ${fadeInAnimation}
`

const ResendButton = styled.span`
  color: ${th('colorPrimary')};
  cursor: pointer;
  font-weight: bold;
`

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`

const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($token: String!) {
    resendVerificationEmail(token: $token)
  }
`

const IconComponent = ({ iconName }) => {
  const color =
    iconName === 'alert_circle' ? th('colorError') : th('colorSuccess')

  return (
    <StyledIcon color={color} size={8}>
      {iconName}
    </StyledIcon>
  )
}

const ExpiredComponent = props => {
  const { token } = props

  return (
    <Mutation mutation={RESEND_VERIFICATION_EMAIL} variables={{ token }}>
      {(resendVerificationEmail, { data, error, loading }) => {
        const success = !loading && !error && data

        if (!success)
          return (
            <>
              <IconComponent iconName="alert_circle" />
              <Info>
                Your verification token has expired! <br /> Click{' '}
                <ResendButton onClick={resendVerificationEmail}>
                  here
                </ResendButton>{' '}
                to receive a new email.
              </Info>
            </>
          )

        return (
          <>
            <IconComponent iconName="check_circle" />
            <Info>
              A new verification email has been sent to your email address!
            </Info>
          </>
        )
      }}
    </Mutation>
  )
}

const isExpired = error => {
  if (!error || !error.message) return false
  const tokenize = error.message.split(' ')
  const lastWord = tokenize[tokenize.length - 1].toLowerCase()
  if (lastWord === 'expired') return true
  return false
}

const isAlreadyConfirmed = error => {
  if (!error || !error.message) return false
  const tokenize = error.message.split(' ')
  const lastWord = tokenize[tokenize.length - 1].toLowerCase()
  if (lastWord === 'confirmed') return true
  return false
}

const Verification = props => {
  const [loaderDelay, setLoaderDelay] = useState(true)
  const timeout = 2000

  const { history, match } = props
  const { token } = match.params
  const mutationVariables = { token }

  const redirectToLogin = () => history.push('/login')

  return (
    <Mutation mutation={VERIFY_EMAIL} variables={mutationVariables}>
      {(verify, { called, data, loading, error }) => {
        if (!called) verify()

        setTimeout(() => {
          setLoaderDelay(false)
        }, timeout)

        const showLoader = loading || loaderDelay
        const showExpiredError = !loaderDelay && error && isExpired(error)

        const showConfirmed =
          !loaderDelay &&
          error &&
          !showExpiredError &&
          isAlreadyConfirmed(error)

        const showError =
          !loaderDelay && error && !showExpiredError && !showConfirmed

        const showSuccess = !loaderDelay && !error && data

        if (showSuccess || showConfirmed)
          setTimeout(() => redirectToLogin(), timeout)

        return (
          <Wrapper>
            {showLoader && (
              <>
                <LoadingText>Verifying your email address ...</LoadingText>
                <Loading />
              </>
            )}

            {showExpiredError && <ExpiredComponent token={token} />}

            {showConfirmed && (
              <>
                <IconComponent iconName="check_circle" />
                <Info>
                  This email has already been verified! <br /> Redirecting you
                  to login...
                </Info>
              </>
            )}

            {showError && (
              <>
                <IconComponent iconName="alert_circle" />
                <Info>
                  Something went wrong!
                  <br /> Please try again or contact us.
                </Info>
              </>
            )}

            {showSuccess && (
              <>
                <IconComponent iconName="check_circle" />
                <Info>
                  Email successfully verified! <br /> Redirecting you to
                  login...
                </Info>
              </>
            )}
          </Wrapper>
        )
      }}
    </Mutation>
  )
}

export default Verification
