/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import isArray from 'lodash/isArray'
import clone from 'lodash/clone'
import * as yup from 'yup'

import { uuid } from '@coko/client'
import { List } from '@pubsweet/ui'

import Button from './Button'
import Form from './Form'
import PanelTextEditor from './PanelTextEditor'

import { stripHTML } from '../_helpers'

const Wrapper = styled.div``

const Editor = styled(PanelTextEditor)`
  box-sizing: border-box;
`

const transformEntries = entries =>
  entries.map(entry => ({
    id: entry.id || uuid(),
    label: entry.displayName,
    readOnly: true,
    value: entry.content,
  }))

/*
  HACK
  In order for the editor content to be reset, we need to destroy the xpub-edit
  instance and create a new one. We only need to reset the value on submit. So
  this is a counter that is incremented on every submit.
*/
let key = 0

const validations = yup.object().shape({
  content: yup
    .string()
    .test(
      'chat-message-not-empty',
      'Chat message is required',
      val => stripHTML(val).length > 0,
    ),
})

const NewEntry = ({
  getSavedChat,
  saveChat,
  sendMessage,
  sending,
  setSending,
  submitText,
}) => {
  const handleSubmit = (values, formikBag) => {
    const { content } = values
    const { resetForm, setStatus } = formikBag

    setSending(true)

    sendMessage(content).then(() => {
      resetForm()
      setStatus({ hasReset: true })
      setSending(false)
    })
  }

  const initialValues = { content: getSavedChat() || '' }
  const isInitialValid = getSavedChat() !== ''

  return (
    <Form
      initialValues={initialValues}
      isInitialValid={isInitialValid}
      onSubmit={handleSubmit}
      validationSchema={validations}
    >
      {formProps => {
        const { isValid, setStatus, status, values } = formProps

        // See note on key above
        if (status && status.hasReset) {
          key += 1
          setStatus({ hasReset: false })
          values.content = ''
        }

        saveChat(values.content)

        return (
          <>
            <Editor
              key={key}
              name="content"
              placeholder="Make a comment"
              value={values.content}
              {...formProps}
            />

            <Button
              disabled={!isValid || sending}
              loading={sending}
              primary
              type="submit"
            >
              {submitText || 'Send'}
            </Button>
          </>
        )
      }}
    </Form>
  )
}

const Chat = props => {
  const { getSavedChat, messages, saveChat, sendMessage, submitText } = props

  const [sending, setSending] = useState(false)

  let entries = clone(messages)
  const hasEntries = isArray(entries) && entries.length > 0
  if (hasEntries) entries = transformEntries(entries)

  return (
    <Wrapper>
      {hasEntries && <List component={Editor} items={entries} />}
      <NewEntry
        getSavedChat={getSavedChat}
        saveChat={saveChat}
        sending={sending}
        sendMessage={sendMessage}
        setSending={setSending}
        submitText={submitText}
      />
    </Wrapper>
  )
}

export default Chat
