import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'

import { Button, Form, TextField } from '../common'

const Wrapper = styled.div``

const validations = yup.object().shape({
  citations: yup.array().of(yup.string()),
  dataRelease: yup.string(),
  dbReferenceId: yup.string(),
  doi: yup.string(),
  categories: yup.array().of(yup.string()),
  pmcId: yup.string(),
  pmId: yup.string(),
  proteopedia: yup.string(),
  reviewPanel: yup.string(),
  speciesOptions: yup.array().of(yup.string()),
  submissionTypes: yup.array().of(yup.string()),
})

const proteopediaUrlBase = 'https://proteopedia.org/emb/ed/MicroPubl_Biol:'

const ProteopediaSection = props => {
  const { doi, proteopedia, setProteopediaReady } = props

  const [showForm, setShowForm] = useState(false)

  if (!showForm)
    return (
      <Wrapper>
        Proteopedia Source: {proteopedia}
        <div>
          <Button onClick={() => setShowForm(true)} primary>
            Edit
          </Button>
        </div>
      </Wrapper>
    )

  /**
   * Or form
   */

  const handleSubmit = (values, formikBag) => {
    setProteopediaReady(values.proteopedia).then(() => setShowForm(false))
  }

  const initialValues = {
    proteopedia: proteopedia || '',
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validations}
    >
      {formProps => {
        const {
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          touched,
          values,
        } = formProps

        const generateSource = () => {
          const doiNumber = doi && doi.split('.')[3]
          setFieldValue('proteopedia', `${proteopediaUrlBase}${doiNumber}`)
        }

        return (
          <Wrapper>
            <TextField
              error={errors.proteopedia}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Proteopedia iFrame Source"
              name="proteopedia"
              touched={touched}
              value={values.proteopedia}
            />
            <Button onClick={generateSource}>Generate Proteopedia URL</Button>

            <Button onClick={() => setShowForm(false)}>Cancel</Button>

            <Button primary type="submit">
              Save and Notify Proteopedia
            </Button>
          </Wrapper>
        )
      }}
    </Form>
  )
}

ProteopediaSection.propTypes = {
  doi: PropTypes.string,
  proteopedia: PropTypes.string,
  setProteopediaReady: PropTypes.func,
}

ProteopediaSection.defaultProps = {
  doi: null,
  proteopedia: null,
  setProteopediaReady: null,
}

export default ProteopediaSection
