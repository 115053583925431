/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Button as PubsweetButton } from '@pubsweet/ui'

import LoaderInline from './LoaderInline'

const Loader = styled(LoaderInline)`
  height: 20px;
  vertical-align: middle;
  width: 20px;

  /* margin-right: 8px; */
`

const Button = props => {
  const { children, loading, primary, ...rest } = props

  const loaderColor = primary ? 'colorTextReverse' : 'colorPrimary'

  return (
    <PubsweetButton primary={primary} {...rest}>
      {loading && <Loader color={loaderColor} />}
      {!loading && children}
      {/* {children} */}
    </PubsweetButton>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  primary: PropTypes.bool,
  type: PropTypes.string,
}

Button.defaultProps = {
  disabled: false,
  loading: false,
  primary: false,
  type: 'button',
}

export default Button
