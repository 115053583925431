import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import DiffArray from './DiffArray'
import Editor from './Editor'
import Label from './Label'

const Wrapper = styled.div`
  > div:not(:first-child):not(:nth-child(2)) {
    margin-top: ${th('gridUnit')};
  }
`

const transformValues = values =>
  values && values.map((value, index) => ({ value }))

const EditorList = props => {
  const { label, previousValues, showDiff, showRemoved, values } = props

  if (!values || values.length === 0) return null

  const transformedValues = transformValues(values)
  const previousTransformedValues = transformValues(previousValues)

  return (
    <Wrapper>
      {label && <Label value={label} />}

      <DiffArray
        component={Editor}
        currentArray={transformedValues}
        previousArray={previousTransformedValues}
        showDiff={showDiff}
        showRemoved={showRemoved}
      />
    </Wrapper>
  )
}

EditorList.propTypes = {
  /** Label to be displayed above all editors */
  label: PropTypes.string,
  /** Same as values, but for previous version */
  previousValues: PropTypes.arrayOf(PropTypes.string),
  /** Whether to display diff results */
  showDiff: PropTypes.bool,
  /** Whether to display diff removals */
  showRemoved: PropTypes.bool,
  /** Will display one editor for each value */
  values: PropTypes.arrayOf(PropTypes.string),
}

EditorList.defaultProps = {
  label: null,
  previousValues: null,
  showDiff: true,
  showRemoved: true,
  values: null,
}

export default EditorList
