import { compose, withState, withHandlers } from 'recompose'
import { withFormik } from 'formik'
import { graphql } from '@apollo/client/react/hoc'

import LOGIN_USER from './graphql'
import Login from './Login'

const getNextUrl = () => {
  const url = new URL(window.location.href)
  const redirectLink = '/'
  return `${url.searchParams.get('next') || redirectLink}`
}

const localStorage = window.localStorage || undefined

const handleSubmit = (values, { props, setSubmitting, setErrors }) =>
  props
    .loginUser({ variables: { input: values } })
    .then(({ data, errors }) => {
      if (!errors) {
        localStorage.setItem('token', data.login.token)
        const next = getNextUrl()
        props.history.push(next)
      }
    })
    .catch(e => {
      if (e.graphQLErrors && e.graphQLErrors.length > 0) {
        setSubmitting(false)
        setErrors(e.graphQLErrors[0].message)
      }
    })

const enhancedFormik = withFormik({
  displayName: 'login',
  handleSubmit,
  initialValues: {
    password: '',
    username: '',
  },
  mapPropsToValues: props => ({
    password: props.password,
    username: props.username,
  }),
})(Login)

export default compose(
  graphql(LOGIN_USER, {
    name: 'loginUser',
  }),
  withState('redirectLink', 'loggedIn', null),
  withHandlers({
    onLoggedIn: ({ loggedIn }) => returnUrl => loggedIn(() => returnUrl),
  }),
)(enhancedFormik)
