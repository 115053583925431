import React, { useState } from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'

import { SEND_PASSWORD_RESET_EMAIL } from '../graphql'
import { RequestPasswordReset as Page } from '../../ui'

const RequestPasswordReset = styled(Page)`
  margin: 0 auto;
  width: 40ch;
`

const RequestPasswordResetPage = props => {
  const [recipient, setRecipient] = useState(null)
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)

  const [sendEmail] = useMutation(SEND_PASSWORD_RESET_EMAIL)

  const onSubmit = data => {
    setSending(true)

    const { email } = data

    const mutationData = {
      variables: {
        email,
      },
    }

    sendEmail(mutationData)
      .then(res => {
        setSending(false)
        setError(false)
        setRecipient(email)
      })
      .catch(e => {
        setSending(false)
        setError(e.message)
        setRecipient(null)
      })
  }

  return (
    <RequestPasswordReset
      error={error}
      onEmailSubmit={onSubmit}
      recipient={recipient}
      sending={sending}
    />
  )
}

export default RequestPasswordResetPage
