import { gql } from '@apollo/client'

const SPECIES = gql`
  query Species {
    species {
      id
      label
      value
      imageSrc
      imageAlt
      modLink
      mod
      linkVariable
      description
      externalId
    }
  }
`

const CATEGORIES = gql`
  query Categories {
    categories {
      id
      label
      value
      description
    }
  }
`

const SUBMISSION_TYPES = gql`
  query submissionTypes {
    submissionTypes {
      id
      label
      value
      description
    }
  }
`

const ADD_TAXONOMY = gql`
  mutation AddTaxonomy($taxonomy: TaxonomyInput!) {
    addTaxonomy(taxonomy: $taxonomy)
  }
`

const UPDATE_TAXONOMY = gql`
  mutation UpdateTaxonomy($id: ID!, $taxonomy: TaxonomyInput!) {
    updateTaxonomy(id: $id, taxonomy: $taxonomy)
  }
`

const DELETE_TAXONOMY = gql`
  mutation deleteTaxonomy($id: ID!) {
    deleteTaxonomy(id: $id)
  }
`

export {
  SPECIES,
  CATEGORIES,
  SUBMISSION_TYPES,
  ADD_TAXONOMY,
  UPDATE_TAXONOMY,
  DELETE_TAXONOMY,
}
