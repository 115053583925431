import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid, th } from '../_helpers'
import { H6, List, Ribbon } from '../common'

import Review from './Review'

const Header = styled(H6)`
  color: ${th('colorText')};
  font-weight: bold;
  margin: 0 0 ${grid(1)} 0;
`

const StyledReview = styled(Review)`
  margin-bottom: ${grid(2)};
`

const ReviewList = props => {
  const { label, onClickChat, reviews, showChat, rateReview } = props

  const [notify] = useState(null)

  const getRibbonMessage = status => {
    if (status === 'success') return 'Notification successfully sent'
    if (status === 'error') return 'Something went wrong'
    return null
  }

  const handleClickChat = reviewerId => onClickChat(reviewerId)

  return (
    <div>
      <Header>{label}</Header>

      {(!reviews || reviews.length === 0) &&
        `No reviewers have accepted their invitation for this article yet`}

      {notify && <Ribbon message={getRibbonMessage(notify)} status={notify} />}

      {reviews && reviews.length > 0 && (
        <List
          component={StyledReview}
          items={reviews}
          onClickChat={handleClickChat}
          rateReview={rateReview}
          showChat={showChat}
        />
      )}
    </div>
  )
}

ReviewList.propTypes = {
  label: PropTypes.string,
  onClickChat: PropTypes.func,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      askedToSeeRevision: PropTypes.bool,
      confidentialComments: PropTypes.string,
      content: PropTypes.string,
      openAcknowledgement: PropTypes.bool,
      pending: PropTypes.bool,
      recommendation: PropTypes.oneOf(['accept', 'reject', 'revise']),
      reviewerId: PropTypes.string,
      reviewerName: PropTypes.string,
      reviseQualifier: PropTypes.string,
    }),
  ),
  showChat: PropTypes.bool,
  rateReview: PropTypes.func,
}

ReviewList.defaultProps = {
  label: 'Reviewer feedback',
  onClickChat: null,
  reviews: [],
  showChat: false,
  rateReview: null,
}

export default ReviewList
