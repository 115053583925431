import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

const Separator = styled.div`
  border-top: 2px ${th('borderStyle')} ${th('colorBorder')};
  margin: calc(${th('gridUnit')} * 2);
`

export default Separator
