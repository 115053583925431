/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import { Select, Icon } from '../common'
import { SPECIALTY } from '../_helpers/researchAreas'

const Error = styled.span`
  color: ${th('colorError')};
  padding-left: ${th('gridUnit')};
`

const Info = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  margin-bottom: ${th('gridUnit')};
`

const MetaDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: calc(${th('gridUnit')} * 2);
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const HelpButton = styled.div`
  cursor: pointer;

  svg {
    height: calc(${th('gridUnit')} * 2);
    stroke: ${th('colorFurniture')};
  }
`

const StyledSelect = styled(Select)`
  margin-left: 50px;
  width: 300px;
`

const Metadata = props => {
  const {
    errors,
    isInitialSubmission,
    speciesOptions,
    categoryOptions,
    showCategoryDescriptions,
    submissionTypeOptions,
    setShowOptIn,
    setFieldValue,
    values,
  } = props

  const handleSelect = (type, newValues) => {
    const selectValues = newValues.map(item => item.value)
    setFieldValue(type, selectValues)
  }

  const handleCategories = newValues => {
    if (newValues.find(item => item.value === 'structural biology data'))
      setShowOptIn(true)
    else setShowOptIn(false)
    handleSelect('categories', newValues)
  }

  const handleSpecies = newValues => handleSelect('species', newValues)

  const handleSubmissionTypes = newValues =>
    handleSelect('submissionTypes', newValues)

  const handleTopics = newValues => handleSelect('topics', newValues)

  const currentValues = (items, options) =>
    items && options
      ? items.map(value => options.find(option => option.value === value))
      : []

  const currentCategoriesValues = currentValues(
    values.categories,
    categoryOptions,
  )

  const currentSpeciesValues = currentValues(values.species, speciesOptions)

  const currentSubmissionTypesValues = currentValues(
    values.submissionTypes,
    submissionTypeOptions,
  )

  const currentTopicsValues = currentValues(values.topics, SPECIALTY)

  return (
    <>
      <Info>
        Metadata *
        <Error>
          {errors.species || errors.categories || errors.submissionTypes}
        </Error>
      </Info>

      <MetaDataWrapper>
        <StyledSelect
          closeMenuOnSelect={false}
          isDisabled={!isInitialSubmission}
          isMulti
          onChange={handleSpecies}
          options={speciesOptions}
          placeholder="Select Species"
          value={currentSpeciesValues}
        />
        <RowWrapper>
          <StyledSelect
            closeMenuOnSelect={false}
            isDisabled={!isInitialSubmission}
            isMulti
            onChange={handleCategories}
            options={categoryOptions}
            placeholder="Select Categories"
            value={currentCategoriesValues}
          />
          <HelpButton onClick={showCategoryDescriptions}>
            <Icon title="Click for category descriptions...">help_circle</Icon>
          </HelpButton>
        </RowWrapper>
      </MetaDataWrapper>
      <MetaDataWrapper>
        <StyledSelect
          closeMenuOnSelect={false}
          isDisabled={!isInitialSubmission}
          isMulti
          onChange={handleSubmissionTypes}
          options={submissionTypeOptions}
          placeholder="Select Submission Types"
          value={currentSubmissionTypesValues}
        />
        <StyledSelect
          closeMenuOnSelect={false}
          isDisabled={!isInitialSubmission}
          isMulti
          onChange={handleTopics}
          options={SPECIALTY}
          placeholder="Select Topic"
          value={currentTopicsValues}
        />
      </MetaDataWrapper>
    </>
  )
}

export default Metadata
