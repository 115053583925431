export { default as Article } from './Article.page'
export { default as AssignReviewers } from './AssignReviewers.page'
export { default as RequestPasswordReset } from './RequestPasswordReset.page'
export { default as ResetPassword } from './ResetPassword.page'
export { default as TeamManager } from './TeamManager.page'
export { default as UserManager } from './UserManager.page'
export { default as CurationTool } from './CurationTool.page'
export { default as Statistics } from './Statistics.page'
export { default as TaxonomyManager } from './TaxonomyManager.page'
export { default as Billing } from './Billing.page'
export { default as Payment } from './Payment.page'
export { default as DataReleases } from './DataReleases.page'
export { default as Linker } from './Linker.page'
export { default as News } from './News.page'
export { default as DeclineInvitation } from './DeclineInvitation.page'
export { default as PublishedManuscripts } from './PublishedManuscripts.page'
export { default as WaiverForm } from './WaiverForm.page'
