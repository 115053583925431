/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import WarningModal from './WarningModal'

const EditConfirm = props => (
  <WarningModal
    headerText="Edit Manuscript"
    justifyText
    textSuccess="edit"
    {...props}
  >
    Create new version and edit manuscript?
  </WarningModal>
)

export default EditConfirm
