/* eslint-disable react/prop-types */

import 'regenerator-runtime/runtime'

import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { Root } from '@pubsweet/client'

import routes from './routes'
import theme from './theme'
import { CurrentUserProvider } from './userContext'

const rootEl = document.getElementById('root')

const App = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  return (
    <CurrentUserProvider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserProvider>
  )
}

ReactDOM.render(
  <App>
    <Root routes={routes} theme={theme} />
  </App>,
  rootEl,
)

export default Root
