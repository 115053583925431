/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { components } from 'react-select'

import { Icon, LoaderInline, Select, Status } from '../common'
import { grid, th } from '../_helpers'

const Wrapper = styled.div``

const StyledSelect = styled(Select)`
  .react-select__control {
    border: 0;
    box-shadow: ${th('colorBorder')} 0 0 0 1px;
    padding: 0 ${grid(1)};
    transition: box-shadow 0.2s ease-in;
  }

  .react-select__control--is-focused {
    box-shadow: ${th('colorPrimary')} 0 0 0 2px;
  }

  .react-select__menu {
    margin-top: 4px;
  }
`
/*
const Status = styled.span`
  background: ${th('colorFurniture')};
  border-radius: 3px;
  color: ${th('colorTextReverse')};
  font-size: ${th('fontSizeBaseSmall')};
  margin-left: 16px;
  padding: 4px 8px;
  text-transform: uppercase;
`
*/

const StyledStatus = styled(Status)`
  margin-left: 16px;
`

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon color="#AAA">search</Icon>
  </components.DropdownIndicator>
)

const LoadingIndicator = props => <LoaderInline color="colorPrimary" size={2} />

/* eslint-disable react/prop-types */
const Option = props => {
  const { data, children, ...rest } = props
  const { status, numInvitations, reviewDate, invitationDate } = data

  const statusColors = {
    'do not ask': 'error',
    'currently reviewing': 'warning',
    disabled: 'error',
  }

  return (
    <components.Option {...rest}>
      {children}
      {status && (
        <StyledStatus reverseColors status={statusColors[status]}>
          {status}
        </StyledStatus>
      )}
      {
        <>
          <StyledStatus
            reverseColors
          >{`Invitations over past year: ${numInvitations}`}</StyledStatus>
          <StyledStatus
            reverseColors
          >{`Last review: ${reviewDate}`}</StyledStatus>
          <StyledStatus
            reverseColors
          >{`Last invitation: ${invitationDate}`}</StyledStatus>
        </>
      }
    </components.Option>
  )
}
/* eslint-enable react/prop-types */

const SearchBox = props => {
  const { className, onClickOption, search, placeholder } = props

  const handleChange = (val, action) => {
    if (action.action === 'select-option') {
      onClickOption(val)
    }
  }

  return (
    <Wrapper className={className}>
      <StyledSelect
        async
        components={{ DropdownIndicator, LoadingIndicator, Option }}
        loadOptions={search}
        onChange={handleChange}
        placeholder={placeholder}
        value={null}
        // menuIsOpen
      />
    </Wrapper>
  )
}

SearchBox.propTypes = {
  /** Function to run on selecting one of the options */
  onClickOption: PropTypes.func.isRequired,
  /** Function that returns a promise. Must resolve to an array objects, each with shape `{ value: <String>, label: <String>, isDisabled: <Boolean>, status: <String>}` */
  search: PropTypes.func.isRequired,
  /** Placeholder text inside input area */
  placeholder: PropTypes.string,
}

SearchBox.defaultProps = {
  placeholder: 'Search',
}

export default SearchBox
