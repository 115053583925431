import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'
import clone from 'lodash/clone'

import { grid, th } from '../_helpers'

import { Button, Radio, List, TextEditor, TextField, Form } from '../common'

const InfoRow = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  text-transform: uppercase;
`

const Header = styled.div`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
  display: flex;
  margin-bottom: ${grid(1)};
`

const Wrapper = styled.div`
  display: flex;
`

const validations = yup.object().shape({
  correctionType: yup.string().required('You need to select a correction type'),
  description: yup
    .string()
    .required('You need to write a description of the correction'),
  doi: yup.string().required('You need to set the DOI'),
  dbReferenceId: yup.string(),
})

const radioOptions = [
  {
    label: 'Corrigendum',
    value: 'corrigendum',
  },
  {
    label: 'Erratum',
    value: 'erratum',
  },
  {
    label: 'Retraction',
    value: 'retraction',
  },
]

const Correction = props => {
  const {
    correctionType,
    description,
    doi,
    date,
    dbReferenceId,
    editCorrection,
    editing,
    setEditing,
  } = props

  const displayDate = new Date(date).toLocaleDateString()

  const handleSubmit = values => {
    editCorrection({
      correctionType: values.correctionType,
      description: values.description,
      doi: values.doi,
      date: values.date,
      dbReferenceId: values.dbReferenceId,
    }).then(() => {
      setEditing(null)
    })
  }

  const currentValues = {
    correctionType,
    doi,
    dbReferenceId,
    date,
    description,
  }

  if (editing === doi) {
    return (
      <Form
        initialValues={currentValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {formProps => {
          const {
            errors,
            handleBlur,
            handleChange,
            setFieldTouched,
            setFieldValue,
            touched,
            values,
          } = formProps

          return (
            <>
              <Radio
                error={errors.correctionType}
                inline
                label="Correction Type"
                name="correctionType"
                options={radioOptions}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
              />
              <TextEditor
                bold
                error={errors.description}
                italic
                name="description"
                placeholder="Description"
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                value={values.description}
              />
              <Wrapper>
                <TextField
                  error={errors.doi}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  name="doi"
                  placeholder="DOI"
                  readOnly
                  touched={touched}
                  value={values.doi}
                />
              </Wrapper>
              <TextField
                error={errors.dbReferenceId}
                handleBlur={handleBlur}
                handleChange={handleChange}
                name="dbReferenceId"
                placeholder="DB Reference ID"
                touched={touched}
                value={values.dbReferenceId}
              />
              <Button primary type="submit">
                Save Correction
              </Button>
            </>
          )
        }}
      </Form>
    )
  }

  return (
    <>
      <Header>
        <InfoRow>{correctionType}</InfoRow>
      </Header>
      <InfoRow>DOI: {doi}</InfoRow>
      <InfoRow>Date Submitted: {displayDate}</InfoRow>
      <InfoRow>DB Reference ID: {dbReferenceId}</InfoRow>
      <TextEditor bold italic link readOnly sup value={description} />
      <Button onClick={() => setEditing(doi)}>Edit</Button>
    </>
  )
}

const CorrectionList = props => {
  const { corrections, editing, editCorrection, setEditing } = props
  if (!corrections) return null

  const correctionsWithEdit = corrections.map(c => {
    const newCorrection = clone(c)

    newCorrection.editCorrection = editCorrection
    newCorrection.editing = editing
    newCorrection.setEditing = setEditing

    return newCorrection
  })

  return (
    <>
      <List component={Correction} itemKey="doi" items={correctionsWithEdit} />
    </>
  )
}

const CorrectionSection = props => {
  const { corrections, createDoi, editCorrection, submitCorrection } = props

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [editorKey, setEditorKey] = useState(0)
  const [editing, setEditing] = useState(null)

  const handleSubmit = (values, { resetForm }) => {
    setSubmitLoading(true)

    submitCorrection({
      correctionType: values.correctionType,
      description: values.description,
      doi: values.doi,
      date: new Date().toDateString(),
      dbReferenceId: values.dbReferenceId,
    }).then(() => {
      setSubmitLoading(false)
      resetForm()
      setEditorKey(editorKey + 1)
    })
  }

  const initialValues = {
    correctionType: '',
    description: '',
    doi: '',
    dbReferenceId: '',
  }

  return (
    <>
      <CorrectionList
        corrections={corrections}
        editCorrection={editCorrection}
        editing={editing}
        setEditing={setEditing}
      />
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {formProps => {
          const {
            errors,
            handleBlur,
            handleChange,
            setFieldTouched,
            setFieldValue,
            touched,
            values,
          } = formProps

          const setDoiField = async () => {
            setLoading(true)
            const newDoi = await createDoi()
            setFieldValue('doi', newDoi)
            setLoading(false)
          }

          return (
            <>
              <Radio
                error={errors.correctionType}
                inline
                key={`radio-${editorKey}`}
                label="Correction Type"
                name="correctionType"
                options={radioOptions}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
              />
              <TextEditor
                bold
                error={errors.description}
                italic
                key={`editor-${editorKey}`}
                name="description"
                placeholder="Description"
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                touched={touched}
                value={values.description}
              />
              <Wrapper>
                <TextField
                  error={errors.doi}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  name="doi"
                  placeholder="DOI"
                  touched={touched}
                  value={values.doi}
                />
                <Button loading={loading} onClick={setDoiField}>
                  Fetch DOI
                </Button>
              </Wrapper>
              <TextField
                error={errors.dbReferenceId}
                handleBlur={handleBlur}
                handleChange={handleChange}
                name="dbReferenceId"
                placeholder="DB Reference ID"
                touched={touched}
                value={values.dbReferenceId}
              />
              <Button loading={submitLoading} primary type="submit">
                Submit Correction
              </Button>
            </>
          )
        }}
      </Form>
    </>
  )
}

Correction.propTypes = {
  correctionType: PropTypes.string,
  description: PropTypes.string,
  doi: PropTypes.string,
  date: PropTypes.string,
  dbReferenceId: PropTypes.string,
  editCorrection: PropTypes.func,
  editing: PropTypes.string,
  setEditing: PropTypes.func,
}

Correction.defaultProps = {
  correctionType: '',
  description: '',
  doi: '',
  date: '',
  dbReferenceId: '',
  editCorrection: null,
  editing: null,
  setEditing: null,
}

CorrectionList.propTypes = {
  corrections: PropTypes.arrayOf(
    PropTypes.shape({
      correctionType: PropTypes.string,
      description: PropTypes.string,
      doi: PropTypes.string,
      date: PropTypes.string,
      dbReferenceId: PropTypes.string,
    }),
  ),
  editing: PropTypes.string,
  editCorrection: PropTypes.func,
  setEditing: PropTypes.func,
}

CorrectionList.defaultProps = {
  corrections: null,
  editing: null,
  editCorrection: null,
  setEditing: null,
}

CorrectionSection.propTypes = {
  corrections: PropTypes.arrayOf(
    PropTypes.shape({
      correctionType: PropTypes.string,
      description: PropTypes.string,
      doi: PropTypes.string,
      date: PropTypes.string,
      dbReferenceId: PropTypes.string,
    }),
  ),
  createDoi: PropTypes.func,
  submitCorrection: PropTypes.func,
  editCorrection: PropTypes.func,
}

CorrectionSection.defaultProps = {
  corrections: [],
  createDoi: null,
  submitCorrection: null,
  editCorrection: null,
}

export default CorrectionSection
