/* eslint-disable react/prop-types */

import React from 'react'
import styled, { css } from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import DashboardItemDate from './DashboardItemDate'
import StatusItem from './StatusItem'
import SectionItem from './SectionItem'

const StatusRowWrapper = styled.div`
  display: flex;
`

const TeamRow = styled.div`
  display: flex;
`

const TeamLabel = styled.div`
  margin-right: 8px;

  &:after {
    border: 1px solid ${th('colorBorder')};
    color: ${th('colorBorder')};
    content: 'TEAM';
    font-family: ${th('fontInterface')};
    font-size: ${th('fontSizeBaseSmall')};
    padding: 2px 6px;
  }
`

const TeamValuesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const dotAfter = css`
  &:after {
    color: ${th('colorBorder')};
    content: '\\2022';
    padding: 0 ${th('gridUnit')};
  }
`

const NameWrapper = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  ${props => !props.last && dotAfter}
`

const Label = styled.span`
  color: ${th('colorBorder')};
  margin-right: calc(${th('gridUnit')} / 2);
  text-transform: capitalize;

  &:after {
    content: ':';
  }
`

const DashboardName = props => {
  const { last, name, label } = props

  return (
    name && (
      <NameWrapper last={last}>
        <Label>{label}</Label>
        {name}
      </NameWrapper>
    )
  )
}

const StatusRow = props => {
  const {
    author,
    curator,
    curatorStatus,
    displayStatus,
    correctionStatus,
    editor,
    scienceOfficer,
    sectionEditor,
    updated,
  } = props

  const authorValue = author ? `${author.firstName} ${author.lastName}` : ''
  const editorValue = editor ? editor.displayName : ''
  const sectionEditorValue = sectionEditor ? sectionEditor.displayName : ''
  const scienceOfficerValue = scienceOfficer ? scienceOfficer.displayName : ''

  const curatorValue =
    curator && curator.length > 0
      ? curator.map(user => user.displayName).join(', ')
      : ''

  return (
    <>
      <StatusRowWrapper>
        <StatusItem label={displayStatus} />
        <StatusItem label={curatorStatus} />
        {correctionStatus &&
          correctionStatus.map(correction => (
            <StatusItem key={correction} label={correction} />
          ))}
        {updated && <DashboardItemDate label="Last updated" value={updated} />}
        {author && (
          <DashboardName label="submitting author" last name={authorValue} />
        )}
      </StatusRowWrapper>

      {(editorValue ||
        sectionEditorValue ||
        scienceOfficerValue ||
        curatorValue) && (
        <TeamRow>
          <TeamLabel />
          <TeamValuesWrapper>
            {editor && (
              <DashboardName
                label="managing editor"
                last={
                  !sectionEditorValue && !scienceOfficerValue && !curatorValue
                }
                name={editorValue}
              />
            )}

            {sectionEditor && (
              <DashboardName
                label="section editor"
                last={!scienceOfficerValue && !curatorValue}
                name={sectionEditorValue}
              />
            )}

            {scienceOfficer && (
              <DashboardName
                label="science officer"
                last={!curatorValue}
                name={scienceOfficerValue}
              />
            )}

            {curator && (
              <DashboardName label="curator" last name={curatorValue} />
            )}
          </TeamValuesWrapper>
        </TeamRow>
      )}
    </>
  )
}

const SectionItemWithStatus = props => {
  const {
    actionsComponent,
    author,
    curator,
    curatorStatus,
    displayStatus,
    correctionStatus,
    editor,
    scienceOfficer,
    sectionEditor,
    title,
    updated,
  } = props

  return (
    <>
      <StatusRow
        author={author}
        correctionStatus={correctionStatus}
        curator={curator}
        curatorStatus={curatorStatus}
        displayStatus={displayStatus}
        editor={editor}
        scienceOfficer={scienceOfficer}
        sectionEditor={sectionEditor}
        updated={updated}
      />
      <SectionItem rightComponent={actionsComponent} title={title} />
    </>
  )
}

export default SectionItemWithStatus
