import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'

import { th } from '@pubsweet/ui-toolkit'

import {
  Loader,
  PageHeader as DefaultPageHeader,
  Button,
  Form,
  List,
  TextEditor,
} from '../common'
import NewsSectionItem from './NewsSectionItem'
import Image from '../submissionForm/Image'

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const PageHeader = styled(DefaultPageHeader)`
  margin-block-end: ${th('gridUnit')};
`

const FormWrapper = styled.div`
  border-bottom: 1px solid black;
  margin-bottom: 5px;
`

const StyledButton = styled(Button)`
  margin-bottom: 5px;
`

const validations = yup.object().shape({
  title: yup.string().required('Title cannot be empty'),
  content: yup.string().required('Content cannot be empty'),
  image: yup.object().shape({ name: yup.string(), url: yup.string() }),
})

const NewsManager = props => {
  const { loading, news, addNews, upload } = props

  const [showAddNews, setShowAddNews] = useState(false)

  const initialValues = {
    title: '',
    content: '',
    image: { name: '' },
    publish: false,
  }

  const handleSubmit = (values, formikBag) => {
    addNews(values).then(() => setShowAddNews(false))
  }

  if (loading) return <Loader />

  return (
    <PageWrapper>
      <PageHeader>News Manager</PageHeader>
      <StyledButton onClick={() => setShowAddNews(true)}>
        Add News Item
      </StyledButton>
      {showAddNews && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => {
            const {
              errors,
              setFieldValue,
              setFieldTouched,
              touched,
              values,
            } = formProps

            return (
              <FormWrapper>
                <TextEditor
                  error={errors.title}
                  italic
                  label="Title"
                  name="title"
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  value={values.title}
                />

                <TextEditor
                  bold
                  error={errors.content}
                  italic
                  label="Content"
                  link
                  name="content"
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  underline
                  value={values.content}
                />

                <Image
                  name="image"
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  upload={upload}
                  values={values}
                />

                <StyledButton onClick={() => setShowAddNews(false)}>
                  Cancel
                </StyledButton>
                <StyledButton primary type="submit">
                  Save Draft
                </StyledButton>
              </FormWrapper>
            )
          }}
        </Form>
      )}
      {news && news.length > 0 && (
        <List component={NewsSectionItem} items={news} />
      )}
      {!news || (news.length === 0 && <>No news items available</>)}
    </PageWrapper>
  )
}

NewsManager.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The lists of items to display */
  news: PropTypes.arrayOf(PropTypes.object.isRequired),
  addNews: PropTypes.func,
  upload: PropTypes.func,
}

NewsManager.defaultProps = {
  loading: false,
  news: [],
  addNews: null,
  upload: null,
}

export default NewsManager
