import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/client'

import { grid, th } from '../_helpers'
import Password from './Password'
import PersonalInfo from './PersonalInfo'
import Username from './Username'
import UserProfileSection from './UserProfileSection'
import SEARCH_SPECIES from '../../../app/graphql/species'

import { Loader, PageHeader } from '../common'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;

  > div:not(:last-child) {
    border-bottom: 1px solid ${th('colorFurniture')};
    padding-bottom: ${grid(3)};
  }
`

const StyledPageHeader = styled(PageHeader)`
  margin-top: 0;
`

const UserProfile = props => {
  const {
    givenNames,
    loading,
    orcid,
    surname,
    reviewer,
    species,
    researchArea,
    specialty,
    updatePassword,
    updatePersonalInformation,
    updateUsername,
    username,
  } = props

  if (loading) return <Loader />

  const client = useApolloClient()

  const searchSpecies = async input => {
    const res = await client.query({
      query: SEARCH_SPECIES,
      fetchPolicy: 'network-only',
      variables: {
        search: input,
        limit: 10,
      },
    })

    if (res.data && res.data.searchSpecies) {
      return res.data.searchSpecies.map(value => ({
        value: value.scientificName,
        label: value.scientificName,
      }))
    }

    return []
  }

  return (
    <Wrapper>
      <StyledPageHeader>User Profile</StyledPageHeader>

      <UserProfileSection
        headerText="Personal Information"
        successMessage="Personal information successfully updated"
        update={updatePersonalInformation}
      >
        {update => (
          <PersonalInfo
            givenNames={givenNames}
            orcid={orcid}
            researchArea={researchArea}
            reviewer={reviewer}
            searchSpecies={searchSpecies}
            specialty={specialty}
            species={species}
            surname={surname}
            update={update}
          />
        )}
      </UserProfileSection>

      <UserProfileSection
        headerText="Username"
        successMessage="Username successfully updated"
        update={updateUsername}
      >
        {update => <Username update={update} username={username} />}
      </UserProfileSection>

      <UserProfileSection
        headerText="Password"
        successMessage="Password successfully updated"
        update={updatePassword}
      >
        {update => <Password update={update} />}
      </UserProfileSection>
    </Wrapper>
  )
}

UserProfile.propTypes = {
  givenNames: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  orcid: PropTypes.string,
  reviewer: PropTypes.bool,
  species: PropTypes.string,
  researchArea: PropTypes.string,
  specialty: PropTypes.string,
  surname: PropTypes.string.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updatePersonalInformation: PropTypes.func.isRequired,
  updateUsername: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

UserProfile.defaultProps = {
  loading: false,
  orcid: null,
  species: null,
  reviewer: false,
  researchArea: null,
  specialty: null,
}

export default UserProfile
