/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useAcceptJs } from 'react-acceptjs'
import CreditCardInput from 'react-credit-card-input'
import { IconButton, Button, Icon } from '@pubsweet/ui'
import clone from 'lodash/clone'
import styled from 'styled-components'
import { th, fadeIn } from '@pubsweet/ui-toolkit'
import * as yup from 'yup'
import { serverUrl } from '@coko/client'

import { TextField, Select, Form } from '../common'

const authData = {
  apiLoginID: process.env.CLIENT_AUTHORIZENET_LOGIN,
  clientKey: process.env.CLIENT_AUTHORIZENET_KEY,
}

const pdfUrl = process.env.CLIENT_PDF_URL

const environment =
  process.env.NODE_ENV === 'production' ? 'PRODUCTION' : 'SANDBOX'

const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'The Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo, Democratic Republic of the',
  'Congo, Republic of the',
  'Costa Rica',
  'Côte d’Ivoire',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'The Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea, North',
  'Korea, South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Sudan, South',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
].map(c => ({ value: c, label: c }))

const InputWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  text-align: left;
`

const FieldWrapper = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
`

const SelectLabel = styled.div`
  display: block;
  font-size: 14px;
  line-height: 16px;
`

const SelectWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-right: ${th('gridUnit')};
  width: calc(${th('gridUnit')} * 50);
`

const StyledTextField = styled(TextField)`
  margin-right: ${th('gridUnit')};
`

const Text = styled.div`
  color: ${th('colorPrimary')};
  display: inline-block;
  font-size: 18px;
  padding: 8px 0;
`

const StyledIcon = styled(Icon)`
  align-self: center;
  margin-bottom: calc(${th('gridUnit')} * 3);
  margin-top: calc(${th('gridUnit')} * 25);

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  animation: ${fadeIn} 0.5s;
`

const Info = styled.div`
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeHeading6')};
  text-align: center;
  width: 100%;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  animation: ${fadeIn} 0.5s;
`

const Error = styled(Info)`
  color: ${th('colorError')};
`

const IconComponent = ({ iconName }) => {
  const color =
    iconName === 'alert_circle' ? th('colorError') : th('colorSuccess')

  return (
    <StyledIcon color={color} size={8}>
      {iconName}
    </StyledIcon>
  )
}

const validations = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  country: yup.string(),
  zip: yup.string(),
  address: yup
    .string()
    .test(
      'is less than 60 characters',
      'Address cannot be greater than 60 characters',
      val => val.length <= 60,
    ),
  state: yup.string(),
  phone: yup.string(),
})

const AcceptJs = props => {
  const { handlePayment, amount, manuscriptId, email } = props

  const { dispatchData, loading, error } = useAcceptJs({
    authData,
    environment,
  })

  const [cardData, setCardData] = useState({
    cardNumber: '',
    exp: '',
    cardCode: '',
  })

  const [country, setCountry] = useState('')

  const [status, setStatus] = useState('unpaid')
  const [receiptUrl, setReceiptUrl] = useState(null)

  const handleSubmit = async values => {
    // Dispatch CC data to Authorize.net and receive payment nonce for use on server
    const expSplit = cardData.exp.split(' / ')

    try {
      const response = await dispatchData({
        cardData: {
          ...cardData,
          cardNumber: cardData.cardNumber.replace(/\s/g, ''),
          month: expSplit[0],
          year: expSplit[1],
        },
      })

      await handlePayment(
        values,
        response.opaqueData.dataValue,
        manuscriptId,
        amount,
      ).then(async res => {
        const result = await res.json()

        if (result.transactionResponse.responseCode === '1') {
          const {
            authCode,
            transId,
            accountType,
            accountNumber,
          } = result.transactionResponse

          setStatus(['paid', accountNumber])
          setReceiptUrl(
            `${pdfUrl}${serverUrl}/api/export/${manuscriptId}/receipt/${transId}/${authCode}/${accountType}/${accountNumber}`,
          )
        } else {
          setStatus([
            'failure',
            result.transactionResponse.errors.error.map(e => e.errorText),
          ])
        }
      })
    } catch (e) {
      setStatus(['failure', e.messages.message.map(m => m.text)])
    }
  }

  const handleCardChange = (type, value) => {
    const newCardData = clone(cardData)
    newCardData[type] = value
    setCardData(newCardData)
  }

  const handleCodeChange = ev => handleCardChange('cardCode', ev.target.value)

  const handleExpiryChange = ev => handleCardChange('exp', ev.target.value)

  const handleNumberChange = ev =>
    handleCardChange('cardNumber', ev.target.value)

  const initialValues = {
    firstName: '',
    lastName: '',
    country: '',
    zip: '',
    address: '',
    state: '',
    phone: '',
    city: '',
  }

  return (
    <InputWrapper>
      {status === 'unpaid' && (
        <>
          <Text>Credit Card</Text>
          <CreditCardInput
            cardCVCInputProps={{
              value: cardData.cardCode,
              onChange: handleCodeChange,
            }}
            cardExpiryInputProps={{
              value: cardData.exp,
              onChange: handleExpiryChange,
            }}
            cardNumberInputProps={{
              value: cardData.cardNumber,
              onChange: handleNumberChange,
            }}
          />

          <Text>Billing Address</Text>

          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validations}
          >
            {formProps => {
              const { errors, handleChange, setFieldValue, values } = formProps

              const handleCountryChange = newCountry => {
                setCountry(newCountry)
                setFieldValue('country', newCountry.value)
              }

              return (
                <>
                  <FieldWrapper>
                    <StyledTextField
                      handleChange={handleChange}
                      label="First Name"
                      name="firstName"
                      value={values.firstName}
                    />
                    <TextField
                      handleChange={handleChange}
                      label="Last Name"
                      name="lastName"
                      value={values.lastName}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <SelectWrapper>
                      <SelectLabel>Country</SelectLabel>
                      <Select
                        name="country"
                        onChange={handleCountryChange}
                        options={countries}
                        value={country}
                      />
                    </SelectWrapper>
                    <TextField
                      handleChange={handleChange}
                      label="Zip"
                      name="zip"
                      value={values.zip}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <StyledTextField
                      handleChange={handleChange}
                      label="Street Address"
                      name="address"
                      value={values.address}
                    />
                    <TextField
                      handleChange={handleChange}
                      label="City"
                      name="city"
                      value={values.city}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <StyledTextField
                      handleChange={handleChange}
                      label="State"
                      name="state"
                      value={values.state}
                    />
                    <TextField
                      handleChange={handleChange}
                      label="Phone Number"
                      name="phone"
                      value={values.phone}
                    />
                  </FieldWrapper>
                  {errors.address && <Error>{errors.address}</Error>}
                  <IconButton
                    disabled={loading || error}
                    icon="credit-card"
                    primary
                    type="Submit"
                  >
                    Pay {amount && `$${amount}`}
                  </IconButton>
                </>
              )
            }}
          </Form>
        </>
      )}
      {status[0] === 'paid' && (
        <>
          <IconComponent iconName="check_circle" />
          <Info>Payment Successful for Account {status[1]}</Info>
          <Info>Receipt Sent to {email}</Info>
          <Info>
            <Button onClick={() => window.open(receiptUrl)}>
              Download Receipt
            </Button>
          </Info>
        </>
      )}
      {status[0] === 'failure' && (
        <>
          <IconComponent iconName="alert_circle" />

          {status[1].map(s => (
            <Info key={s}>{s}</Info>
          ))}

          <Info>
            <Button onClick={() => setStatus('unpaid')}>BACK</Button>
          </Info>
        </>
      )}
    </InputWrapper>
  )
}

export default AcceptJs
