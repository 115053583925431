import React from 'react'
import PropTypes from 'prop-types'

import { Ribbon } from '../common'

const options = {
  default: {
    message: 'Datatype selection pending',
    status: null,
  },
  decisionPending: {
    message: 'Editor decision pending',
    status: null,
  },
  accept: {
    message: 'This version has been accepted to proofs',
    status: 'success',
  },
  decline: {
    message: 'This article has been declined',
    status: 'error',
  },
  publish: {
    message: 'This article has been published',
    status: 'success',
  },
  reject: {
    message: 'This article has been rejected',
    status: 'error',
  },
  withdraw: {
    message: 'This article has been withdrawn',
    status: 'error',
  },
  revise: {
    message: 'This version was marked for revision',
    status: 'warning',
  },
  edit: {
    message: 'This version is being edited by an editor',
    status: 'warning',
  },
  curation: {
    message: 'This version has been assigned to a 3rd party reviewer',
    status: 'warning',
  },
  retract: {
    message: 'This article has been retracted',
    status: 'error',
  },
}

const EditorPanelRibbon = props => {
  const { dataType, decision } = props

  const type = dataType ? decision || 'decisionPending' : 'default'
  const { message, status } = options[type]

  return <Ribbon message={message} status={status} />
}

EditorPanelRibbon.propTypes = {
  dataType: PropTypes.string,
  decision: PropTypes.oneOf([
    'accept',
    'decline',
    'reject',
    'revise',
    'publish',
    'edit',
    'withdraw',
    'retract',
  ]),
}

EditorPanelRibbon.defaultProps = {
  dataType: null,
  decision: null,
}

export default EditorPanelRibbon
