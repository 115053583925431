import uniq from 'lodash/uniq'
import last from 'lodash/last'

import {
  getInvitedReviewersTeam,
  getManuscriptTeam,
  getReviewerCounts,
  getSubmittingAuthor,
  // membersOfTeam,
  transformChatMessages,
  transformCuratorReviews,
  transformReviews,
} from './common'

/**
 * HELPER FUNCTIONS
 */
const authorIdsOfAllVersions = versions => {
  if (!versions) return null

  const authorIds = []

  versions.forEach(version => {
    const authorTeam = version.teams.find(t => t.role === 'author')
    authorTeam.members.forEach(member => {
      if (!authorIds.includes(member.user.id)) authorIds.push(member.user.id)
    })
  })

  return authorIds
}

const disableAuthors = (userList, authorIds) => {
  return userList.map(user => ({
    isDisabled: authorIds.includes(user.id),
    ...user,
    id: user.id.replace(`${user.role}-`, ''),
  }))
}

const initials = name => {
  const matches = name.match(/\b([A-Z])/g)
  return matches && matches.join('')
}

const transformUserManuscripts = (teams, manuscriptId) => {
  const authorManuscripts = []

  const statuses = [
    'rejectedInvitation',
    'invited',
    'invitationRevoked',
    'declinedInvitation',
    'acceptedInvitation',
  ]

  teams.forEach(t => {
    if (t.role === 'author') {
      t.members.forEach(m => {
        m.user.manuscripts.forEach(a => {
          const { id, versions } = a
          let numInvites = 0

          if (id !== manuscriptId) {
            const reviewers = []
            versions.forEach(v => {
              v.reviews.forEach(r => {
                if (r.status.submitted) {
                  const reviewer = r.openAcknowledgement
                    ? `Reviewer: ${r.reviewer.displayName}`
                    : `Anonymous reviewer: ${r.reviewer.displayName}`

                  reviewers.push(reviewer)
                }
              })
              v.teams.forEach(team => {
                if (team.role === 'reviewer') {
                  team.members.forEach(member => {
                    if (statuses.includes(member.status)) {
                      numInvites += 1
                    }
                  })
                }
              })
            })

            const { title, authors, decision } = last(versions)
            const firstAuthor = `${authors[0].lastName} et al.,`

            authorManuscripts.push({
              manuscriptId: id,
              title: `${firstAuthor} ${title
                .replace('<p>', '')
                .replace('</p>', '')}.`,
              reviewers: uniq(reviewers),
              numInvites,
              decision,
            })
          }
        })
      })
    }
  })

  return authorManuscripts
}

/**
 * END HELPER FUNCTIONS
 */

export default (data, mutations) => {
  const { manuscript, getGlobalTeamsForEditor: globalTeams } = data

  const {
    categories,
    citations,
    corrections,
    chatThreads,
    dataRelease,
    dataType,
    dbReferenceId,
    doi,
    history,
    isInitiallySubmitted,
    isDataTypeSelected,
    ithenticate,
    integrations,
    pmId,
    pmcId,
    proteopedia,
    reviewPanel,
    species,
    submissionTypes,
    topics,
    versions: dataVersions,
    paymentStatus,
  } = manuscript

  const {
    finalizeDoi,
    reinviteReviewerMutation,
    sendChatMutation,
    setDataTypeMutation,
    setProteopediaReadyMutation,
    submitDecisionMutation,
    updateManuscriptTeamsMutation,
    updateMetadataMutation,
    addCorrectionMutation,
    editCorrectionMutation,
    publishCorrectionDoi,
    updateEntitiesMutation,
    updateHistoryMutation,
    updatePaymentStatusMutation,
    updateIntegrationsMutation,
    publishData,
  } = mutations

  /**
   * Build global team member lists
   */

  // const globalEditors = membersOfTeam(globalTeams, 'editors')
  // const globalScienceOfficers = membersOfTeam(globalTeams, 'scienceOfficers')
  // const globalCurators = membersOfTeam(globalTeams, 'globalCurator')
  // const globalSectionEditors = membersOfTeam(globalTeams, 'globalSectionEditor')

  const globalEditors = globalTeams.filter(team => team.role === 'editors')

  const globalScienceOfficers = globalTeams.filter(
    team => team.role === 'scienceOfficers',
  )

  const globalCurators = globalTeams.filter(
    team => team.role === 'globalCurator',
  )

  const globalSectionEditors = globalTeams.filter(
    team => team.role === 'globalSectionEditor',
  )

  // pass through all members to disable authors
  // (eg. cannot be the editor of the manuscript you wrote)
  const authorIds = authorIdsOfAllVersions(dataVersions)

  const scienceOfficers = disableAuthors(globalScienceOfficers, authorIds)
  const curators = disableAuthors(globalCurators, authorIds)
  const editors = disableAuthors(globalEditors, authorIds)
  const sectionEditors = disableAuthors(globalSectionEditors, authorIds)

  /**
   * Manuscript-level teams
   */

  const manuscriptCuratorTeam = manuscript.teams.find(t => t.role === 'curator')
  const manuscriptEditorTeam = manuscript.teams.find(t => t.role === 'editor')

  const manuscriptSectionEditorTeam = manuscript.teams.find(
    t => t.role === 'sectionEditor',
  )

  const manuscriptSOTeam = manuscript.teams.find(
    t => t.role === 'scienceOfficer',
  )

  const {
    curator: assignedCurator,
    editor: assignedEditor,
    sectionEditor: assignedSectionEditor,
    scienceOfficer: assignedScienceOfficer,
  } = getManuscriptTeam(manuscript.teams)

  /**
   * Build chat data
   */

  const chatData = chatThreads.map(thread => ({
    id: thread.id,
    chatType: thread.chatType,
    reviewerId: thread.userId,
    messages: transformChatMessages(thread.messages),
  }))

  /**
   * Build version specific data
   */

  const filteredVersions = dataVersions.filter(
    v =>
      (!v.submitted && isInitiallySubmitted && !isDataTypeSelected) ||
      v.submitted,
  )

  const versions = filteredVersions.map((version, index) => {
    const {
      authors,
      confidentialComments,
      created,
      curatorReviews,
      decision,
      decisionLetter,
      editorEdit,
      extendedData,
      id,
      reviews,
      submitted,
      teams,
      title,
      proteopediaOptIn,
      updated,
    } = version

    const latest = index === filteredVersions.length - 1

    const { authorEmail, authorName } = getSubmittingAuthor(authors)
    const invitedReviewers = getInvitedReviewersTeam(teams)
    const reviewerCounts = getReviewerCounts(teams)
    const versionReviews = transformReviews(reviews)
    const curatorReview = transformCuratorReviews(curatorReviews)

    const authorArticles = transformUserManuscripts(
      version.teams,
      manuscript.id,
    )

    const previousReviewers = []

    if (latest && filteredVersions.length > 1) {
      // loop backwards in the array so that you get latest reviews
      // of the same reviewer first
      let currentIndex = index - 1

      while (currentIndex >= 0) {
        filteredVersions[currentIndex].reviews.forEach(review => {
          const invited = invitedReviewers.find(
            r => r.user.id === review.reviewer.id,
          )

          if (
            // exclude reviewers already added to the array
            !previousReviewers.find(
              reviewer => reviewer.id === review.reviewer.id,
            ) &&
            // exclude reviewers that never submitted
            review.status.submitted
          ) {
            previousReviewers.push({
              id: review.reviewer.id,
              displayName: review.reviewer.displayName,
              recommendation: review.recommendation,
              invited,
            })
          }
        })
        currentIndex -= 1
      }
    }

    let authorsString = ''

    if (authors.length > 8) {
      const lastAuthor = authors[authors.length - 1]
      authorsString = `${authors
        .slice(0, 6)
        .map(author => `${author.lastName}, ${initials(author.firstName)}`)
        .join('; ')}; et al.; ${lastAuthor.lastName}, ${initials(
        lastAuthor.firstName,
      )}`
    } else {
      authorsString = authors
        .map(author => {
          const firstInitials = initials(author.firstName)
          return firstInitials
            ? `${author.lastName}, ${firstInitials}`
            : `${author.firstName} ${author.lastName}`
        })
        .join('; ')
    }

    // Published date might not be set yet, so use current date
    const year = new Date().getFullYear()

    const titleWithoutP = title.replace('<p>', '').replace('</p>', '')

    const citation = `${authorsString} (${year}). ${titleWithoutP}. microPublication Biology. <a href="https://doi.org/${doi}">${doi}</a>.`

    return {
      id,
      created,
      latest,
      updated,

      confidentialComments,
      dataType,
      decision,
      decisionLetter,
      editorEdit,
      extendedData,
      title,
      citation,
      proteopediaOptIn,

      authorEmail,
      authorName,
      authorArticles,

      invitedReviewersCount: reviewerCounts.invited,
      acceptedReviewersCount: reviewerCounts.accepted,
      rejectedReviewersCount: reviewerCounts.rejected,
      revokedReviewersCount: reviewerCounts.revoked,
      invitedReviewers,

      curatorReview,
      reviews: versionReviews,
      previousReviewers,
      submitted,
    }
  })

  /**
   * Mutations
   */

  const reinviteReviewer = (versionId, reviewerId) =>
    reinviteReviewerMutation({
      variables: {
        input: {
          manuscriptVersionId: versionId,
          reviewerId,
        },
      },
    })

  const sendChatMessage = (content, chatThreadId) =>
    sendChatMutation({
      variables: {
        input: {
          content,
          chatThreadId,
        },
      },
    })

  const setDataType = value =>
    setDataTypeMutation({
      variables: {
        manuscriptId: manuscript.id,
        input: { dataType: value },
      },
    })

  const submitDecision = async (versionId, input) => {
    const result = await submitDecisionMutation({
      variables: {
        manuscriptVersionId: versionId,
        input,
      },
    })

    if (input.decision === 'publish') {
      await publishData(versionId)
      await finalizeDoi()
    }

    return result
  }

  const updateManuscriptTeams = values => {
    const curatorMembers = []

    if (values.curator) {
      values.curator.forEach(curator => {
        if (curator.id) {
          curatorMembers.push(curator.id)
        }
      })
    }

    const editorMembers = []

    if (values.editor && values.editor.id) {
      editorMembers.push(values.editor.id)
    }

    const sectionEditorMembers = []

    if (values.sectionEditor && values.sectionEditor.id) {
      sectionEditorMembers.push(values.sectionEditor.id)
    }

    const scienceOfficerMembers = []

    if (values.scienceOfficer && values.scienceOfficer.id) {
      scienceOfficerMembers.push(values.scienceOfficer.id)
    }

    return updateManuscriptTeamsMutation({
      variables: {
        input: {
          teams: [
            {
              teamId: manuscriptCuratorTeam.id,
              members: curatorMembers,
            },
            {
              teamId: manuscriptEditorTeam.id,
              members: editorMembers,
            },
            {
              teamId: manuscriptSectionEditorTeam.id,
              members: sectionEditorMembers,
            },
            {
              teamId: manuscriptSOTeam.id,
              members: scienceOfficerMembers,
            },
          ],
        },
      },
    })
  }

  const updateHistory = newHistory => {
    return updateHistoryMutation({
      variables: {
        manuscriptId: manuscript.id,
        data: {
          received: newHistory.received,
          sentForReview: newHistory.sentForReview,
          reviewReceived: newHistory.reviewReceived,
          revisionReceived: newHistory.revisionReceived,
          accepted: newHistory.accepted,
          published: newHistory.published,
          indexed: newHistory.indexed,
          curated: newHistory.curated,
          thirdParty: newHistory.thirdParty,
        },
      },
    })
  }

  const updateMetadata = metadata =>
    updateMetadataMutation({
      variables: {
        manuscriptId: manuscript.id,
        data: {
          categories: metadata.categories,
          dataRelease: metadata.dataRelease,
          dbReferenceId: metadata.dbReferenceId,
          doi: metadata.doi,
          pmId: metadata.pmId,
          pmcId: metadata.pmcId,
          proteopedia: metadata.proteopedia,
          reviewPanel: metadata.reviewPanel,
          species: metadata.species,
          submissionTypes: metadata.submissionTypes,
          topics: metadata.topics,
        },
      },
    })

  const setProteopediaReady = proteopediaSource =>
    setProteopediaReadyMutation({
      variables: {
        manuscriptId: manuscript.id,
        proteopedia: proteopediaSource,
      },
    })

  const addCorrection = async correction => {
    const id = await addCorrectionMutation({
      variables: {
        manuscriptId: manuscript.id,
        input: correction,
      },
    })

    await publishCorrectionDoi(manuscript.id, correction.doi)
    return id
  }

  const editCorrection = async correction => {
    const id = await editCorrectionMutation({
      variables: {
        manuscriptId: manuscript.id,
        input: correction,
      },
    })

    return id
  }

  const updateIntegrations = async articles => {
    return updateIntegrationsMutation({
      variables: {
        manuscriptId: manuscript.id,
        articles: articles.map(article => article.manuscriptId),
      },
    })
  }

  const updateEntities = entities =>
    updateEntitiesMutation({
      variables: {
        manuscriptId: manuscript.id,
        data: entities,
      },
    })

  const updatePaymentStatus = status => {
    updatePaymentStatusMutation({
      variables: {
        manuscriptId: manuscript.id,
        input: { paymentStatus: status },
      },
    })
  }

  return {
    editors,
    sectionEditors,
    scienceOfficers,
    curators,

    assignedCurator,
    assignedEditor,
    assignedSectionEditor,
    assignedScienceOfficer,

    categories,
    citations,
    corrections,
    dataRelease,
    dbReferenceId,
    doi,
    pmId,
    pmcId,
    proteopedia,
    reviewPanel,
    species,
    submissionTypes,
    history,
    ithenticate,
    integrations,
    topics,
    // isInitiallySubmitted,
    // isDataTypeSelected,

    chatData,
    sendChatMessage,

    versions,

    reinviteReviewer,
    setDataType,
    setProteopediaReady,
    submitDecision,
    updateHistory,
    updateManuscriptTeams,
    updateMetadata,
    addCorrection,
    editCorrection,
    updateEntities,
    paymentStatus,
    updatePaymentStatus,
    updateIntegrations,
  }
}
