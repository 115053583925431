import React from 'react'
import { useQuery } from '@apollo/client'

import { DATA_RELEASES } from '../graphql'
import { DataReleases } from '../../ui'

const DataReleasesPage = () => {
  const { data, loading } = useQuery(DATA_RELEASES)

  const releases = data && data.dataReleases

  return <DataReleases dataReleases={releases} loading={loading} />
}

export default DataReleasesPage
