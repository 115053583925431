/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import { DateParser, Action } from '../common'

const LineWrapper = styled.div`
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Box = styled.div``

const Reviews = props => {
  const { hasReviews, reviewsData } = props
  return (
    <>
      {!hasReviews && 'No review invitations'}
      {hasReviews &&
        reviewsData.manuscripts.map(m => (
          <LineWrapper key={m.manuscriptId}>
            <Box dangerouslySetInnerHTML={{ __html: m.title }} />
            <Box>
              Invitation Status: {m.invitationStatus}{' '}
              <DateParser
                dateFormat="MM-DD-YY"
                timestamp={new Date(Number(m.updated))}
              >
                {timestamp => `(${timestamp})`}
              </DateParser>
            </Box>
            <Box>
              {m.reviewed ? (
                <DateParser
                  dateFormat="MM-DD-YY"
                  timestamp={new Date(Number(m.reviewed))}
                >
                  {timestamp => `Date Reviewed: ${timestamp}`}
                </DateParser>
              ) : (
                'Did not review'
              )}
            </Box>
            <Action to={`/article/${m.manuscriptId}`}>Go to Article</Action>
            {m.reason && <Box>Reason to Decline Invitation: {m.reason}</Box>}
          </LineWrapper>
        ))}
    </>
  )
}

export default Reviews
