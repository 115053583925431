/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

const Steps = styled.div`
  border: 1px solid ${th('colorPrimary')};
  border-radius: 10px;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
`

const Step = styled.div`
  background-color: ${th('colorPrimary')};
  border-right: 0.5px solid #000;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 5px;
`

const GreenStep = styled.div`
  background-color: ${th('colorSuccess')};
  border-right: 0.5px solid #000;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 5px;
`

const ArticleProgress = props => {
  const { milestones } = props

  return (
    <Steps>
      {milestones.map(milestone => {
        const { label, dates } = milestone

        if (dates.length === 0) return null

        if (label === 'Proof' || label === 'Published')
          return (
            <GreenStep key={`step-${label}`}>
              <div>{label}</div>
              {dates.map(date => (
                <div key={`date-${date}`}>{date.toDateString()}</div>
              ))}
            </GreenStep>
          )
        return (
          <Step key={`step-${label}`}>
            <div>{label}</div>
            {dates.map(date => (
              <div key={`date-${date}`}>{date.toDateString()}</div>
            ))}
          </Step>
        )
      })}
    </Steps>
  )
}

export default ArticleProgress
