import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import clone from 'lodash/clone'

// TO DO -- clean this up once compose/pieces is deprecated
import { GET_GLOBAL_TEAMS } from '../components/compose/pieces/getGlobalTeams'
import { DASHBOARD_MANUSCRIPTS } from '../components/compose/pieces/dashboardManuscripts'

import { TEAM_MANAGER_DATA, UPDATE_GLOBAL_TEAMS } from '../graphql'
import { TeamManager } from '../../ui'

const TeamManagerPage = () => {
  const { data, loading } = useQuery(TEAM_MANAGER_DATA)

  const [updateGlobalTeams] = useMutation(UPDATE_GLOBAL_TEAMS, {
    refetchQueries: [
      {
        query: GET_GLOBAL_TEAMS,
      },
      {
        query: DASHBOARD_MANUSCRIPTS,
      },
    ],
  })

  /**
   * Prepare data for use by the UI
   */
  const teams =
    data &&
    data.getGlobalTeams &&
    data.getGlobalTeams.map(team => ({
      id: team.id,
      name: team.name,
      role: team.role,
      members: team.members.map(m => m.user),
    }))

  const users = data && data.users

  /**
   * Prepare data for consumption by the API
   */
  const updateTeamsFn = teamData => {
    const mutationData = teamData.map(team => {
      const cloned = clone(team)
      cloned.members = team.members.map(m => m.id)
      cloned.teamId = teams.find(t => t.role === cloned.role).id
      delete cloned.role
      return cloned
    })

    return updateGlobalTeams({
      variables: {
        input: {
          teams: mutationData,
        },
      },
    })
  }

  return (
    <TeamManager
      loading={loading}
      teams={teams}
      updateGlobalTeamMembers={updateTeamsFn}
      users={users}
    />
  )
}

export default TeamManagerPage
