import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import WarningModal from './WarningModal'
import ValueList from '../editors/ValueList'

const StyledList = styled(ValueList)`
  width: 70%;
`

const AcceptConfirmation = props => {
  const {
    categories,
    curated,
    dbReferenceId,
    dataUploaded,
    doi,
    species,
    submissionTypes,
    proteopediaVerify,
    ...rest
  } = props

  const data = [
    { label: 'Species', status: 'primary', value: species },
    {
      label: 'Categories',
      status: 'primary',
      value: categories,
    },
    {
      label: 'Submission Types',
      status: 'primary',
      value: submissionTypes,
    },
    {
      label: 'DOI',
      status: 'primary',
      value: doi,
    },
    {
      label: 'DB Reference ID',
      status: 'primary',
      value: dbReferenceId,
    },
    {
      label: 'Curator Assessment',
      status: 'primary',
      value: curated,
    },
    {
      label: 'Extended Data Uploaded',
      status: 'primary',
      value: dataUploaded,
    },
  ]

  if (proteopediaVerify) {
    data.push({
      label: 'Proteopedia Source',
      status: 'primary',
      value: proteopediaVerify,
    })
  }

  return (
    <WarningModal
      headerText="Confirm Acceptance"
      justifyText
      size="medium"
      textSuccess="send proofs"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <>
        <StyledList data={data} />
      </>
    </WarningModal>
  )
}

AcceptConfirmation.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  curated: PropTypes.string,
  dataUploaded: PropTypes.string,
  dbReferenceId: PropTypes.string,
  doi: PropTypes.string,
  species: PropTypes.arrayOf(PropTypes.string),
  submissionTypes: PropTypes.arrayOf(PropTypes.string),
  proteopediaVerify: PropTypes.string,
}

AcceptConfirmation.defaultProps = {
  categories: null,
  curated: null,
  dataUploaded: null,
  dbReferenceId: null,
  doi: null,
  species: null,
  submissionTypes: null,
  proteopediaVerify: null,
}

export default AcceptConfirmation
