import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import DiffArray from './DiffArray'
import { diff, smallFontReading } from './_cssFragments'

const Wrapper = styled.div`
  ${smallFontReading}
  text-align: left;
`

const StyledAuthor = styled.span`
  ${diff}
`

const Author = props => {
  const {
    firstName,
    lastName,
    email,
    isAdded,
    isRemoved,
    isLast,
    showDiff,
  } = props

  return (
    <StyledAuthor isAdded={isAdded} isRemoved={isRemoved} showDiff={showDiff}>
      {firstName} {lastName} ({email}){!isLast && '; '}
    </StyledAuthor>
  )
}

Author.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  isAdded: PropTypes.bool,
  isLast: PropTypes.bool.isRequired,
  isRemoved: PropTypes.bool,
  lastName: PropTypes.string.isRequired,
  showDiff: PropTypes.bool.isRequired,
}

Author.defaultProps = {
  isAdded: false,
  isRemoved: false,
}

const CorrespondingAuthor = props => {
  const { data, previousData, showDiff, showRemoved, hasOneAuthor } = props
  if (!data || data.length === 0) return null

  return (
    <Wrapper>
      {!hasOneAuthor && <sup>&sect;</sup>}Correspondence to:&nbsp;
      <DiffArray
        component={Author}
        currentArray={data}
        previousArray={previousData}
        showDiff={showDiff}
        showRemoved={showRemoved}
      />
    </Wrapper>
  )
}

CorrespondingAuthor.propTypes = {
  /** Array of author objects */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      id: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ).isRequired,
  /** Same as data, but the previous version. For diffs. */
  previousData: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      id: PropTypes.string,
      lastName: PropTypes.string,
    }),
  ),
  /** Whether to show the diffs at all */
  showDiff: PropTypes.bool,
  /** Whether to show the diff removals */
  showRemoved: PropTypes.bool,
  /** Whether there is only one author */
  hasOneAuthor: PropTypes.bool,
}

CorrespondingAuthor.defaultProps = {
  previousData: null,
  showDiff: true,
  showRemoved: true,
  hasOneAuthor: false,
}

export default CorrespondingAuthor
