/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { Icon as UIIcon, Button } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import TextField from '../common/TextField'
import { TextEditor } from '../common'

const ReferenceWrapper = styled.div`
  margin-bottom: ${th('gridUnit')};
`

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const ReferenceField = styled(TextField)`
  width: calc(${th('gridUnit')} * 49);
`

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const Icon = styled(UIIcon)`
  svg {
    height: calc(${th('gridUnit')} * 2);
    stroke: ${th('colorFurniture')};
  }
`

const IconButton = styled(Button)`
  min-width: calc(${th('gridUnit')} * 6);
`

const ReferenceInput = props => {
  const {
    errors,
    handleBlur,
    handleChange,
    name,
    fetchReference,
    fetchCrossref,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
    currentReference,
  } = props

  if (currentReference < 0) return null

  const [loadingPubMed, setLoadingPubMed] = useState(false)
  const [loadingCrossref, setLoadingCrossref] = useState(false)

  const pubMedLookup = async event => {
    const trimmedValue = event.clipboardData
      ? event.clipboardData.getData('Text')
      : values.references[currentReference].pubmedId.trim()

    if (!/^\d+$/.test(trimmedValue)) {
      return
    }

    setLoadingPubMed(true)
    await fetchReference(trimmedValue, `${name}[${currentReference}]`)
    setLoadingPubMed(false)
  }

  const crossrefLookup = async event => {
    const trimmedValue = event.clipboardData
      ? event.clipboardData.getData('Text')
      : values.references[currentReference].doi.trim()

    setLoadingCrossref(true)
    await fetchCrossref(trimmedValue, `${name}[${currentReference}]`)
    setLoadingCrossref(false)
  }

  const referencesErrors = get(errors, 'references')

  return (
    <ReferenceWrapper>
      <Error>{referencesErrors}</Error>
      <FieldWrapper>
        <ReferenceField
          handleBlur={handleBlur}
          handleChange={handleChange}
          label="PubMed ID"
          name={`${name}[${currentReference}].pubmedId`}
          onPaste={pubMedLookup}
          placeholder="PubMed IDs are required unless they don't exist"
          touched={touched}
          value={values.references[currentReference].pubmedId}
        />

        <IconButton
          loading={loadingPubMed}
          onClick={pubMedLookup}
          title="PubMed lookup"
        >
          <Icon>search</Icon>
        </IconButton>

        <ReferenceField
          handleBlur={handleBlur}
          handleChange={handleChange}
          label="DOI"
          name={`${name}[${currentReference}].doi`}
          onPaste={crossrefLookup}
          placeholder="Recommended if PubMed ID is not available (e.g. 10.17912/micropub.biology.000000)."
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touched}
          value={values.references[currentReference].doi}
        />
        <IconButton
          loading={loadingCrossref}
          onClick={crossrefLookup}
          title="Crossref lookup"
        >
          <Icon>search</Icon>
        </IconButton>
      </FieldWrapper>

      <TextEditor
        bold
        error={get(errors, 'references')}
        italic
        key={values.references[currentReference].id}
        name={`${name}[${currentReference}].reference`}
        placeholder="Reference text if PubMed lookup is not available (only enter one per text box)."
        required
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        subscript
        superscript
        touched={touched}
        value={values.references[currentReference].reference}
      />
    </ReferenceWrapper>
  )
}

export default ReferenceInput
