import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'

import ValueList from './ValueList'
import { Button, Form, TextField } from '../common'

const validations = yup.object().shape({
  received: yup.string().nullable(),
  sentForReview: yup.string().nullable(),
  reviewReceived: yup.string().nullable(),
  revisionReceived: yup.string().nullable(),
  curated: yup.string().nullable(),
  accepted: yup.string().nullable(),
  published: yup.string().nullable(),
  indexed: yup.string().nullable(),
})

const formatDate = date => {
  const jsDate = new Date(date)
  return `${
    jsDate.getUTCMonth() + 1
  }/${jsDate.getUTCDate()}/${jsDate.getUTCFullYear()}`
}

const HistorySection = props => {
  const { history, updateHistory } = props

  const [showForm, setShowForm] = useState(false)

  const received = history && formatDate(history.received)

  const sentForReview =
    history.sentForReview && formatDate(history.sentForReview)

  const reviewReceived =
    history.reviewReceived && formatDate(history.reviewReceived)

  const revisionReceived =
    history.revisionReceived && formatDate(history.revisionReceived)

  const accepted = history.accepted && formatDate(history.accepted)

  const published = history.published && formatDate(history.published)

  const indexed = history.indexed && formatDate(history.indexed)

  const curated = history.curated && formatDate(history.curated)

  const thirdParty = history.thirdParty && formatDate(history.thirdParty)

  const data = [
    {
      label: 'Received',
      status: 'primary',
      value: received,
    },
    {
      label: 'Sent For Review',
      status: 'primary',
      value: sentForReview,
    },
    {
      label: 'Review Received',
      status: 'primary',
      value: reviewReceived,
    },
    {
      label: 'Revision Received',
      status: 'primary',
      value: revisionReceived,
    },
    {
      label: 'Sent for Third Party Review',
      status: 'primary',
      value: thirdParty,
    },
    {
      label: 'Curated',
      status: 'primary',
      value: curated,
    },
    {
      label: 'Accepted',
      status: 'primary',
      value: accepted,
    },
    {
      label: 'Published',
      status: 'primary',
      value: published,
    },
    {
      label: 'Indexed',
      status: 'primary',
      value: indexed,
    },
  ]

  if (!showForm) {
    return (
      <>
        <ValueList data={data} />
        <Button onClick={() => setShowForm(true)} primary>
          Edit
        </Button>
      </>
    )
  }

  const handleSubmit = (values, formikBag) =>
    updateHistory(values).then(() => setShowForm(false))

  const initialValues = {
    received,
    sentForReview,
    reviewReceived,
    revisionReceived,
    accepted,
    published,
    indexed,
    curated,
    thirdParty,
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validations}
    >
      {formProps => {
        const { errors, handleBlur, handleChange, touched, values } = formProps

        return (
          <>
            <TextField
              error={errors.received}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Received"
              name="received"
              touched={touched}
              value={values.received}
            />

            <TextField
              error={errors.sentForReview}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Sent For Review"
              name="sentForReview"
              touched={touched}
              value={values.sentForReview}
            />

            <TextField
              error={errors.reviewReceived}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Review Received"
              name="reviewReceived"
              touched={touched}
              value={values.reviewReceived}
            />

            <TextField
              error={errors.revisionReceived}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Revision Received"
              name="revisionReceived"
              touched={touched}
              value={values.revisionReceived}
            />

            <TextField
              error={errors.accepted}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Accepted"
              name="accepted"
              touched={touched}
              value={values.accepted}
            />

            <TextField
              error={errors.thirdParty}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Sent For Third Party Review"
              name="thirdParty"
              touched={touched}
              value={values.thirdParty}
            />

            <TextField
              error={errors.curated}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Curated"
              name="curated"
              touched={touched}
              value={values.curated}
            />

            <TextField
              error={errors.published}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Published"
              name="published"
              touched={touched}
              value={values.published}
            />

            <TextField
              error={errors.indexed}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Indexed"
              name="indexed"
              touched={touched}
              value={values.indexed}
            />

            <Button onClick={() => setShowForm(false)}>Cancel</Button>

            <Button primary type="submit">
              Save
            </Button>
          </>
        )
      }}
    </Form>
  )
}

HistorySection.propTypes = {
  history: PropTypes.shape({
    received: PropTypes.string,
    sentForReview: PropTypes.string,
    reviewReceived: PropTypes.string,
    revisionReceived: PropTypes.string,
    accepted: PropTypes.string,
    published: PropTypes.string,
    indexed: PropTypes.string,
    curated: PropTypes.string,
    thirdParty: PropTypes.string,
  }),
  updateHistory: PropTypes.func,
}

HistorySection.defaultProps = {
  history: null,
  updateHistory: null,
}

export default HistorySection
