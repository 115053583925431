import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import { ImageModal } from '../..'

const added = css`
  box-shadow: -5px 0 ${th('colorAddition')};
`

const removed = css`
  box-shadow: -5px 0 ${th('colorRemoval')};
`

const StyledImage = styled.img`
  height: auto;
  width: 100%;

  /* stylelint-disable order/properties-alphabetical-order */
  ${props => {
    if (props.isRemoved) return removed
    if (props.isAdded) return added
    if (!props.isModal) return 'cursor: zoom-in;'
    return null
  }}
`

const Image = props => {
  const { isModal, name, previousSource, showDiff, showRemoved, source } = props
  if (!source) return null

  if (showDiff && previousSource && previousSource !== source) {
    return (
      <>
        {showRemoved && <StyledImage isRemoved src={previousSource} />}
        <StyledImage alt={name} isAdded src={source} />
      </>
    )
  }

  if (isModal) {
    return <StyledImage alt={name} isModal src={source} />
  }

  const [showImageModal, setShowImageModal] = useState(false)

  return (
    <div>
      <StyledImage
        alt={name}
        onClick={() => setShowImageModal(true)}
        src={source}
      />

      <ImageModal
        headerText="Figure 1."
        image={source}
        isOpen={showImageModal}
        onRequestClose={() => setShowImageModal(false)}
      />
    </div>
  )
}

Image.propTypes = {
  /** Indicate if the manuscript is in a modal */
  isModal: PropTypes.bool,
  /** Will go into image's "alt" attribute */
  name: PropTypes.string,
  /** Same as source, but from the previous version (for diffs) */
  previousSource: PropTypes.string,
  /** Whether to display diffs at all */
  showDiff: PropTypes.bool,
  /** Whether to display removals in diffs */
  showRemoved: PropTypes.bool,
  /** Image src */
  source: PropTypes.string,
}

Image.defaultProps = {
  isModal: false,
  name: null,
  previousSource: null,
  showDiff: true,
  showRemoved: true,
  source: null,
}

export default Image
