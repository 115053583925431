import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid, th } from '../_helpers'
import { TextEditor } from '../common'

const Wrapper = styled.div`
  border: 1px dashed ${th('colorPrimary')};
  font-size: ${th('fontSizeBaseSmall')};
  height: 200px;
  margin: ${grid(1)};
  overflow-y: auto;
  padding: ${grid(1)};
`

const Label = styled.span`
  color: ${th('colorPrimary')};
  text-transform: uppercase;
`

const Editor = styled(TextEditor)`
  background-color: white;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
`

const SuggestedReviewer = props => {
  const { className, name } = props

  return (
    <Wrapper className={className}>
      <Label>Author Suggested Reviewer:</Label> <Editor readOnly value={name} />
    </Wrapper>
  )
}

SuggestedReviewer.propTypes = {
  /** Display name of suggested reviewer */
  name: PropTypes.string.isRequired,
}

SuggestedReviewer.defaultProps = {}

export default SuggestedReviewer
