/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import clone from 'lodash/clone'

import AuthorRow from './ReferenceRow'
import DndList from '../common/DndList'

const ReferenceList = props => {
  const {
    error,
    onReorder,
    references,
    onClickRemoveRow,
    onClickEditRow,
  } = props

  const items = references.map((reference, index) => {
    const newReference = clone(reference)
    newReference.error = error[index]

    return newReference
  })

  return (
    <DndList
      component={AuthorRow}
      items={items}
      onClickEditRow={onClickEditRow}
      onClickRemoveRow={onClickRemoveRow}
      onReorder={onReorder}
    />
  )
}

ReferenceList.propTypes = {
  /** Function to run on reorder. Receives the new list as an argument. */
  onReorder: PropTypes.func.isRequired,
  references: PropTypes.arrayOf(PropTypes.object),
  onClickRemoveRow: PropTypes.func,
  onClickEditRow: PropTypes.func,
  error: PropTypes.arrayOf(PropTypes.string),
}

ReferenceList.defaultProps = {
  references: [],
  onClickRemoveRow: null,
  onClickEditRow: null,
  error: [],
}

export default ReferenceList
