import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { diff } from './_cssFragments'

const Wrapper = styled.div``

const StyledFunding = styled.span`
  ${diff}
`

const Funding = props => {
  const {
    isAdded,
    isRemoved,
    showDiff,
    funderName,
    awardId,
    awardRecipient,
  } = props

  return (
    <Wrapper>
      <StyledFunding
        isAdded={isAdded}
        isRemoved={isRemoved}
        showDiff={showDiff}
      >
        Supported by {funderName} {awardId} to {awardRecipient}
      </StyledFunding>
    </Wrapper>
  )
}

Funding.propTypes = {
  /** Whether it was an addition. For diffs. */
  isAdded: PropTypes.bool,
  /** Whether it was a removal. For diffs. */
  isRemoved: PropTypes.bool,
  /** Whether to show diffs */
  showDiff: PropTypes.bool,
  /** The funder name */
  funderName: PropTypes.string.isRequired,
  /** The award ID */
  awardId: PropTypes.string.isRequired,
  /** The award recipient */
  awardRecipient: PropTypes.string.isRequired,
}

Funding.defaultProps = {
  isAdded: false,
  isRemoved: false,
  showDiff: true,
}

export default Funding
