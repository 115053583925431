/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import InfoModal from './InfoModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CategoryDescriptions = props => {
  const { categories } = props

  return (
    <InfoModal
      headerText="category descriptions"
      justifyText
      size="medium"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Wrapper>
        {categories &&
          categories.map(category => {
            return (
              <p key={category.id}>
                <b>{category.label}:</b>{' '}
                {category.description && category.description}
              </p>
            )
          })}
      </Wrapper>
    </InfoModal>
  )
}

export default CategoryDescriptions
