import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid, th } from '../_helpers'

const Header = styled.div`
  font-weight: bold;
`

const SideWrapper = styled.div`
  border-left: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
  display: flex;
`

const Side = styled.div`
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  display: flex;
  margin-left: auto;
  padding: 0 ${grid(2)} 0 ${grid(1)};
`

const ReviewersNumbers = props => {
  const { invited, accepted, rejected, revoked } = props

  const items = [
    {
      amount: invited,
      id: 'invited',
      label: 'invited',
    },
    {
      amount: accepted,
      id: 'accepted',
      label: 'accepted invitation',
    },
    {
      amount: rejected,
      id: 'rejected',
      label: 'rejected invitation',
    },
    {
      amount: revoked,
      id: 'revoked',
      label: 'invitation revoked',
    },
  ]

  return (
    <div>
      <Header>Reviewer stats for this version</Header>

      <SideWrapper>
        <Side>
          {items.map(item => (
            <Label key={item.id}>{item.amount}</Label>
          ))}
        </Side>

        <Side>
          {items.map(item => (
            <div key={item.id}>{item.label}</div>
          ))}
        </Side>
      </SideWrapper>
    </div>
  )
}

ReviewersNumbers.propTypes = {
  accepted: PropTypes.number.isRequired,
  invited: PropTypes.number.isRequired,
  rejected: PropTypes.number.isRequired,
  revoked: PropTypes.number.isRequired,
}

export default ReviewersNumbers
