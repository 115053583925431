import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

const StyledLabel = styled.div`
  font-family: ${th('fontInterface')};
  font-weight: bold;
  text-transform: capitalize;
`

const Label = props => {
  const { value } = props

  return <StyledLabel>{value}:</StyledLabel>
}

Label.propTypes = {
  /** String to be rendered as label */
  value: PropTypes.string.isRequired,
}

export default Label
