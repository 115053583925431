import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { uuid } from '@coko/client'

import { grid } from '../_helpers'
import { Accordion as AccordionBase, PageHeader } from '../common'
import FeedbackItem from './FeedbackItem'

const Wrapper = styled.div``

const Header = styled(PageHeader)`
  margin: 0;
`

const AccordionContent = styled.div`
  margin: ${grid(1)} 0 ${grid(1)} ${grid(4)};
`

const Accordion = props => {
  /* eslint-disable-next-line react/prop-types */
  const { children, label, startExpanded } = props

  return (
    <AccordionBase label={label} startExpanded={startExpanded}>
      <AccordionContent>{children}</AccordionContent>
    </AccordionBase>
  )
}

const Feedback = props => {
  const { data } = props

  return (
    <Wrapper>
      <Header>Feedback</Header>

      {data.map((item, index) => {
        const { decisionLetter, label } = item
        const isLast = index === data.length - 1

        return (
          <Accordion key={uuid()} label={label} startExpanded={isLast}>
            <FeedbackItem decisionLetter={decisionLetter} />
          </Accordion>
        )
      })}
    </Wrapper>
  )
}

Feedback.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      decisionLetter: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
}

Feedback.defaultProps = {
  data: [],
}

export default Feedback
