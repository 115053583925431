import { css } from 'styled-components'
import merge from 'lodash/merge'

/* eslint-disable import/extensions */
import 'fontsource-fira-sans-condensed'
import 'fontsource-vollkorn'
/* eslint-enable import/extensions */

import { theme as cokoTheme } from '@coko/client'
import { th } from '@pubsweet/ui-toolkit'

const activeTab = css`
  background: ${th('colorPrimary')};
  color: ${th('colorTextReverse')};

  :hover {
    background: ${th('colorPrimary')};
  }
`

const Tab = css`
  border-color: ${th('colorPrimary')};
  border-radius: 0.375rem 0.375rem 0 0;
  border-right: 0;
  border-width: ${th('borderWidth')};
  color: ${th('colorText')};
  cursor: pointer;
  transition: all 0.1s ease-in;
  user-select: none;

  /* stylelint-disable-next-line order/properties-alphabetical-order, order/order */
  ${props => props.active && activeTab};
`

const AccordionRoot = css`
  cursor: default;
`

const Button = css`
  ${cokoTheme.cssOverrides.ui.Button};

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  border-radius: 0.375rem;
  cursor: pointer;
  line-height: calc(${th('gridUnit')} * 3);
`

const HeadingWeight = css`
  font-weight: 400;
`

const edits = {
  // fonts
  fontInterface: "'Fira Sans Condensed'",
  fontHeading: "'Fira Sans Condensed'",
  fontReading: "'Vollkorn'",
  fontWriting: "'Fira Sans Condensed'",

  // diffs
  colorAddition: 'palegreen',
  colorRemoval: 'lightcoral',

  cssOverrides: {
    ui: {
      Accordion: {
        Root: AccordionRoot,
      },
      Button,
      H1: HeadingWeight,
      H2: HeadingWeight,
      H3: HeadingWeight,
      H4: HeadingWeight,
      H5: HeadingWeight,
      H6: HeadingWeight,
      Tab,
    },
  },
}

const editedTheme = merge(cokoTheme, edits)
export default editedTheme
