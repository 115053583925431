import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/bioentity`

const getKeywordSets = async () => {
  const url = `${apiUrl}/kws`
  return fetch(url)
}

const getEntities = async (versionId, keywordSet) => {
  const url = `${apiUrl}/entities?versionId=${versionId}&kws=${keywordSet}`
  return fetch(url)
}

const getExtendedDataEntities = async (filename, keywordSet) => {
  const url = `${apiUrl}/docx?filename=${filename}&kws=${keywordSet}`
  return fetch(url)
}

export { getKeywordSets, getEntities, getExtendedDataEntities }
