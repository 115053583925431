const clearLocalStorage = () => {
  const whitelist = [] // keys you shouldn't delete on logout

  const keysToDelete = Object.keys(localStorage).filter(
    key => !whitelist.includes(key),
  )

  keysToDelete.forEach(item => localStorage.removeItem(item))
}

/* eslint-disable-next-line import/prefer-default-export */
export { clearLocalStorage }
