import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '../_helpers'

const background = props => {
  const { recommendation } = props
  if (recommendation === 'revise') return th('colorWarning')
  if (recommendation === 'accept') return th('colorSuccess')
  if (recommendation === 'reject') return th('colorError')
  if (recommendation === 'decline') return th('colorError')
  return th('colorFurniture')
}

const Dot = styled.div`
  align-self: center;
  background: ${background};
  border-radius: 50%;
  display: inline-flex;
  height: calc(${th('gridUnit')} * 1);
  /* margin-right: ${th('gridUnit')}; */
  width: calc(${th('gridUnit')} * 1);
`

const RecommendationDot = props => {
  const { className, recommendation } = props
  return <Dot className={className} recommendation={recommendation} />
}

RecommendationDot.propTypes = {
  recommendation: PropTypes.oneOf(['accept', 'decline', 'reject', 'revise']),
}

RecommendationDot.defaultProps = {
  recommendation: null,
}

export default RecommendationDot
