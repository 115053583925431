import React from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

const Btn = styled.span`
  border-radius: 2px;
  color: ${th('colorPrimary')};
  cursor: pointer;
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: calc(${th('gridUnit')} / 2) ${th('gridUnit')};
  text-transform: uppercase;
  transition: background 0.1s ease-in;

  &:hover {
    background: ${th('colorBackgroundHue')};
  }
`

const DiscreetButton = ({ className, children, onClick }) => (
  <Btn className={className} onClick={onClick}>
    {children}
  </Btn>
)

export default DiscreetButton
