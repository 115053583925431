import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import clone from 'lodash/clone'

import {
  SPECIES,
  CATEGORIES,
  SUBMISSION_TYPES,
  DELETE_TAXONOMY,
  ADD_TAXONOMY,
  UPDATE_TAXONOMY,
} from '../graphql'
import { TaxonomyManager } from '../../ui'

const TaxonomyManagerPage = () => {
  const { data: speciesData, loading: speciesLoading } = useQuery(SPECIES)

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    CATEGORIES,
  )

  const { data: typesData, loading: typesLoading } = useQuery(SUBMISSION_TYPES)

  const queryRefetch = {
    refetchQueries: [
      {
        query: SPECIES,
      },
      {
        query: CATEGORIES,
      },
      {
        query: SUBMISSION_TYPES,
      },
    ],
  }

  const [deleteTaxonomy] = useMutation(DELETE_TAXONOMY, queryRefetch)
  const [addTaxonomy] = useMutation(ADD_TAXONOMY, queryRefetch)
  const [updateTaxonomy] = useMutation(UPDATE_TAXONOMY, queryRefetch)

  const deleteTaxonomyFn = id => deleteTaxonomy({ variables: { id } })
  const addTaxonomyFn = taxonomy => addTaxonomy({ variables: { taxonomy } })

  const updateSpecies = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'species'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  const updateCategory = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'categories'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  const updateType = (id, taxonomy) => {
    const newTaxonomy = clone(taxonomy)
    delete newTaxonomy.id
    newTaxonomy.type = 'submissionTypes'
    return updateTaxonomy({ variables: { id, taxonomy: newTaxonomy } })
  }

  /**
   * Prepare data for use by the UI
   */
  const species =
    speciesData &&
    speciesData.species.map(s => {
      const newSpecies = {
        updateTaxonomy: updateSpecies,
        deleteTaxonomy: deleteTaxonomyFn,
        id: s.id,
        label: s.label,
        value: s.value,
        imageSrc: s.imageSrc,
        imageAlt: s.imageAlt,
        modLink: s.modLink,
        mod: s.mod,
        linkVariable: s.linkVariable,
        description: s.description,
        externalId: s.externalId,
      }

      return newSpecies
    })

  const categories =
    categoriesData &&
    categoriesData.categories.map(c => {
      const newCategory = {
        updateTaxonomy: updateCategory,
        deleteTaxonomy: deleteTaxonomyFn,
        id: c.id,
        label: c.label,
        value: c.value,
        description: c.description,
      }

      return newCategory
    })

  const submissionTypes =
    typesData &&
    typesData.submissionTypes.map(s => {
      const newSpecies = {
        updateTaxonomy: updateType,
        deleteTaxonomy: deleteTaxonomyFn,
        id: s.id,
        label: s.label,
        value: s.value,
        description: s.description,
      }

      return newSpecies
    })

  return (
    <TaxonomyManager
      addTaxonomy={addTaxonomyFn}
      categories={categories}
      loading={speciesLoading || categoriesLoading || typesLoading}
      species={species}
      submissionTypes={submissionTypes}
    />
  )
}

export default TaxonomyManagerPage
