/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import { State } from 'react-powerplug'
import { clone, uniqBy } from 'lodash'

import { Action as UIAction } from '@pubsweet/ui'

import {
  ArticlePreviewModal,
  AuthorSectionItem,
  CuratorSectionItem,
  EditorSectionItem,
  ReviewerSectionItem,
  Section,
} from './ui'

import ComposedDashboard from './compose/Dashboard'
import { Loader } from '../../ui/src/common'
import { DASHBOARD_MANUSCRIPTS } from './compose/pieces/dashboardManuscripts'
import { CURRENT_USER } from './Private'
import Select from './ui/Select'
import { UploadModal } from '../../ui'
import DashboardEntityTable from '../../ui/src/modals/DashboardEntityTable'

const Action = styled(UIAction)`
  line-height: unset;
`

const DashboardWrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`

const Dashboard = props => {
  const {
    allCurators,
    allEditors,
    allScienceOfficers,
    allSectionEditors,
    authorArticles,
    client,
    createManuscript,
    curatorArticles,
    currentUser,
    deleteArticle,
    editorAssigned,
    editorArticles,
    handleInvitation,
    history,
    loading,
    makePayment,
    reviewerArticles,
    scienceOfficerArticles,
    sectionEditorArticles,
    updateAssignedEditor,
    updateAssignedScienceOfficer,
    upload,
    withdrawArticle,
  } = props

  const [showUploadModal, setShowUploadModal] = useState(false)

  if (loading) return <Loader />

  const isAdmin = currentUser.admin
  const isEditor = currentUser.auth.isGlobalEditor
  const isScienceOfficer = currentUser.auth.isGlobalScienceOfficer
  const isSectionEditor = currentUser.auth.isGlobalSectionEditor
  const isCurator = currentUser.auth.isGlobalCurator

  const options = [
    {
      label: 'Submitted - Needs Triage',
      value: 'submitted - needs triage',
    },
    {
      label: 'Datatype Selected',
      value: 'datatype selected',
    },
    {
      label: 'Submitted',
      value: 'submitted',
    },
    {
      label: 'Approved By Science Officer',
      value: 'approved by science officer',
    },
    {
      label: 'Not Approved By Science Officer',
      value: 'not approved by science officer',
    },
    {
      label: 'Reviewer Invited',
      value: 'reviewer invited',
    },
    {
      label: 'Reviewer Accepted',
      value: 'reviewer accepted',
    },
    {
      label: 'Review Submitted',
      value: 'review submitted',
    },
    {
      label: 'Under Revision',
      value: 'under revision',
    },
    {
      label: 'Declined',
      value: 'declined',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
    {
      label: 'Withdrawn',
      value: 'withdrawn',
    },
    {
      label: 'Accepted To Proofs',
      value: 'accepted to proofs',
    },
    {
      label: 'Revision Submitted',
      value: 'revision submitted',
    },
    {
      label: 'Proofs Submitted',
      value: 'proofs submitted',
    },
    {
      label: 'Editorial Re-submission',
      value: 'editorial re-submission',
    },
    {
      label: 'Corrigendum',
      value: 'corrigendum',
    },
    {
      label: 'Erratum',
      value: 'erratum',
    },
    {
      label: 'Retraction',
      value: 'retraction',
    },
    { label: '3rd Party Review', value: 'curation' },
  ]

  const curatorOptions = [
    { label: 'Assessment Requested', value: 'Assessment Requested' },
    { label: 'Assessment Sent', value: 'Assessment Sent' },
    { label: 'Waiting Curation in DB', value: 'Waiting Curation in DB' },
    { label: 'Nothing to Curate', value: 'Nothing to Curate' },
    { label: 'Curated in DB', value: 'Curated in DB' },
  ]

  const globalTeamMembers = [
    ...allScienceOfficers,
    ...allCurators,
    ...allEditors,
    ...allSectionEditors,
  ]

  const teamMemberOptions = uniqBy(
    globalTeamMembers.map(member => ({
      label: member.displayName,
      value: member.id,
    })),
    'value',
  )

  const loadArticle = manuscriptId => {
    client
      .query({
        fetchPolicy: 'network-only',
        query: CURRENT_USER,
      })
      .then(() => {
        client.query({
          fetchPolicy: 'network-only',
          query: DASHBOARD_MANUSCRIPTS,
          variables: { reviewerId: currentUser.id },
        })

        history.push(`/article/${manuscriptId}`)
      })
  }

  const SubmitButton = () => {
    // const { client, createManuscript, currentUser, history } = props

    const onClick = () => {
      createManuscript().then(res => {
        const manuscriptId = res.data.createManuscript

        /* 
          TO DO -- This needs to go. See comment in mutation.
        */
        loadArticle(manuscriptId)
      })
    }

    return (
      <Action data-test-id="new-submission-button" onClick={onClick}>
        New Submission
      </Action>
    )
  }

  const UploadButton = () => {
    // Sconst { setShowUploadModal } = props

    return (
      <Action
        data-test-id="upload-docx-button"
        onClick={() => setShowUploadModal(true)}
      >
        Upload DOCX
      </Action>
    )
  }

  const headerActions = [
    <SubmitButton
      client={client}
      createManuscript={createManuscript}
      currentUser={currentUser}
      history={history}
      key="createManuscript"
    />,
    <UploadButton key="uploadDox" setShowUploadModal={setShowUploadModal} />,
  ]

  const storedStatuses = localStorage.getItem('selectedStatuses')
    ? JSON.parse(localStorage.getItem('selectedStatuses'))
    : []

  const initialArticles = () => {
    if (storedStatuses.length > 0) {
      const correctionStatuses = storedStatuses
        .map(s => s.value)
        .filter(s => ['corrigendum', 'erratum', 'retraction'].includes(s))

      return editorArticles.filter(article => {
        if (correctionStatuses.length > 0 && article.correctionStatus) {
          const corrections = article.correctionStatus.filter(c =>
            correctionStatuses.includes(c),
          )

          if (corrections.length > 0) {
            return true
          }
        }

        return storedStatuses.map(s => s.value).includes(article.displayStatus)
      })
    }

    return (
      editorArticles &&
      editorArticles.filter(
        article =>
          ![
            'published',
            'rejected',
            'declined',
            'withdrawn',
            'retraction',
          ].includes(article.displayStatus),
      )
    )
  }

  const curatorArticlesWithStatus =
    curatorArticles &&
    curatorArticles
      .map(article => {
        let curatorStatus = 'Assessment Requested'

        const userAssessments = article.curatorReviews.filter(
          r => r.curator.id === currentUser.id,
        )

        if (userAssessments.length > 0) {
          curatorStatus = 'Assessment Sent'

          if (article.isAccepted) {
            curatorStatus = 'Waiting Curation in DB'
          }

          const assessment = userAssessments.find(a => a.curated)

          if (assessment && assessment.curated) {
            curatorStatus =
              assessment.curated === 'curated in db'
                ? 'Curated in DB'
                : 'Nothing to Curate'
          }
        }

        const articleWithStatus = clone(article)
        articleWithStatus.curatorStatus = curatorStatus
        return articleWithStatus
      })
      .sort((a, b) => {
        if (a.curatorStatus === 'Assessment Requested') return -1
        if (a.curatorStatus === 'Assessment Sent') return 0
        return 1
      })

  return (
    <State
      initial={{
        filteredArticles: initialArticles(),
        previewData: null,
        savedStatuses: storedStatuses,
        showModal: false,
        teamFilter: null,
        filteredCuratorArticles: curatorArticlesWithStatus,
        selectedCuratorStatus: null,
        showEntityTable: false,
        selectedEntities: null,
      }}
    >
      {({ state, setState }) => {
        const {
          previewData,
          showModal,
          savedStatuses,
          teamMemberFilter,
          selectedCuratorStatus,
          selectedEntities,
          showEntityTable,
        } = state

        const openModal = article =>
          setState({
            previewData: article,
            showModal: true,
          })

        const closeModal = () =>
          setState({
            previewData: null,
            showModal: false,
          })

        const openReviewerPreviewModal = articleId => {
          const article = reviewerArticles.find(a => a.id === articleId)
          openModal(article)
        }

        const openEntityTable = entities => {
          setState({ selectedEntities: entities, showEntityTable: true })
        }

        const closeEntityTable = () => {
          setState({ selectedEntities: null, showEntityTable: false })
        }

        const filterArticles = (selectedStatuses, teamMember) => {
          const correctionStatuses = selectedStatuses
            .map(s => s.value)
            .filter(s => ['corrigendum', 'erratum', 'retraction'].includes(s))

          const filteredArticles =
            selectedStatuses.length > 0
              ? editorArticles.filter(article => {
                  if (
                    correctionStatuses.length > 0 &&
                    article.correctionStatus
                  ) {
                    const corrections = article.correctionStatus.filter(c =>
                      correctionStatuses.includes(c),
                    )

                    if (corrections.length > 0) {
                      return true
                    }
                  }

                  return selectedStatuses
                    .map(s => s.value)
                    .includes(article.displayStatus)
                })
              : editorArticles.filter(
                  article =>
                    ![
                      'published',
                      'rejected',
                      'declined',
                      'withdrawn',
                      'retraction',
                    ].includes(article.displayStatus),
                )

          if (teamMember) {
            filterByTeamMember(teamMember, filteredArticles)
          } else {
            setState({ filteredArticles })
          }
        }

        const handleSelectedStatuses = selectedStatuses => {
          localStorage.setItem(
            'selectedStatuses',
            JSON.stringify(selectedStatuses),
          )
          setState({ savedStatuses: selectedStatuses })
          filterArticles(selectedStatuses, state.teamMemberFilter)
        }

        const deleteFilterArticle = articleId => {
          deleteArticle({ variables: { id: articleId } }).then(() => {
            const filteredArticles = state.filteredArticles.filter(
              article => article.id !== articleId,
            )

            setState({ filteredArticles })
          })
        }

        const filterByTeamMember = (teamMember, articles) => {
          const teamTypes = [
            'curator',
            'scienceOfficer',
            'editor',
            'sectionEditor',
          ]

          const filteredArticles = articles.filter(article =>
            teamTypes.some(
              type => article[type] && article[type].id === teamMember.value,
            ),
          )

          setState({ filteredArticles })
        }

        const handleSelectedTeamMember = teamMember => {
          setState({ teamMemberFilter: teamMember })

          if (teamMember) {
            filterByTeamMember(teamMember, state.filteredArticles)
          } else {
            filterArticles(savedStatuses, teamMember)
          }
        }

        const handleSelectedCuratorStatus = curatorStatus => {
          setState({ selectedCuratorStatus: curatorStatus })

          if (curatorStatus) {
            const filteredCuratorArticles = curatorArticlesWithStatus.filter(
              a => a.curatorStatus === curatorStatus.value,
            )

            setState({ filteredCuratorArticles })
          } else {
            setState({ filteredCuratorArticles: curatorArticlesWithStatus })
          }
        }

        const editorActions = [
          <Select
            closeMenuOnSelect={false}
            isMulti
            key="action-filter-by-status"
            onChange={handleSelectedStatuses}
            options={options}
            placeholder="Filter By Status"
            value={savedStatuses}
          />,
          <Select
            isClearable
            isSearchable
            key="action-filter-by-team-member"
            onChange={handleSelectedTeamMember}
            options={teamMemberOptions}
            placeholder="Filter By Team Member"
            value={teamMemberFilter}
          />,
        ]

        if (isSectionEditor) {
          editorActions.unshift(
            <Action
              key="action-section-editor-published"
              to="/published/sectionEditor"
            >
              Published Articles (Section Editor)
            </Action>,
          )
        }

        if (isEditor || isAdmin) {
          editorActions.unshift(
            <Action key="action-editor-published" to="/published/editor">
              Published Articles (Editor)
            </Action>,
          )
        }

        if (isEditor || isAdmin) {
          editorActions.unshift(
            <Action
              key="action-editor-all"
              to={`/dashboard${editorAssigned ? '/all' : ''}`}
            >
              {`${editorAssigned ? 'Show' : 'Hide'} Managing Editor Articles`}
            </Action>,
          )
        }

        const curatorActions = [
          <Action key="action-curator-published" to="/published/curator">
            Published Articles
          </Action>,
          <Select
            key="action-filter-by-curator-status"
            onChange={handleSelectedCuratorStatus}
            options={curatorOptions}
            placeholder="Filter By Status"
            value={selectedCuratorStatus}
          />,
        ]

        const soActions = [
          <Action key="action-so-published" to="/published/scienceOfficer">
            Published Articles
          </Action>,
        ]

        return (
          <>
            <DashboardWrapper>
              <Section
                actions={headerActions}
                deleteArticle={deleteArticle}
                itemComponent={AuthorSectionItem}
                items={authorArticles}
                label="My Articles"
                makePayment={makePayment}
                withdrawArticle={withdrawArticle}
              />

              <Section
                handleInvitation={handleInvitation}
                itemComponent={ReviewerSectionItem}
                items={reviewerArticles}
                label="Reviews"
                openReviewerPreviewModal={openReviewerPreviewModal}
              />

              {(isEditor || isAdmin) && (
                <Section
                  actions={editorActions}
                  allEditors={allEditors}
                  allScienceOfficers={allScienceOfficers}
                  deleteArticle={deleteFilterArticle}
                  isAdmin={isAdmin}
                  itemComponent={EditorSectionItem}
                  items={state.filteredArticles}
                  label="Editor Section"
                  onClickOpenEntityTable={openEntityTable}
                  updateAssignedEditor={updateAssignedEditor}
                  updateAssignedScienceOfficer={updateAssignedScienceOfficer}
                  variant="editor"
                />
              )}

              {isSectionEditor && !isEditor && (
                <Section
                  actions={editorActions}
                  allEditors={allEditors}
                  allScienceOfficers={allScienceOfficers}
                  itemComponent={EditorSectionItem}
                  items={sectionEditorArticles}
                  label="Editor Section"
                  updateAssignedEditor={updateAssignedEditor}
                  updateAssignedScienceOfficer={updateAssignedScienceOfficer}
                  variant="editor"
                />
              )}

              {isScienceOfficer && (
                <Section
                  actions={soActions}
                  itemComponent={EditorSectionItem}
                  items={scienceOfficerArticles}
                  label="Science Officer Section"
                />
              )}

              {isCurator && (
                <Section
                  actions={curatorActions}
                  itemComponent={CuratorSectionItem}
                  items={state.filteredCuratorArticles}
                  label="Curator Section"
                />
              )}
            </DashboardWrapper>

            <ArticlePreviewModal
              article={previewData}
              isOpen={showModal}
              onRequestClose={closeModal}
            />

            <UploadModal
              createManuscript={createManuscript}
              isOpen={showUploadModal}
              loadArticle={loadArticle}
              onRequestClose={() => setShowUploadModal(false)}
              upload={upload}
            />

            <DashboardEntityTable
              entities={selectedEntities}
              isOpen={showEntityTable}
              onRequestClose={closeEntityTable}
            />
          </>
        )
      }}
    </State>
  )
}

const Composed = props => <ComposedDashboard render={Dashboard} {...props} />
export default Composed
