/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'

// import { Button } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import { grid } from '../../../ui/src/_helpers'

import Ribbon from './Ribbon'
import Manuscript from '../../../ui/src/preview/Manuscript'
import { ChatModal } from '../../../ui'

import DiscreetButton from './DiscreetButton'
// import ChatModal from './ChatModal'
import PageHeader from './PageHeader'

const Wrapper = styled.div`
  font-family: ${th('fontReading')};
  margin: 0 auto;
  max-width: 1024px;
  /* padding: 0 calc(${th('gridUnit')} * 7) 0 calc(${th('gridUnit')} * 6); */
  /* padding: ${grid(2)} ${grid(7)} 0 ${grid(6)}; */
  padding: ${grid(2)};
`

const StyledPageHeader = styled(PageHeader)`
  margin-top: 0;
`

// const HeaderWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
// `

// const ExportWrapper = styled.div`
//   display: flex;
//   margin-left: auto;

//   button {
//     align-self: center;
//   }
// `

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ArticlePreview = props => {
  const {
    article, // rename
    authorChatMessages,
    categories,
    exportManuscript,
    getSavedAuthorChat,
    isAuthor,
    isEditor,
    isModal,
    showAdditionalData,
    // livePreview,
    // manuscriptId,
    previousVersion,
    saveAuthorChat,
    sendAuthorChatMessage,
    showHeader = true,
  } = props

  const [showChatModal, setShowChatModal] = useState(false)

  const { decision } = article
  const accepted = decision === 'accept'
  const rejected = decision === 'reject'
  const declined = decision === 'decline'
  const withdrawn = decision === 'withdraw'

  let ribbonMessage = `
    Your submission is with the editorial team. 
    You will be notified when there are updates.
  `
  if (accepted) ribbonMessage = 'This article has been accepted'
  if (rejected) ribbonMessage = 'This article has been rejected'
  if (declined) ribbonMessage = 'This article has been declined'
  if (withdrawn) ribbonMessage = 'This article has been withdrawn'

  let ribbonStatus
  if (accepted) ribbonStatus = 'success'
  if (rejected) ribbonStatus = 'error'
  if (declined) ribbonStatus = 'error'
  if (withdrawn) ribbonStatus = 'error'

  // const showAdditionalData = isEditor || isAuthor
  const showChat = isEditor || isAuthor

  const chatMessage =
    showChat && `chat with the ${isEditor ? 'author' : 'editors'}`
  // const showExport = isEditor && accepted

  return (
    <Wrapper>
      {showHeader && (
        <>
          <StyledPageHeader>Article Preview</StyledPageHeader>
          {isAuthor && <Ribbon message={ribbonMessage} status={ribbonStatus} />}
        </>
      )}

      <ButtonWrapper>
        {exportManuscript && (
          <DiscreetButton onClick={() => exportManuscript(article.id)}>
            Export to PDF
          </DiscreetButton>
        )}

        {showChat && (
          <DiscreetButton onClick={() => setShowChatModal(true)}>
            {chatMessage}
          </DiscreetButton>
        )}

        {showChat && (
          <ChatModal
            getSavedChat={getSavedAuthorChat}
            headerText={chatMessage}
            isOpen={showChatModal}
            messages={authorChatMessages}
            onRequestClose={() => setShowChatModal(false)}
            saveChat={saveAuthorChat}
            sendMessage={sendAuthorChatMessage}
          />
        )}
      </ButtonWrapper>

      {/* {!livePreview && accepted && (
        <HeaderWrapper>
          {showHeader && <PageHeader>Article Preview</PageHeader>}

          {!isAuthor && isEditor && (
            <ExportWrapper>
              <Button onClick={() => exportManuscript(manuscriptId)} primary>
                Export to HTML
              </Button>
            </ExportWrapper>
          )}
        </HeaderWrapper>
      )} */}

      {article && (
        <Manuscript
          // livePreview={livePreview}
          categories={categories}
          isModal={isModal}
          previousVersion={previousVersion}
          showAdditionalData={showAdditionalData}
          version={article}
          // showDiff={showDiff}
        />
      )}

      {!article && 'No data'}
    </Wrapper>
  )
}

export default ArticlePreview
