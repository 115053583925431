/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
import ReactModal from 'react-modal'
import styled, { css } from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import Body from './ModalBody'

ReactModal.setAppElement('#root')

/*
  This is to make react modal and styled components play nice
  See https://github.com/styled-components/styled-components/issues/1494#issuecomment-363362709
*/
function ReactModalAdapter({ className, modalClassName, ...props }) {
  return (
    <ReactModal
      className={modalClassName}
      closeTimeoutMS={150}
      portalClassName={className}
      {...props}
    />
  )
}

const large = css`
  bottom: 40px;
  left: 40px;
  right: 40px;
  top: 40px;
`

/**
 * Set heigth and width to desired size
 *
 * left and top 50% will make the top left point of the box to start at the
 * middle of the screen
 *
 * negative margins will offset the middle point to the center of the box
 * margin-left should be half the width
 * margin-top should be half the height
 */
const medium = css`
  height: 600px;
  left: 50%;
  margin-left: -450px;
  margin-top: -300px;
  top: 50%;
  width: 900px;
`

const small = css`
  height: 250px;
  left: 50%;
  margin-left: -250px;
  margin-top: -250px;
  top: 50%;
  width: 500px;
`

const StyledModal = styled(ReactModalAdapter).attrs(props => ({
  modalClassName: 'Modal',
  overlayClassName: 'Overlay',
}))`
  .Overlay {
    background-color: rgba(240, 240, 240, 0.85);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .Modal {
    background: ${th('colorBackground')};
    border: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    outline: none;
    overflow: hidden;
    padding: 0;
    position: absolute;

    /* stylelint-disable order/properties-alphabetical-order */
    ${props => props.size === 'large' && large};
    ${props => props.size === 'medium' && medium};
    ${props => props.size === 'small' && small};
    /* stylelint-enable order/properties-alphabetical-order */
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`

const Modal = props => {
  const {
    children,
    className,
    footerComponent = null,
    headerComponent = null,
    onRequestClose,
    ...rest
  } = props

  /* eslint-disable-next-line react/destructuring-assignment */
  if (!props.isOpen) return null

  return (
    <StyledModal
      className={className}
      onRequestClose={onRequestClose}
      {...rest}
    >
      {headerComponent &&
        React.cloneElement(headerComponent, {
          onRequestClose,
          ...rest,
        })}

      <Body>{children}</Body>

      {footerComponent &&
        React.cloneElement(footerComponent, {
          onRequestClose,
          ...rest,
        })}
    </StyledModal>
  )
}

export default Modal
