/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import PropTypes from 'prop-types'

import WarningModal from './WarningModal'

/**
 * General component description in JSDoc format. Markdown is *supported*.
 */
const SubmissionConfirmationModal = props => {
  const {
    isFullSubmission,
    isInitialSubmission,
    isRevisionSubmission,
    isProofSubmission,
    ...rest
  } = props

  return (
    <WarningModal
      headerText="confirm your submission"
      textSuccess="yes"
      {...rest}
    >
      {isInitialSubmission &&
        `You are submitting your article to the editorial office. Are you ready 
        to submit?`}

      {isFullSubmission &&
        `You are submitting your data, it will not be editable until after
        editorial review. Are you ready to submit?`}

      {isRevisionSubmission &&
        `You are submitting a revision of your article. You will not have 
        editing access while the changes to your manuscript are reviewed. 
        Are you ready to continue?`}
      {isProofSubmission &&
        `You are submitting a Proof version of your article.
        Please make sure there are no typos, errors or omissions, including in 
        your title, author names, affiliations, reagents, etc. in addition to your 
        reported results. These are little things that if wrong will require a 
        separate corrigendum article if they need correction after publication.`}
    </WarningModal>
  )
}

SubmissionConfirmationModal.propTypes = {
  isFullSubmission: PropTypes.bool.isRequired,
  isInitialSubmission: PropTypes.bool.isRequired,
  isRevisionSubmission: PropTypes.bool.isRequired,
  isProofSubmission: PropTypes.bool.isRequired,
}

export default SubmissionConfirmationModal
