import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import { Icon, Ribbon } from '../common'

const LockWrapper = styled.div`
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 0 calc(${th('gridUnit')} / 2) 0 ${th('gridUnit')};
  text-transform: uppercase;
  transition: background ${th('transitionDuration')}
    ${th('transitionTimingFunction')};
  user-select: none;

  :hover {
    background: ${th('colorBackgroundHue')};
  }

  > :first-child {
    align-items: center;
    color: ${th('colorPrimary')};
    display: inline-flex;
    margin-top: 4px;
  }
`

/* eslint-disable-next-line react/prop-types */
const Lock = ({ locked, onClick }) => {
  const action = `${locked ? 'lock' : 'unlock'}`
  const actionText = `${locked ? 'unlock' : 'lock'}`
  const message = `Click to ${actionText} tabs`

  return (
    <LockWrapper onClick={onClick}>
      <span>{message}</span>
      <Icon size={2}>{action}</Icon>
    </LockWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledRibbon = styled(Ribbon)`
  flex-grow: 1;
  margin: 0 calc(${th('gridUnit')} * 2) 0 0;
`

const warningMessage = `
  Version tabs between the two panes are not in sync. 
  Make sure you are looking at the correct version of the article.
`

const TabsLock = props => {
  const { className, locked, onClick } = props

  return (
    <Wrapper className={className}>
      {!locked && <StyledRibbon message={warningMessage} status="warning" />}
      <Lock locked={locked} onClick={onClick} />
    </Wrapper>
  )
}

TabsLock.propTypes = {
  /** Whether tabs are synced or not */
  locked: PropTypes.bool.isRequired,
  /** Function to run on clicking lock/unlock */
  onClick: PropTypes.func.isRequired,
}

export default TabsLock
