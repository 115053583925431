/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Icon, TextField } from '../common'

const Row = styled.div`
  display: flex;
`

const ColOne = styled.div`
  min-width: 150px;
`

const ColTwo = styled.div`
  min-width: 300px;
`

const ColThree = styled.div`
  min-width: 150px;
`

const ColFour = styled.div`
  min-width: 100px;
`

const ColFive = styled.div``

const StyledButton = styled(Button)`
  min-width: 0;
`

const StyledInput = styled(TextField)`
  width: 100px;
`

const formatDate = date => {
  const jsDate = new Date(Number(date))
  return jsDate.toISOString().split('T')[0]
}

const Reminders = props => {
  const { remindersData, cancelJob, rescheduleJob } = props
  const [confirmDelete, setConfirmDelete] = useState(null)
  const [reschedule, setReschedule] = useState(null)
  const [newDate, setNewDate] = useState(0)

  const deleteJob = jobName => {
    cancelJob({ variables: { jobName } })
    setConfirmDelete(null)
  }

  const setDate = (jobName, date) => {
    rescheduleJob({
      variables: { jobName, date: `${new Date(date).valueOf()}` },
    }).then(() => setReschedule(null))
  }

  const handleChange = e => setNewDate(e.target.value)

  return (
    <>
      <Row key="header">
        <ColOne>Job Type</ColOne>
        <ColTwo>Job ID</ColTwo>
        <ColThree>Run Date</ColThree>
        <ColFour>Delay Job</ColFour>
        <ColFive>Cancel Job</ColFive>
      </Row>
      {remindersData &&
        remindersData.map(r => {
          const jobType = r.name.substring(0, r.name.length - 37)
          const uuid = r.name.slice(-36)
          return (
            <>
              <Row key={r.name}>
                <ColOne>{jobType.replace(/-/g, ' ')}</ColOne>
                <ColTwo>{uuid}</ColTwo>
                <ColThree>{formatDate(r.runDate)}</ColThree>
                <ColFour>
                  <StyledButton
                    onClick={() => {
                      setConfirmDelete(null)
                      setReschedule(r.name)
                      setNewDate(formatDate(r.runDate))
                    }}
                  >
                    <Icon>edit</Icon>
                  </StyledButton>
                </ColFour>
                <ColFive>
                  <StyledButton
                    onClick={() => {
                      setReschedule(null)
                      setConfirmDelete(r.name)
                    }}
                  >
                    <Icon>x-square</Icon>
                  </StyledButton>
                </ColFive>
              </Row>

              {reschedule && reschedule === r.name && (
                <Row key={`edit-${r.name}`}>
                  <ColOne>New Date:</ColOne>
                  <ColTwo>
                    <StyledInput handleChange={handleChange} value={newDate} />
                  </ColTwo>
                  <ColThree>
                    <StyledButton onClick={() => setDate(r.name, newDate)}>
                      Save
                    </StyledButton>
                  </ColThree>
                  <ColFour>
                    <StyledButton onClick={() => setReschedule(null)}>
                      Cancel
                    </StyledButton>
                  </ColFour>
                </Row>
              )}
              {confirmDelete && confirmDelete === r.name && (
                <Row key={`cancel-${r.name}`}>
                  <ColTwo>Are you sure?</ColTwo>
                  <ColThree>
                    <StyledButton onClick={() => deleteJob(r.name, r.userId)}>
                      Yes
                    </StyledButton>
                  </ColThree>
                  <ColFour>
                    <StyledButton onClick={() => setConfirmDelete(null)}>
                      No
                    </StyledButton>
                  </ColFour>
                </Row>
              )}
            </>
          )
        })}
    </>
  )
}

export default Reminders
