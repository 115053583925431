import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/pubmed/fetch`

const get = async (endpoint, value) => {
  const url = `${apiUrl}/${endpoint}?id=${value}`
  return fetch(url)
}

const validate = async (endpoint, value) => {
  const { id } = value
  const url = `${apiUrl}/validate/${endpoint}?id=${id}`
  return fetch(url)
}

const getReference = value => get('reference', value)

const validateReference = value => validate('reference', value)

const ncbiAccessionLookup = value =>
  fetch(`${serverUrl}/api/ncbi/fetch?id=${value}`)

export { getReference, validateReference, ncbiAccessionLookup }
