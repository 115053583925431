/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { th } from '@pubsweet/ui-toolkit'
import FileUpload from '../common/FileUpload'
import CsvPreview from './CsvPreview'

// TO DO -- extract Labels from TextField
const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
`

const Img = styled.img`
  max-width: 600px;
`

const StyledFileUpload = styled(FileUpload)`
  div > div:first-of-type {
    border-style: solid;
    height: 200px;
    width: 400px;
  }
`

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const Wrapper = styled.div`
  margin-bottom: calc(${th('gridUnit')} * 2);
`

const Image = props => {
  const {
    'data-test-id': dataTestId,
    errors,
    fileProgress,
    label,
    name,
    readOnly,
    required,
    setFieldTouched,
    setFieldValue,
    touched,
    upload,
    values,
  } = props

  const [internalError, setInternalError] = useState(null)

  const formError = get(errors, 'image.url')
  const touchedThis = get(touched, 'image')
  const error = internalError || (touchedThis && formError)

  const message = (
    <p>
      Drop an image (jpeg, png under 10 MB) or csv
      <br />
      here {values.image && 'to replace current'} or click to select
    </p>
  )

  return (
    <Wrapper>
      {label && (
        <>
          <Label>
            {label} {required && ' *'}
          </Label>
          {touched && <Error>{error}</Error>}
        </>
      )}
      <StyledFileUpload
        accept={['image/jpeg', 'image/png', 'text/csv']}
        data-test-id={dataTestId}
        fileProgress={fileProgress}
        message={message}
        name={name}
        readOnly={readOnly}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        setInternalError={setInternalError}
        upload={upload}
      />
      {values.image && values.image.name && (
        <>
          {values.image.name.toLowerCase().split('.').pop() !== 'csv' && (
            <Img
              alt={values.image.name}
              src={values.image.preview || values.image.url}
            />
          )}
          {values.image.name.toLowerCase().split('.').pop() === 'csv' &&
            values.image.data && (
              <CsvPreview
                csv={values.image.data}
                header={values.image.header}
              />
            )}
          {values.image.name}
        </>
      )}
    </Wrapper>
  )
}

export default Image
