/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import camelCase from 'lodash/camelCase'
import Papa from 'papaparse'

import { th } from '@pubsweet/ui-toolkit'
import { uuid } from '@coko/client'

import { FileUpload, Icon } from '../common'

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const StyledIcon = styled(Icon)`
  svg {
    height: calc(${th('gridUnit')} * 2);
    stroke: ${th('colorFurniture')};
  }
`

const truthList = ['true', 'True', 'TRUE', 'yes', 'Yes', 'YES']

const AuthorCsvUpload = props => {
  const {
    authors,
    name,
    setFieldValue,
    setFieldTouched,
    touched,
    readOnly,
  } = props

  const [internalError, setInternalError] = useState(null)

  const upload = async file =>
    new Promise(resolve =>
      Papa.parse(file, {
        header: true,
        complete: results => {
          const newAuthors = results.data
            .map(row => {
              if (row['Last Name']) {
                const newAuthor = {
                  id: uuid(),
                  firstName: row['First Name'],
                  lastName: row['Last Name'],
                  affiliations: [row.Affiliation],
                  departments: [row.Department || ''],
                  orcid: row.ORCiD,
                  email: row.Email,
                  credit:
                    row['Credit Contributions'] &&
                    row['Credit Contributions'].split(', ').map(c => {
                      if (
                        c === 'Writing - original draft' ||
                        c === 'Writing – original draft'
                      ) {
                        return 'writing_originalDraft'
                      }

                      if (
                        c === 'Writing - review & editing' ||
                        c === 'Writing – review & editing'
                      ) {
                        return 'writing_reviewEditing'
                      }

                      if (c === 'Project administration') {
                        return 'project'
                      }

                      return camelCase(c)
                    }),
                  academicStatus:
                    row['Academic Status'] &&
                    row['Academic Status'].toLowerCase(),
                  correspondingAuthor: truthList.includes(
                    row['Corresponding Author'],
                  ),
                  submittingAuthor: truthList.includes(
                    row['Submitting Author'],
                  ),
                  equalContribution: truthList.includes(
                    row['Equal Contribution'],
                  ),
                }

                return newAuthor
              }

              return null
            })
            .filter(a => a)

          setFieldValue(name, [...authors, ...newAuthors])

          const res = { data: { upload: { url: null } } }
          resolve(res)
        },
      }),
    )

  const message = <p>Drag CSV file here, or click to select CSV file</p>

  return (
    <p>
      <p>
        Upload Author CSV File.{' '}
        <StyledIcon title="For long author's lists, it may be easier for you to fill out a spreadsheet. If you are interested in doing this, please contact editors@micropublication.org">
          help_circle
        </StyledIcon>
      </p>
      {touched && internalError && <Error>{internalError}</Error>}
      <FileUpload
        message={message}
        name="authorCsv"
        readOnly={readOnly}
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        setInternalError={setInternalError}
        upload={upload}
      />
    </p>
  )
}

export default AuthorCsvUpload
