import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery, useMutation } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'

import {
  VALIDATE_PASSWORD_RESET_TOKEN_EXPIRY,
  RESET_PASSWORD,
} from '../graphql'
import { ResetPassword as Page } from '../../ui'

const ResetPassword = styled(Page)`
  margin: 0 auto;
  width: 40ch;
`

const ResetPasswordPage = props => {
  const history = useHistory()
  const { token } = useParams()
  const timeout = 2000

  const [loaderDelay, setLoaderDelay] = useState(true)
  setTimeout(() => {
    setLoaderDelay(false)
  }, timeout)

  const {
    data: validateExpiry,
    loading: validateExpiryLoading,
    error: validateExpiryError,
  } = useQuery(VALIDATE_PASSWORD_RESET_TOKEN_EXPIRY, {
    fetchPolicy: 'network-only',
    variables: { token },
  })

  const [
    resetPassword,
    { data: resetPasswordData, error: resetPasswordError },
  ] = useMutation(RESET_PASSWORD)

  const submitNewPassword = password => {
    resetPassword({
      variables: {
        password,
        token,
      },
    }).then(res => {
      setTimeout(() => {
        history.push('/login')
      }, timeout)
    })
  }

  return (
    <ResetPassword
      error={!!validateExpiryError || !!resetPasswordError}
      expired={validateExpiry && !validateExpiry.validatePasswordTokenExpiry}
      submitNewPassword={submitNewPassword}
      success={
        !resetPasswordError &&
        resetPasswordData &&
        resetPasswordData.resetPassword
      }
      verifyingToken={validateExpiryLoading || loaderDelay}
    />
  )
}

export default ResetPasswordPage
