import { gql } from '@apollo/client'

const TEAM_MANAGER_DATA = gql`
  query TeamManagerData {
    getGlobalTeams {
      id
      name
      role
      members {
        id
        user {
          id
          displayName
        }
      }
    }

    users {
      id
      displayName
    }
  }
`

const UPDATE_GLOBAL_TEAMS = gql`
  mutation UpdateGlobalTeams($input: UpdateTeamMembershipInput!) {
    updateGlobalTeamMembership(input: $input)
  }
`

export { TEAM_MANAGER_DATA, UPDATE_GLOBAL_TEAMS }
