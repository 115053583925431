import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import InviteReviewer from './InviteReviewer'
import ReviewerList from './ReviewerList'
import SearchBox from './SearchBox'
import SuggestedReviewer from './SuggestedReviewer'
import ReviewerMessage from './ReviewerMessage'
import { Button, Checkbox, NumberInput, Ribbon, PageHeader } from '../common'
import { grid, th } from '../_helpers'

const Wrapper = styled.div``

const Separator = styled.div`
  border-bottom: 2px solid ${th('colorPrimary')};
  margin: ${grid(5)} ${grid(3)};
`

const StyledPageHeader = styled(PageHeader)`
  margin-top: 0;
`

const Top = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: ${grid(3)};

  label:last-of-type {
    margin-top: 0;
  }
`

const Head = styled.div`
  margin-bottom: ${grid(1)};
`

const HeadSecondRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  > div > button:not(:last-child) {
    margin-right: ${grid(1)};
  }
`

const Search = styled(SearchBox)`
  margin-bottom: ${grid(1)};
`

const AssignReviewers = props => {
  const {
    addExternalReviewer,
    amountOfReviewers,
    automate,
    canAddMore,
    className,
    onAmountOfReviewersChange,
    onClickReviewer,
    // maxReviewers,
    reviewerPool,
    onReviewerPoolReorder,
    onClickInvite,
    onClickRemoveRow,
    onClickRevokeInvitation,
    onClickStart,
    onClickStop,
    search,
    setShowAddMessage,
    setShowEmails,
    showAddMessage,
    showEmails,
    suggestedReviewerName,
    reviewerMessage,
    saveReviewerMessage,
  } = props

  // Explainer text: Adding reviewers to the list won't send them an invite yet
  return (
    <Wrapper className={className}>
      <StyledPageHeader>Assign Reviewers</StyledPageHeader>

      {suggestedReviewerName && (
        <Top>
          <SuggestedReviewer name={suggestedReviewerName} />
        </Top>
      )}
      <Checkbox
        checkBoxText="Show reviewer emails"
        checked={showEmails}
        inline
        onChange={() => setShowEmails(!showEmails)}
      />
      <Search
        onClickOption={onClickReviewer}
        placeholder="Add a reviewer to the list"
        search={search}
      />

      <Head>
        <Ribbon
          message={automate ? 'Automation is on' : 'Automation is off'}
          status={automate && 'success'}
        />

        <HeadSecondRow>
          <div>
            <Button disabled={automate} onClick={onClickStart} primary>
              start
            </Button>
            <Button disabled={!automate} onClick={onClickStop} primary>
              stop
            </Button>
          </div>
          {!reviewerMessage && (
            <Button
              disabled={showAddMessage}
              onClick={() => setShowAddMessage(true)}
              primary
            >
              add message to reviewer
            </Button>
          )}
          <NumberInput
            disabled={automate}
            label="How many reviewers"
            onChange={onAmountOfReviewersChange}
            value={amountOfReviewers}
          />
        </HeadSecondRow>
      </Head>

      <ReviewerMessage
        reviewerMessage={reviewerMessage}
        saveReviewerMessage={saveReviewerMessage}
        setShowAddMessage={setShowAddMessage}
        showAddMessage={showAddMessage}
      />

      <ReviewerList
        canAddMore={canAddMore}
        onClickInvite={onClickInvite}
        onClickRemoveRow={onClickRemoveRow}
        onClickRevokeInvitation={onClickRevokeInvitation}
        onReorder={onReviewerPoolReorder}
        reviewers={reviewerPool}
      />

      <Separator />

      <InviteReviewer sendInvitation={addExternalReviewer} />
    </Wrapper>
  )
}

AssignReviewers.propTypes = {
  /** Function that sends invitation to non-signed up users. Must return a promise. */
  addExternalReviewer: PropTypes.func.isRequired,
  /** Maximum amount of reviewers that can be invited at the same time */
  amountOfReviewers: PropTypes.number.isRequired,
  /** Whether reviewer invitation automation is on for this manuscript version */
  automate: PropTypes.bool.isRequired,
  /** Whether more reviewers can be invited */
  canAddMore: PropTypes.bool.isRequired,
  /** Handle change in amount of reviewers input */
  onAmountOfReviewersChange: PropTypes.func.isRequired,
  /** Function to run when "invite" is clicked on a row from the pool */
  onClickInvite: PropTypes.func.isRequired,
  /** Function to run when the "X" button is clicked on a row from the pool */
  onClickRemoveRow: PropTypes.func.isRequired,
  /** Function to run on selecting a reviewer in the search box */
  onClickReviewer: PropTypes.func.isRequired,
  /** Function to run when "revoke invitation" is clicked on a row from the pool */
  onClickRevokeInvitation: PropTypes.func.isRequired,
  /** Function to run when "start automation" is clicked */
  onClickStart: PropTypes.func.isRequired,
  /** Function to run when "stop automation" is clicked */
  onClickStop: PropTypes.func.isRequired,
  /** Function to run when a drag and drop event ends */
  onReviewerPoolReorder: PropTypes.func.isRequired,
  /** Reviewers added to the pool list. Shape defined in ReviewerRow */
  reviewerPool: PropTypes.arrayOf(PropTypes.object),
  /** Function that returns a promise. Must resolve to an array objects, each with shape `{ value: <String>, label: <String>, isDisabled: <Boolean>, status: <String>}` */
  search: PropTypes.func.isRequired,
  setShowEmails: PropTypes.func.isRequired,
  showEmails: PropTypes.func.isRequired,
  setShowAddMessage: PropTypes.func.isRequired,
  showAddMessage: PropTypes.func.isRequired,
  /** Display name of suggested reviewer */
  suggestedReviewerName: PropTypes.string,
  /** Additional message to send to reviewer */
  reviewerMessage: PropTypes.string,
  saveReviewerMessage: PropTypes.func.isRequired,
}

AssignReviewers.defaultProps = {
  reviewerPool: [],
  suggestedReviewerName: null,
  reviewerMessage: null,
}

export default AssignReviewers
