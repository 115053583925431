/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { diffArrays } from 'diff'
import last from 'lodash/last'
import isEqual from 'lodash/isEqual'
import uniqueId from 'lodash/uniqueId'

import { th } from '@pubsweet/ui-toolkit'

import { diff } from './_cssFragments'

/* DEFAULT COMPONENT */
const StyledDefault = styled.span`
  margin-right: ${props => !props.isLast && th('gridUnit')};
  ${diff}
`

const DefaultComponent = props => {
  const { children, isAdded, isRemoved, isLast, showDiff, value } = props

  return (
    <StyledDefault
      isAdded={isAdded}
      isLast={isLast}
      isRemoved={isRemoved}
      showDiff={showDiff}
    >
      {children || value || null}
    </StyledDefault>
  )
}

DefaultComponent.propTypes = {
  children: PropTypes.node,
  isAdded: PropTypes.bool,
  isLast: PropTypes.bool,
  isRemoved: PropTypes.bool,
  showDiff: PropTypes.bool,
  value: PropTypes.string,
}

DefaultComponent.defaultProps = {
  children: null,
  isAdded: false,
  isLast: false,
  isRemoved: false,
  showDiff: true,
  value: null,
}
/* END DEFAULT COMPONENT */

const DiffArray = props => {
  const {
    component: Component,
    componentProps,
    currentArray,
    previousArray,
    showDiff,
    showRemoved,
  } = props

  if (!currentArray || currentArray.length === 0) return null

  let sections

  if (previousArray) {
    sections = diffArrays(previousArray, currentArray, { comparator: isEqual })
  } else {
    sections = [
      {
        value: currentArray,
      },
    ]
  }

  const hasSingleItem = currentArray.length === 1

  return (
    <>
      {sections.map(section =>
        section.value.map(item => {
          if (section.removed && (!showRemoved || !showDiff)) return null

          let isLast

          if (typeof item === 'string' || typeof item === 'number') {
            isLast = item === last(last(sections))
            return (
              <Component
                isAdded={section.added}
                isLast={isLast}
                isRemoved={section.removed}
                key={uniqueId()}
                {...componentProps}
              >
                {item}
              </Component>
            )
          }

          const { id, ...rest } = item

          isLast = isEqual(item, last(currentArray))

          // isLast = item.id === last(last(sections).value).id
          // id wasn't assigned, so using name instead for comparison
          // isLast = isEqual(item.name, last(last(sections).value).name)

          return (
            <Component
              hasSingleItem={hasSingleItem}
              isAdded={section.added}
              isLast={isLast}
              isRemoved={section.removed}
              key={id || uniqueId()}
              showDiff={showDiff}
              {...componentProps}
              {...rest}
            />
          )
        }),
      )}
    </>
  )
}

DiffArray.propTypes = {
  /** The component to render for each item in the array */
  component: PropTypes.node,
  /** Props to be passed to "component" */
  /* eslint-disable-next-line react/forbid-prop-types */
  componentProps: PropTypes.object,
  /** Current array of values */
  currentArray: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  /** Previous array of values for comparison */
  previousArray: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  /** Whether to show diffs at all */
  showDiff: PropTypes.bool,
  /** Whether to show diff removals */
  showRemoved: PropTypes.bool,
}

DiffArray.defaultProps = {
  component: DefaultComponent,
  componentProps: {},
  previousArray: null,
  showDiff: true,
  showRemoved: true,
}

export default DiffArray
