import styled from 'styled-components'

import { H4 } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

const Header = styled(H4)`
  border-bottom: 2px solid ${th('colorPrimary')};
  color: ${th('colorPrimary')};
  text-transform: uppercase;
`

export default Header
