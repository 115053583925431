import { gql } from '@apollo/client'

const BILLING = gql`
  query Billing {
    manuscripts(role: "billing") {
      id
      history {
        accepted
        published
      }
      versions {
        authors {
          firstName
          lastName
          email
          submittingAuthor
          correspondingAuthor
        }
        title
      }
      paymentStatus
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { BILLING }
