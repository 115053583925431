import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import uniqueId from 'lodash/uniqueId'
// import { diffWords } from 'diff'
import htmldiff from 'htmldiff'

import { th } from '@pubsweet/ui-toolkit'

import { AbstractEditor } from '../../../app/xpubEdit'
import { diff, smallFontReading } from './_cssFragments'
import { isHTMLEmpty } from '../_helpers'
import Label from './Label'

const noop = () => {}

const titleStyles = css`
  font-size: ${th('fontSizeHeading4')};
  font-weight: bold;
  line-height: ${th('lineHeightHeading4')};
`

const captionStyles = css`
  font-size: ${th('fontSizeBaseSmall')};

  p {
    display: inline-block;
    margin-left: calc(${th('gridUnit')} / 2);
    padding-top: calc(${th('gridUnit')} / 2);
    text-align: justify;

    &:first-child {
      padding-top: 0;

      &::before {
        content: 'Figure 1.';
        font-weight: bold;
        margin-right: calc(${th('gridUnit')} / 2);
      }
    }
  }
`

const metadataStyles = css`
  font-family: ${th('fontInterface')};
`

const linkerStyles = css`
  i {
    color: magenta;
  }
`

const StyledEditor = styled(AbstractEditor)`
  border: 0;
  font-family: ${th('fontReading')};
  font-size: ${th('fontSizeBase')};
  line-height: ${th('lineHeightBase')};
  margin-bottom: 0;
  padding: 0;

  /* stylelint-disable-next-line order/order, order/properties-alphabetical-order */
  ${diff}

  span[data-diff-added='true'] {
    background: ${th('colorAddition')};
  }

  ins {
    background: ${th('colorAddition')};
    text-decoration: none;
  }

  del {
    background: ${th('colorRemoval')};
    text-decoration: none;
  }

  span[data-diff-removed='true'] {
    background: ${th('colorRemoval')};
  }

  /* stylelint-disable-next-line order/order, order/properties-alphabetical-order */
  ${props => {
    const { variant } = props

    if (variant) {
      if (variant === 'title') return titleStyles
      if (variant === 'caption') return captionStyles
      if (variant === 'metadata') return metadataStyles
      if (variant === 'equalContribution') return smallFontReading
      if (variant === 'linker') return linkerStyles
    }

    return null
  }}
`

const diffIt = (oldValues, newValues, options = {}) => {
  const { isAdded, isRemoved, showRemoved } = options

  if (typeof oldValues !== 'string') {
    if (isAdded) return `<span data-diff-added="true">${newValues}</span>`
    if (isRemoved) return `<span data-diff-removed="true">${newValues}</span>`
    return newValues
  }

  // const value = stripHTML(newValues)
  // const previousValue = stripHTML(oldValues)
  // const difference = diffWords(previousValue, value)

  let differences = htmldiff(oldValues, newValues)

  if (!showRemoved) {
    differences = differences.replace(/<del .+?<\/del>/g, '')
  }

  return differences

  /*
    TO DO -- do not use this because tokenizing html strings might break words
             mid-tag, in turn causing tags to show in the text
             Revisit at some point, but it will probably need a different
             algorithm / library
  */
  // const difference = diffWords(oldValues, newValues)
  /*
  const sections = difference.map(item => {
    if (item.added) return `<span data-diff-added="true">${item.value}</span>`

    if (item.removed) {
      if (showRemoved)
        return `<span data-diff-removed="true">${item.value}</span>`

      return ''
    }

    return item.value
  })

  return sections.join('')
  */
}

const Editor = props => {
  const {
    handleSelect,
    isAdded,
    isRemoved,
    label,
    previousValue,
    showDiff,
    showRemoved,
    figureTitle,
    previousFigureTitle,
    value,
    variant,
  } = props

  if (isHTMLEmpty(value)) return null
  let output = value

  if (value && showDiff) {
    output = diffIt(previousValue, value, { isAdded, isRemoved, showRemoved })
  }

  let figureTitleOutput = figureTitle

  if (figureTitle) {
    if (showDiff) {
      figureTitleOutput = diffIt(previousFigureTitle, figureTitle, {
        isAdded,
        isRemoved,
        showRemoved,
      })
    }

    output = `<b>${figureTitleOutput}:</b>  ${output}`
  }

  if (label)
    return (
      <div>
        <Label value={label} />

        <StyledEditor
          key={uniqueId()}
          onBlur={noop}
          onChange={noop}
          onSelect={handleSelect}
          readonly
          value={output}
          variant={variant}
        />
      </div>
    )

  return (
    <StyledEditor
      figureTitle=""
      key={uniqueId()}
      onBlur={noop}
      onChange={noop}
      onSelect={handleSelect}
      readonly
      value={output}
      variant={variant}
    />
  )
}

Editor.propTypes = {
  /** Function to hanle when text is selected */
  handleSelect: PropTypes.func,
  /** Whether the whole text is an addition */
  isAdded: PropTypes.bool,
  /** Whether the whole text is a removal */
  isRemoved: PropTypes.bool,
  /** Label to be displayed above editor */
  label: PropTypes.string,
  /** Old value to compare against for diffing */
  previousValue: PropTypes.string,
  /** Control whether to display diffs at all */
  showDiff: PropTypes.bool,
  /** Show diffs, but hide removed parts */
  showRemoved: PropTypes.bool,
  /** Figure title */
  figureTitle: PropTypes.string,
  /** Old Figure title */
  previousFigureTitle: PropTypes.string,
  /** Text that will be rendered. Not required as the preview could be partially complete. (eg. before submission) */
  value: PropTypes.string,
  /** Different style setups */
  variant: PropTypes.oneOf([
    'abstract',
    'caption',
    'metadata',
    'title',
    'linker',
    'equalContribution',
  ]),
}

Editor.defaultProps = {
  handleSelect: null,
  isAdded: false,
  isRemoved: false,
  label: null,
  previousValue: null,
  showDiff: true,
  showRemoved: true,
  figureTitle: null,
  previousFigureTitle: null,
  value: null,
  variant: null,
}

export default Editor
