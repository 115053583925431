import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'

import { uuid } from '@coko/client'

import { grid, th } from '../_helpers'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
`

const Side = styled.div`
  display: flex;
  flex-direction: row;

  padding: 2px 0;
`

const Label = styled.div`
  align-self: right;
  flex-basis: 25%;
  font-variant-ligatures: none;
  margin-right: ${grid(1)};
  text-align: right;

  &::after {
    content: ':';
  }
`

const Value = styled.div`
  color: ${props => {
    const { status } = props
    if (status === 'primary') return th('colorPrimary')
    if (status === 'error') return th('colorError')
    if (status === 'warning') return th('colorWarning')
    if (status === 'success') return th('colorSuccess')
    return th('colorText')
  }};
`

const MissingValue = styled.div`
  color: ${th('colorError')};
  text-transform: uppercase;
`

/**
 * List of key/value pairs with the labels justified to the right.
 * Supports colors in the values and customizable missing value text.
 */
const ValueList = props => {
  const { className, data, missingValueText } = props
  if (!data || data.length === 0) return null

  return (
    <Wrapper className={className}>
      {data.map(item => (
        <>
          <Side>
            <Label key={uuid()}>{item.label}</Label>

            {item.value && item.value.length !== 0 ? (
              <Value key={uuid()} status={item.status}>
                {Array.isArray(item.value)
                  ? item.value.map(v => capitalize(v)).join(', ')
                  : item.value}
              </Value>
            ) : (
              <MissingValue key={uuid()}>{missingValueText}</MissingValue>
            )}
          </Side>
        </>
      ))}
    </Wrapper>
  )
}

ValueList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      status: PropTypes.oneOf(['primary', 'success', 'warning', 'error']),
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
  ),
  missingValueText: PropTypes.string,
}

ValueList.defaultProps = {
  data: [],
  missingValueText: 'not set',
}

export default ValueList
