import React from 'react'
import { useParams } from 'react-router-dom'

import { useQuery, useMutation } from '@apollo/client'
import clone from 'lodash/clone'
import get from 'lodash/get'
import last from 'lodash/last'

import CurationTool from '../../ui/src/curationTool/CurationTool'
import {
  CURATION_ANNOTATIONS,
  SAVE_ANNOTATION,
  FULL_PREVIEW,
  DELETE_ANNOTATION,
} from '../graphql'
import { transformAnnotations, transformEntities } from './_helpers/common'
import { linkHTML } from './_helpers/linking'

const CurationToolPage = props => {
  const { id: manuscriptId } = useParams()

  const { data: manuscript, loading: manuscriptLoading } = useQuery(
    FULL_PREVIEW,
    {
      variables: { id: manuscriptId },
    },
  )

  const entities =
    manuscript &&
    manuscript.manuscript &&
    transformEntities(get(manuscript.manuscript, 'entities'))

  const { data, loading } = useQuery(CURATION_ANNOTATIONS, {
    variables: { manuscriptId },
  })

  const oldAnnotations = data && transformAnnotations(data.curationAnnotations)

  const [deleteAnnotation] = useMutation(DELETE_ANNOTATION, {
    refetchQueries: [
      {
        query: CURATION_ANNOTATIONS,
        variables: { manuscriptId },
      },
    ],
  })

  const [saveAnnotation] = useMutation(SAVE_ANNOTATION, {
    refetchQueries: [
      {
        query: CURATION_ANNOTATIONS,
        variables: { manuscriptId },
      },
    ],
  })

  const saveAnnotations = annotations => {
    annotations.expression.forEach(annotation => {
      const input = clone(annotation)

      delete input.dateAssigned
      delete input.assay.modId

      input.whereExpressed = clone(annotation.whereExpressed)
      input.whenExpressed = clone(annotation.whenExpressed)
      input.cellularComponent = clone(annotation.cellularComponent)

      saveAnnotation({
        variables: { manuscriptId, type: 'expression', input },
      })
    })

    annotations.phenotype.forEach(annotation => {
      const input = clone(annotation)
      delete input.dateAssigned
      delete input.alleles
      delete input.transgenes

      input.anatomyTerms = clone(annotation.anatomyTerms)
      input.lifeStages = clone(annotation.lifeStages)
      input.genes = clone(annotation.genes)
      input.strains = clone(annotation.strains)
      input.assay = { value: annotation.assay }

      saveAnnotation({
        variables: {
          manuscriptId,
          type: 'phenotype',
          input,
        },
      })
    })

    annotations.anatomyFunction.forEach(annotation => {
      const input = clone(annotation)
      delete input.dateAssigned

      input.anatomyTerms = clone(annotation.anatomyTerms)
      saveAnnotation({
        variables: {
          manuscriptId,
          type: 'anatomyFunction',
          input,
        },
      })
    })

    oldAnnotations.expression.forEach(annotation => {
      if (
        !annotations.expression.some(
          newAnnotation =>
            newAnnotation.annotationId === annotation.annotationId,
        )
      ) {
        deleteAnnotation({ variables: { id: annotation.annotationId } })
      }
    })
  }

  const version =
    manuscript &&
    manuscript.manuscript &&
    linkHTML(
      last(manuscript.manuscript.versions),
      manuscript.manuscript.entities,
    )

  return (
    <CurationTool
      anatomyFuncAnnotations={get(oldAnnotations, 'anatomyFunction', [])}
      entities={entities || []}
      exprAnnotations={get(oldAnnotations, 'expression', [])}
      loading={manuscriptLoading || loading}
      phenoAnnotations={get(oldAnnotations, 'phenotype', [])}
      saveAnnotations={saveAnnotations}
      version={version}
    />
  )
}

export default CurationToolPage
