import { gql } from '@apollo/client'

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`

const RESET_PASSWORD = gql`
  mutation($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`

const VALIDATE_PASSWORD_RESET_TOKEN_EXPIRY = gql`
  query($token: String!) {
    validatePasswordTokenExpiry(token: $token)
  }
`

export {
  SEND_PASSWORD_RESET_EMAIL,
  RESET_PASSWORD,
  VALIDATE_PASSWORD_RESET_TOKEN_EXPIRY,
}
