import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { H4 } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import { unCamelCase } from './_helpers'

import Editor from './Editor'
import GeneExpression from './GeneExpression'
import Separator from './Separator'
import TextBlock from './TextBlock'

const AdditionalDataWrapper = styled.div`
  background: ${th('colorBackgroundHue')};
  font-family: ${th('fontInterface')};
  padding: ${th('gridUnit')};

  > div {
    margin-top: ${th('gridUnit')};
  }
`

const AdditionalDataHeader = styled(H4)`
  color: ${th('colorText')};
  margin: 0;
`

const AdditionalData = props => {
  const {
    comments,
    dataType,
    previousDataType,
    geneExpression,
    previousGeneExpression,
    laboratory,
    previousLaboratory,
    suggestedReviewer,
    previousSuggestedReviewer,
    showDiff,
    showRemoved,
  } = props

  return (
    <AdditionalDataWrapper>
      <AdditionalDataHeader>Additional Data</AdditionalDataHeader>

      <Editor label="author comments" value={comments} variant="metadata" />

      <TextBlock
        label="laboratory"
        previousValue={previousLaboratory}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={laboratory}
        variant="metadata"
      />

      <Editor
        label="suggested reviewer"
        previousValue={previousSuggestedReviewer}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={suggestedReviewer}
      />

      <TextBlock
        label="datatype"
        previousValue={unCamelCase(previousDataType)}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={unCamelCase(dataType)}
        variant="metadata"
      />

      {dataType === 'geneExpression' && geneExpression && (
        <>
          <Separator />
          <GeneExpression
            data={geneExpression}
            previousData={previousGeneExpression}
            showDiff={showDiff}
            showRemoved={showRemoved}
          />
        </>
      )}
    </AdditionalDataWrapper>
  )
}

AdditionalData.propTypes = {
  /** Comments by the author to the editors */
  comments: PropTypes.string,
  /** Datatype chosen by the editors */
  dataType: PropTypes.string,
  /** Same as datatype, but previous version. For diffs. */
  previousDataType: PropTypes.string,
  /** Laboratory of manuscript */
  laboratory: PropTypes.string,
  /** Same as laboratory, but previous version. For diffs. */
  previousLaboratory: PropTypes.string,
  /** Suggested reviewer by the author. */
  suggestedReviewer: PropTypes.string,
  /** Same as suggested reviewer, but previous version. For diffs. */
  previousSuggestedReviewer: PropTypes.string,
  /** Gene expression form data. */
  geneExpression: PropTypes.shape({
    antibodyUsed: PropTypes.string,
    backboneVector: PropTypes.shape({
      name: PropTypes.string,
    }),
    coinjected: PropTypes.string,
    constructComments: PropTypes.string,
    detectionMethod: PropTypes.string,
    dnaSequence: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    expressionPattern: PropTypes.shape({
      name: PropTypes.string,
    }),
    fusionType: PropTypes.shape({
      name: PropTypes.string,
    }),
    genotype: PropTypes.string,
    injectionConcentration: PropTypes.string,
    inSituDetails: PropTypes.string,
    integratedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    reporter: PropTypes.shape({
      name: PropTypes.string,
    }),
    species: PropTypes.shape({
      name: PropTypes.string,
    }),
    strain: PropTypes.string,
    transgeneName: PropTypes.string,
    transgeneUsed: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    utr: PropTypes.shape({
      name: PropTypes.string,
    }),
    variation: PropTypes.shape({
      name: PropTypes.string,
    }),
    observeExpression: PropTypes.shape({
      certainly: PropTypes.arrayOf(
        PropTypes.shape({
          certainly: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      possibly: PropTypes.arrayOf(
        PropTypes.shape({
          possibly: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      probably: PropTypes.arrayOf(
        PropTypes.shape({
          probably: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      not: PropTypes.arrayOf(
        PropTypes.shape({
          not: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  /** Same as gene expression, but previous version. For diffs. */
  previousGeneExpression: PropTypes.shape({
    antibodyUsed: PropTypes.string,
    backboneVector: PropTypes.shape({
      name: PropTypes.string,
    }),
    coinjected: PropTypes.string,
    constructComments: PropTypes.string,
    detectionMethod: PropTypes.string,
    dnaSequence: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    expressionPattern: PropTypes.shape({
      name: PropTypes.string,
    }),
    fusionType: PropTypes.shape({
      name: PropTypes.string,
    }),
    genotype: PropTypes.string,
    injectionConcentration: PropTypes.string,
    inSituDetails: PropTypes.string,
    integratedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    reporter: PropTypes.shape({
      name: PropTypes.string,
    }),
    species: PropTypes.shape({
      name: PropTypes.string,
    }),
    strain: PropTypes.string,
    transgeneName: PropTypes.string,
    transgeneUsed: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    utr: PropTypes.shape({
      name: PropTypes.string,
    }),
    variation: PropTypes.shape({
      name: PropTypes.string,
    }),
    observeExpression: PropTypes.shape({
      certainly: PropTypes.arrayOf(
        PropTypes.shape({
          certainly: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      possibly: PropTypes.arrayOf(
        PropTypes.shape({
          possibly: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      probably: PropTypes.arrayOf(
        PropTypes.shape({
          probably: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      not: PropTypes.arrayOf(
        PropTypes.shape({
          not: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  /** Whether to show diffs at all */
  showDiff: PropTypes.bool,
  /** Whether to show removals in diffs */
  showRemoved: PropTypes.bool,
}

AdditionalData.defaultProps = {
  comments: null,
  dataType: null,
  previousDataType: null,
  laboratory: null,
  previousLaboratory: null,
  suggestedReviewer: null,
  previousSuggestedReviewer: null,
  geneExpression: null,
  previousGeneExpression: null,
  showDiff: true,
  showRemoved: true,
}

export default AdditionalData
