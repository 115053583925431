/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'
import { stripHTML } from '../formElements/helpers'
import { AbstractEditor } from '../../xpubEdit'

const isHTMLNotEmpty = html => stripHTML(html).length > 0

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  /* margin-bottom: calc(${th('gridUnit')}); */

  > div:first-child {
    flex-grow: 1;
  }
`

const Title = styled(AbstractEditor)`
  border: 0;
  font-family: ${th('fontReading')};
  font-size: ${th('fontSizeHeading5')};
  line-height: ${th('lineHeightHeading5')};
  /* margin: 0 0 0 ${th('gridUnit')}; */
  margin: 0;
  padding: 0;
`

const SectionItem = props => {
  const { className, rightComponent, title } = props
  const titleValue = isHTMLNotEmpty(title) ? title : 'Untitled'

  return (
    <Wrapper className={className}>
      <Title
        onBlur={() => {}}
        onChange={() => {}}
        readonly
        value={titleValue || 'Untitled'}
      />
      {rightComponent}
    </Wrapper>
  )
}

export default SectionItem
