import React from 'react'

import InfoModal from './InfoModal'

const KwsDescriptions = props => (
  <InfoModal
    headerText="entity set descriptions"
    justifyText
    size="medium"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <div>
      <p>
        <b>Yeast Entities:</b> SGD genes, alleles and proteins
      </p>
      <p>
        <b>Fly Entities:</b> FlyBase genes and alleles
      </p>
      <p>
        <b>Worm Entities:</b> WormBase transgenes, clones, strains,
        rearrangements, proteins, variants, C. elegans genes, all nematode
        species genes, sequences
      </p>
      <p>
        <b>Worm Curation:</b> WormBase transgenes, clones, strains,
        rearrangements, proteins, variants, C. elegans genes, all nematode
        species genes, sequences, anatomy, phenoterms, methods, gene ontology
      </p>
      <p>
        <b>Mouse Entities:</b> MGI genes
      </p>
      <p>
        <b>Pombe Entities:</b> PomBase genes
      </p>
      <p>
        <b>Arabidosis Entities:</b> TAIR genes
      </p>
      <p>
        <b>Human Entities:</b> Hugo genes
      </p>
      <p>
        <b>Zebrafish Entities:</b> ZFIN genes
      </p>
      <p>
        <b>NCBI Species:</b> Subset from NCBI Taxonomy Database
      </p>
    </div>
  </InfoModal>
)

export default KwsDescriptions
