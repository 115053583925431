export { DateParser } from './src/common'

export { default as ReviewerPanel } from './src/reviewerPanel/ReviewerPanel'
export { default as EditorPanel } from './src/editors/EditorPanel'
export { default as Loader } from './src/common/Loader'
export { default as ExtendedData } from './src/submissionForm/ExtendedData'
export { default as Split } from './src/split/Split'
export { default as SubmissionFormFeedback } from './src/submissionForm/Feedback'
export { default as SyncedTabs } from './src/split/SyncedTabs'
export { default as RequestPasswordReset } from './src/authentication/RequestPasswordReset'
export { default as ResetPassword } from './src/authentication/ResetPassword'
export { default as TeamManager } from './src/teamManager/TeamManager'
export { default as UserManager } from './src/userManager/UserManager'
export { default as UserProfile } from './src/userProfile/UserProfile'
// export { default as CurationTool } from './src/curationTool/CurationTool'
export { default as Statistics } from './src/statistics/Statistics'
export { default as Billing } from './src/billing/Billing'
export { default as DataReleases } from './src/dataReleases/DataReleases'
export { default as TaxonomyManager } from './src/taxonomyManager/TaxonomyManager'
export { default as NewsManager } from './src/newsManager/NewsManager'
export { default as DeclineInvitation } from './src/assignReviewers/DeclineInvitation'
export { default as PublishedManuscripts } from './src/publishedManuscripts/PublishedManuscripts'

// Modals
export { default as ChatModal } from './src/modals/ChatModal'

export { default as DataTypeConfirmation } from './src/modals/DataTypeConfirmation'

export { default as ReviewSubmissionConfirmation } from './src/modals/ReviewSubmissionConfirmation'

export { default as CuratorSubmissionConfirmation } from './src/modals/CuratorSubmissionConfirmation'

export { default as ManuscriptTeamManager } from './src/modals/ManuscriptTeamManager'

export { default as ImageModal } from './src/modals/ImageModal'

export { default as ReviewerGuidelines } from './src/modals/ReviewerGuidelines'

export { default as UploadModal } from './src/modals/UploadModal'
// export { default as LinkingModal } from './src/modals/LinkingModal'
export { default as EditConfirm } from './src/modals/EditConfirm'
