import React, { useState } from 'react'
import styled from 'styled-components'
import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'
import maxBy from 'lodash/maxBy'
import without from 'lodash/without'

import {
  ADD_EXTERNAL_REVIEWER,
  CHANGE_AMOUNT_OF_REVIEWERS,
  CHANGE_REVIEWER_AUTOMATION_STATUS,
  INVITE_REVIEWER,
  MANUSCRIPT_FOR_ASSIGN_REVIEWERS,
  SEARCH_FOR_REVIEWERS,
  REVOKE_INVITATION,
  UPDATE_REVIEWER_POOL,
  SAVE_REVIEWER_MESSAGE,
} from '../graphql'

import AssignReviewers from '../../ui/src/assignReviewers/AssignReviewers'
import { Loader } from '../../ui/src/common'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`

const AssignReviewersPage = props => {
  const { id: manuscriptId } = useParams()
  const client = useApolloClient()

  const [showEmails, setShowEmails] = useState(false)
  const [showAddMessage, setShowAddMessage] = useState(false)

  /* GET MANUSCRIPT VERSION ID & REVIEWER POOL */

  const { data: manuscriptData } = useQuery(MANUSCRIPT_FOR_ASSIGN_REVIEWERS, {
    variables: {
      id: manuscriptId,
    },
  })

  const versions =
    manuscriptData &&
    manuscriptData.manuscript &&
    manuscriptData.manuscript.versions

  const latestVersion = versions && maxBy(versions, 'created')
  const latestVersionId = latestVersion && latestVersion.id
  const reviewerPool = latestVersion && latestVersion.reviewerPool
  const reviews = latestVersion && latestVersion.reviews
  const isAutomationOn = latestVersion && latestVersion.isReviewerAutomationOn

  const suggestedReviewerName =
    latestVersion &&
    latestVersion.suggestedReviewer &&
    latestVersion.suggestedReviewer.name

  const amountOfReviewers = latestVersion && latestVersion.amountOfReviewers
  const reviewerMessage = latestVersion && latestVersion.reviewerMessage

  /* SEARCH */

  // Use custom async function because useLazyQuery is not a promise
  // and react-select's async functionality requires a promise
  const search = async input => {
    // if (input && input.trim().length < 4) {
    //   return []
    // }

    const res = await client.query({
      query: SEARCH_FOR_REVIEWERS,
      fetchPolicy: 'network-only', // make sure status is always up to date
      variables: {
        manuscriptVersionId: latestVersionId,
        searchQuery: input,
        limit: 10,
      },
    })

    if (res.data && res.data.searchForReviewer) {
      const result = res.data.searchForReviewer.map(item => {
        let status

        const {
          isAuthorOfItem,
          isEditorOfItem,
          isCuratorOfItem,
          isSectionEditorOfItem,
          isScienceOfficerOfItem,
          isReviewerOfItem,
          currentlyReviewing,
          numInvitations,
          lastReview,
          lastInvitation,
        } = item.meta

        const reviewDate = lastReview
          ? new Date(Number(lastReview)).toLocaleDateString()
          : 'never'

        const invitationDate = lastInvitation
          ? new Date(Number(lastInvitation)).toLocaleDateString()
          : 'never'

        // Display message in autocomplete row
        if (!item.user.isActive) {
          status = 'disabled'
        } else if (item.user.notReviewer) {
          status = 'do not ask'
        } else if (currentlyReviewing) {
          status = 'currently reviewing'
        } else if (isAuthorOfItem) {
          status = 'author'
        } else if (isEditorOfItem) {
          status = 'editor'
        } else if (isCuratorOfItem) {
          status = 'curator'
        } else if (isSectionEditorOfItem) {
          status = 'section editor'
        } else if (isScienceOfficerOfItem) {
          status = 'science officer'
        } else if (isReviewerOfItem) {
          status = 'in reviewer list'
        }

        // Disallow inviting existing roles as a reviewer
        const isDisabled =
          isAuthorOfItem ||
          isCuratorOfItem ||
          isEditorOfItem ||
          isSectionEditorOfItem ||
          isScienceOfficerOfItem ||
          isReviewerOfItem

        return {
          value: item.user.id,
          label: item.user.displayName,
          status,
          isDisabled,
          numInvitations,
          reviewDate,
          invitationDate,
        }
      })

      return result
    }

    return []
  }

  /* MANAGE REVIEWER POOL */

  const reviewers =
    reviewerPool &&
    reviewerPool.map(reviewerTeamMember => {
      const { status, user, reason, suggestedReviewer } = reviewerTeamMember
      const { agreedTc, displayName, email, id, isActive } = user

      const invited =
        status === 'invited' ||
        status === 'invitationRevoked' ||
        status === 'acceptedInvitation' ||
        status === 'rejectedInvitation' ||
        status === 'declinedInvitation'

      const invitationRevoked = status === 'invitationRevoked'
      const acceptedInvitation = status === 'acceptedInvitation'
      const rejectedInvitation = status === 'rejectedInvitation'
      const declinedInvitation = status === 'declinedInvitation'

      const reviewSubmitted = !!reviews.find(
        review => review.reviewerId === id && review.status.submitted,
      )

      return {
        displayName,
        email,
        id,
        invited,
        invitationRevoked,
        isSignedUp: agreedTc,
        isActive,
        acceptedInvitation,
        rejectedInvitation,
        declinedInvitation,
        reasonForDecline: reason,
        suggestedReviewer,
        reviewSubmitted,
        onClickInvite: () => inviteReviewer(id),
        onClickRemove: () => removeReviewerFromPool(id),
        onClickRevokeInvitation: () => revokeInvitation(id),
        showEmail: showEmails,
      }
    })

  const getCurrentIds = () =>
    reviewerPool &&
    reviewerPool.map(reviewerTeamMember => reviewerTeamMember.user.id)

  const [updatePool] = useMutation(UPDATE_REVIEWER_POOL, {
    refetchQueries: [
      {
        query: MANUSCRIPT_FOR_ASSIGN_REVIEWERS,
        variables: {
          id: manuscriptId,
        },
      },
    ],
  })

  const addToReviewerPool = option => {
    const currentIds = getCurrentIds()
    const newReviewerId = option.value
    const newPool = [...currentIds, newReviewerId]

    updatePool({
      variables: {
        manuscriptVersionId: latestVersionId,
        reviewerIds: newPool,
      },
    })
  }

  const removeReviewerFromPool = reviewerId => {
    const currentIds = getCurrentIds()
    const newPool = without(currentIds, reviewerId)

    updatePool({
      variables: {
        manuscriptVersionId: latestVersionId,
        reviewerIds: newPool,
      },
    })
  }

  const reorderPool = newOrderedOptions => {
    const newPool = newOrderedOptions.map(reviewer => reviewer.id)

    updatePool({
      variables: {
        manuscriptVersionId: latestVersionId,
        reviewerIds: newPool,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateReviewerPoolNew: {
          __typename: 'ManuscriptVersion',
          id: latestVersionId,
          reviewerPool: (() =>
            newOrderedOptions.map(option => {
              const memberOfPool = reviewerPool.find(
                r => r.user.id === option.id,
              )

              return memberOfPool
            }))(),
        },
      },
    })
  }

  const [addExternalReviewerFunc] = useMutation(ADD_EXTERNAL_REVIEWER, {
    refetchQueries: [
      {
        query: MANUSCRIPT_FOR_ASSIGN_REVIEWERS,
        variables: {
          id: manuscriptId,
        },
      },
    ],
  })

  const addExternalReviewer = formValues =>
    addExternalReviewerFunc({
      variables: {
        manuscriptVersionId: latestVersionId,
        input: formValues,
      },
    })

  const [saveReviewerMessageFunc] = useMutation(SAVE_REVIEWER_MESSAGE, {
    refetchQueries: [
      {
        query: MANUSCRIPT_FOR_ASSIGN_REVIEWERS,
        variables: {
          id: manuscriptId,
        },
      },
    ],
  })

  const saveReviewerMessage = message =>
    saveReviewerMessageFunc({
      variables: {
        versionId: latestVersionId,
        message,
      },
    })

  /* MANAGE INVITATIONS */

  const isActive = reviewer =>
    reviewer.invited &&
    !reviewer.invitationRevoked &&
    !reviewer.rejectedInvitation &&
    !reviewer.declinedInvitation

  const canInviteMore = () => {
    if (!reviewers) return false

    const howMany = reviewers.filter(r => isActive(r)).length

    if (howMany > amountOfReviewers) return false
    if (howMany === amountOfReviewers) return false
    if (howMany < amountOfReviewers) return true

    return false
  }

  const [inviteReviewerFunc] = useMutation(INVITE_REVIEWER)

  const inviteReviewer = reviewerId => {
    inviteReviewerFunc({
      variables: {
        manuscriptVersionId: latestVersionId,
        input: {
          reviewerId,
        },
      },
    })
  }

  const [revokeInvitationFunc] = useMutation(REVOKE_INVITATION)

  const revokeInvitation = reviewerId => {
    revokeInvitationFunc({
      variables: {
        manuscriptVersionId: latestVersionId,
        reviewerId,
      },
    })
  }

  const [changeAmountOfReviewers] = useMutation(CHANGE_AMOUNT_OF_REVIEWERS)

  const handleAmountOfReviewersChange = val =>
    changeAmountOfReviewers({
      variables: {
        manuscriptVersionId: latestVersionId,
        amount: val,
      },
    })

  /* MANAGE AUTOMATION */

  const [changeAutomationFunc] = useMutation(CHANGE_REVIEWER_AUTOMATION_STATUS)

  const changeAutomation = value => {
    changeAutomationFunc({
      variables: {
        manuscriptVersionId: latestVersionId,
        value,
      },
    })
  }

  if (!latestVersion) return <Loader />

  return (
    <Wrapper>
      <AssignReviewers
        addExternalReviewer={addExternalReviewer}
        amountOfReviewers={amountOfReviewers}
        automate={isAutomationOn}
        canAddMore={canInviteMore()}
        onAmountOfReviewersChange={handleAmountOfReviewersChange}
        onClickReviewer={addToReviewerPool}
        onClickStart={() => changeAutomation(true)}
        onClickStop={() => changeAutomation(false)}
        onReviewerPoolReorder={reorderPool}
        reviewerMessage={reviewerMessage}
        // search={debounce(search, 500)}
        reviewerPool={reviewers}
        saveReviewerMessage={saveReviewerMessage}
        search={search}
        setShowAddMessage={setShowAddMessage}
        setShowEmails={setShowEmails}
        showAddMessage={showAddMessage}
        showEmails={showEmails}
        suggestedReviewerName={suggestedReviewerName}
      />
    </Wrapper>
  )
}

export default AssignReviewersPage
