import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Redirect, withRouter } from 'react-router-dom'

import CurrentUserContext from '../userContext'
import { clearLocalStorage } from '../auth'

const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      admin
      auth {
        isAcceptedReviewerForManuscript
        isAcceptedReviewerForVersion
        isAssignedCurator
        isAssignedEditor
        isAssignedSectionEditor
        isAssignedScienceOfficer
        isAuthor
        isGlobal
        isGlobalCurator
        isGlobalEditor
        isGlobalScienceOfficer
        isGlobalSectionEditor
      }
      displayName
      id
      teams {
        id
      }
      username
    }
  }
`

const Private = ({ children, location }) => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)

  return (
    <Query query={CURRENT_USER}>
      {({ client, data, error, loading, networkStatus }) => {
        if (loading) return null

        // remove invalid token and prepare for login redirect
        if (
          (networkStatus === 7 &&
            !loading &&
            !error &&
            data.currentUser === null) ||
          error
        ) {
          client.cache.reset()
          clearLocalStorage()
        }

        if (!localStorage.getItem('token')) {
          const { pathname, search = '' } = location
          const url = pathname + search
          return <Redirect to={`/login?next=${url}`} />
        }

        setCurrentUser(data.currentUser)

        // wait for user context value to be updated
        if (!currentUser) return null
        return children
      }}
    </Query>
  )
}

Private.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
}

export { CURRENT_USER }
export default withRouter(Private)
