/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import { components } from 'react-select'

import { grid, th } from '../_helpers'
import { Icon, LoaderInline, TextField, Select } from '../common'

import { lookupFunder } from '../../../app/fetch/RorApi'

const StyledSelect = styled(Select)`
  .react-select__control {
    border: 0;
    box-shadow: ${th('colorBorder')} 0 0 0 1px;
    padding: 0 ${grid(1)};
    transition: box-shadow 0.2s ease-in;
  }

  .react-select__control--is-focused {
    box-shadow: ${th('colorPrimary')} 0 0 0 2px;
  }

  .react-select__menu {
    margin-top: 4px;
  }

  margin: 10px;
`

const Wrapper = styled.div``

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon color="#AAA">search</Icon>
  </components.DropdownIndicator>
)

const LoadingIndicator = props => <LoaderInline color="colorPrimary" size={2} />

const Option = props => {
  const { data, children, ...rest } = props

  return <components.Option {...rest}>{children}</components.Option>
}

const Funding = props => {
  const {
    currentAward,
    onClickOption,
    placeholder,
    setFieldValue,
    handleChange,
    value,
  } = props

  const onChange = (val, action) => {
    if (action.action === 'select-option') {
      onClickOption(val)
    }
  }

  const loadOptions = async input => {
    const response = await lookupFunder(input)

    const json = await response.json()

    return json.values
  }

  return (
    <>
      <StyledSelect
        asyncCreatable
        components={{ DropdownIndicator, LoadingIndicator, Option }}
        loadOptions={loadOptions}
        onChange={onChange}
        onCreateOption={onClickOption}
        placeholder={placeholder}
        value=""
      />
      {value && value[currentAward] && (
        <Wrapper key={`funder-${value[currentAward].funderName}`}>
          <TextField
            handleChange={handleChange}
            label="Funder Name"
            name={`awards[${currentAward}].funderName`}
            setFieldValue={setFieldValue}
            value={value[currentAward].funderName}
          />
          <TextField
            handleChange={handleChange}
            label="Award ID"
            name={`awards[${currentAward}].awardId`}
            setFieldValue={setFieldValue}
            value={value[currentAward].awardId}
          />
          <TextField
            handleChange={handleChange}
            label="Award Recipient"
            name={`awards[${currentAward}].awardRecipient`}
            setFieldValue={setFieldValue}
            value={value[currentAward].awardRecipient}
          />
        </Wrapper>
      )}
    </>
  )
}

export default Funding
