/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import Modal from './Modal'
import AuthorInput from '../submissionForm/AuthorInput'
import ModalHeader from './ModalHeader'
import ModalFooterDialog from './ModalFooterDialog'

const AuthorInputModal = props => {
  const Header = <ModalHeader text="Author Input" />

  const Footer = (
    <ModalFooterDialog showCancelButton={false} textSuccess="Done" />
  )

  return (
    <Modal
      footerComponent={Footer}
      headerComponent={Header}
      size="medium"
      {...props}
    >
      <AuthorInput {...props} />
    </Modal>
  )
}

export default AuthorInputModal
