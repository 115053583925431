/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

import WarningModal from './WarningModal'

const ReviewerCoiModal = props => (
  <WarningModal
    headerText="conflict of interest disclaimer"
    justifyText
    textSuccess="accept"
    {...props}
  >
    {`By accepting this invitation to review, you declare that you have 
      no conflict of interest that could bias or complicate your review. 
      Further you agree to not use knowledge of the work you are reviewing 
      before its publication to further your own interests. You must recuse 
      yourself from reviewing the manuscript should the potential for bias exists.`}
  </WarningModal>
)

export default ReviewerCoiModal
