/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'

import { Chat } from '../common'
import Modal from './Modal'
import ModalHeader from './ModalHeader'

const ChatModal = props => {
  const {
    getSavedChat,
    headerText,
    messages,
    saveChat,
    sendMessage,
    ...rest
  } = props

  const Header = <ModalHeader text={headerText || 'Chat'} />

  return (
    <Modal headerComponent={Header} size="large" {...rest}>
      <Chat
        getSavedChat={getSavedChat}
        messages={messages}
        saveChat={saveChat}
        sendMessage={sendMessage}
      />
    </Modal>
  )
}

export default ChatModal
