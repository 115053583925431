/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Toggle } from 'react-powerplug'

import { Action, ActionGroup } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

// import AssignToManuscript from './AssignToManuscript'
import SectionItemWithStatus from './SectionItemWithStatus'
import ConfirmManuscriptDelete from '../../../ui/src/modals/ConfirmManuscriptDelete'
// import { exportManuscriptEntities } from '../../fetch/exportManuscript'

const Wrapper = styled.div`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')}
    ${th('colorFurniture')};
  /* margin-bottom: ${th('gridUnit')}; */
  padding: 4px 0;
`

// const EditorToolRow = styled.div`
//   display: flex;
//   margin-bottom: ${th('gridUnit')};
// `

const ActionsWrapper = styled.div`
  flex-shrink: 0;
`

// const Separator = styled.span`
//   width: calc(${th('gridUnit')} * 2);
// `

const Actions = ({
  articleId,
  deleteArticle,
  entities,
  isAdmin,
  title,
  onClickOpenEntityTable,
}) => {
  const confirmDelete = () => {
    deleteArticle(articleId)
  }

  const entityTable = () => onClickOpenEntityTable(entities)

  // const downloadEntities = () => exportManuscriptEntities(articleId)

  return (
    <ActionsWrapper>
      <ActionGroup>
        <Action
          data-test-id={`go-to-article-link-${articleId}`}
          to={`/article/${articleId}`}
        >
          Go to Article
        </Action>
        {isAdmin && (
          <Toggle intial={false}>
            {({ on, toggle }) => (
              <>
                <Action onClick={toggle} primary>
                  Delete
                </Action>

                <ConfirmManuscriptDelete
                  isOpen={on}
                  onConfirm={confirmDelete}
                  onRequestClose={toggle}
                  title={title}
                />
              </>
            )}
          </Toggle>
        )}
        {entities && (
          <Action onClick={entityTable}>
            Entity Table ({entities.filter(e => !e.deleted).length})
          </Action>
        )}
      </ActionGroup>
    </ActionsWrapper>
  )
}

const EditorSectionItem = props => {
  const {
    // allEditors,
    // allScienceOfficers,
    id: articleId,
    author,
    // authorIds,
    curator,
    deleteArticle,
    entities,
    editor,
    isAdmin,
    sectionEditor,
    scienceOfficer,
    title,
    onClickOpenEntityTable,
    // updateAssignedEditor,
    // updateAssignedScienceOfficer,
    // variant,
  } = props

  const ActionsComponent = (
    <Actions
      articleId={articleId}
      deleteArticle={deleteArticle}
      entities={entities}
      isAdmin={isAdmin}
      onClickOpenEntityTable={onClickOpenEntityTable}
      title={title}
    />
  )

  return (
    <Wrapper>
      <SectionItemWithStatus
        actionsComponent={ActionsComponent}
        author={author}
        curator={curator}
        editor={editor}
        scienceOfficer={scienceOfficer}
        sectionEditor={sectionEditor}
        {...props}
      />

      {/* {variant === 'editor' && (
        <EditorToolRow>
          <AssignToManuscript
            allOptions={allEditors}
            articleId={articleId}
            authorIds={authorIds}
            currentlyAssigned={editor}
            label="Editor"
            update={updateAssignedEditor}
          />

          <Separator />

          <AssignToManuscript
            allOptions={allScienceOfficers}
            articleId={articleId}
            authorIds={authorIds}
            currentlyAssigned={scienceOfficer}
            label="Science Officer"
            update={updateAssignedScienceOfficer}
          />
        </EditorToolRow>
      )} */}
    </Wrapper>
  )
}

export default EditorSectionItem
