import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import { uniqueId } from 'lodash'

// import { th } from '@pubsweet/ui-toolkit'

import DiffArray from './DiffArray'
import Label from './Label'
import TextBlock from './TextBlock'

const Wrapper = styled.div`
  /* > p:not(:first-of-type) {
    margin-top: x;
  } */
`

const transformValues = values =>
  values &&
  values.map(value => ({
    // id: uniqueId(),
    value,
  }))

const TextBlockList = props => {
  const {
    label,
    previousValues,
    showDiff,
    showRemoved,
    values,
    variant,
  } = props

  if (!values || values.length === 0) return null

  return (
    <Wrapper>
      {label && <Label value={label} />}

      <DiffArray
        component={TextBlock}
        componentProps={{ variant }}
        currentArray={transformValues(values)}
        previousArray={transformValues(previousValues)}
        showDiff={showDiff}
        showRemoved={showRemoved}
      />
    </Wrapper>
  )
}

TextBlockList.propTypes = {
  /** Label to be displayed above all text blocks */
  label: PropTypes.string,
  /** Same as values, but previous version. For diffs. */
  previousValues: PropTypes.arrayOf(PropTypes.string),
  /** Whether to show diffs at all */
  showDiff: PropTypes.bool,
  /** Whether to show diff removals */
  showRemoved: PropTypes.bool,
  /** Will render one text block for each value */
  values: PropTypes.arrayOf(PropTypes.string),
  /** Different style setups */
  variant: PropTypes.oneOf(['metadata']),
}

TextBlockList.defaultProps = {
  label: null,
  showDiff: true,
  showRemoved: true,
  previousValues: null,
  values: null,
  variant: null,
}

export default TextBlockList
