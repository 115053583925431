import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/alliance`

const searchAlliance = async (symbol, type) => {
  const url = `${apiUrl}/search?q=${symbol}&category=${type}`
  return fetch(url)
}

const lookupAllele = async id => {
  const url = `${apiUrl}/allele?id=${id}`
  return fetch(url)
}

const lookupStrain = async id => {
  const url = `${apiUrl}/strain?id=${id}`
  return fetch(url)
}

export { searchAlliance, lookupAllele, lookupStrain }
