import { uuid } from '@coko/client'

import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import mergeWith from 'lodash/mergeWith'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import union from 'lodash/union'
import lodashValues from 'lodash/values'

const defaultFormValues = {
  abstract: '',
  acknowledgements: '',
  authors: [
    {
      affiliations: [''],
      correspondingAuthor: false,
      credit: [''],
      departments: [''],
      email: '',
      equalContribution: false,
      firstName: '',
      lastName: '',
      orcid: '',
      submittingAuthor: false,
      WBId: '',
    },
  ],
  comments: '',
  disclaimer: false,
  apcDisclaimer: false,
  proteopediaOptIn: false,
  funding: '',
  geneExpression: {
    antibodyUsed: '',
    backboneVector: {
      name: '',
      WBId: '',
    },
    coinjected: '',
    constructComments: '',
    constructionDetails: '',
    detectionMethod: '',
    dnaSequence: [
      {
        id: uuid(),
        name: '',
        WBId: '',
      },
    ],
    expressionPattern: {
      name: '',
      WBId: '',
    },
    fusionType: {
      name: '',
      WBId: '',
    },
    genotype: '',
    injectionConcentration: '',
    inSituDetails: '',
    integratedBy: {
      name: '',
      WBId: '',
    },
    observeExpression: {
      certainly: [
        {
          certainly: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          during: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          id: uuid(),
          subcellularLocalization: {
            id: uuid(),
            name: '',
            WBId: '',
          },
        },
      ],
      not: [
        {
          during: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          id: uuid(),
          not: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          subcellularLocalization: {
            id: uuid(),
            name: '',
            WBId: '',
          },
        },
      ],
      partially: [
        {
          during: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          id: uuid(),
          partially: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          subcellularLocalization: {
            id: uuid(),
            name: '',
            WBId: '',
          },
        },
      ],
      possibly: [
        {
          during: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          id: uuid(),
          possibly: {
            id: uuid(),
            name: '',
            WBId: '',
          },
          subcellularLocalization: {
            id: uuid(),
            name: '',
            WBId: '',
          },
        },
      ],
    },
    reporter: {
      name: '',
      WBId: '',
    },
    species: {
      name: '',
      WBId: '',
    },
    strain: '',
    transgeneName: '',
    transgeneUsed: [
      {
        id: uuid(),
        name: '',
        WBId: '',
      },
    ],
    utr: {
      name: '',
      WBId: '',
    },
    variation: {
      name: '',
      WBId: '',
    },
  },
  image: {},
  imageCaption: '<p></p>',
  imageTitle: '',
  laboratory: {
    name: '',
    WBId: '',
  },
  methods: '<p></p>',
  patternDescription: '<p></p>',
  reagents: '<p></p>',
  references: [{ reference: '<p></p>', pubmedId: '', doi: '' }],
  suggestedReviewer: {
    name: '',
    WBId: '',
  },
  title: '',
}

// if (process.env.NODE_ENV === 'development') {
//   defaultFormValues = {
//     acknowledgements: '',
//     author: {
//       affiliations: 'University of Chicago.',
//       credit: ['software'],
//       email: 'john@john.com',
//       name: 'John A Bryden',
//       WBId: 'WBPerson6903',
//     },
//     coAuthors: [
//       {
//         affiliations: 'University of Minnesota',
//         credit: ['formalAnalysis'],
//         id: uuid(),
//         name: 'Yanna Cen',
//         WBId: 'WBPerson18694',
//       },
//       {
//         affiliations: 'University of Chicago',
//         credit: ['dataCuration'],
//         id: uuid(),
//         name: 'Alec Barret',
//         WBId: 'WBPerson15466',
//       },
//     ],
//     comments: '<p>some comments here</p>',
//     disclaimer: true,
//     funding: 'blah',
//     geneExpression: {
//       antibodyUsed: 'an antibody',
//       backboneVector: {
//         name: '',
//         WBId: '',
//       },
//       coinjected: '',
//       constructComments: '',
//       constructionDetails: '',
//       detectionMethod: 'antibody',
//       dnaSequence: [
//         {
//           id: uuid(),
//           name: '',
//           WBId: '',
//         },
//       ],
//       expressionPattern: {
//         name: 'some expression',
//         WBId: '1',
//       },
//       fusionType: {
//         name: '',
//         WBId: '',
//       },
//       genotype: '',
//       injectionConcentration: '',
//       inSituDetails: '',
//       integratedBy: {
//         name: '',
//         WBId: '',
//       },
//       observeExpression: {
//         certainly: [
//           {
//             certainly: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//             during: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//             id: uuid(),
//             subcellularLocalization: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//           },
//         ],
//         not: [
//           {
//             during: {
//               id: uuid(),
//               name: 'soma',
//               WBId: '67',
//             },
//             id: uuid(),
//             not: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//             subcellularLocalization: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//           },
//         ],
//         partially: [
//           {
//             during: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//             id: uuid(),
//             partially: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//             subcellularLocalization: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//           },
//         ],
//         possibly: [
//           {
//             during: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//             id: uuid(),
//             possibly: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//             subcellularLocalization: {
//               id: uuid(),
//               name: '',
//               WBId: '',
//             },
//           },
//         ],
//       },
//       reporter: {
//         name: '',
//         WBId: '',
//       },
//       species: {
//         name: 'Caenorhabditis brenneri',
//         WBId: 'Caenorhabditis brenneri',
//       },
//       strain: '',
//       transgeneName: '',
//       transgeneUsed: [
//         {
//           id: uuid(),
//           name: 'Some',
//           WBId: '',
//         },
//         {
//           id: uuid(),
//           name: 'Some',
//           WBId: '',
//         },
//       ],
//       utr: {
//         name: '',
//         WBId: '',
//       },
//       variation: {
//         name: '',
//         WBId: '',
//       },
//     },
//     image: {},
//     imageCaption: '<p>fdsfsd</p>',
//     laboratory: {
//       name: 'Jean-Claude Labbe',
//       WBId: 'UM',
//     },
//     methods: '<p>Yello</p>',
//     patternDescription: '<p>Hello there</p>',
//     references: '<p>fdsfdsfsd</p>',
//     suggestedReviewer: {
//       name: 'John C Cooper',
//       WBId: 'lklajfldjsf',
//     },
//     title: '<p>titlez <em>mymy</em> <strong>here</strong><sup>there</sup></p>',
//   }
// }

const dataToFormValues = data => {
  const defaultValues = cloneDeep(defaultFormValues)
  const values = cloneDeep(data)
  const { authors, image, references } = data

  if (authors) {
    const modAuthors = authors.map(item => {
      const modAuthor = cloneDeep(item)
      // if (item.wormBaseId) {
      //   modAuthor.WBPerson = item.wormBaseId
      //   delete modAuthor.wormBaseId
      // }
      modAuthor.id = uuid()

      if (isEmpty(modAuthor.affiliations)) {
        modAuthor.affiliations = ['']
      }

      if (isEmpty(modAuthor.departments)) {
        modAuthor.departments = modAuthor.affiliations.map(() => '')
      }

      return omit(modAuthor, '__typename')
    })

    // const author = find(modAuthors, entry => entry.submittingAuthor)
    // values.coAuthors = omitBy(modAuthors, entry => entry.submittingAuthor)
    /// values.author = author

    values.authors = modAuthors
  }

  if (references) {
    values.references = references.map(item => {
      const modReference = cloneDeep(item)
      modReference.id = uuid()
      return modReference
    })
  }

  // delete values.authors
  delete values.__typename // eslint-disable-line no-underscore-dangle

  // eslint-disable-next-line no-underscore-dangle
  if (image && image.__typename) {
    values.image = omit(image, '__typename')
  }

  return mergeWith(defaultValues, values, (defaultValue, incomingValue) => {
    if (Array.isArray(defaultValue)) {
      return incomingValue === null ? defaultValue : lodashValues(incomingValue)
    }

    return incomingValue === null ? defaultValue : incomingValue
  })
}

/* eslint-disable no-underscore-dangle, no-param-reassign */
// TODO -- write data cleanup functions (eg. remove __typename)
const formValuesToData = values => {
  const data = cloneDeep(values)
  const { authors, status, references } = data

  if (authors) {
    data.authors = union([], authors)
    // author.submittingAuthor = true
    // data.authors.push(author)

    data.authors = data.authors
      .map(item => {
        const modAuthor = cloneDeep(item)
        delete modAuthor.id
        delete modAuthor.__typename
        // if (!modAuthor.submittingAuthor) modAuthor.submittingAuthor = null
        if (!modAuthor.email) modAuthor.email = null
        else modAuthor.email = modAuthor.email.trim()

        modAuthor.affiliations = modAuthor.affiliations.filter(
          aff => aff !== '',
        )
        return modAuthor
      })
      .filter(item => {
        if (
          isEmpty(item.firstName) &&
          (isEmpty(item.credit) || isEqual(item.credit, [''])) &&
          isEmpty(item.affiliations) &&
          !item.submittingAuthor &&
          isEmpty(item.orcid)
        ) {
          return false
        }

        return true
      })
  }

  if (status) {
    delete status.__typename
    if (status.decision) delete status.decision.__typename
    if (status.scienceOfficer) delete status.scienceOfficer.__typename
    if (status.submission) delete status.submission.__typename
  }

  if (references) {
    data.references = references
      .map(item => {
        const modReference = cloneDeep(item)
        delete modReference.__typename
        delete modReference.id
        return modReference
      })
      .filter(item => {
        if (
          isEmpty(item.pubmedId) &&
          isEmpty(item.doi) &&
          (isEmpty(item.reference) || isEqual(item.reference, '<p></p>'))
        ) {
          return false
        }

        return true
      })
  }

  // delete data.author
  // delete data.coAuthors

  if (data.laboratory) delete data.laboratory.__typename

  if (data.geneExpression) {
    delete data.geneExpression.__typename

    if (data.geneExpression.dnaSequence) {
      data.geneExpression.dnaSequence.forEach(item => {
        delete item.__typename
        delete item.id
      })
    }

    if (data.geneExpression.transgeneUsed) {
      data.geneExpression.transgeneUsed.forEach(item => {
        delete item.__typename
        delete item.id
      })
    }

    if (data.geneExpression.variation) {
      delete data.geneExpression.variation.__typename
    }

    if (data.geneExpression.utr) {
      delete data.geneExpression.utr.__typename
    }

    if (data.geneExpression.species) {
      delete data.geneExpression.species.__typename
    }

    if (data.geneExpression.reporter) {
      delete data.geneExpression.reporter.__typename
    }

    if (data.geneExpression.integratedBy) {
      delete data.geneExpression.integratedBy.__typename
    }

    if (data.geneExpression.fusionType) {
      delete data.geneExpression.fusionType.__typename
    }

    if (data.geneExpression.expressionPattern) {
      delete data.geneExpression.expressionPattern.__typename
    }

    if (data.geneExpression.backboneVector) {
      delete data.geneExpression.backboneVector.__typename
    }

    if (data.geneExpression.observeExpression) {
      delete data.geneExpression.observeExpression.__typename

      keys(data.geneExpression.observeExpression).forEach(key => {
        data.geneExpression.observeExpression[key].forEach(item => {
          delete item.__typename
          if (!item.id) item.id = uuid()

          lodashValues(item).forEach(entry => {
            delete entry.__typename
            delete entry.id
          })
        })
      })
    }
  }

  const autocompleteKeys = keys(data).filter(key => {
    const match = key.match(/react-autowhatever*/)
    return match !== null
  })

  autocompleteKeys.forEach(key => delete data[key])

  if (data.image) {
    delete data.image.__typename

    if (data.image.data) {
      data.csvData = data.image.data
    }

    if (data.image.header) {
      data.csvHeader = data.image.header
    }
  }

  if (data.suggestedReviewer) delete data.suggestedReviewer.__typename

  delete data.__typename

  delete data.active
  delete data.dataType
  delete data.submitted
  delete data.created
  delete data.isApprovedByScienceOfficer
  delete data.articleTitle

  return data
}
/* eslint-enable no-underscore-dangle, no-param-reassign */

const getParentField = field => field.split('.').slice(0, -1).join('.')

// When a suggestion is selected, set the WB id
const onSuggestionSelected = (event, options, setFieldValue, name) => {
  const field = getParentField(name)
  setFieldValue(`${field}.WBId`, options.suggestion.WBId)
}

// When the autocomplete value changes, remove the WB id
const onAutocompleteChange = (e, field, setFieldValue, handleChange) => {
  const parent = getParentField(field)
  setFieldValue(`${parent}.WBId`, '')
  handleChange(e)
}

const stripHTML = html => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

const isWord = word => {
  const alphaNumeric = stripHTML(word)
    .split('')
    .filter(char => {
      const code = char.charCodeAt(0)

      if (
        (code > 47 && code < 58) || // numeric (0-9)
        (code > 64 && code < 91) || // upper alpha (A-Z)
        (code > 96 && code < 123) // lower alpha (a-z)
      ) {
        return true
      }

      return false
    })

  return alphaNumeric.length > 0
}

const countWords = words => {
  const text = words.split(' ')
  let wordCount = 0
  text.forEach(word => {
    if (word !== ' ' && isWord(word)) {
      wordCount += 1
    }
  })
  return wordCount
}

export {
  dataToFormValues,
  formValuesToData,
  onAutocompleteChange,
  onSuggestionSelected,
  countWords,
  stripHTML,
}
