/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'
import last from 'lodash/last'

import Modal from './Modal'
import ModalHeader from './ModalHeader'
import ModalFooterDialog from './ModalFooterDialog'
import { Action, Status } from '../common'

const LineWrapper = styled.div`
  border-bottom: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Box = styled.div``

const decisionMapper = {
  publish: 'Published',
  reject: 'Rejected',
  withdraw: 'Withdrawn',
  decline: 'declined',
}

const ArticlesModal = props => {
  const { articleData } = props
  const Header = <ModalHeader text="Articles" />

  const Footer = (
    <ModalFooterDialog showCancelButton={false} textSuccess="Close" />
  )

  const hasManuscripts = articleData && articleData.length > 0

  return (
    <Modal
      footerComponent={Footer}
      headerComponent={Header}
      size="medium"
      {...props}
    >
      {!hasManuscripts && 'No articles'}
      {hasManuscripts &&
        articleData.map(article => (
          <LineWrapper key={article.manuscriptId}>
            <Status reverseColors>
              {decisionMapper[last(article.versions).decision]}
            </Status>
            <Box
              dangerouslySetInnerHTML={{ __html: last(article.versions).title }}
            />

            <Action to={`/article/${article.id}`}>Go to Article</Action>
          </LineWrapper>
        ))}
    </Modal>
  )
}

export default ArticlesModal //
