import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { PUBLISHED_MANUSCRIPTS } from '../graphql'
import { PublishedManuscripts } from '../../ui'
import CurrentUserContext from '../userContext'
import DashboardEntityTable from '../../ui/src/modals/DashboardEntityTable'

const PublishedManuscriptsPage = props => {
  const { role } = useParams()
  const { currentUser } = useContext(CurrentUserContext)
  const [selectedEntities, setSelectedEntities] = useState(null)
  const [showEntityTable, setShowEntityTable] = useState(false)

  const { data, loading } = useQuery(PUBLISHED_MANUSCRIPTS, {
    variables: { role },
  })

  const manuscripts = data && data.publishedManuscripts

  const openEntityTable = entities => {
    setSelectedEntities(entities)
    setShowEntityTable(true)
  }

  const closeEntityTable = () => {
    setSelectedEntities(null)
    setShowEntityTable(true)
  }

  const transformedManuscripts =
    manuscripts &&
    manuscripts.map(b => {
      const { id: articleId, history, versions, entities } = b

      const version = versions[versions.length - 1]
      const { title, authors } = version
      const submittingAuthor = authors.find(a => a.submittingAuthor)

      let curatorStatus

      if (role === 'curator') {
        curatorStatus = 'Assessment Requested'

        const userAssessments = versions.map(v =>
          v.curatorReviews.filter(r => r.curator.id === currentUser.id),
        )

        if (userAssessments.length > 0) {
          curatorStatus = 'Waiting Curation in DB'

          const assessment = userAssessments.find(a => a[0].curated)

          if (assessment && assessment[0].curated) {
            curatorStatus =
              assessment[0].curated === 'curated in db'
                ? 'Curated in DB'
                : 'Nothing to Curate'
          }
        }
      }

      return {
        articleId,
        curatorStatus,
        entities,
        title,
        submittingAuthor,
        datePublished: history.published,
      }
    })

  return (
    <>
      <PublishedManuscripts
        loading={loading}
        manuscripts={transformedManuscripts}
        onClickOpenEntityTable={openEntityTable}
      />
      <DashboardEntityTable
        entities={selectedEntities}
        isOpen={showEntityTable}
        onRequestClose={closeEntityTable}
      />
    </>
  )
}

export default PublishedManuscriptsPage
