/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import clone from 'lodash/clone'

import PropTypes from 'prop-types'

import AuthorRow from './AuthorRow'
import DndList from '../common/DndList'

const AuthorList = props => {
  const { errors, onReorder, authors, onClickRemoveRow, onClickEditRow } = props

  const items = authors.map((author, index) => {
    const newAuthor = clone(author)
    newAuthor.error = Array.isArray(errors) ? errors[index] : errors

    return newAuthor
  })

  return (
    <DndList
      component={AuthorRow}
      items={items}
      onClickEditRow={onClickEditRow}
      onClickRemoveRow={onClickRemoveRow}
      onReorder={onReorder}
    />
  )
}

AuthorList.propTypes = {
  /** Function to run on reorder. Receives the new list as an argument. */
  onReorder: PropTypes.func.isRequired,
  /** List of data for reviewer. List is controlled via props, not state. Object shape defined in ReviewerRow component */
  authors: PropTypes.arrayOf(PropTypes.object),
  onClickRemoveRow: PropTypes.func,
  onClickEditRow: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.object),
}

AuthorList.defaultProps = {
  authors: [],
  errors: [],
  onClickRemoveRow: null,
  onClickEditRow: null,
}

export default AuthorList
