/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import WarningModal from './WarningModal'

const DataTypeConfirmation = props => {
  const { dataTypeLabel, ...rest } = props

  if (!dataTypeLabel) return null

  return (
    <WarningModal
      headerText="Confirm initiate editorial review"
      textSuccess="Initiate Editorial Review"
      {...rest}
    >
      Notify author it is in the hands of an editor, upload to iThenticate, set
      DOI and create entity table.
    </WarningModal>
  )
}

DataTypeConfirmation.propTypes = {
  dataTypeLabel: PropTypes.string.isRequired,
}

export default DataTypeConfirmation
