import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Dashboard from './components/Dashboard'
import NavigationBar from './components/NavigationBar'
import Login from './components/login/LoginContainer'
import Signup from './components/signup/SignupContainer'
import Verification from './components/signup/Verification'

import UserProfile from './components/compose/UserProfile'

import AdminRoute from './components/AdminRoute'
import Page from './components/Page'
import PageLayout from './components/PageLayout'
import Private from './components/Private'

import {
  Article,
  AssignReviewers,
  CurationTool,
  DeclineInvitation,
  RequestPasswordReset,
  ResetPassword,
  TeamManager,
  UserManager,
  Statistics,
  TaxonomyManager,
  Billing,
  Payment,
  DataReleases,
  Linker,
  News,
  PublishedManuscripts,
  WaiverForm,
} from './pages'

const routes = (
  <PageLayout>
    <Route component={NavigationBar} path="/" />
    <Page>
      <Switch>
        <Route component={Login} exact path="/login" />
        <Route component={Signup} exact path="/signup" />
        {/* <Route component={PasswordReset} exact path="/password-reset" /> */}
        <Route
          component={RequestPasswordReset}
          exact
          path="/request-password-reset"
        />
        <Route component={ResetPassword} exact path="/password-reset/:token" />
        <Route
          component={Verification}
          exact
          path="/email-verification/:token"
        />
        <Route component={Payment} exact path="/payment/:id" />
        <Route component={DeclineInvitation} exact path="/decline/:id" />
        <Private>
          <Switch>
            <Route component={WaiverForm} exact path="/waiver" />
            <Route component={Dashboard} exact path="/dashboard" />
            <Route component={Dashboard} exact path="/dashboard/all" />
            <Route
              component={AssignReviewers}
              exact
              path="/assign-reviewers/:id"
            />
            <Route component={Article} exact path="/article/:id" />
            <Route component={CurationTool} exact path="/curation/:id" />
            <Route component={Linker} exact path="/linker/:id" />
            <Route component={UserProfile} exact path="/profile" />
            <Route component={Statistics} exact path="/statistics" />
            <Route
              component={PublishedManuscripts}
              exact
              path="/published/:role"
            />
            <AdminRoute component={TeamManager} exact path="/teams" />
            <AdminRoute component={TaxonomyManager} exact path="/taxonomies" />
            <AdminRoute component={Billing} exact path="/billing" />
            <AdminRoute component={DataReleases} exact path="/releases" />
            <AdminRoute component={News} exact path="/news" />
            <Route component={UserManager} exact path="/users" />

            <Redirect from="/" to="/dashboard" />
          </Switch>
        </Private>
      </Switch>
    </Page>
  </PageLayout>
)

export default routes
