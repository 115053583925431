import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import { STATISTICS } from '../graphql'
import { Statistics } from '../../ui'
import {
  yearData,
  speciesData,
  categoriesData,
  submissionTypesData,
  speciesYearData,
} from '../fetch/StatisticsGraph'

const StatisticsPage = () => {
  const { data, loading } = useQuery(STATISTICS)
  const [speciesGraph, setSpeciesGraph] = useState(null)
  const [yearGraph, setYearGraph] = useState(null)
  const [categoriesGraph, setCategoriesGraph] = useState(null)
  const [submissionTypesGraph, setSubmissionTypesGraph] = useState(null)
  const [speciesYearGraph, setSpeciesYearGraph] = useState(null)

  useEffect(() => {
    if (!speciesGraph)
      speciesData()
        .then(result => result.json())
        .then(json => {
          setSpeciesGraph(json)
        })

    if (!yearGraph)
      yearData()
        .then(result => result.json())
        .then(json => setYearGraph(json))

    if (!categoriesGraph)
      categoriesData()
        .then(result => result.json())
        .then(json => setCategoriesGraph(json))

    if (!submissionTypesGraph)
      submissionTypesData()
        .then(result => result.json())
        .then(json => setSubmissionTypesGraph(json))

    if (!speciesYearGraph)
      speciesYearData()
        .then(result => result.json())
        .then(json => setSpeciesYearGraph(json))
  }, [])

  const statistics = data && data.statistics

  return (
    <Statistics
      categoriesGraph={categoriesGraph}
      loading={loading}
      speciesGraph={speciesGraph}
      speciesYearGraph={speciesYearGraph}
      statistics={statistics}
      submissionTypesGraph={submissionTypesGraph}
      yearGraph={yearGraph}
    />
  )
}

export default StatisticsPage
