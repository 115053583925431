import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'

import { Icon } from '@pubsweet/ui'

import { grid, fadeIn } from '../_helpers'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const fadeInAnimation = css`
  animation: ${fadeIn} 0.5s;
`

const StyledIcon = styled(Icon)`
  align-self: center;
  margin: ${grid(3)} 0;

  ${fadeInAnimation}
`

const Info = styled.div`
  text-align: center;
  /* stylelint-disable order/properties-alphabetical-order */
  ${fadeInAnimation}
`

const Response = props => {
  const { children, className, icon, status, theme } = props

  let iconColor

  switch (status) {
    case 'success':
      iconColor = theme.colorSuccess
      break
    case 'error':
      iconColor = theme.colorError
      break
    case 'warning':
      iconColor = theme.colorWarning
      break
    default:
      break
  }

  return (
    <Wrapper className={className}>
      <StyledIcon color={iconColor} size={8} status={status}>
        {icon}
      </StyledIcon>
      <Info>{children}</Info>
    </Wrapper>
  )
}

Response.propTypes = {
  /** Feather icon name (lowercase & underscores) */
  icon: PropTypes.string.isRequired,
  /** Status will affect the color of the icon */
  status: PropTypes.oneOf(['success', 'error', 'warning']).isRequired,
}

Response.defaultProps = {}

export default withTheme(Response)
