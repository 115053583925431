/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import WarningModal from './WarningModal'

const ReviewerCoiModal = props => (
  <WarningModal
    headerText="Confirm assessment submission"
    justifyText
    textSuccess="submit"
    {...props}
  >
    Are you sure you want to submit your assessment?
  </WarningModal>
)

export default ReviewerCoiModal
