/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import get from 'lodash/get'

import { th } from '@pubsweet/ui-toolkit'
import { AbstractEditor } from '../../xpubEdit'

import Tabs from '../../../ui/src/split/Tabs'

// TO DO -- extract Labels from TextField
const Label = styled.label`
  display: block;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
`

const readOnlyStyles = css`
  background: ${th('colorBackgroundHue')};
  cursor: not-allowed;
`

const Editor = styled(AbstractEditor)`
  border-color: ${th('colorBorder')};
  border-radius: ${th('borderRadius')};
  font-family: ${th('fontInterface')};
  padding: ${th('gridUnit')} calc(${th('gridUnit')} / 2);

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${props => props.readonly && readOnlyStyles};
`

const menuBorder = css`
  border: 1px solid ${th('colorBorder')};
  /* border-bottom: 0; */
`

const menuStyles = css`
  font-size: ${th('fontSizeBaseSmall')};
  margin: 0 0 0 ${th('gridUnit')};

  ${props => !props.readOnly && menuBorder};
`

const Wrapper = styled.div`
  margin-bottom: 8px;

  > div > div:first-child {
    margin: 0 0 0 ${th('gridUnit')};

    ${props => (props.bold || props.italic) && menuStyles};
  }
`

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const StyledContentEditable = styled.div`
  :empty:not(:focus):before {
    color: gray;
    content: attr(data-placeholder);
  }

  border-bottom: 1px solid ${th('colorBorder')};
  padding: 8px;
  white-space: pre-wrap;
`

const StyledTabs = styled(Tabs)`
  border-top: 1px solid ${th('colorBorder')};
`

const ContentEditable = props => {
  const { handleChange, readOnly, value } = props

  const originalValue = useRef(value)

  const handleInput = e => handleChange(e.target.innerText)

  return (
    <StyledContentEditable contentEditable={!readOnly} onInput={handleInput}>
      {originalValue.current}
    </StyledContentEditable>
  )
}

const TextEditor = props => {
  const {
    className,
    error,
    handleChange,
    label,
    name,
    placeholder,
    readOnly,
    required,
    setFieldValue,
    setFieldTouched,
    touched,
    value,
    // editor props
    bold,
    createtable,
    heading,
    italic,
    link,
    smallcaps,
    subscript,
    superscript,
    table,
    underline,
  } = props

  const onChange = newValue => {
    const strippedValue = newValue.replace('<h1>', '').replace('</h1>', '')
    if (handleChange) handleChange(strippedValue)
    setFieldValue(name, strippedValue)
  }

  const handleBlur = e => {
    setFieldTouched(name, true)
  }

  const touchedThis = get(touched, name)

  const visual = (
    <Editor
      bold={bold || false}
      className={className}
      createtable={createtable || false}
      debounceDelay={0}
      greek
      heading={heading || false}
      italic={italic || false}
      link={link || false}
      mathml
      onBlur={handleBlur}
      onChange={onChange}
      placeholder={placeholder}
      // {...props}
      readonly={readOnly}
      smallcaps={smallcaps || false}
      subscript={subscript || false}
      superscript={superscript || false}
      table={table || false}
      underline={underline || false}
      value={value || ''}
    />
  )

  const text = (
    <ContentEditable
      handleBlur={handleBlur}
      handleChange={onChange}
      placeholder={placeholder}
      readOnly={readOnly}
      value={value || ''}
    />
  )

  const tabs = [
    { key: `${name}-visual`, label: 'Visual', content: visual },
    { key: `${name}-text`, label: 'Text', content: text },
  ]

  const title = label && (
    <Label>
      {`${label}${required ? ' *' : ''}`}{' '}
      {touchedThis && error && <Error>{error}</Error>}
    </Label>
  )

  return (
    <Wrapper className={className} {...props}>
      <StyledTabs activeKey={`${name}-visual`} sections={tabs} title={title} />
    </Wrapper>
  )
}

export default TextEditor
