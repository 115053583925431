/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import Modal from './Modal'
import Funding from '../submissionForm/Funding'
import ModalHeader from './ModalHeader'
import ModalFooterDialog from './ModalFooterDialog'

const FundingModal = props => {
  const {
    currentAward,
    search,
    onClickOption,
    setFieldValue,
    value,
    handleChange,
  } = props

  const Header = <ModalHeader text="Add Award" />

  const Footer = (
    <ModalFooterDialog showCancelButton={false} textSuccess="Done" />
  )

  return (
    <Modal
      footerComponent={Footer}
      headerComponent={Header}
      size="medium"
      {...props}
    >
      <Funding
        currentAward={currentAward}
        handleChange={handleChange}
        onClickOption={onClickOption}
        placeholder="Search for Funder..."
        search={search}
        setFieldValue={setFieldValue}
        value={value}
      />
    </Modal>
  )
}

export default FundingModal
