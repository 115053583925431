import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import styled from 'styled-components'
import clone from 'lodash/clone'

import { th } from '@pubsweet/ui-toolkit'

import { Button, Form, Icon, TextEditor } from '../common'
import Image from '../submissionForm/Image'

const validations = yup.object().shape({
  title: yup.string().required('Title cannot be empty'),
  content: yup.string().required('Content cannot be empty'),
  image: yup
    .object()
    .shape({ name: yup.string(), url: yup.string().nullable() }),
  publish: yup.bool(),
})

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledTextEditor = styled(TextEditor)`
  background: ${th('colorBackground')};
  border: 0;
  margin-bottom: 0;
  padding: 0;
`

const FormWrapper = styled.div`
  border-bottom: 1px solid black;
`

const StyledButton = styled(Button)`
  margin-bottom: 5px;
`

const NewsSectionItem = props => {
  const { title, content, image, deleteNews, editNews, upload, publish } = props

  const [showForm, setShowForm] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const initialValues = {
    title: title || '',
    content: content || '',
    image: image || { name: '', url: '' },
    publish: publish || false,
  }

  const handleSubmit = (values, formikBag) =>
    editNews(values).then(() => setShowForm(false))

  return (
    <>
      {!showForm && !showDelete && (
        <Wrapper>
          {!publish && <em>Draft</em>}
          <StyledTextEditor readOnly value={title} />
          <Wrapper>
            <StyledButton onClick={() => setShowForm(true)}>
              <Icon>edit</Icon>
            </StyledButton>
            <StyledButton onClick={() => setShowDelete(true)}>
              <Icon>x-square</Icon>
            </StyledButton>
          </Wrapper>
        </Wrapper>
      )}
      {showDelete && (
        <Wrapper>
          Confirm Delete of &#34;{title.replace(/<[^>]*>?/gm, '')}&#34;?
          <StyledButton onClick={() => deleteNews()} primary>
            Yes
          </StyledButton>
          <StyledButton onClick={() => setShowDelete(false)} primary>
            No
          </StyledButton>
        </Wrapper>
      )}

      {showForm && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => {
            const {
              errors,
              setFieldTouched,
              setFieldValue,
              touched,
              values,
            } = formProps

            const setPublished = input => {
              const publishValues = clone(values)
              publishValues.publish = true
              return editNews(publishValues).then(() => setShowForm(false))
            }

            return (
              <FormWrapper>
                <TextEditor
                  error={errors.title}
                  italic
                  label="Title"
                  name="title"
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  value={values.title}
                />

                <TextEditor
                  bold
                  error={errors.content}
                  italic
                  label="Content"
                  link
                  name="content"
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  underline
                  value={values.content}
                />

                <Image
                  name="image"
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  upload={upload}
                  values={values}
                />

                <StyledButton onClick={() => setShowForm(false)}>
                  Cancel
                </StyledButton>
                {!values.publish && (
                  <StyledButton onClick={setPublished}>Publish</StyledButton>
                )}
                <StyledButton primary type="submit">
                  Save
                </StyledButton>
              </FormWrapper>
            )
          }}
        </Form>
      )}
    </>
  )
}

NewsSectionItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.shape({ name: PropTypes.string, url: PropTypes.string }),
  deleteNews: PropTypes.func,
  editNews: PropTypes.func,
  upload: PropTypes.func,
  publish: PropTypes.bool,
}

NewsSectionItem.defaultProps = {
  title: null,
  content: null,
  image: null,
  deleteNews: null,
  editNews: null,
  upload: null,
  publish: false,
}

export default NewsSectionItem
