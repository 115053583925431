import { gql } from '@apollo/client'

const USER_MANAGER_DATA = gql`
  query UserManagerData {
    users {
      admin
      displayName
      email
      givenNames
      id
      isActive
      orcid
      surname
      username
      reviewer
      notReviewer
      species
      researchArea
      specialty
      numArticles
      numReviews
      numReminders
      hasBadReview
      hasGoodReview
      currentlyReviewing
      isConfirmed
    }
  }
`

const NEW_USER_MANAGER_DATA = gql`
  query NewUsersManagerData {
    users {
      id
      givenNames
      surname
      species
      isActive
      email
    }
  }
`

const UPDATE_PERSONAL_INFORMATION = gql`
  mutation UpdatePersonalInformation($input: UpdatePersonalInformationInput!) {
    updatePersonalInformation(input: $input) {
      id
    }
  }
`

const UPDATE_USERNAME = gql`
  mutation UpdateUsername($input: UpdateUsernameInput!) {
    updateUsername(input: $input) {
      id
    }
  }
`

const TOGGLE_IS_ACTIVE = gql`
  mutation toggleIsActive($userId: ID!) {
    toggleIsActive(userId: $userId) {
      id
    }
  }
`

const OVERRIDE_VERIFY_EMAIL = gql`
  mutation OverrideVerifyEmail($userId: ID!) {
    overrideVerifyEmail(userId: $userId) {
      id
    }
  }
`

const GET_USER = gql`
  query getUser($userId: ID!) {
    user(id: $userId) {
      admin
      displayName
      email
      givenNames
      id
      isActive
      orcid
      surname
      username
      reviewer
      species
      researchArea
      specialty
      isConfirmed
      confirmationToken
    }
  }
`

const GET_REVIEWS = gql`
  query reviewHistory($userId: ID!) {
    reviewHistory(userId: $userId) {
      manuscripts {
        title
        manuscriptId
        invitationStatus
        rating
        reviewed
        reason
        updated
      }
    }
  }
`

const GET_MANUSCRIPTS = gql`
  query authorManuscripts($userId: ID!) {
    authorManuscripts(userId: $userId) {
      id
      versions {
        title
        decision
      }
    }
  }
`

const GET_REMINDERS = gql`
  query userReminders($userId: ID!) {
    userReminders(userId: $userId) {
      name
      runDate
      userId
    }
  }
`

const CANCEL_JOB = gql`
  mutation cancelJob($jobName: String!) {
    cancelJob(jobName: $jobName)
  }
`

const RESCHEDULE_JOB = gql`
  mutation rescheduleJob($jobName: String!, $date: String!) {
    rescheduleJob(jobName: $jobName, date: $date)
  }
`

// eslint-disable-next-line import/prefer-default-export
export {
  TOGGLE_IS_ACTIVE,
  USER_MANAGER_DATA,
  UPDATE_PERSONAL_INFORMATION,
  UPDATE_USERNAME,
  NEW_USER_MANAGER_DATA,
  GET_USER,
  GET_REVIEWS,
  GET_MANUSCRIPTS,
  GET_REMINDERS,
  CANCEL_JOB,
  RESCHEDULE_JOB,
  OVERRIDE_VERIFY_EMAIL,
}
