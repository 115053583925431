import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { th } from '@pubsweet/ui-toolkit'

import { Tab } from '../common'

const TabsContainer = styled.div`
  display: flex;
  /* margin-bottom: calc(${th('gridUnit')} * 2); */

  > :last-child {
    > div {
      border-right: ${th('borderWidth')} ${th('borderStyle')}
        ${th('colorPrimary')};
    }
  }
`

const Title = styled.div`
  border: 0;
  border-bottom: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
  padding: ${th('gridUnit')} 1em;
`

const TabContainer = styled.div.attrs(props => ({
  'data-test-id': props['data-test-id'] || 'tab-container',
}))``

const Content = styled.div``

const Tabs = props => {
  const {
    activeKey: activeKeyFromProps,
    alwaysUseActiveKeyFromProps,
    onChange,
    sections,
    title,
  } = props

  const [activeKey, setActiveKey] = useState(
    activeKeyFromProps || (sections && sections[0] && sections[0].key),
  )

  const changeActive = key => {
    if (alwaysUseActiveKeyFromProps) {
      onChange && onChange(key)
    } else {
      setActiveKey(key)
    }
  }

  const keyToUse = alwaysUseActiveKeyFromProps ? activeKeyFromProps : activeKey

  const activeSection = sections.find(section => section.key === keyToUse)
  const activeContent = activeSection && activeSection.content

  return (
    <>
      <TabsContainer>
        {title && <Title>{title}</Title>}

        {sections.map(({ key, label }) => (
          <TabContainer key={key}>
            <Tab active={keyToUse === key} onClick={() => changeActive(key)}>
              {label || key}
            </Tab>
          </TabContainer>
        ))}
      </TabsContainer>

      <Content>{activeContent}</Content>
    </>
  )
}

Tabs.propTypes = {
  /** Key of tab to display */
  activeKey: PropTypes.string,
  /** Manage active key only through props */
  alwaysUseActiveKeyFromProps: PropTypes.bool,
  /** Function to run when the active tab changes */
  onChange: PropTypes.func,
  /** Array of values to display. Key is the unique identifier of the tab, label
   *  is the text to display on it and content is the component that will be
   * displayed underneath.
   */
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string,
}

Tabs.defaultProps = {
  activeKey: null,
  alwaysUseActiveKeyFromProps: false,
  onChange: null,
  title: null,
}

export default Tabs
