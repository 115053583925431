/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

const StatusLabel = styled.span`
  align-self: center;
  color: ${({ label }) => {
    if (label === 'accepted to proofs') return th('colorSuccess')
    if (label === 'rejected') return th('colorError')
    if (label === 'declined') return th('colorError')
    if (label === 'withdrawn') return th('colorError')
    if (label === 'under revision') return th('colorWarning')
    if (label === 'editorial re-submission') return th('colorWarning')
    if (label === 'assessment requested') return th('colorWarning')
    if (label === 'assessment received') return th('colorSuccess')
    if (label === 'nothing to curate') return th('colorWarning')
    if (label === 'curated in db') return th('colorSuccess')
    if (label === 'erratum') return th('colorWarning')
    if (label === 'corrigendum') return th('colorWarning')
    if (label === 'retraction') return th('colorError')

    return th('colorPrimary')
  }};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-right: calc(${th('gridUnit')} * 2);
  text-transform: uppercase;
`

const StatusItem = props => {
  const { label } = props
  return <StatusLabel label={label}>{label}</StatusLabel>
}

export default StatusItem
