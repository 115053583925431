import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid, th } from '../_helpers'

import { DiscreetButton, Icon, PanelTextEditor, Button } from '../common'
import RecommendationDot from './RecommendationDot'

const Wrapper = styled.div`
  border: 2px solid ${th('colorBackgroundHue')};
  padding: ${grid(1)};
`

const Header = styled.div`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')};
  display: flex;
  margin-bottom: ${grid(1)};
`

const Dot = styled(RecommendationDot)`
  margin-right: ${grid(1)};
`

const Pending = styled.div`
  align-self: flex-end;
  color: ${th('colorPrimary')};
  font-size: ${th('fontSizeBaseSmall')};
  font-style: italic;
  line-height: ${th('lineHeightBaseSmall')};
  margin-left: auto; /* pull right */
`

const Acknowledgement = styled.div`
  align-self: flex-end;
  color: ${props =>
    props.open ? props.theme.colorSuccess : props.theme.colorError};
  font-size: ${th('fontSizeBaseSmall')};
  font-style: italic;
  font-weight: bold;
  line-height: ${th('lineHeightBaseSmall')};
  margin-left: auto; /* pull right */
`

const InfoRow = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  text-transform: uppercase;
`

const Recommendation = styled.span`
  color: ${props => {
    if (props.status === 'revise') return th('colorWarning')
    if (props.status === 'accept') return th('colorSuccess')
    if (props.status === 'reject') return th('colorError')
    return th('colorText')
  }};
`

const Editor = styled(PanelTextEditor)`
  padding: 0;

  div[contenteditable] {
    border: 0;
  }
`

const ButtonWrapper = styled.div`
  margin-top: ${grid(1)};
`

const StyledButton = styled(Button)`
  min-width: 0;

  :hover {
    background-color: ${th('colorWarning')};
  }
`

const RatingMessage = styled.div`
  margin-left: 6rem;
  text-transform: uppercase;
`

const Review = props => {
  const {
    askedToSeeRevision,
    className,
    confidentialComments,
    content,
    onClickChat,
    openAcknowledgement,
    reviewId,
    pending,
    recommendation,
    reviewerId,
    reviewerName,
    reviseQualifier,
    showChat,
    showRequestToSeeRevision,
    showRating,
    rateReview,
    rating,
  } = props

  const [ratingMessage, setRatingMessage] = useState(null)

  const handleClickChat = () => onClickChat(reviewerId)

  const recommendationOrQualifier = reviseQualifier || recommendation

  const setRating = newRating => {
    setRatingMessage(null)
    return rateReview({ variables: { reviewId, input: { rating: newRating } } })
  }

  const mouseEnter = message => setRatingMessage(message)

  return (
    <Wrapper className={className}>
      <Header>
        <Dot recommendation={pending ? null : recommendation} />
        {reviewerName}

        {pending && <Pending>pending</Pending>}
        {!pending && (
          <Acknowledgement open={openAcknowledgement}>
            {openAcknowledgement ? 'open acknowledgement' : 'anonymous'}
          </Acknowledgement>
        )}
      </Header>

      {!pending && (
        <>
          <InfoRow>
            recommendation:{' '}
            <Recommendation status={recommendation}>
              {recommendationOrQualifier}
            </Recommendation>
          </InfoRow>

          {showRequestToSeeRevision && (
            <InfoRow>
              {askedToSeeRevision
                ? 'Reviewer would like to see revision'
                : 'Reviewer does not need to see revision'}
            </InfoRow>
          )}

          <Editor
            bold
            italic
            label="Review"
            readOnly
            subscript
            superscript
            value={content}
          />

          {confidentialComments && (
            <Editor
              bold
              italic
              label="Confidential Comments"
              readOnly
              subscript
              superscript
              value={confidentialComments}
            />
          )}
        </>
      )}

      {showChat && (
        <ButtonWrapper>
          <DiscreetButton onClick={handleClickChat}>
            Chat with {reviewerName}
          </DiscreetButton>
        </ButtonWrapper>
      )}
      {showRating && rating === null && (
        <ButtonWrapper>
          Rate Review:
          <StyledButton
            onClick={() => setRating(1)}
            onMouseEnter={() => mouseEnter('Excellent')}
            onMouseLeave={() => mouseEnter(null)}
          >
            <Icon>smile</Icon>
          </StyledButton>
          <StyledButton
            onClick={() => setRating(0)}
            onMouseEnter={() => mouseEnter('Average')}
            onMouseLeave={() => mouseEnter(null)}
          >
            <Icon>meh</Icon>
          </StyledButton>
          <StyledButton
            onClick={() => setRating(-1)}
            onMouseEnter={() => mouseEnter('Poor')}
            onMouseLeave={() => mouseEnter(null)}
          >
            <Icon>frown</Icon>
          </StyledButton>
        </ButtonWrapper>
      )}
      {ratingMessage && <RatingMessage>{ratingMessage}</RatingMessage>}
      {!ratingMessage && <br />}
      {showRating && rating !== null && (
        <ButtonWrapper>
          Rating:{' '}
          {rating === 0 && (
            <StyledButton
              onClick={() => setRating(null)}
              title="Click to reset"
            >
              <Icon>meh</Icon>
            </StyledButton>
          )}
          {rating === 1 && (
            <StyledButton
              onClick={() => setRating(null)}
              title="Click to reset"
            >
              <Icon>smile</Icon>
            </StyledButton>
          )}
          {rating === -1 && (
            <StyledButton
              onClick={() => setRating(null)}
              title="Click to reset"
            >
              <Icon>frown</Icon>
            </StyledButton>
          )}
        </ButtonWrapper>
      )}
    </Wrapper>
  )
}

Review.propTypes = {
  /** Controls the message underneath the recommendation */
  askedToSeeRevision: PropTypes.bool,
  /** Confidential comments to the editor */
  confidentialComments: PropTypes.string,
  /** The review text */
  content: PropTypes.string,
  /** Function to run on clicking the chat button */
  onClickChat: PropTypes.func,
  /** Controls the "anonymous" vs "open acknowledgement element" */
  openAcknowledgement: PropTypes.bool,
  /** Whether the review has been submitted */
  pending: PropTypes.bool.isRequired,
  /** The reviewer's recommendation */
  recommendation: PropTypes.oneOf(['accept', 'reject', 'revise']),
  /** The reviewer's user id */
  reviewerId: PropTypes.string.isRequired,
  /** The reviewer's display name */
  reviewerName: PropTypes.string,
  /** Qualifer for the revise recommedation */
  reviseQualifier: PropTypes.string,
  /** Controls whether to show the chat button */
  showChat: PropTypes.bool.isRequired,
  /** Controls whether to show the "asked to see revision" row */
  showRequestToSeeRevision: PropTypes.bool,
  showRating: PropTypes.bool,
  rateReview: PropTypes.func,
  reviewId: PropTypes.string.isRequired,
  rating: PropTypes.number,
}

Review.defaultProps = {
  askedToSeeRevision: null,
  confidentialComments: null,
  content: null,
  onClickChat: null,
  openAcknowledgement: null,
  recommendation: null,
  reviewerName: null,
  reviseQualifier: null,
  showRequestToSeeRevision: true,
  showRating: true,
  rateReview: null,
  rating: null,
}

export default Review
