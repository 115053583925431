import { css } from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

const diff = css`
  background: ${props => {
    if (!props.showDiff) return null
    if (props.isAdded) return th('colorAddition')
    if (props.isRemoved) return th('colorRemoval')
    return null
  }};
`

const fontInterface = css`
  font-family: ${th('fontInterface')};
`

const fontReading = css`
  font-family: ${th('fontReading')};
`

const fontSmall = css`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
`

const smallFontReading = css`
  ${fontReading}
  ${fontSmall}
`

export { diff, fontInterface, fontReading, smallFontReading }
