import { gql } from '@apollo/client'

const SEARCH_SPECIES = gql`
  query SearchSpecies($search: String!, $limit: Int) {
    searchSpecies(search: $search, limit: $limit) {
      scientificName
    }
  }
`

export default SEARCH_SPECIES
