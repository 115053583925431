/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'

import { DateParser } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

const Wrapper = styled.span`
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeighBaseSmall')};

  &:after {
    color: ${th('colorBorder')};
    content: '\\2022';
    padding: 0 ${th('gridUnit')};
  }
`

const Label = styled.span`
  color: ${th('colorBorder')};
  margin-right: calc(${th('gridUnit')} / 2);
  padding-left: calc(${th('gridUnit') * 2});
  text-transform: capitalize;

  &:after {
    content: ':';
  }
`

const DateStatus = props => {
  const { label, value } = props

  let dateValue = value

  if (value && typeof value === 'string') {
    // dateValue = new Date(Number(value))
    dateValue = Number(value)
  }

  return (
    <Wrapper>
      <Label>{label}</Label>
      <DateParser
        dateFormat="MM.DD.YYYY"
        humanizeThreshold={3}
        timestamp={dateValue}
      >
        {timestamp => <span>{timestamp}</span>}
      </DateParser>
    </Wrapper>
  )
}

export default DateStatus
