import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '../_helpers'

const statuses = {
  success: ['success', 'accept'],
  error: ['error', 'reject', 'decline'],
  warning: ['warn', 'warning', 'revise', 'curation'],
  primary: ['primary', 'publish'],
}

const StyledStatus = styled.span`
  background: ${props => {
    const { reverseColors, status } = props
    if (!reverseColors) return null

    if (statuses.success.includes(status)) return th('colorSuccess')
    if (statuses.error.includes(status)) return th('colorError')
    if (statuses.warning.includes(status)) return th('colorWarning')
    if (statuses.primary.includes(status)) return th('colorPrimary')

    return th('colorFurniture')
  }};
  border-radius: 3px;
  color: ${props => {
    const { reverseColors, status } = props
    if (reverseColors) return th('colorTextReverse')

    if (statuses.success.includes(status)) return th('colorSuccess')
    if (statuses.error.includes(status)) return th('colorError')
    if (statuses.warning.includes(status)) return th('colorWarning')
    if (statuses.primary.includes(status)) return th('colorPrimary')

    return th('colorText')
  }};
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: ${props => props.reverseColors && '4px 8px'};
  text-transform: uppercase;
`

const Status = props => {
  const { children, className, reverseColors, status } = props
  if (!children) return null

  return (
    <StyledStatus
      className={className}
      reverseColors={reverseColors}
      status={status}
    >
      {children}
    </StyledStatus>
  )
}

Status.propTypes = {
  reverseColors: PropTypes.bool,
  status: PropTypes.oneOf([
    'success',
    'error',
    'warn',
    'warning',
    'accept',
    'reject',
    'revise',
    'primary',
    'publish',
    'curation',
  ]),
}

Status.defaultProps = {
  reverseColors: false,
  status: null,
}

export default Status
