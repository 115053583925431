/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
import { Redirect } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { Field } from 'formik'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import styled from 'styled-components'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'

import { fadeIn, override, th } from '@pubsweet/ui-toolkit'
import {
  CenteredColumn,
  ErrorText,
  H3 as UIH3,
  H4,
  Icon,
  Link,
  Button,
  TextField,
} from '@pubsweet/ui'

const H3 = styled(UIH3)`
  border-bottom: 2px ${th('borderStyle')} ${th('colorPrimary')};
  text-transform: uppercase;
`

const Logo = styled.div`
  ${override('Login.Logo')};
`

Logo.displayName = 'Logo'

const FormContainer = styled.div`
  ${override('Login.FormContainer')};
`

const UnverifiedWrapper = styled.div`
  animation: ${fadeIn} 1s;
  font-family: ${th('fontSizeInterface')};
  margin-top: calc(${th('gridUnit')} * 25);
  text-align: center;
`

const StyledIcon = styled(Icon)`
  margin-bottom: calc(${th('gridUnit')} * 3);
`

const UsernameInput = props => (
  <TextField
    label="Username"
    placeholder="Username or email"
    // eslint-disable-next-line react/destructuring-assignment
    {...props.field}
  />
)

const PasswordInput = props => (
  <TextField
    label="Password"
    placeholder="Password"
    /* eslint-disable-next-line react/destructuring-assignment */
    {...props.field}
    type="password"
  />
)

const makeErrorMessage = error => {
  if (first(error.split(' ')) === 'AuthorizationError:')
    return 'Invalid credentials'

  if (last(error.split(':')).trim() === 'User account has been disabled')
    return 'User account has been disabled'

  return null
}

const unconfirmed = error => {
  if (isEmpty(error)) return false

  if (last(error.split(':')).trim() === 'Identity not confirmed') {
    return true
  }

  return false
}

const ResendButton = styled.span`
  color: ${th('colorPrimary')};
  cursor: pointer;
  font-weight: bold;
`

const RESEND_VERIFICATION_EMAIL = gql`
  mutation ResendVerificationEmail($username: String!, $password: String!) {
    resendVerificationEmailFromLogin(username: $username, password: $password)
  }
`

const Login = props => {
  const {
    errors,
    contact = true,
    handleSubmit,
    logo = null,
    passwordReset = true,
    redirectLink,
    signup = true,
    values,
  } = props

  if (redirectLink) return <Redirect to={redirectLink} />
  const { password, username } = values

  const isUnconfirmed = unconfirmed(errors)
  if (isUnconfirmed)
    return (
      <Mutation
        mutation={RESEND_VERIFICATION_EMAIL}
        variables={{ password, username }}
      >
        {(resendVerificationEmail, { data, error, loading }) => {
          const success = !loading && !error && data

          if (!success) {
            return (
              <UnverifiedWrapper>
                <StyledIcon color={th('colorError')} size={8}>
                  alert_circle
                </StyledIcon>
                <br />
                This account has not been verified yet.
                <br />
                Check your email for further instructions.
                <br />
                If you have not received an email, click{' '}
                <ResendButton onClick={resendVerificationEmail}>
                  here
                </ResendButton>{' '}
                to receive a new one.
              </UnverifiedWrapper>
            )
          }

          return (
            <UnverifiedWrapper>
              <StyledIcon color={th('colorSuccess')} size={8}>
                check_circle
              </StyledIcon>
              <br />A new verification email has been sent to your email
              address!
            </UnverifiedWrapper>
          )
        }}
      </Mutation>
    )

  return (
    <CenteredColumn>
      {logo && (
        <Logo>
          <img alt="pubsweet-logo" src={`${logo}`} />
        </Logo>
      )}
      <H4>microPublication Manuscript Submission Portal</H4>

      <FormContainer>
        <H3>Login</H3>

        {!isEmpty(errors) && <ErrorText>{makeErrorMessage(errors)}</ErrorText>}

        <form onSubmit={handleSubmit}>
          <Field component={UsernameInput} name="username" />
          <Field component={PasswordInput} name="password" />
          <Button primary type="submit">
            Login
          </Button>
        </form>

        {signup && (
          <>
            <br />
            <span>Don&apos;t have an account? </span>
            <Link to="/signup">Sign up</Link>
            <br />
          </>
        )}

        {passwordReset && (
          <>
            <span>Forgot your password? </span>
            <Link to="/request-password-reset">Reset password</Link>
            <br />
          </>
        )}

        {contact && (
          <>
            <span>Any questions? </span>
            <a href="mailto:editors@micropublication.org">
              Contact us at editors@micropublication.org
            </a>
          </>
        )}
      </FormContainer>
    </CenteredColumn>
  )
}

// Login.propTypes = {
//   error: PropTypes.string,
//   actions: PropTypes.object,
//   location: PropTypes.object,
//   signup: PropTypes.bool,
//   passwordReset: PropTypes.bool,
//   logo: PropTypes.string,
// }

export default Login
