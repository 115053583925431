/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import get from 'lodash/get'

import { th, fadeIn } from '@pubsweet/ui-toolkit'

import AutoComplete from '../../../app/components/formElements/AutoComplete'
import { lookupAffiliation } from '../../../app/fetch/RorApi'

import {
  FileUpload,
  Form,
  PageHeader,
  Select,
  TextEditor,
  TextField,
  Button,
  Icon,
} from '../common'

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const StyledSelect = styled(Select)`
  margin-bottom: 20px;
`

const Label = styled.label`
  display: block;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  /* text-transform: uppercase; */
`

const Info = styled.div`
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeHeading6')};
  width: 100%;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  animation: ${fadeIn} 0;
`

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const StyledIcon = styled(Icon)`
  align-self: center;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  animation: ${fadeIn} 0.5s;
`

const validations = yup.object().shape({
  reason: yup
    .string()
    .required('You need to select a reason for requesting a waiver.'),
  articleTitle: yup
    .string()
    .required('You need to include the title for the article.'),
  otherReason: yup.string().test(
    'other is provide when selected for reason',
    'You need to provide a reason for requesting a waiver.',
    // eslint-disable-next-line func-names, prefer-arrow-callback
    function (val) {
      const { reason } = this.parent

      if (reason === 'other' && (!val || val === '')) {
        return false
      }

      return true
    },
  ),
  supportLetter: yup
    .string()
    .nullable()
    .test(
      'support letter is provided when insufficient funds selected',
      'You need to upload a supporting letter from you department chair.',
      // eslint-disable-next-line func-names, prefer-arrow-callback
      function (val) {
        const { reason } = this.parent

        if (reason === 'insufficientFunds' && !val) {
          return false
        }

        return true
      },
    ),
  affiliation: yup.string().required('You need to provide your affiliation.'),
})

const reasons = [
  {
    label:
      'My lab is located in a low income country as defined by the World Bank',
    value: 'lowIncomeCountry',
  },
  {
    label: 'My lab does not have sufficient funding to pay for APCs',
    value: 'insufficientFunds',
  },
  {
    label:
      'The Corresponding Author for the submission is a microPublication Science Officer',
    value: 'scienceOfficer',
  },
  // { label: 'Other', value: 'other' },
]

const initialValues = {
  reason: '',
  articleTitle: '',
  otherReason: '',
  supportLetter: null,
  affiliation: '',
}

const message = <p>Drag letter of support here, or click to select file</p>

const WaiverForm = props => {
  const { handleUpload, submitWaiverRequest, submitted } = props

  const handleSubmit = (formValues, formikBag) => {
    const {
      affiliation,
      reason,
      otherReason,
      supportLetter,
      articleTitle,
    } = formValues

    delete supportLetter.preview

    const input = { affiliation, file: supportLetter, title: articleTitle }
    input.reason = reason === 'other' ? otherReason : reason

    submitWaiverRequest(input)
  }

  return (
    <PageWrapper>
      <PageHeader>Article Processing Charge (APC) waiver request</PageHeader>
      {!submitted && (
        <>
          <Info>
            To request a waiver for Article Processing Charges please fill out
            the form below. microPublication Biology grants waivers to labs that
            are located in low income countries as defined by the World Bank; to
            PIs serving as Science Officers for the journal; and to labs that do
            not have sufficient funding to pay for APCs. In this latter case, a
            letter of support from the department chair is needed.
            microPublication grants a maximum of 2 waivers per lab per year.
          </Info>

          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validations}
          >
            {formProps => {
              const {
                setFieldValue,
                values,
                handleChange,
                setFieldTouched,
                errors,
              } = formProps

              const handleReasonChange = reason => {
                setFieldValue('reason', reason.value)
              }

              const err =
                get(errors, 'articleTitle') ||
                get(errors, 'affiliation') ||
                get(errors, 'reason') ||
                get(errors, 'supportLetter') ||
                get(errors, 'otherReason')

              return (
                <>
                  <Label>{err && <Error>{err}</Error>}</Label>

                  <TextEditor
                    bold
                    italic
                    label="Article Title"
                    name="articleTitle"
                    placeholder="Article Title"
                    setFieldValue={setFieldValue}
                    value={values.articleTitle}
                  />
                  <AutoComplete
                    fetchData={lookupAffiliation}
                    label="Affiliation"
                    name="affiliation"
                    onChange={handleChange}
                    placeholder=""
                    value={values.affiliation}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...formProps}
                  />

                  <StyledSelect
                    name="reason"
                    onChange={handleReasonChange}
                    options={reasons}
                    value={reasons.find(r => r.value === values.reason)}
                  />
                  {values.reason === 'other' && (
                    <TextField
                      handleChange={handleChange}
                      label="Other Reason"
                      name="otherReason"
                      setFieldTouched={setFieldTouched}
                      value={values.otherReason}
                    />
                  )}
                  {values.reason === 'insufficientFunds' && (
                    <>
                      <Label>
                        Please upload a letter of support from your department
                        chair
                      </Label>
                      {values.supportLetter && values.supportLetter.name}
                      <FileUpload
                        message={message}
                        name="supportLetter"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        upload={handleUpload}
                      />
                    </>
                  )}
                  <Button primary type="submit">
                    Submit
                  </Button>
                </>
              )
            }}
          </Form>
        </>
      )}
      {submitted && (
        <>
          <StyledIcon color={th('colorSuccess')} size={8}>
            check_circle
          </StyledIcon>
          <Info>We have received your waiver request.</Info>
        </>
      )}
    </PageWrapper>
  )
}

export default WaiverForm
