import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/ithenticate`

const uploadDocument = manuscriptId => {
  const url = `${apiUrl}/upload?id=${manuscriptId}`
  return fetch(url)
}

const getScore = manuscriptId => {
  const url = `${apiUrl}/score?id=${manuscriptId}`
  return fetch(url)
}

const getReportUrl = manuscriptId => {
  const url = `${apiUrl}/report?id=${manuscriptId}`
  return fetch(url)
}

export { uploadDocument, getScore, getReportUrl }
