/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'
// import { IconButton } from '@pubsweet/ui'

import { Accordion, H6, Icon, Button } from '../common'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Heading = styled(H6)`
  margin-top: 10px;
`

const Header = styled.div.attrs(props => ({
  'data-test-id': props['data-test-id'] || 'accordion-header',
}))`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`

const HeaderLabel = styled.div`
  color: ${props => {
    return props.predicted ? th('colorSuccess') : th('colorPrimary')
  }};
  font-family: ${th('fontHeading')};
  font-size: ${th('fontSizeBase')};
`

const HeaderIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  transform: ${({ expanded }) => `rotateZ(${expanded ? 0 : 180}deg)`};
  transition: transform ${th('transitionDuration')};
`

// const DeleteButton = styled(IconButton)`
//  min-width: calc(${th('gridUnit')} * 4);
// `

const entityTypes = [
  'GENE',
  'PROTEIN',
  'VARIANT',
  'TRANSGENE',
  'STRAIN',
  'ANATOMY',
  'LIFESTAGE',
  'ANTIBODY',
  'DISEASE',
  'FISH',
  'GENOTYPE',
  'METHOD',
  'MOLECULE',
  'PHENOTYPE',
  'PHENOTERMS',
  'ABBERATION',
  'CONSTRUCT',
  'REARRANGEMENT',
  'BALANCER',
  'SPECIES',
  'OTHER',
]

const EntityList = props => {
  const {
    ///  deleteLinks,
    entities,
    setHighlightEntity,
    ncbiAccessionLookup,
    highlightEntity,
  } = props

  if (!entities || entities.length === 0) {
    return <>No entities linked</>
  }

  const [ncbiData, setNcbiData] = useState()
  const [ncbiLoading, setNcbiLoading] = useState(false)

  const handleNcbiLookup = async accession => {
    setNcbiLoading(true)
    const result = await ncbiAccessionLookup(accession)
    const json = await result.json()
    setNcbiData(json.join(', '))
    setNcbiLoading(false)
  }

  const HeaderComponent = ({
    icon,
    expanded,
    label,
    toggle,
    ...accordionProps
  }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Header expanded={expanded} onClick={toggle} {...accordionProps}>
      <HeaderIcon expanded={expanded}>
        <Icon primary size={3}>
          {icon}
        </Icon>
      </HeaderIcon>
      <HeaderLabel predicted={label.method === 'prediction'}>
        {label.match} ({label.sourceId || 'Not Set'})
      </HeaderLabel>
      <HeaderLabel>Count: {label.count}</HeaderLabel>
    </Header>
  )

  return entityTypes.map(type => {
    return (
      entities.find(entity => entity.type === type) && (
        <div key={type}>
          <Heading>{type}</Heading>
          {entities
            .filter(entity => entity.type === type)
            .map(entity => (
              <Accordion
                header={HeaderComponent}
                key={`${type}-${entity.match}`}
                label={entity}
                onToggle={() => {
                  setNcbiData(null)
                  setHighlightEntity(entity.match)
                }}
              >
                <Wrapper>
                  <div>Source ID: {entity.sourceId || 'Source Not Set'}</div>
                  {entity.url && (
                    <div>
                      Database Link:{' '}
                      <a href={entity.url} rel="noreferrer" target="_blank">
                        {entity.url}
                      </a>
                    </div>
                  )}
                  <div>Method: {entity.method}</div>
                  {entity.source === 'NCBI' &&
                    ncbiData &&
                    highlightEntity === entity.match && (
                      <div key={entity.source}>{ncbiData}</div>
                    )}
                  {entity.source === 'NCBI' && !ncbiData && (
                    <Button
                      loading={ncbiLoading}
                      onClick={() => handleNcbiLookup(entity.sourceId)}
                    >
                      NCBI
                    </Button>
                  )}
                </Wrapper>
              </Accordion>
            ))}
        </div>
      )
    )
  })
}

export default EntityList
