/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import Modal from './Modal'
import ModalHeader from './ModalHeader'
import ModalFooterDialog from './ModalFooterDialog'
import Reminders from '../userManager/Reminders'

const RemindersModal = props => {
  const { remindersData, cancelJob, rescheduleJob } = props
  const Header = <ModalHeader text="Scheduled Notifications" />

  const Footer = (
    <ModalFooterDialog showCancelButton={false} textSuccess="Close" />
  )

  return (
    <Modal
      footerComponent={Footer}
      headerComponent={Header}
      size="medium"
      {...props}
    >
      <Reminders
        cancelJob={cancelJob}
        remindersData={remindersData}
        rescheduleJob={rescheduleJob}
      />
    </Modal>
  )
}

export default RemindersModal
