/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import last from 'lodash/last'

import { th } from '@pubsweet/ui-toolkit'

import { makePayment } from '../fetch/AuthorizeNetApi'
import AcceptJs from '../../ui/src/authorizeNet/AcceptJs'
import { PageHeader, Loader } from '../../ui/src/common'
import { PAYMENT } from '../graphql'

const amount = '250'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;

  > div:not(:last-child) {
    border-bottom: 1px solid ${th('colorFurniture')};
  }
`

const StyledPageHeader = styled(PageHeader)`
  margin-top: 0;
`

const PaymentPage = props => {
  const { id: manuscriptId } = useParams()

  const { data, loading } = useQuery(PAYMENT, {
    variables: { id: manuscriptId },
  })

  if (loading) return <Loader />

  const articleData = data && data.manuscript

  const paymentStatus = articleData && articleData.paymentStatus

  const version =
    articleData && articleData.versions && last(articleData.versions)

  const { email } = version.teams.find(t => t.role === 'author').members[0].user

  return (
    <Wrapper>
      <StyledPageHeader>Article Processing Charge</StyledPageHeader>
      <div dangerouslySetInnerHTML={{ __html: `Title: ${version.title}` }} />
      {paymentStatus === 'unpaid' && (
        <AcceptJs
          amount={amount}
          email={email}
          handlePayment={makePayment}
          manuscriptId={manuscriptId}
        />
      )}
      {paymentStatus !== 'unpaid' && <>This page is not available</>}
    </Wrapper>
  )
}

export default PaymentPage
