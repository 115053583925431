import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'

import { grid } from '../_helpers'
import { Button, Select, Form } from '../common'
import ValueList from './ValueList'

const Wrapper = styled.div`
  max-width: ${grid(35)};
`

const statusOptions = [
  { label: 'Before APC', value: 'beforeAPC' },
  { label: 'Waived', value: 'waived' },
  { label: 'Unpaid', value: 'unpaid' },
  { label: 'Paid', value: 'paid' },
]

const validations = yup.object().shape({
  paymentStatus: yup.string(),
})

const findSelected = value =>
  statusOptions.find(option => option.value === value)

const PaymentSection = props => {
  const { paymentStatus, updatePaymentStatus } = props
  const [showForm, setShowForm] = useState(false)

  const initialValues = {
    paymentStatus,
  }

  const valueListData = [
    {
      label: 'STATUS',
      status: 'primary',
      value: findSelected(paymentStatus) && findSelected(paymentStatus).label,
    },
  ]

  const handleSubmit = values => {
    const selected = findSelected(values.paymentStatus)
    updatePaymentStatus(selected.value)
    setShowForm(false)
  }

  return (
    <Wrapper>
      <ValueList data={valueListData} />

      {!showForm && (
        <Button onClick={() => setShowForm(true)} primary>
          Edit
        </Button>
      )}

      {showForm && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => {
            const { isValid, setFieldValue, values } = formProps

            const onChange = valueObject => {
              if (!valueObject) setFieldValue('paymentStatus', '')
              else setFieldValue('paymentStatus', valueObject.value)
            }

            const selectValue = findSelected(values.paymentStatus)

            return (
              <>
                <Select
                  isSearchable={false}
                  name="paymentStatus"
                  onChange={onChange}
                  options={statusOptions}
                  placeholder="Select Payment Status"
                  value={selectValue}
                />

                <Button disabled={!isValid} primary type="submit">
                  Set Status
                </Button>
              </>
            )
          }}
        </Form>
      )}
    </Wrapper>
  )
}

PaymentSection.propTypes = {
  paymentStatus: PropTypes.string,
  updatePaymentStatus: PropTypes.func,
}

PaymentSection.defaultProps = {
  paymentStatus: null,
  updatePaymentStatus: null,
}

export default PaymentSection
