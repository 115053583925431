import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid, th } from '../_helpers'
import { DiscreetButton } from '../common'
import RecommendationDot from './RecommendationDot'

const RowWrapper = styled.div`
  align-items: center;
  border-bottom: 1px solid ${th('colorFurniture')};
  cursor: default;
  display: flex;
  justify-content: space-between;
  margin: ${grid(1)} 0;
  transition: border-color 0.1s ease-in;

  &:hover {
    border-color: ${th('colorPrimary')};
  }
`

const LeftSide = styled.div`
  display: flex;
`

const Dot = styled(RecommendationDot)`
  margin-right: ${grid(1)};
`

const Name = styled.span`
  transition: all 0.1s ease-in;
`

const ReinviteReviewer = props => {
  const {
    displayName,
    onClickInvite,
    recommendation,
    showChat,
    onClickChat,
    showReinviteButton,
  } = props

  return (
    <RowWrapper>
      <LeftSide>
        <Dot recommendation={recommendation} />
        <Name>{displayName}</Name>
      </LeftSide>
      {showReinviteButton && (
        <DiscreetButton onClick={onClickInvite}>reinvite</DiscreetButton>
      )}
      {!showReinviteButton && <>Invited</>}
      {showChat && (
        <DiscreetButton onClick={onClickChat}>
          Chat with {displayName}
        </DiscreetButton>
      )}
    </RowWrapper>
  )
}

ReinviteReviewer.propTypes = {
  displayName: PropTypes.string.isRequired,
  onClickInvite: PropTypes.func.isRequired,
  recommendation: PropTypes.oneOf(['accept', 'reject', 'revise']).isRequired,
  showChat: PropTypes.bool,
  onClickChat: PropTypes.func,
  showReinviteButton: PropTypes.bool,
}

ReinviteReviewer.defaultProps = {
  showChat: true,
  onClickChat: null,
  showReinviteButton: false,
}

export default ReinviteReviewer
