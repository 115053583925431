/* eslint-disable react/prop-types */

import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { DASHBOARD_MANUSCRIPTS } from './dashboardManuscripts'

const DELETE_ARTICLE = gql`
  mutation DeleteArticle($id: ID!) {
    deleteManuscript(id: $id)
  }
`

const DeleteArticleMutation = props => {
  const { render } = props

  const refetch = [
    {
      query: DASHBOARD_MANUSCRIPTS,
    },
  ]

  return (
    <Mutation mutation={DELETE_ARTICLE} refetchQueries={refetch}>
      {(deleteArticle, deleteArticleResponse) =>
        render({ deleteArticle, deleteArticleResponse })
      }
    </Mutation>
  )
}

export default DeleteArticleMutation
