import { gql } from '@apollo/client'

const DATA_RELEASES = gql`
  query DataReleases {
    dataReleases {
      dataReleases
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { DATA_RELEASES }
