/* eslint-disable react/prop-types */

import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { DASHBOARD_MANUSCRIPTS } from './dashboardManuscripts'
import { CURRENT_USER } from '../../Private'

const HANDLE_INVITATION = gql`
  mutation HandleInvitation($action: String!, $articleVersionId: ID!) {
    handleInvitation(action: $action, articleVersionId: $articleVersionId)
  }
`

const HandleInvitationMutation = props => {
  const { render } = props

  const refetch = [
    {
      query: CURRENT_USER,
    },
    {
      query: DASHBOARD_MANUSCRIPTS,
    },
  ]

  return (
    <Mutation mutation={HANDLE_INVITATION} refetchQueries={refetch}>
      {(handleInvitation, handleInvitationResponse) =>
        render({ handleInvitation, handleInvitationResponse })
      }
    </Mutation>
  )
}

export default HandleInvitationMutation
