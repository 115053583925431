const RESEARCH_AREAS = [
  { label: 'Cell Biology', value: 'Cell Biology' },
  { label: 'Development', value: 'Development' },
  { label: 'Ecology and Evolution', value: 'Ecology and Evolution' },
  {
    label: 'Gene Regulation and Genomics',
    value: 'Gene Regulation and Genomics',
  },
  { label: 'Neurobiology', value: 'Neurobiology' },
  { label: 'Physiology', value: 'Physiology' },
  { label: 'Science and Society', value: 'Science and Society' },
  {
    label: 'Modeling Human Disease to Gene Regulation and Genomics',
    value: 'Modeling Human Disease to Gene Regulation and Genomics',
  },
  { label: 'Structural Biology', value: 'Structural Biology' },
  { label: 'Microbiology', value: 'Microbiology' },
  { label: 'Biophysics', value: 'Biophysics' },
  { label: 'Computational Biology', value: 'Computational Biology' },
]

const SPECIALTY = [
  { label: 'Cell Polarity', value: 'Cell Polarity' },
  { label: 'Cell Death', value: 'Cell Death' },
  { label: 'Cell Division', value: 'Cell Division' },
  { label: 'Cytoskeleton and Migration', value: 'Cytoskeleton and Migration' },
  { label: 'Germline, Meiosis', value: 'Germline, Meiosis' },
  { label: 'Imaging Methods', value: 'Imaging Methods' },
  {
    label: 'Intracellular Trafficking and Organelles',
    value: 'Intracellular Trafficking and Organelles',
  },

  { label: 'Cell Fate and Patterning', value: 'Cell Fate and Patterning' },
  {
    label: 'Differentiation and Plasticity',
    value: 'Differentiation and Plasticity',
  },
  { label: 'Germline Development', value: 'Germline Development' },
  { label: 'Morphogenesis', value: 'Morphogenesis' },
  { label: 'Sex Determination', value: 'Sex Determination' },
  {
    label: 'Signaling Pathways in Development',
    value: 'Signaling Pathways in Development',
  },

  {
    label: 'Ecology, Biotic Interactions, Chemical Signaling',
    value: 'Ecology, Biotic Interactions, Chemical Signaling',
  },
  {
    label: 'Evo-Devo, Evolution of Mating Systems',
    value: 'Evo-Devo, Evolution of Mating Systems',
  },
  {
    label: 'Evolution of Complex Traits, Quantitative Genetics',
    value: 'Evolution of Complex Traits, Quantitative Genetics',
  },
  {
    label: 'Experimental Evolution and Ecology',
    value: 'Experimental Evolution and Ecology',
  },
  {
    label: 'Phylogeny and Diversification',
    value: 'Phylogeny and Diversification',
  },
  { label: 'Population Genetics', value: 'Population Genetics' },
  { label: 'Speciation', value: 'Speciation' },

  { label: 'Databases and Software', value: 'Databases and Software' },
  { label: 'Gene Regulation', value: 'Gene Regulation' },
  { label: 'Genomics', value: 'Genomics' },
  { label: 'Epigenetics', value: 'Epigenetics' },
  { label: 'Novel Genetic Technologies', value: 'Novel Genetic Technologies' },
  {
    label: 'RNA Interference and noncoding RNAs',
    value: 'RNA Interference and noncoding RNAs',
  },

  { label: 'Behavior', value: 'Behavior' },
  { label: 'Neuronal Development', value: 'Neuronal Development' },
  { label: 'Novel Neuronal Methods', value: 'Novel Neuronal Methods' },
  {
    label: 'Regeneration and Degeneration',
    value: 'Regeneration and Degeneration',
  },
  {
    label: 'Synaptic Function and Circuits',
    value: 'Synaptic Function and Circuits',
  },

  { label: 'Aging and Stress', value: 'Aging and Stress' },
  { label: 'Dauer Larvae', value: 'Dauer Larvae' },
  { label: 'Metabolism', value: 'Metabolism' },
  { label: 'Pathogenesis', value: 'Pathogenesis' },
  { label: 'Novel Technologies', value: 'Novel Technologies' },

  { label: 'Education', value: 'Education' },
  {
    label: 'Ethical, Legal and Social Issues',
    value: 'Ethical, Legal and Social Issues',
  },
  { label: 'Science Communication', value: 'Science Communication' },
  {
    label: 'Science Policy and Outreach',
    value: 'Science Policy and Outreach',
  },
]

export { RESEARCH_AREAS, SPECIALTY }
