import React, { useState } from 'react'
import { useMutation } from '@apollo/client'

import { UPLOAD_FILE } from '../graphql'
import SUBMIT_WAIVER_REQUEST from '../graphql/waiverRequest'
import WaiverForm from '../../ui/src/waiverForm/WaiverForm'

const WaiverFormPage = () => {
  const [submitted, setSubmitted] = useState(false)
  const [uploadFile] = useMutation(UPLOAD_FILE)
  const handleUpload = file => uploadFile({ variables: { file } })

  const [submitWaiverRequest] = useMutation(SUBMIT_WAIVER_REQUEST)

  const submitWaiverRequestFn = input => {
    submitWaiverRequest({ variables: { input } }).then(() => setSubmitted(true))
  }

  return (
    <WaiverForm
      handleUpload={handleUpload}
      submitted={submitted}
      submitWaiverRequest={submitWaiverRequestFn}
    />
  )
}

export default WaiverFormPage
