import get from 'lodash/get'

const getInvitedReviewersTeam = versionTeams => {
  const reviewerTeam = versionTeams.find(t => t.role === 'reviewer')

  const invitedReviewers = reviewerTeam.members.filter(member =>
    [
      'invited',
      'acceptedInvitation',
      'rejectedInvitation',
      'invitationRevoked',
    ].includes(member.status),
  )

  return invitedReviewers
}

const getManuscriptTeam = manuscriptTeams => {
  const manuscriptEditors = membersOfTeam(manuscriptTeams, 'editor')
  const manuscriptEditor = manuscriptEditors && manuscriptEditors[0]

  const manuscriptSectionEditors = membersOfTeam(
    manuscriptTeams,
    'sectionEditor',
  )

  const manuscriptSectionEditor =
    manuscriptSectionEditors && manuscriptSectionEditors[0]

  const manuscriptSOs = membersOfTeam(manuscriptTeams, 'scienceOfficer')
  const manuscriptSO = manuscriptSOs && manuscriptSOs[0]

  const manuscriptCurators = membersOfTeam(manuscriptTeams, 'curator')
  const manuscriptCurator = manuscriptCurators

  return {
    curator: manuscriptCurator,
    editor: manuscriptEditor,
    sectionEditor: manuscriptSectionEditor,
    scienceOfficer: manuscriptSO,
  }
}

const getReviewerCounts = versionTeams => {
  const reviewerTeam = getReviewerTeam(versionTeams)
  const invitedReviewers = getInvitedReviewersTeam(versionTeams)

  const acceptedReviewers = reviewerTeam.members.filter(
    member => member.status === 'acceptedInvitation',
  )

  const rejectedReviewers = reviewerTeam.members.filter(
    member => member.status === 'rejectedInvitation',
  )

  const revokedReviewers = reviewerTeam.members.filter(
    member => member.status === 'invitationRevoked',
  )

  return {
    invited: invitedReviewers.length,
    accepted: acceptedReviewers.length,
    rejected: rejectedReviewers.length,
    revoked: revokedReviewers.length,
  }
}

const getReviewerTeam = versionTeams =>
  versionTeams.find(t => t.role === 'reviewer')

const getSubmittingAuthor = authors => {
  const submittingAuthor = authors.find(author => author.submittingAuthor)

  return {
    authorEmail: submittingAuthor.email,
    authorName: `${submittingAuthor.firstName} ${submittingAuthor.lastName}`,
  }
}

const membersOfTeam = (teams, role, getTeamMetadata, teamMetadata) => {
  if (!teams || !role) return null

  const team = teams.find(t => t.role === role)
  if (!team) return null

  return team.members.map(member => ({
    id: member.user.id,
    displayName: member.user.displayName,
  }))
}

const transformChatMessages = messages =>
  messages &&
  messages.map(message => {
    const { content, id, timestamp, user } = message
    const chatTime = new Date(Number(timestamp))

    return {
      id,
      content,
      displayName: `${user.displayName} ( ${chatTime.toLocaleString()} )`,
    }
  })

const transformCuratorReviews = reviews =>
  reviews.map(review => ({
    content: review.content,
    curatorId: get(review, 'curator.id'),
    curatorName: get(review, 'curator.displayName'),
    openAcknowledgement: review.openAcknowledgement,
    pending: !review.submitted,
    recommendation: review.recommendation,
    reviewerId: get(review, 'curator.id'),
    reviewerName: get(review, 'curator.displayName'),
    showRequestToSeeRevision: false,
    submitted: review.submitted,
  }))

const transformReviews = reviews =>
  reviews.map(review => ({
    askedToSeeRevision: review.askedToSeeRevision,
    confidentialComments: review.confidentialComments,
    content: review.content,
    openAcknowledgement: review.openAcknowledgement,
    pending: review.status.pending,
    rating: review.rating,
    recommendation: review.recommendation,
    reviewId: review.id,
    reviewerId: review.reviewer.id,
    reviewerName: review.reviewer.displayName,
    reviseQualifier: review.reviseQualifier,
    updated: review.updated,
  }))

const getFromStorage = item => localStorage.getItem(item)

const saveToStorage = (item, key) => {
  if (!item || item === '<p></p>') {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, item)
  }
}

const transformAnnotations = annotations => {
  const newAnnotations = { phenotype: [], expression: [], anatomyFunction: [] }

  annotations.forEach(annotation => {
    const { id, dateAssigned, evidence } = annotation

    const newAnnotation = {
      annotationId: id,
      dateAssigned: parseInt(dateAssigned, 10),
      evidence,
    }

    if (annotation.type === 'phenotype') {
      newAnnotation.assay = annotation.assay.value
      newAnnotation.genes = annotation.genes
      newAnnotation.phenotype = annotation.phenotype
      newAnnotation.anatomyTerms = annotation.anatomyTerms
      newAnnotation.lifeStages = annotation.lifeStages
      newAnnotation.phenotypeStatement = annotation.phenotypeStatement
      newAnnotation.notObserved = annotation.notObserved
      newAnnotation.strains = annotation.strains
      newAnnotation.alleles = []
      newAnnotation.transgenes = []
      newAnnotations.phenotype.push(newAnnotation)
    } else if (annotation.type === 'expression') {
      newAnnotation.gene = annotation.gene
      newAnnotation.whenExpressed = annotation.whenExpressed
      newAnnotation.assay = annotation.assay
      newAnnotation.whereExpressed = annotation.whereExpressed
      newAnnotation.cellularComponent = annotation.cellularComponent
      newAnnotations.expression.push(newAnnotation)
    } else if (annotation.type === 'anatomyFunction') {
      newAnnotation.phenotype = annotation.phenotype
      newAnnotation.gene = annotation.gene
      newAnnotation.anatomyTerms = annotation.anatomyTerms
      newAnnotation.involved = annotation.involved
      newAnnotation.assay = annotation.assay
      newAnnotation.remark = annotation.remark
      newAnnotation.noctuamodel = annotation.noctuamodel
      newAnnotation.genotype = annotation.genotype
      newAnnotations.anatomyFunction.push(newAnnotation)
    }
  })

  return newAnnotations
}

const transformEntities = entities => {
  if (!entities) return null

  const savedEntities = {
    GENE: [],
    ANATOMY: [],
    GENEONTOLOGY: [],
    GOCC: [],
    LIFESTAGE: [],
    PROTEIN: [],
    EXPRTYPE: [],
    OTHER: [],
    VARIANT: [],
    PHENOTERMS: [],
    ANATOMY_FUNCTION_ASSAYS: [],
    TRANSGENE: [],
    SEQUENCE: [],
    STRAIN: [],
    ASSAY: [
      { value: 'In situ Hybridization' },
      { value: 'Immunohistochemistry' },
      { value: 'Reporter gene' },
      { value: 'Western Blot' },
      { value: 'Northern blot' },
      { value: 'RT-PCR' },
      { value: 'Genome editing' },
      { value: 'Other' },
    ],
    REARRANGEMENT: [],
    MOLECULE: [],
    DISEASE: [],
  }

  entities.forEach(entity => {
    if (
      entity.sourceId &&
      entity.sourceId !== '' &&
      !entity.deleted &&
      !savedEntities[entity.type].some(e => e.modId === entity.sourceId)
    ) {
      savedEntities[entity.type].push({
        value: entity.match,
        modId: entity.sourceId,
      })
    }
  })
  return savedEntities
}

const speciesMap = s => {
  const translateSpecies = { 'Caenorhabditis elegans': 'c. elegans' }

  return translateSpecies[s] || s
}

export {
  getFromStorage,
  getInvitedReviewersTeam,
  getManuscriptTeam,
  getReviewerCounts,
  getReviewerTeam,
  getSubmittingAuthor,
  membersOfTeam,
  saveToStorage,
  transformChatMessages,
  transformCuratorReviews,
  transformReviews,
  transformAnnotations,
  transformEntities,
  speciesMap,
}
