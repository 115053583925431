import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'
import isEmpty from 'lodash/isEmpty'

import { Button, Form, TextField } from '../common'

const Wrapper = styled.div``

const Username = props => {
  const { className, update, username } = props

  const initialValues = {
    username,
  }

  const validations = yup.object().shape({
    username: yup.string().required('Username is required'),
  })

  return (
    <Wrapper className={className}>
      <Form
        initialValues={initialValues}
        onSubmit={update}
        validationSchema={validations}
      >
        {formProps => {
          const {
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
          } = formProps

          const isValid = isEmpty(errors)

          const disabled =
            values.username === initialValues.username || !isValid

          return (
            <>
              <TextField
                error={errors.username}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="Username"
                name="username"
                touched={touched}
                value={values.username}
              />

              <Button disabled={disabled} primary type="submit">
                Change username
              </Button>
            </>
          )
        }}
      </Form>
    </Wrapper>
  )
}

Username.propTypes = {
  update: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

Username.defaultProps = {}

export default Username
