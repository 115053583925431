/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
import { Field } from 'formik'
import { fadeIn, override, th } from '@pubsweet/ui-toolkit'
import styled from 'styled-components'
import { useApolloClient } from '@apollo/client'

import {
  CenteredColumn,
  Link,
  H3 as UIH3,
  ErrorText,
  Icon,
  Button,
  TextField,
} from '@pubsweet/ui'

import SEARCH_SPECIES from '../../graphql/species'

import { Checkbox, Select } from '../../../ui/src/common'
import {
  RESEARCH_AREAS,
  SPECIALTY,
} from '../../../ui/src/_helpers/researchAreas'

const H3 = styled(UIH3)`
  border-bottom: 2px ${th('borderStyle')} ${th('colorPrimary')};
  text-transform: uppercase;
`

const FormContainer = styled.div`
  ${override('Login.FormContainer')};
  width: 600px;
`

const SuccessText = styled.div`
  color: ${props => props.theme.colorSuccess};
`

const Logo = styled.div`
  margin: 0 auto;
  max-width: 100px;
  width: 100%;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${override('Login.Logo')};
`

Logo.displayName = 'Logo'

const SuccessWrapper = styled.div`
  animation: ${fadeIn} 1s;
  font-family: ${th('fontSizeInterface')};
  margin-top: calc(${th('gridUnit')} * 25);
  text-align: center;
`

const StyledIcon = styled(Icon)`
  margin-bottom: calc(${th('gridUnit')} * 3);
`

const StyledSelect = styled(Select)`
  margin-bottom: calc(${th('gridUnit')} * 3);
`

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
`

const Column = styled.div``

const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 10px 10px 0 0;
`

const UsernameInput = props => (
  <TextField
    label="Username"
    /* eslint-disable-next-line react/destructuring-assignment */
    {...props.field}
    placeholder="Username"
    required
  />
)

const GivenNamesInput = props => (
  <TextField
    label="Given Names"
    /* eslint-disable-next-line react/destructuring-assignment */
    {...props.field}
    placeholder="Given names"
    required
  />
)

const SurnameInput = props => (
  /* eslint-disable-next-line react/destructuring-assignment */
  <TextField label="Surname" {...props.field} placeholder="Surname" required />
)

const EmailInput = props => (
  <TextField
    label="Email"
    /* eslint-disable-next-line react/destructuring-assignment */
    {...props.field}
    placeholder="Email"
    required
    type="email"
  />
)

const OrcidInput = props => (
  /* eslint-disable-next-line react/destructuring-assignment */
  <TextField label="ORCID iD" {...props.field} placeholder="Optional" />
)

const ReviewerInput = props => (
  <Checkbox
    // eslint-disable-next-line react/destructuring-assignment
    checked={props.field.value || false}
    label="Are you willing to be a reviewer?"
    /* eslint-disable-next-line react/destructuring-assignment */
    {...props.field}
    /* eslint-disable-next-line react/destructuring-assignment */
    {...props.form}
  />
)

const SpeciesInput = props => {
  const { form } = props

  const client = useApolloClient()

  const searchSpecies = async input => {
    const res = await client.query({
      query: SEARCH_SPECIES,
      fetchPolicy: 'network-only',
      variables: {
        search: input,
        limit: 10,
      },
    })

    if (res.data && res.data.searchSpecies) {
      return res.data.searchSpecies.map(species => ({
        value: species.scientificName,
        label: species.scientificName,
      }))
    }

    return []
  }

  const handleSelect = species =>
    form.setFieldValue('species', species && species.map(v => v.value))

  return (
    <>
      <Label>Species</Label>
      <StyledSelect
        async
        isClearable
        isMulti
        loadOptions={searchSpecies}
        onChange={handleSelect}
        placeholder="Species"
      />
    </>
  )
}

const ResearchAreaInput = props => {
  const { field } = props

  const handleSelect = researchArea =>
    props.form.setFieldValue(
      'researchArea',
      researchArea && researchArea.map(v => v.value),
    )

  const currentValue =
    field.value && field.value.map(v => ({ value: v, label: v }))

  return (
    <>
      <Label>Research Area</Label>
      <StyledSelect
        isClearable
        isMulti
        onChange={handleSelect}
        options={RESEARCH_AREAS}
        placeholder="Research Area"
        value={currentValue}
      />
    </>
  )
}

const SpecialtyInput = props => {
  const { field } = props

  const handleSelect = specialty =>
    props.form.setFieldValue(
      'specialty',
      specialty && specialty.map(v => v.value),
    )

  const currentValue =
    field.value && field.value.map(v => ({ value: v, label: v }))

  return (
    <>
      <Label>Specialty</Label>
      <StyledSelect
        isClearable
        isMulti
        onChange={handleSelect}
        options={SPECIALTY}
        placeholder="Specialty"
        value={currentValue}
      />
    </>
  )
}

const PasswordInput = props => (
  <TextField
    label="Password"
    /* eslint-disable-next-line react/destructuring-assignment */
    {...props.field}
    placeholder="Password"
    required
    type="password"
  />
)

const Signup = ({ values, handleSubmit, status, logo = null }) => {
  const success = status && status.success

  if (success)
    return (
      <SuccessWrapper>
        <StyledIcon color={th('colorSuccess')} size={8}>
          check_circle
        </StyledIcon>
        <br />
        You have successfully signed up!
        <br />
        Your account still needs to be verified.
        <br />
        Please check your email for further instructions.
      </SuccessWrapper>
    )

  return (
    <CenteredColumn small>
      {logo && (
        <Logo>
          <img alt="pubsweet-logo" src={`${logo}`} />
        </Logo>
      )}

      {!success && (
        <FormContainer>
          <H3>Sign up</H3>

          {status && status.success && (
            <SuccessText>{status.success}</SuccessText>
          )}
          {status && status.error && <ErrorText>{status.error}</ErrorText>}

          <form onSubmit={handleSubmit}>
            <Columns>
              <Column>
                <Field component={UsernameInput} name="username" />
                <Field component={GivenNamesInput} name="givenNames" />
                <Field component={SurnameInput} name="surname" />
                <Field component={EmailInput} name="email" />
                <Field component={OrcidInput} name="orcid" />
              </Column>

              <Column>
                <Field component={ReviewerInput} name="reviewer" />
                <Field component={SpeciesInput} name="species" />
                <Field component={ResearchAreaInput} name="researchArea" />
                <Field component={SpecialtyInput} name="specialty" />

                <Field component={PasswordInput} name="password" />
              </Column>
            </Columns>
            <Button primary type="submit">
              Sign up
            </Button>
          </form>

          <br />

          <div>
            <span>Already have an account? </span>
            <Link to="/login">Login</Link>
          </div>
        </FormContainer>
      )}
    </CenteredColumn>
  )
}

export default Signup
