/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Action, ActionGroup, Icon, Status } from '../common'
import { grid, th } from '../_helpers'
import Editor from '../preview/Editor'

const draggingStyles = css`
  box-shadow: 0 0 0 2px ${th('colorPrimary')};
`

const Wrapper = styled.div`
  background: ${th('colorBackground')};
  box-shadow: 0 0 0 1px ${th('colorPrimary')};
  display: flex;
  flex-direction: column;
  padding: ${grid(1)};
  transition: box-shadow 0.1s ease-in;

  &:hover {
    ${draggingStyles}
  }

  /* stylelint-disable-next-line order/order, order/properties-alphabetical-order */
  ${props => props.isDragging && draggingStyles}
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftSide = styled.div`
  width: 85%;
`

const RightSide = styled.div`
  display: flex;
`

const StatusWrapper = styled.div`
  display: inline-block;
  margin-left: ${grid(2)};
`

const StyledStatus = styled(Status)`
  font-size: 12px;
  margin: 0 4px;
`

const LineWrapper = styled.div`
  display: inline-block;
  font-size: ${th('fontSizeBaseSmall')};
  margin-left: ${grid(2)};
  /* padding-top: ${grid(0.5)}; */
`

const LineLabel = styled.span`
  color: ${th('colorPrimary')};
  margin-right: ${grid(1)};
  text-transform: uppercase;
`

const ErrorWrapper = styled.span`
  color: ${th('colorError')};
  display: inline-block;
  font-size: ${th('fontSizeBaseSmall')};
  margin-left: ${grid(2)};
`

const ReferenceRow = props => {
  const {
    className,
    isDragging,
    innerRef,
    item,
    onClickRemoveRow,
    onClickEditRow,
    ...rest
  } = props

  const { id, reference, pubmedId, doi, error } = item

  return (
    <Wrapper
      className={className}
      isDragging={isDragging}
      ref={innerRef}
      {...rest}
    >
      <TopRow>
        <LeftSide>
          {error && <ErrorWrapper>Error: {error}</ErrorWrapper>}
          <LineWrapper>
            <LineLabel>Reference:</LineLabel>
            <Editor value={reference} />
          </LineWrapper>
          <StatusWrapper>
            {pubmedId && <StyledStatus>PubMed ID: {pubmedId}</StyledStatus>}
            {doi && <StyledStatus>DOI: {doi}</StyledStatus>}
          </StatusWrapper>
        </LeftSide>
        <RightSide>
          <ActionGroup>
            <Action onClick={() => onClickEditRow(id)}>
              <Icon>edit-2</Icon>
            </Action>
            <Action onClick={() => onClickRemoveRow(id)}>
              <Icon>trash-2</Icon>
            </Action>
          </ActionGroup>
        </RightSide>
      </TopRow>
    </Wrapper>
  )
}

ReferenceRow.propTypes = {
  onClickRemoveRow: PropTypes.func,
  onClickEditRow: PropTypes.func,
  /** Row's innerRef. For use by dnd */
  innerRef: PropTypes.func.isRequired,
  /** Whether this row is being dragged */
  isDragging: PropTypes.bool.isRequired,
  /** The item to be displayed in the row */
  item: PropTypes.shape({
    id: PropTypes.string,
    reference: PropTypes.string,
    pubmedId: PropTypes.string,
    doi: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
}

ReferenceRow.defaultProps = {
  onClickRemoveRow: null,
  onClickEditRow: null,
}

export default ReferenceRow
