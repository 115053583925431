/* eslint-disable react/prop-types */

import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { DASHBOARD_MANUSCRIPTS } from './dashboardManuscripts'
import { withCurrentUser } from '../../../userContext'

const UPDATE_ASSIGNED_EDITOR = gql`
  mutation UpdateAssignedEditor($input: UpdateAssignedInput!) {
    updateAssignedEditor(input: $input)
  }
`

const UpdateAssignedEditorMutation = props => {
  const { currentUser, render } = props

  const refetchQueries = [
    {
      query: DASHBOARD_MANUSCRIPTS,
      variables: { reviewerId: currentUser.id },
    },
  ]

  return (
    <Mutation mutation={UPDATE_ASSIGNED_EDITOR} refetchQueries={refetchQueries}>
      {(updateAssignedEditor, updateAssignedEditorResponse) =>
        render({ updateAssignedEditor, updateAssignedEditorResponse })
      }
    </Mutation>
  )
}

export default withCurrentUser(UpdateAssignedEditorMutation)
