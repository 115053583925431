import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import uniqueId from 'lodash/uniqueId'
import { diffSentences } from 'diff'

// import { th } from '@pubsweet/ui-toolkit'

import { diff, fontReading, fontInterface } from './_cssFragments'
import Label from './Label'

const Text = styled.p`
  ${fontReading}
  ${props => props.variant === 'metadata' && fontInterface}
`

const StyledDiffText = styled.span`
  ${diff}
`

const diffText = (oldValues, newValues, options = {}) => {
  const { isAdded, isRemoved, showRemoved } = options

  if (typeof oldValues !== 'string') {
    if (isAdded)
      return (
        <StyledDiffText isAdded showDiff>
          {newValues}
        </StyledDiffText>
      )
    if (isRemoved)
      return (
        <StyledDiffText isRemoved showDiff>
          {newValues}
        </StyledDiffText>
      )

    return newValues
  }

  const difference = diffSentences(oldValues, newValues)

  const sections = difference.map(item => {
    if (item.added)
      return (
        <StyledDiffText isAdded key={uniqueId()} showDiff>
          {item.value}
        </StyledDiffText>
      )

    if (item.removed) {
      if (showRemoved)
        return (
          <StyledDiffText isRemoved key={uniqueId()} showDiff>
            {item.value}
          </StyledDiffText>
        )

      return ''
    }

    return item.value
  })

  return sections
}

const TextBlock = props => {
  const {
    isAdded,
    isRemoved,
    label,
    previousValue,
    showDiff,
    showRemoved,
    value,
    variant,
  } = props

  if (!value) return null

  let output = value
  if (showDiff)
    output = diffText(previousValue, value, { isAdded, isRemoved, showRemoved })

  if (label) {
    return (
      <div>
        <Label value={label} />
        <Text variant={variant}>{output}</Text>
      </div>
    )
  }

  return <Text variant={variant}>{output}</Text>
}

TextBlock.propTypes = {
  /** Whether the whole text is an addition */
  isAdded: PropTypes.bool,
  /** Whether the whole text is a removal */
  isRemoved: PropTypes.bool,
  /** Label to display above text */
  label: PropTypes.string,
  /** Same as value, but previous version. For diffs. */
  previousValue: PropTypes.string,
  /** Whether to show diffs at all */
  showDiff: PropTypes.bool,
  /** Whether to show diff removals */
  showRemoved: PropTypes.bool,
  /** Text that will be rendered */
  value: PropTypes.string,
  /** Different style setups */
  variant: PropTypes.oneOf(['metadata']),
}

TextBlock.defaultProps = {
  isAdded: false,
  isRemoved: false,
  label: null,
  previousValue: null,
  showDiff: true,
  showRemoved: true,
  value: null,
  variant: null,
}

export default TextBlock
