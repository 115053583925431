import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid } from '../_helpers'
import { Action, RibbonFeedback } from '../common'

import ReinviteReviewerList from './ReinviteReviewerList'
import ReviewList from './ReviewList'
import ReviewersNumbers from './ReviewersNumbers'

const Feedback = styled(RibbonFeedback)`
  margin-bottom: 0;
`

const TopRow = styled.div`
  display: flex;

  > div:first-child {
    flex-grow: 1;
  }
`

const Wrapper = styled.div`
  > div:not(:first-child):not(:last-child) {
    margin-bottom: ${grid(3)};
  }
`

const LinkWrapper = styled.div``

const ReviewsSection = props => {
  const {
    acceptedReviewersCount,
    invitedReviewersCount,
    onClickChat,
    onClickInvite,
    rateReview,
    rejectedReviewersCount,
    reviews,
    reviewersFromPreviousVersions,
    reviewersPageUrl,
    revokedReviewersCount,
    showChat,
    showManageReviewers,
    showPreviousReviewers,
  } = props

  return (
    <Wrapper>
      <Feedback
        keepSpaceOccupied={showPreviousReviewers}
        successMessage="Reviewer has been successfully reinivited"
      >
        {notifyRibbon => {
          const reinvite = reviewerId =>
            onClickInvite(reviewerId).then(() => notifyRibbon(true))

          return (
            <>
              <TopRow>
                <ReviewersNumbers
                  accepted={acceptedReviewersCount}
                  invited={invitedReviewersCount}
                  rejected={rejectedReviewersCount}
                  revoked={revokedReviewersCount}
                />

                {showManageReviewers && (
                  <LinkWrapper>
                    <Action to={reviewersPageUrl}>Manage Reviewers</Action>
                  </LinkWrapper>
                )}
              </TopRow>

              {showPreviousReviewers && (
                <ReinviteReviewerList
                  onClickChat={onClickChat}
                  onClickInvite={reinvite}
                  reviewers={reviewersFromPreviousVersions}
                  showChat={showChat}
                />
              )}

              <ReviewList
                onClickChat={onClickChat}
                rateReview={rateReview}
                reviews={reviews}
                showChat={showChat}
              />
            </>
          )
        }}
      </Feedback>
    </Wrapper>
  )
}

ReviewsSection.propTypes = {
  acceptedReviewersCount: PropTypes.number.isRequired,
  invitedReviewersCount: PropTypes.number.isRequired,
  rejectedReviewersCount: PropTypes.number.isRequired,
  revokedReviewersCount: PropTypes.number.isRequired,
  onClickChat: PropTypes.func.isRequired,
  onClickInvite: PropTypes.func.isRequired,
  reviewersFromPreviousVersions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      recommendation: PropTypes.oneOf([
        'accept',
        'reject',
        'revise',
        'publish',
        'decline',
      ]),
    }),
  ),
  reviewersPageUrl: PropTypes.string.isRequired,
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      askedToSeeRevision: PropTypes.bool,
      content: PropTypes.string,
      openAcknowledgement: PropTypes.bool,
      pending: PropTypes.bool,
      recommendation: PropTypes.oneOf([
        'accept',
        'reject',
        'revise',
        'publish',
        'decline',
      ]),
      reviewerId: PropTypes.string,
      reviewerName: PropTypes.string,
    }),
  ),
  showChat: PropTypes.bool,
  showManageReviewers: PropTypes.bool,
  showPreviousReviewers: PropTypes.bool,
  rateReview: PropTypes.func,
}

ReviewsSection.defaultProps = {
  reviewersFromPreviousVersions: [],
  reviews: [],
  showChat: false,
  showManageReviewers: false,
  showPreviousReviewers: false,
  rateReview: null,
}

export default ReviewsSection
