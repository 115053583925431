/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'

import { Action, ActionGroup } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import SectionItemWithStatus from './SectionItemWithStatus'

const Wrapper = styled.div`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')}
    ${th('colorFurniture')};
  /* margin-bottom: ${th('gridUnit')}; */
  padding: 4px 0;
`

const CuratorSectionItem = props => {
  const { id: articleId, author, curatorStatus, title } = props

  const CuratorLink = (
    <ActionGroup>
      <Action to={`/article/${articleId}`}>{curatorStatus}</Action>
    </ActionGroup>
  )

  return (
    <Wrapper>
      <SectionItemWithStatus
        actionsComponent={CuratorLink}
        author={author}
        title={title}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Wrapper>
  )
}

export default CuratorSectionItem
