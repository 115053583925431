import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'

import { Button, Form, TextField } from '../common'

const Wrapper = styled.div``

const initialValues = {
  currentPassword: '',
  newPassword: '',
  newPassword2: '',
}

const validations = yup.object().shape({
  currentPassword: yup.string().required('Current password is required'),
  newPassword: yup.string().required('New password is required'),
  newPassword2: yup
    .string()
    .required('Please re-enter your new password')
    .test(
      'new-password-match',
      'Passwords do not match',
      /* eslint-disable func-names */
      function (val) {
        return val === this.parent.newPassword
      },
    ),
})

const Password = props => {
  const { className, update } = props

  return (
    <Wrapper className={className}>
      <Form
        initialValues={initialValues}
        onSubmit={update}
        validationSchema={validations}
      >
        {formProps => {
          const {
            errors,
            handleBlur,
            handleChange,
            isValid,
            touched,
            values,
          } = formProps

          return (
            <>
              <TextField
                error={errors.currentPassword}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="Current password"
                name="currentPassword"
                touched={touched}
                type="password"
                value={values.currentPassword}
              />

              <TextField
                error={errors.newPassword}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="New password"
                name="newPassword"
                touched={touched}
                type="password"
                value={values.newPassword}
              />

              <TextField
                error={errors.newPassword2}
                handleBlur={handleBlur}
                handleChange={handleChange}
                label="Repeat new password"
                name="newPassword2"
                touched={touched}
                type="password"
                value={values.newPassword2}
              />

              <Button disabled={!isValid} primary type="submit">
                Change password
              </Button>
            </>
          )
        }}
      </Form>
    </Wrapper>
  )
}

Password.propTypes = {
  update: PropTypes.func.isRequired,
}

Password.defaultProps = {}

export default Password
