import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/ror`

const lookupAffiliation = async affiliation => {
  const url = `${apiUrl}/search?affiliation=${affiliation}`

  if (affiliation.trim().length < 3) return { values: [] }

  return fetch(url)
}

const lookupFunder = async funder => {
  const url = `${apiUrl}/funder?funder=${funder}`

  if (funder.trim().length < 3) return { values: [] }

  return fetch(url)
}

export { lookupAffiliation, lookupFunder }
