/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import Select, { Async, AsyncCreatable } from 'react-select'
import React from 'react'

import { withTheme } from 'styled-components'

const StyledSelect = props => {
  const { async, asyncCreatable, theme, ...rest } = props

  const {
    borderStyle,
    borderWidth,
    colorBackground,
    colorBackgroundHue,
    colorBorder,
    colorPrimary,
    colorText,
    colorTextReverse,
    fontInterface,
    fontSizeBase,
    // gridUnit,
    lineHeightBase,
  } = theme

  const stylesFromTheme = {
    container: base => ({
      ...base,
      minWidth: '200px',
    }),
    control: (base, state) => {
      const myBase = {
        backgroundColor: colorBackground,
        border: 0,
        borderBottom: `${borderWidth} ${borderStyle} ${colorBorder}`,
        borderRadius: 0,
        color: colorText,
        display: 'flex',
        flex: '0 1 auto',
        fontFamily: fontInterface,
        fontSize: fontSizeBase,
        lineHeight: lineHeightBase,
      }

      if (state.isFocused) {
        return {
          ...myBase,
          borderBottom: `${borderWidth} ${borderStyle} ${colorPrimary}`,
        }
      }

      return myBase
    },
    dropdownIndicator: base => ({
      ...base,
      padding: 6,
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    input: base => ({
      ...base,
      margin: 0,
    }),
    menu: base => ({
      ...base,
      border: `${borderWidth} ${borderStyle} ${colorBorder}`,
      borderRadius: 0,
      boxShadow: 'none',
      flex: '0 1 100%',
      marginTop: 0,
    }),
    menuList: base => ({
      ...base,
    }),
    option: (base, state) => {
      const myBase = {
        ...base,
        backgroundColor: colorBackground,
        color: colorText,
        fontFamily: fontInterface,
        fontSize: fontSizeBase,
        lineHeight: lineHeightBase,
      }

      if (state.isDisabled) {
        return {
          ...myBase,
          ':active': {
            ...base[':active'],
            backgroundColor: colorBackground,
          },
          backgroundColor: colorBackground,
          color: colorBorder,
          cursor: 'not-allowed',
        }
      }

      if (state.isSelected) {
        return {
          ...myBase,
          backgroundColor: colorPrimary,
          color: colorTextReverse,
        }
      }

      if (state.isFocused) {
        return {
          ...myBase,
          backgroundColor: colorBackgroundHue,
          color: colorText,
        }
      }

      return {
        ...myBase,
      }
    },
    valueContainer: base => ({
      ...base,
      padding: 0,
    }),
  }

  if (async) {
    return (
      <Async
        classNamePrefix="react-select"
        {...rest}
        /* menuIsOpen */
        styles={stylesFromTheme}
      />
    )
  }

  if (asyncCreatable) {
    return (
      <AsyncCreatable
        classNamePrefix="react-select"
        {...rest}
        /* menuIsOpen */
        styles={stylesFromTheme}
      />
    )
  }

  return (
    <Select
      classNamePrefix="react-select"
      {...rest}
      /* menuIsOpen */
      styles={stylesFromTheme}
    />
  )
}

export default withTheme(StyledSelect)
