import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/authorizenet`

const getFormToken = async amount => {
  const url = `${apiUrl}/token?amount=${amount}`
  return fetch(url)
}

const makePayment = async (formData, token, id, amount) => {
  const data = {
    amount,
    id,
    token,
    ...formData,
  }

  const urlParameters = Object.entries(data)
    .map(e => e.join('='))
    .join('&')

  const url = `${apiUrl}/makepayment?${urlParameters}`
  return fetch(url)
}

export { getFormToken, makePayment }
