/* eslint-disable react/prop-types */

import React from 'react'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { useLocation } from 'react-router-dom'

const DASHBOARD_MANUSCRIPTS = gql`
  fragment ManuscriptFields on Manuscript {
    id
    dataType
    dbReferenceId
    entities {
      match
      deleted
      source
      sourceId
      url
      type
    }
    isInitiallySubmitted
    isDataTypeSelected
    paymentStatus
    corrections {
      correctionType
      description
      doi
      date
    }
  }

  fragment VersionFields on ManuscriptVersion {
    id
    isApprovedByScienceOfficer
    decision
    submitted
    title
    created
    updated
    authors {
      firstName
      lastName
      submittingAuthor
    }
  }

  fragment TeamFields on Team {
    id
    role
    members {
      id
      status
      user {
        id
        displayName
      }
    }
  }

  query DashboardManuscripts($editorAssigned: Boolean) {
    author: manuscripts(role: "author") {
      ...ManuscriptFields
      versions {
        ...VersionFields
        reviews {
          status {
            submitted
          }
        }
        teams {
          ...TeamFields
        }
      }
    }
    reviewer: manuscripts(role: "reviewer") {
      ...ManuscriptFields
      reviewerStatus
      versions(invitedToReviewOnly: true) {
        ...VersionFields
        reviews(currentUserOnly: true) {
          status {
            submitted
          }
        }
        abstract
        acknowledgements
        authors {
          affiliations
          credit
          departments
          email
          firstName
          lastName
          submittingAuthor
          correspondingAuthor
          equalContribution
          WBId
        }
        comments
        funding
        geneExpression {
          antibodyUsed
          backboneVector {
            name
            WBId
          }
          coinjected
          constructComments
          constructionDetails
          detectionMethod
          dnaSequence {
            name
            WBId
          }
          expressionPattern {
            name
            WBId
          }
          fusionType {
            name
            WBId
          }
          genotype
          injectionConcentration
          inSituDetails
          integratedBy {
            name
            WBId
          }
          observeExpression {
            certainly {
              certainly {
                name
                WBId
              }
              during {
                name
                WBId
              }
              id
              subcellularLocalization {
                name
                WBId
              }
            }
            partially {
              partially {
                name
                WBId
              }
              during {
                name
                WBId
              }
              id
              subcellularLocalization {
                name
                WBId
              }
            }
            possibly {
              possibly {
                name
                WBId
              }
              during {
                name
                WBId
              }
              id
              subcellularLocalization {
                name
                WBId
              }
            }
            not {
              not {
                name
                WBId
              }
              during {
                name
                WBId
              }
              id
              subcellularLocalization {
                name
                WBId
              }
            }
          }
          reporter {
            name
            WBId
          }
          species {
            name
            WBId
          }
          strain
          transgeneName
          transgeneUsed {
            name
            WBId
          }
          utr {
            name
            WBId
          }
          variation {
            name
            WBId
          }
        }
        image {
          name
          url
        }
        imageCaption
        imageTitle
        laboratory {
          name
        }
        methods
        reagents
        patternDescription
        references {
          reference
          pubmedId
          doi
        }
      }
    }
    editor: manuscripts(role: "editor", editorAssigned: $editorAssigned) {
      ...ManuscriptFields
      teams {
        ...TeamFields
      }
      versions {
        ...VersionFields
        curatorReviews {
          submitted
          curated
        }
        reviews {
          status {
            submitted
          }
        }
        teams {
          ...TeamFields
        }
      }
    }
    sectionEditor: manuscripts(role: "sectionEditor") {
      ...ManuscriptFields
      teams {
        ...TeamFields
      }
      versions {
        ...VersionFields
        curatorReviews {
          submitted
        }
        reviews {
          status {
            submitted
          }
        }
        teams {
          ...TeamFields
        }
      }
    }
    scienceOfficer: manuscripts(role: "scienceOfficer") {
      ...ManuscriptFields
      teams {
        ...TeamFields
      }
      versions {
        ...VersionFields
        reviews {
          status {
            submitted
          }
        }
        teams {
          ...TeamFields
        }
      }
    }
    curator: manuscripts(role: "curator") {
      ...ManuscriptFields
      teams {
        ...TeamFields
      }
      versions {
        ...VersionFields
        curatorReviews {
          curator {
            id
          }
          submitted
          curated
        }
        reviews {
          status {
            submitted
          }
        }
        teams {
          ...TeamFields
        }
      }
    }
  }
`

const DashboardManuscriptsQuery = props => {
  const { render } = props

  const editorAssigned = useLocation().pathname !== '/dashboard/all'

  return (
    <Query
      fetchPolicy="network-only"
      query={DASHBOARD_MANUSCRIPTS}
      variables={{ editorAssigned }}
    >
      {render}
    </Query>
  )
}

export { DASHBOARD_MANUSCRIPTS }
export default DashboardManuscriptsQuery
