import { gql } from '@apollo/client'

const GET_REVIEWER = gql`
  query($teamMemberId: ID!) {
    getReviewer(teamMemberId: $teamMemberId) {
      reason
      status
      suggestedReviewer
    }
  }
`

const DECLINE_INVITATION = gql`
  mutation($teamMemberId: ID!, $reason: String, $suggestedReviewer: String) {
    declineInvitation(
      teamMemberId: $teamMemberId
      reason: $reason
      suggestedReviewer: $suggestedReviewer
    )
  }
`

// eslint-disable-next-line import/prefer-default-export
export { DECLINE_INVITATION, GET_REVIEWER }
