import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { th } from '@pubsweet/ui-toolkit'

import { WBAutocomplete } from '@wormbase/graphical-curation/lib/autocomplete'

import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

// import { v4 as uuid } from 'uuid'

import { Loader, PageHeader as DefaultPageHeader } from '../common'
import Split from '../split/Split'
import Manuscript from '../preview/Manuscript'

const GraphicalCuration = React.lazy(() =>
  import('@wormbase/graphical-curation'),
)

const PageWrapper = styled.div`
  height: 100%;
`

const PageHeader = styled(DefaultPageHeader)`
  margin-block-end: ${th('gridUnit')};
`

const StyledSplit = styled(Split)`
  max-height: calc(100% - (${th('gridUnit')} * 6));
`

const autocompleteObj = new WBAutocomplete(
  'https://tazendra.caltech.edu/~azurebrd/cgi-bin/forms/datatype_objects.cgi?action=autocompleteXHR&objectType=',
)

/*

const entities = {
  PROTEIN: [{ value: 'UNC-59', modId: 'WBGene00006793' }],
  ANATOMY: [
    { value: 'pharynx', modId: 'WBbt:0003681' },
    { value: 'gonad', modId: 'WBbt:0005175' },
    { value: 'sex myoblast', modId: 'WBbt:0008373' },
    { value: 'distal tip cell', modId: 'WBbt:0006865' },
    { value: 'spermatheca', modId: 'WBbt:0005319' },
    { value: 'procorpus', modId: 'WBbt:0003713' },
    { value: 'embryonic cell', modId: 'WBbt:0007028' },
    { value: 'terminal bulb', modId: 'WBbt:0003732' },
    { value: 'sperm', modId: 'WBbt:0006798' },
    { value: 'buccal cavity', modId: 'WBbt:0005255' },
  ],
  EXPRTYPE: [
    { value: 'localization', modId: 'expression' },
    { value: 'antibody', modId: 'Antibody' },
    { value: 'expression', modId: 'expression' },
    { value: 'detected', modId: 'expression' },
  ],
  LIFESTAGE: [
    { value: 'L3', modId: 'WBls:0000035' },
    { value: 'L1', modId: 'WBls:0000024' },
    { value: 'L4', modId: 'WBls:0000038' },
    { value: 'embryo', modId: 'WBls:0000003' },
    { value: 'L2', modId: 'WBls:0000027' },
    { value: 'adult', modId: 'WBls:0000041' },
  ],
  GENE: [
    { value: 'unc-61', modId: 'WBGene00006795' },
    { value: 'unc-59', modId: 'WBGene00006793' },
  ],
  GOCC: [
    { value: 'cell', modId: 'GO:0005623' },
    { value: 'cleavage furrow', modId: 'GO:0032154' },
  ],
  VARIANT: [{ value: 'ttTi5605', modId: 'WBVar00254893' }],
  PHENOTERMS: [
    {
      value: 'stage specific cuticle antigenicity variant',
      modId: 'WBPhenotype:0001677',
    },
    { value: 'chemosensory response variant', modId: 'WBPhenotype:0001040' },
  ],
  ASSAY: [
    { value: 'In situ Hybridization' },
    { value: 'Immunohistochemistry' },
    { value: 'Reporter gene' },
    { value: 'Western Blot' },
    { value: 'Northern blot' },
    { value: 'RT-PCR' },
    { value: 'Other' },
  ],

  ANATOMY_FUNCTION_ASSAYS: [
    { value: 'Expression_mosaic' },
    { value: 'Genetic_mosaic' },
    { value: 'Laser_ablation' },
    { value: 'Optogenetic' },
    { value: 'Blastomere_isolation' },
    { value: 'Genetic_ablation' },
  ],
  TRANSGENES: [
    { value: 'grEx157', modId: 'WBTransgene00008530' },
    { value: 'geEx1', modId: 'WBTransgene00016395' },
  ],
  STRAIN: [{ value: 'N2', modId: 'WBStrain00000001' }],
}

const exprAnnotations = [
  {
    annotationId: 1,
    gene: { value: 'unc-61', modId: 'WBGene00006795' },
    whenExpressed: [{ value: 'L3', modId: 'WBls:0000035' }],
    assay: { value: 'In situ Hybridization' },
    evidence: '',
    whereExpressed: [{ value: 'pharynx', modId: 'WBbt:0003681' }],
    cellularComponent: [{ value: 'cell', modId: 'GO:0005623' }],
    dateAssigned: Date.now(),
  },
]

const anatomyFuncAnnotations = [
  {
    annotationId: 1,
    phenotype: {
      value: 'high sodium chloride concentration osmotic avoidance defective',
      modId: 'WBPhenotype:0001677',
      options: { Autonomous: true, Nonautonomous: false },
    },
    gene: { value: 'unc-61', modId: 'WBGene00006795' },
    anatomyTerms: [
      {
        value: 'pharynx',
        modId: 'WBbt:0003681',
        options: { Insufficient: true, Unnecessary: false },
      },
    ],
    involved: 'not_involved',
    assay: { value: 'Blastomere_isolation' },
    evidence: 'WBPaper00045678',
    remarks: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      'Curabitur eget tellus enim. Quisque placerat finibus nunc, eu laoreet ligula dictum et. In in quam et neque rhoncus dignissim. Suspendisse et nisi orci. Maecenas ac quam venenatis, faucibus est in, vulputate eros. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent blandit sollicitudin ligula, ut pretium ipsum. Nulla vel ante rhoncus, congue purus sed, consectetur nisi. Fusce tempor lorem quis mattis molestie. Sed metus ligula, blandit non eros et, lacinia venenatis tellus. Maecenas nec lorem risus.',
    ],
    noctuamodels: [],
    genotypes: ['test', 'test2'],
    authorstatements: [],
    dateAssigned: Date.now(),
  },
]

const phenotypeAnnotations = [
  {
    annotationId: 1,
    phenotype: {
      value: 'stage specific cuticle antigenicity variant',
      modId: 'WBPhenotype:0001677',
    },
    assay: 'RNAi',
    alleles: [{ value: 'ttTi5605', modId: 'WBVar00254893' }],
    genes: [{ value: 'unc-61', modId: 'WBGene00006795' }],
    transgenes: [{ value: 'grEx157', modId: 'WBTransgene00008530' }],
    anatomyTerms: [],
    lifeStages: [{ value: 'L3', modId: 'WBls:0000035' }],
    strains: [],
    evidence: 'WBPaper00001254',
    phenotypeStatement: 'test',
    dateAssigned: Date.now(),
  },
]

*/

const CurationTool = props => {
  const {
    loading,
    entities,
    exprAnnotations,
    evidence,
    anatomyFuncAnnotations,
    phenoAnnotations,
    saveAnnotations,
    version,
  } = props

  if (loading) return <Loader />

  const top = <Manuscript version={version} />

  const bottom = (
    <Suspense fallback={<Loader />}>
      <GraphicalCuration
        anatomyFunctionAnnotations={anatomyFuncAnnotations}
        annotationsSaved={saveAnnotations}
        autocompleteObj={autocompleteObj}
        entities={entities}
        error={false}
        evidence={evidence}
        expressionAnnotations={exprAnnotations}
        loading={loading}
        phenotypeAnnotations={phenoAnnotations}
        showAnnotationIds
      />
    </Suspense>
  )

  return (
    <PageWrapper>
      <PageHeader>Curation Tool</PageHeader>
      <StyledSplit left={top} right={bottom} />
    </PageWrapper>
  )
}

CurationTool.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** Entities that appear in the manuscript, */
  entities: PropTypes.shape({
    PROTEIN: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, nodId: PropTypes.string }),
    ),
    ANATOMY: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, nodId: PropTypes.string }),
    ),
    EXPRTYPE: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, nodId: PropTypes.string }),
    ),
    LIFESTAGE: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, modId: PropTypes.string }),
    ),
    GENE: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, nodId: PropTypes.string }),
    ),
    GENEONTOLOGY: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, nodId: PropTypes.string }),
    ),
    VARIANT: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, nodId: PropTypes.string }),
    ),
    PHENOTERMS: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, nodId: PropTypes.string }),
    ),
    ASSAYS: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string, nodId: PropTypes.string }),
    ),
    ANATOMY_FUNCTION_ASSAYS: PropTypes.arrayOf(
      PropTypes.shape({ value: PropTypes.string }),
    ),
  }).isRequired,
  evidence: PropTypes.string,
  /** Expression annotations */
  exprAnnotations: PropTypes.arrayOf(
    PropTypes.shape({
      annotationId: PropTypes.number,
      gene: PropTypes.shape({
        value: PropTypes.string,
        modId: PropTypes.string,
      }),
      whenExpreessed: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, modId: PropTypes.string }),
      ),
      assay: PropTypes.shape({ value: PropTypes.string }),
      evidence: PropTypes.string,
      whereExpreessed: PropTypes.arrayOf(
        PropTypes.shape({ value: PropTypes.string, modId: PropTypes.string }),
      ),
      dateAssigned: PropTypes.number,
    }),
  ),
  /** Anatomy functional annotations */
  anatomyFuncAnnotations: PropTypes.arrayOf(
    PropTypes.shape({
      annotationId: PropTypes.number,
      phenotype: PropTypes.shape({
        value: PropTypes.string,
        modId: PropTypes.string,
      }),
      gene: PropTypes.shape({
        value: PropTypes.string,
        modId: PropTypes.string,
      }),
      anatomyTerms: PropTypes.shape({
        value: PropTypes.string,
        modId: PropTypes.string,
        options: PropTypes.shape({
          Insufficient: PropTypes.bool,
          Unnecessary: PropTypes.bool,
        }),
      }),
      involved: PropTypes.string,
      assay: PropTypes.shape({ value: PropTypes.string }),
      evidence: PropTypes.string,
      remark: PropTypes.string,
      notuamodel: PropTypes.string,
      genotype: PropTypes.string,
      dateAssigned: PropTypes.number,
    }),
  ),
  /** Phenotype annotations */
  phenoAnnotations: PropTypes.arrayOf(
    PropTypes.shape({
      annotationId: PropTypes.uuid,
      object: PropTypes.shape({
        value: PropTypes.string,
        modId: PropTypes.string,
      }),
      phenotypeTerms: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          modId: PropTypes.string,
        }),
      ),
      anatomyTerms: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          modId: PropTypes.string,
        }),
      ),
      lifeStages: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          modId: PropTypes.string,
        }),
      ),
      evidence: PropTypes.string,
      dateAssigned: PropTypes.nummber,
    }),
  ),
  saveAnnotations: PropTypes.func,
  /** Manuscript version */
  version: PropTypes.shape({
    acknowledgements: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        correspondingAuthor: PropTypes.bool,
        email: PropTypes.string,
      }),
    ),
    comments: PropTypes.string,
    dataType: PropTypes.string,
    funding: PropTypes.string,
    image: PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    }),
    imageTitle: PropTypes.string,
    imageCaption: PropTypes.string,
    laboratory: PropTypes.shape({
      name: PropTypes.string,
    }),
    methods: PropTypes.string,
    patternDescription: PropTypes.string,
    reagents: PropTypes.string,
    references: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
      }),
    ),
    suggestedReviewer: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
  }).isRequired,
}

CurationTool.defaultProps = {
  loading: false,
  exprAnnotations: [],
  evidence: '',
  anatomyFuncAnnotations: [],
  phenoAnnotations: [],
  saveAnnotations: null,
}

export default CurationTool
