import React from 'react'

import WarningModal from './WarningModal'

const ReviewerRejectModal = props => (
  <WarningModal
    headerText="confirm reject invitation"
    justifyText
    textSuccess="reject"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    Thank you for letting us know you are not interested in reviewing this
    article, your access to it will be rescinded. We will not contact you about
    this article again.
  </WarningModal>
)

export default ReviewerRejectModal
