/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import WarningModal from './WarningModal'
import { stripHTML } from '../_helpers'

const ConfirmManuscriptDelete = props => {
  const { title, ...rest } = props

  const formattedTitle = title ? stripHTML(title) : 'Untitled'

  return (
    <WarningModal headerText="confirm delete" textSuccess="delete" {...rest}>
      {`You are about to delete the article "${formattedTitle}". Are you sure?`}
    </WarningModal>
  )
}

ConfirmManuscriptDelete.propTypes = {
  title: PropTypes.string,
}

ConfirmManuscriptDelete.defaultProps = {
  title: 'Untitled',
}

export default ConfirmManuscriptDelete
