import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid } from '../_helpers'
import { Accordion as AccordionBase, Chat } from '../common'

import ArticleProgress from './ArticleProgress'
import CorrectionSection from './CorrectionSection'
import CuratorSection from './CuratorSection'
import DataType from './DataType'
import DecisionSection from './DecisionSection'
import EditorPanelRibbon from './EditorPanelRibbon'
import HistorySection from './HistorySection'
import InfoSection from './InfoSection'
import MetadataSection from './MetadataSection'
import ReviewsSection from './ReviewsSection'
import ExtendedDataSection from './ExtendedDataSection'
import PaymentSection from './PaymentSection'
import ProteopediaSection from './ProteopediaSection'
import IntegrationsSection from './IntegrationsSection'
import PreviousSubmissions from './PreviousSubmissions'
import SubmitReviewSection from './SubmitReviewSection'

const Wrapper = styled.div`
  padding: ${grid(1)} 0;
`

const AccordionContent = styled.div`
  margin: ${grid(1)} 0 ${grid(1)} ${grid(4)};
`

const Accordion = props => {
  /* eslint-disable-next-line react/prop-types */
  const { children, label, startExpanded } = props

  return (
    <AccordionBase label={label} startExpanded={startExpanded}>
      <AccordionContent>{children}</AccordionContent>
    </AccordionBase>
  )
}

const EditorPanel = props => {
  const {
    acceptedReviewersCount,
    articleTitle,
    articleSearch,
    invitedReviewersCount,
    rejectedReviewersCount,
    revokedReviewersCount,
    articleUrl,
    authorArticles,
    authorEmail,
    authorName,
    editorCanEdit,
    categories,
    categoryOptions,
    citation,
    citations,
    createDoi,
    createSOReview,
    confidentialComments,
    corrections,
    curatorId,
    curatorName,
    curatorReview,
    curatorWarning,
    dataType,
    dataRelease,
    dbReferenceId,
    decision,
    decisionLetter,
    decisionSubmitted,
    doi,
    editCorrection,
    editorName,
    extendedData,
    getSavedDecision,
    getSavedSOChat,
    history,
    integrations,
    invoiceNo,
    invoiceUrl,
    isResubmission,
    ithenticate,
    ithenticateUpload,
    linkerLink,
    loadingExtendedDataEntities,
    milestones,
    onClickAcceptConfirm,
    onClickEdit,
    onClickCuratorChat,
    onClickReviewerChat,
    onClickReviewerReinvite,
    onClickSetDataType,
    onClickTeamManager,
    // onClickShowLinker,
    onClickIntegrationArticle,
    onClickShowExtendedDataEntities,
    paymentStatus,
    paymentUrl,
    pmcId,
    pmId,
    proofLink,
    proteopedia,
    publishedUrl,
    rateReview,
    removeIntegration,
    reorderIntegrations,
    reviewersFromPreviousVersions,
    reviewersPageUrl,
    reviewPanel,
    reviews,
    reviewExists,
    reviseQualifier,
    saveDecision,
    saveSOChat,
    scienceOfficerChatMessages,
    scienceOfficerName,

    sectionEditorName,
    sendScienceOfficerChatMessage,
    setProteopediaReady,
    setReportUrl,
    setScore,
    showAuthorChat,
    showAuthorArticles,
    showCorrections,
    showCuratorChat,
    showCurators,
    showDataType,
    showDataTypeSelection,
    showDecision,
    showExtendedData,
    showHistory,
    showInfo,
    showIntegrations,
    showManageReviewers,
    showMetadata,
    showPaymentStatus,
    showPreviousReviewers,
    showProteopedia,
    showReviewersChat,
    showReviews,
    showScienceOfficerChat,
    showSOLabels,
    showTeamManager,
    showSubmitReview,
    skippedProof,
    soReview,
    saveSOReview,
    submitSOReview,
    soReviewSubmitted,
    species,
    speciesOptions,
    startAuthorChatExpanded,
    startAuthorArticlesExpanded,
    startCorrectionsExpanded,
    startChatExpanded,
    startCuratorsExpanded,
    startDataTypeExpanded,
    startExtendedDataExpanded,
    startDecisionExpanded,
    startIntegrationsExpanded,
    startHistoryExpanded,
    startMetadataExpanded,
    startPaymentExpanded,
    startProteopediaExpanded,
    startReviewsExpanded,
    submissionTypeOptions,
    submissionTypes,
    submitCorrection,
    submitDecision,
    updateHistory,
    updateMetadata,
    updatePaymentStatus,
    uploadData,
    saveAuthorChat,
    authorChatMessages,
    getSavedAuthorChat,
    sendAuthorChatMessage,
    topics,
  } = props

  const curatorResponse =
    curatorReview && curatorReview.map(c => c.submitted && c.content).join('\n')

  return (
    <Wrapper>
      <EditorPanelRibbon dataType={dataType} decision={decision} />

      {showInfo && (
        <InfoSection
          authorEmail={authorEmail}
          authorName={authorName}
          canEdit={editorCanEdit}
          curatorName={curatorName}
          editorName={editorName}
          ithenticate={ithenticate}
          ithenticateUpload={ithenticateUpload}
          linkerLink={linkerLink}
          onClickEdit={onClickEdit}
          // onClickShowLinker={onClickShowLinker}
          onClickTeamManager={onClickTeamManager}
          scienceOfficerName={scienceOfficerName}
          sectionEditorName={sectionEditorName}
          setReportUrl={setReportUrl}
          setScore={setScore}
          showTeamManager={showTeamManager}
        />
      )}

      <ArticleProgress milestones={milestones} />

      {showAuthorArticles && (
        <Accordion
          label="Other Submissions (disabled)"
          startExpanded={startAuthorArticlesExpanded}
        >
          <PreviousSubmissions authorArticles={authorArticles} />
        </Accordion>
      )}

      {showDataType && (
        <Accordion
          label="Initiate Editorial Review"
          startExpanded={startDataTypeExpanded}
        >
          <DataType
            dataType={dataType}
            onClickSetDataType={onClickSetDataType}
            showDataTypeSelection={showDataTypeSelection}
          />
        </Accordion>
      )}

      {showScienceOfficerChat && (
        <Accordion
          label={
            showSOLabels
              ? 'Chat / Suggest Reviewer (SO)'
              : 'Chat / Suggest Reviewer'
          }
          startExpanded={startChatExpanded}
        >
          <Chat
            getSavedChat={getSavedSOChat}
            messages={scienceOfficerChatMessages}
            saveChat={saveSOChat}
            sendMessage={sendScienceOfficerChatMessage}
          />
        </Accordion>
      )}

      {showCurators && (
        <Accordion label="Curator" startExpanded={startCuratorsExpanded}>
          <CuratorSection
            curatorId={curatorId}
            curatorName={curatorName}
            onClickChat={onClickCuratorChat}
            reviews={curatorReview}
            showChat={showCuratorChat}
          />
        </Accordion>
      )}

      {showReviews && (
        <Accordion
          label={showSOLabels ? 'Reviews (SO)' : 'Reviews'}
          startExpanded={startReviewsExpanded}
        >
          <ReviewsSection
            acceptedReviewersCount={acceptedReviewersCount}
            invitedReviewersCount={invitedReviewersCount}
            onClickChat={onClickReviewerChat}
            onClickInvite={onClickReviewerReinvite}
            rateReview={rateReview}
            rejectedReviewersCount={rejectedReviewersCount}
            reviewersFromPreviousVersions={reviewersFromPreviousVersions}
            reviewersPageUrl={reviewersPageUrl}
            reviews={reviews}
            revokedReviewersCount={revokedReviewersCount}
            showChat={showReviewersChat}
            showManageReviewers={showManageReviewers}
            showPreviousReviewers={showPreviousReviewers}
          />
        </Accordion>
      )}

      {showDecision && (
        <Accordion
          label={showSOLabels ? 'Decision (SO)' : 'Decision'}
          startExpanded={startDecisionExpanded}
        >
          <DecisionSection
            articleTitle={articleTitle}
            articleUrl={articleUrl}
            authorName={authorName}
            citation={citation}
            confidentialComments={confidentialComments}
            curatorResponse={curatorResponse}
            curatorWarning={curatorWarning}
            dbReferenceId={dbReferenceId}
            decision={decision}
            decisionLetter={decisionLetter}
            getSavedDecision={getSavedDecision}
            invoiceNo={invoiceNo}
            invoiceUrl={invoiceUrl}
            isResubmission={isResubmission}
            onClickAcceptConfirm={onClickAcceptConfirm}
            onClickTeamManager={onClickTeamManager}
            paymentStatus={paymentStatus}
            paymentUrl={paymentUrl}
            proofLink={proofLink}
            proteopedia={proteopedia}
            publishedUrl={publishedUrl}
            reviewExists={reviewExists}
            reviseQualifier={reviseQualifier}
            saveDecision={saveDecision}
            skippedProof={skippedProof}
            submitDecision={submitDecision}
            submitted={decisionSubmitted}
          />
        </Accordion>
      )}

      {showMetadata && (
        <Accordion label="Metadata" startExpanded={startMetadataExpanded}>
          <MetadataSection
            categories={categories}
            categoryOptions={categoryOptions}
            citations={citations}
            createDoi={createDoi}
            dataRelease={dataRelease}
            dbReferenceId={dbReferenceId}
            doi={doi}
            pmcId={pmcId}
            pmId={pmId}
            proteopedia={proteopedia}
            reviewPanel={reviewPanel}
            species={species}
            speciesOptions={speciesOptions}
            submissionTypeOptions={submissionTypeOptions}
            submissionTypes={submissionTypes}
            topics={topics}
            updateMetadata={updateMetadata}
          />
        </Accordion>
      )}

      {showProteopedia && (
        <Accordion label="Proteopedia" startExpanded={startProteopediaExpanded}>
          <ProteopediaSection
            doi={doi}
            proteopedia={proteopedia}
            setProteopediaReady={setProteopediaReady}
          />
        </Accordion>
      )}

      {showHistory && (
        <Accordion label="History" startExpanded={startHistoryExpanded}>
          <HistorySection history={history} updateHistory={updateHistory} />
        </Accordion>
      )}

      {showExtendedData && (
        <Accordion
          label="Extended Data"
          startExpanded={startExtendedDataExpanded}
        >
          <ExtendedDataSection
            loading={loadingExtendedDataEntities}
            openEntityTable={onClickShowExtendedDataEntities}
            uploadData={uploadData}
            values={extendedData}
          />
        </Accordion>
      )}

      {showCorrections && (
        <Accordion label="Corrections" startExpanded={startCorrectionsExpanded}>
          <CorrectionSection
            corrections={corrections}
            createDoi={createDoi}
            editCorrection={editCorrection}
            submitCorrection={submitCorrection}
          />
        </Accordion>
      )}
      {showIntegrations && (
        <Accordion
          label="Integrations"
          startExpanded={startIntegrationsExpanded}
        >
          <IntegrationsSection
            articleSearch={articleSearch}
            integrations={integrations}
            onClickIntegrationArticle={onClickIntegrationArticle}
            removeIntegration={removeIntegration}
            reorderIntegrations={reorderIntegrations}
          />
        </Accordion>
      )}

      {showAuthorChat && (
        <Accordion
          label="Chat with Author"
          startExpanded={startAuthorChatExpanded}
        >
          <Chat
            getSavedChat={getSavedAuthorChat}
            messages={authorChatMessages}
            saveChat={saveAuthorChat}
            sendMessage={sendAuthorChatMessage}
            submitText="Send to Author"
          />
        </Accordion>
      )}

      {showPaymentStatus && (
        <Accordion label="Payment" startExpanded={startPaymentExpanded}>
          <PaymentSection
            paymentStatus={paymentStatus}
            updatePaymentStatus={updatePaymentStatus}
          />
        </Accordion>
      )}

      {showSubmitReview && (
        <Accordion label="Submit Review">
          <SubmitReviewSection
            createSOReview={createSOReview}
            review={soReview}
            save={saveSOReview}
            submit={submitSOReview}
            submitted={soReviewSubmitted}
          />
        </Accordion>
      )}
    </Wrapper>
  )
}

const validDecisions = [
  'accept',
  'reject',
  'revise',
  'publish',
  'edit',
  'decline',
]

EditorPanel.propTypes = {
  /** Datatype selected for this submission */
  dataType: PropTypes.string,

  /** How many reviewers have been invited so far */
  invitedReviewersCount: PropTypes.number.isRequired,
  /** How many reviewers have accepted their invitation so far */
  acceptedReviewersCount: PropTypes.number.isRequired,
  /** How many reviewers have rejected their invitation so far */
  rejectedReviewersCount: PropTypes.number.isRequired,
  /** How many reviewers had their invitation revoked so far */
  revokedReviewersCount: PropTypes.number.isRequired,

  /** Title of article */
  articleTitle: PropTypes.string,
  /** Url for the article */
  articleUrl: PropTypes.string,
  /** Confidential comments for decision */
  confidentialComments: PropTypes.string,
  /** Decision that has been taken on this version */
  decision: PropTypes.oneOf(validDecisions),
  /** The decision letter that was sent to the author */
  decisionLetter: PropTypes.string,
  /** Whether the decision has been sent (as opposed to simply saved) */
  decisionSubmitted: PropTypes.bool,
  /** Link for the proof */
  proofLink: PropTypes.string,
  /** Whether a review has been submitted yet */
  reviewExists: PropTypes.bool,
  /** Qualifier for the revise review decision */
  reviseQualifier: PropTypes.string,
  /** URL for the final published article */
  publishedUrl: PropTypes.string,
  /** Citation of article */
  citation: PropTypes.string,
  /** URL to link to the payment page */
  paymentUrl: PropTypes.string,
  /** URL for the invoice */
  invoiceUrl: PropTypes.string,
  /** Methods for search aricles by title */
  articleSearch: PropTypes.func,
  /** Is this a resubmission? */
  isResubmission: PropTypes.bool,

  /** Reference ID from organism database */
  dbReferenceId: PropTypes.string,
  /** Data Release for partner database */
  dataRelease: PropTypes.string,
  /** DOI given to the manuscript */
  doi: PropTypes.string,
  /** Function to generate new DOI */
  createDoi: PropTypes.func,
  /** Categories for the manuscript */
  categories: PropTypes.arrayOf(PropTypes.string),
  /** PubMed ID for the manuscript */
  pmId: PropTypes.string,
  /** PMC ID for the manuscript */
  pmcId: PropTypes.string,
  /** Source for Proteopedia iFrame */
  proteopedia: PropTypes.string,
  /** Name of review panel */
  reviewPanel: PropTypes.string,
  /** Species for the manuscript */
  species: PropTypes.arrayOf(PropTypes.string),
  /** Submission Type */
  submissionTypes: PropTypes.arrayOf(PropTypes.string),
  /** Citations from Crossref Event Data */
  citations: PropTypes.arrayOf(PropTypes.string),
  /** Extended data */
  extendedData: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      resourceType: PropTypes.string,
      doi: PropTypes.string,
    }),
  ),
  /** Payment status */
  paymentStatus: PropTypes.string,
  integrations: PropTypes.arrayOf(PropTypes.string),
  topics: PropTypes.arrayOf(PropTypes.string),

  /** Options for metadata */
  speciesOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),
  submissionTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
  ),

  /** Dates of events for the manuscript */
  history: PropTypes.shape({
    received: PropTypes.string,
    sentForReview: PropTypes.string,
    reviewReceived: PropTypes.string,
    revisionReceived: PropTypes.string,
    accepted: PropTypes.string,
    published: PropTypes.string,
    indexed: PropTypes.string,
  }),

  /** Data from iThenticate */
  ithenticate: PropTypes.shape({
    id: PropTypes.string,
    docId: PropTypes.string,
    score: PropTypes.string,
  }),

  /** Submitting author's email */
  authorEmail: PropTypes.string,
  /** Submitting author's display name */
  authorName: PropTypes.string,
  /** Assigned curator's display name */
  curatorName: PropTypes.arrayOf(PropTypes.string),
  /** Assigned editor's display name */
  editorName: PropTypes.string,
  /** Articles previously submitted by this author */
  authorArticles: PropTypes.arrayOf(PropTypes.object),
  /** Warn when to curator is assigned */
  curatorWarning: PropTypes.bool,
  /** Warn if proof was skipped */
  skippedProof: PropTypes.bool,

  /** Assigned section editor's display name */
  sectionEditorName: PropTypes.string,
  /** Assigned science officer's display name */
  scienceOfficerName: PropTypes.string,

  /** Open confirmation modal when decision is accept */
  onClickAcceptConfirm: PropTypes.func,
  /** Function to run on clicking 'edit' */
  onClickEdit: PropTypes.func,
  /** Function to run on clicking 'chat with curator' */
  onClickCuratorChat: PropTypes.func,
  /** Function to run on clicking 'chat with reviewer' */
  onClickReviewerChat: PropTypes.func,
  /** Function to run on clicking 'reinvite reviewer' */
  onClickReviewerReinvite: PropTypes.func,
  /** Function to run on clicking 'set datatype' */
  onClickSetDataType: PropTypes.func,
  /** Function to run on clicking 'team manager' */
  onClickTeamManager: PropTypes.func,
  onClickIntegrationArticle: PropTypes.func,
  /** Function to open the linking modal */
  // onClickShowLinker: PropTypes.func,
  /** Function to run on clicking 'send to author' in the decision section */
  submitDecision: PropTypes.func,
  /** Function to run on clicking 'update metadata' */
  updateMetadata: PropTypes.func,
  /** Function to run on clicking 'update history' */
  updateHistory: PropTypes.func,
  /** Function to upload the PDF to ithenticate */
  ithenticateUpload: PropTypes.func,
  /** Function to run when uploading extended data */
  uploadData: PropTypes.func,
  /** Function to save correction */
  submitCorrection: PropTypes.func,
  /** Function to update the payment status */
  updatePaymentStatus: PropTypes.func,
  /** Function to trigger actions for Proteopedia */
  setProteopediaReady: PropTypes.func,
  /** Function to edit an existing correction */
  editCorrection: PropTypes.func,
  removeIntegration: PropTypes.func,
  reorderIntegrations: PropTypes.func,
  milestones: PropTypes.arrayOf(
    PropTypes.shape({
      received: PropTypes.string,
      inititialRevision: PropTypes.string,
      reviewerInvited: PropTypes.string,
      reviewReceived: PropTypes.string,
      reviewRevision: PropTypes.string,
      proof: PropTypes.string,
      publishe: PropTypes.string,
    }),
  ),
  onClickShowExtendedDataEntities: PropTypes.func,
  loadingExtendedDataEntities: PropTypes.bool,

  /** List of reviewers that existed on previous versions */
  reviewersFromPreviousVersions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      recommendation: PropTypes.oneOf(validDecisions),
    }),
  ),

  /** URL of 'manage reviewers' page */
  reviewersPageUrl: PropTypes.string,

  /** Pending or submitted reviews for this version */
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      askedToSeeRevision: PropTypes.bool,
      content: PropTypes.string,
      openAcknowledgement: PropTypes.bool,
      pending: PropTypes.bool,
      recommendation: PropTypes.oneOf(validDecisions),
      reviewerId: PropTypes.string,
      reviewerName: PropTypes.string,
    }),
  ),

  /** List of corrections submitted */
  corrections: PropTypes.arrayOf(
    PropTypes.shape({
      correctionType: PropTypes.string,
      description: PropTypes.string,
      doi: PropTypes.string,
      date: PropTypes.string,
      dbReferenceId: PropTypes.string,
    }),
  ),
  /** Curator's user id */
  curatorId: PropTypes.string,

  /** Curator's review */
  curatorReview: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      openAcknowledgement: PropTypes.bool,
      submitted: PropTypes.string,
      recommendation: PropTypes.oneOf(validDecisions),
    }),
  ),

  /** Fetch saved (but not submitted) decision */
  getSavedDecision: PropTypes.func,
  /** Save decision without submitting */
  saveDecision: PropTypes.func,
  /** Save science officer chat without sending */
  saveSOChat: PropTypes.func,
  /** Fetch saved but not sent science officer chat */
  getSavedSOChat: PropTypes.func,

  /** Science officer chat messages */
  scienceOfficerChatMessages: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ),

  /** Function to run on clicking 'send' in SO chat */
  sendScienceOfficerChatMessage: PropTypes.func,
  /** Function to query ithenticate for URL */
  setReportUrl: PropTypes.func,
  /** Function to query ithenticate for score */
  setScore: PropTypes.func,
  /** Link to linker page */
  linkerLink: PropTypes.string,
  saveAuthorChat: PropTypes.func,
  authorChatMessages: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ),
  getSavedAuthorChat: PropTypes.func,
  sendAuthorChatMessage: PropTypes.func,

  soReview: PropTypes.shape({
    content: PropTypes.string,
    openAcknowledgement: PropTypes.bool,
    submitted: PropTypes.string,
    recommendation: PropTypes.oneOf(validDecisions),
  }),
  saveSOReview: PropTypes.func,
  submitSOReview: PropTypes.func,
  soReviewSubmitted: PropTypes.bool,
  createSOReview: PropTypes.func,
  rateReview: PropTypes.func,

  /** Show the author chat section */
  showAuthorChat: PropTypes.bool,
  /** Control whether the info section at the top is shown or not */
  showInfo: PropTypes.bool,
  /** Control whether to show the datatype section */
  showDataType: PropTypes.bool,
  /** Control whether the science officer chat is shown or not */
  showScienceOfficerChat: PropTypes.bool,
  /** Control whether the curator section is show or not */
  showCurators: PropTypes.bool,
  /** Control whether the reviews section is shown or not */
  showReviews: PropTypes.bool,
  /** Control whether the decision section is shown or not */
  showDecision: PropTypes.bool,
  /** Control whether the metadata section is shown or not */
  showMetadata: PropTypes.bool,
  /** Control whether the history section is shown or not */
  showHistory: PropTypes.bool,
  /** Control whether the extended data section is shown or now */
  showExtendedData: PropTypes.bool,
  /** Control whether the curator section is shown or not */
  showCorrections: PropTypes.bool,
  /** Control whther the payment status section is shown or not */
  showPaymentStatus: PropTypes.bool,
  /** Control whther the proteopedia section is shown or not */
  showProteopedia: PropTypes.bool,
  /** Control whether to show the datatype dropdown when there is no value */
  showDataTypeSelection: PropTypes.bool,
  showIntegrations: PropTypes.bool,
  showSubmitReview: PropTypes.bool,
  showSOLabels: PropTypes.bool,

  /** Controal where the previous submission is shown */
  showAuthorArticles: PropTypes.bool,
  /** Control whether the curator chat is shown or not */
  showCuratorChat: PropTypes.bool,
  /** Control whether to show the previous reviewers list in the reviewers section */
  showPreviousReviewers: PropTypes.bool,
  /** Control whether the 'chat with reviewer' buttons in the reviews section are shown or not */
  showReviewersChat: PropTypes.bool,
  /** Control whether the 'Manage reviewers' link is displayed in the reviews section */
  showManageReviewers: PropTypes.bool,
  /** Control whether the 'manage team' button in the info section is shown or not */
  showTeamManager: PropTypes.bool,
  /** Control whether the editor can edit the manuscript */
  editorCanEdit: PropTypes.bool,

  /** Control where the author chat should start expanded */
  startAuthorChatExpanded: PropTypes.bool,
  /** Control whether the previous submission should start expanded */
  startAuthorArticlesExpanded: PropTypes.bool,
  /** Control whether the science officer chat should start expanded */
  startChatExpanded: PropTypes.bool,
  /** Control whether the curator section should start expanded */
  startCuratorsExpanded: PropTypes.bool,
  /** Control whether the datatype section should start expanded */
  startDataTypeExpanded: PropTypes.bool,
  /** Control whether the reviews section should start expanded */
  startReviewsExpanded: PropTypes.bool,
  /** Control whether the decision section should start expanded */
  startDecisionExpanded: PropTypes.bool,
  /** Control whether the metadata section should start expanded */
  startMetadataExpanded: PropTypes.bool,
  /** Control whether the history section should start expanded */
  startHistoryExpanded: PropTypes.bool,
  /** Control whether the extended data section should start expanded */
  startExtendedDataExpanded: PropTypes.bool,
  /** Control whether the history section should start expanded */
  startCorrectionsExpanded: PropTypes.bool,
  /** Control whether the payment status section should start expanded */
  startPaymentExpanded: PropTypes.bool,
  /** Control whether the proteopedia section should start expanded */
  startProteopediaExpanded: PropTypes.bool,
  startIntegrationsExpanded: PropTypes.bool,

  invoiceNo: PropTypes.string,
}

EditorPanel.defaultProps = {
  articleSearch: null,
  articleTitle: null,
  articleUrl: null,
  authorChatMessages: [],
  authorArticles: [],
  authorEmail: null,
  authorName: null,
  categories: null,
  categoryOptions: null,
  corrections: null,
  citation: null,
  citations: null,
  confidentialComments: null,
  createDoi: null,
  createSOReview: null,
  curatorId: null,
  curatorName: null,
  curatorReview: null,
  curatorWarning: false,
  dataType: null,
  dataRelease: null,
  dbReferenceId: null,
  decision: null,
  decisionLetter: null,
  decisionSubmitted: false,
  doi: null,
  editCorrection: null,
  editorCanEdit: false,
  editorName: null,
  extendedData: [],
  getSavedAuthorChat: null,
  getSavedDecision: null,
  getSavedSOChat: null,
  history: null,
  integrations: [],
  invoiceNo: null,
  invoiceUrl: null,
  isResubmission: false,
  ithenticate: null,
  ithenticateUpload: null,
  loadingExtendedDataEntities: false,
  linkerLink: null,
  milestones: [],
  onClickAcceptConfirm: null,
  onClickEdit: null,
  onClickCuratorChat: null,
  onClickReviewerChat: null,
  onClickReviewerReinvite: null,
  onClickSetDataType: null,
  onClickTeamManager: null,
  onClickIntegrationArticle: null,
  onClickShowExtendedDataEntities: null,
  // onClickShowLinker: null,
  paymentStatus: null,
  paymentUrl: null,
  pmId: null,
  pmcId: null,
  proofLink: null,
  proteopedia: null,
  publishedUrl: null,
  rateReview: null,
  removeIntegration: null,
  reorderIntegrations: null,
  reviewersFromPreviousVersions: [],
  reviewersPageUrl: null,
  reviewPanel: null,
  reviews: [],
  reviewExists: false,
  reviseQualifier: null,
  saveAuthorChat: null,
  saveDecision: null,
  saveSOChat: null,
  scienceOfficerName: null,
  scienceOfficerChatMessages: [],
  sectionEditorName: null,
  setProteopediaReady: null,
  setReportUrl: null,
  setScore: null,
  sendAuthorChatMessage: null,
  sendScienceOfficerChatMessage: null,
  showAuthorChat: false,
  showAuthorArticles: false,
  showCorrections: false,
  showCuratorChat: false,
  showCurators: false,
  showDataType: false,
  showDataTypeSelection: false,
  showDecision: false,
  showExtendedData: false,
  showInfo: false,
  showHistory: false,
  showManageReviewers: false,
  showMetadata: false,
  showPreviousReviewers: false,
  showProteopedia: false,
  showReviewersChat: false,
  showReviews: false,
  showScienceOfficerChat: false,
  showTeamManager: false,
  showPaymentStatus: false,
  showIntegrations: false,
  showSubmitReview: false,
  showSOLabels: false,
  skippedProof: false,
  soReview: null,
  saveSOReview: null,
  submitSOReview: null,
  soReviewSubmitted: false,
  species: null,
  speciesOptions: null,
  startAuthorChatExpanded: false,
  startAuthorArticlesExpanded: false,
  startChatExpanded: false,
  startCorrectionsExpanded: false,
  startCuratorsExpanded: false,
  startDataTypeExpanded: false,
  startDecisionExpanded: false,
  startExtendedDataExpanded: false,
  startHistoryExpanded: false,
  startMetadataExpanded: false,
  startReviewsExpanded: false,
  startPaymentExpanded: false,
  startProteopediaExpanded: false,
  startIntegrationsExpanded: false,
  submissionTypes: null,
  submissionTypeOptions: null,
  submitDecision: null,
  submitCorrection: null,
  topics: null,
  updateHistory: null,
  updateMetadata: null,
  uploadData: null,
  updatePaymentStatus: null,
}

export default EditorPanel
