/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'

const CurrentUserContext = React.createContext({
  currentUser: null,
  setCurrentUser: () => {},
})

const { Provider, Consumer } = CurrentUserContext

const withCurrentUser = Component => {
  const C = props => (
    <Consumer>
      {({ currentUser }) => <Component currentUser={currentUser} {...props} />}
    </Consumer>
  )

  return C
}

export {
  Consumer as CurrentUserConsumer,
  Provider as CurrentUserProvider,
  withCurrentUser,
}

export default CurrentUserContext
