import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { diff, smallFontReading } from './_cssFragments'

const Wrapper = styled.div`
  ${smallFontReading}
`

const StyledAffiliation = styled.span`
  ${diff}
`

const Affiliation = props => {
  const {
    isAdded,
    isRemoved,
    index,
    showDiff,
    value,
    hasSingleAuthor,
    hasSingleItem,
  } = props

  return (
    <Wrapper>
      <StyledAffiliation
        isAdded={isAdded}
        isRemoved={isRemoved}
        showDiff={showDiff}
      >
        {!hasSingleAuthor && !hasSingleItem && <sup>{index}</sup>}
        {value}
      </StyledAffiliation>
    </Wrapper>
  )
}

Affiliation.propTypes = {
  /** The affiliation's position in the list. Number referenced next to author. */
  index: PropTypes.number.isRequired,
  /** Whether it was an addition. For diffs. */
  isAdded: PropTypes.bool,
  /** Whether it was a removal. For diffs. */
  isRemoved: PropTypes.bool,
  /** Whether to show diffs */
  showDiff: PropTypes.bool,
  /** The affiliation */
  value: PropTypes.string.isRequired,
  /** Whether there are multiple authors */
  hasSingleAuthor: PropTypes.bool,
  /** Whether there are multiple affiliations */
  hasSingleItem: PropTypes.bool,
}

Affiliation.defaultProps = {
  isAdded: false,
  isRemoved: false,
  showDiff: true,
  hasSingleAuthor: false,
  hasSingleItem: false,
}

export default Affiliation
