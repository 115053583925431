/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import Modal from './Modal'
import ModalHeader from './ModalHeader'
import ModalFooterDialog from './ModalFooterDialog'
import Reviews from '../userManager/Reviews'

const ReviewsModal = props => {
  const { reviewsData } = props
  const Header = <ModalHeader text="Reviews" />

  const Footer = (
    <ModalFooterDialog showCancelButton={false} textSuccess="Close" />
  )

  const hasReviews =
    reviewsData && reviewsData.manuscripts && reviewsData.manuscripts.length > 0

  return (
    <Modal
      footerComponent={Footer}
      headerComponent={Header}
      size="medium"
      {...props}
    >
      <Reviews hasReviews={hasReviews} reviewsData={reviewsData} />
    </Modal>
  )
}

export default ReviewsModal
