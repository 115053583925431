/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { Status } from '../common'

const StyledStatus = styled(Status)`
  margin-left: 5px;
`

const Categories = props => {
  const { categories } = props

  if (!categories) return null

  return (
    <span>
      {categories.map(category => (
        <StyledStatus key={category} reverseColors status="primary">
          {category}
        </StyledStatus>
      ))}
    </span>
  )
}

export default Categories
