/* eslint-disable react/prop-types */

import React from 'react'
import { Query } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

const GET_GLOBAL_TEAMS = gql`
  query GetGlobalTeams {
    getGlobalTeams {
      id
      role
      members {
        id
        user {
          id
          displayName
        }
      }
    }
  }
`

const getGlobalTeamsQuery = ({ render }) => (
  <Query query={GET_GLOBAL_TEAMS}>{render}</Query>
)

export { GET_GLOBAL_TEAMS }
export default getGlobalTeamsQuery
