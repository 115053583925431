/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import PropTypes from 'prop-types'

import ReviewerRow from './ReviewerRow'
import DndList from '../common/DndList'

const ReviewerList = props => {
  const { canAddMore, onReorder, reviewers } = props

  return (
    <DndList
      canAddMore={canAddMore}
      component={ReviewerRow}
      items={reviewers}
      onReorder={onReorder}
    />
  )
}

ReviewerList.propTypes = {
  /** Whether more reviewers can be invited */
  canAddMore: PropTypes.bool.isRequired,
  /** Function to run on reorder. Receives the new list as an argument. */
  onReorder: PropTypes.func.isRequired,
  /** List of data for reviewer. List is controlled via props, not state. Object shape defined in ReviewerRow component */
  reviewers: PropTypes.arrayOf(PropTypes.object),
}

ReviewerList.defaultProps = {
  reviewers: [],
}

export default ReviewerList
