/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import { Toggle } from 'react-powerplug'

import { Action, ActionGroup } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import SectionItem from './SectionItem'
import ReviewerCoiModal from '../../../ui/src/modals/ReviewerCoiModal'
import ReviewerRejectModal from '../../../ui/src/modals/ReviewerRejectModal'

const RejectNotification = styled.div`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 0 ${th('gridUnit')};
  text-transform: uppercase;
`

const ActionsWrapper = styled.div`
  flex-shrink: 0;
`

const StyledSectionItem = styled(SectionItem)`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')}
    ${th('colorFurniture')};
  /* margin-bottom: ${th('gridUnit')}; */
  padding: 4px 0;
`

const ReviewerSectionItem = props => {
  const {
    handleInvitation,
    id: articleId,
    openReviewerPreviewModal,
    reviewerStatus: status,
    reviewSubmitted,
    title,
  } = props

  let actions

  const acceptInvitation = () => handleInvitation('accept', articleId)
  const rejectInvitation = () => handleInvitation('reject', articleId)

  const InvitationActions = (
    <ActionsWrapper>
      <ActionGroup>
        <Action onClick={() => openReviewerPreviewModal(articleId)}>
          Preview
        </Action>
        <Toggle intial={false}>
          {({ on, toggle }) => (
            <>
              <Action onClick={toggle} primary>
                Accept Invitation
              </Action>

              <ReviewerCoiModal
                isOpen={on}
                onConfirm={acceptInvitation}
                onRequestClose={toggle}
              />
            </>
          )}
        </Toggle>
        <Toggle intial={false}>
          {({ on, toggle }) => (
            <>
              <Action onClick={toggle}>Reject Invitation</Action>

              <ReviewerRejectModal
                isOpen={on}
                onConfirm={rejectInvitation}
                onRequestClose={toggle}
              />
            </>
          )}
        </Toggle>
      </ActionGroup>
    </ActionsWrapper>
  )

  const ReviewLink = (
    <ActionGroup>
      <Action to={`/article/${articleId}`}>
        {status === 'acceptedInvitation' && !reviewSubmitted && 'Review'}
        {reviewSubmitted && 'Submitted review'}
      </Action>
    </ActionGroup>
  )

  if (status === 'invited') actions = InvitationActions
  if (status === 'acceptedInvitation' || reviewSubmitted) actions = ReviewLink
  if (status === 'rejectedInvitation')
    actions = <RejectNotification>Invitation Rejected</RejectNotification>

  return <StyledSectionItem rightComponent={actions} title={title} />
}

export default ReviewerSectionItem
