import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import { diff } from './_cssFragments'
import { transformObserveExpressionData, unCamelCase } from './_helpers'
import DiffArray from './DiffArray'

const ObserveExpressionGroup = styled.div`
  border-bottom: ${th('borderWidth')} dashed ${th('colorBorder')};
  margin-bottom: ${th('gridUnit')};
`

const StyledItem = styled.div`
  ${diff}
`

/* eslint-disable react/prop-types */
const Group = props => {
  const { isAdded, isRemoved, value, showDiff } = props

  return (
    <ObserveExpressionGroup>
      {value.map(element => (
        <StyledItem
          isAdded={isAdded}
          isRemoved={isRemoved}
          key={element[0]}
          showDiff={showDiff}
        >
          {unCamelCase(element[0])}: &nbsp; <em>{element[1]}</em>
        </StyledItem>
      ))}
    </ObserveExpressionGroup>
  )
}
/* eslint-enable react/prop-types */

const ObserveExpression = props => {
  const { data, previousData, showDiff, showRemoved } = props
  if (!data) return null

  return (
    <>
      <strong>Observe Expression</strong>

      <DiffArray
        component={Group}
        currentArray={transformObserveExpressionData(data)}
        previousArray={transformObserveExpressionData(previousData)}
        showDiff={showDiff}
        showRemoved={showRemoved}
      />
    </>
  )
}

ObserveExpression.propTypes = {
  /** Observe expression form data */
  data: PropTypes.shape({
    certainly: PropTypes.arrayOf(
      PropTypes.shape({
        certainly: PropTypes.shape({
          name: PropTypes.string,
        }),
        during: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcellularLocalization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    possibly: PropTypes.arrayOf(
      PropTypes.shape({
        possibly: PropTypes.shape({
          name: PropTypes.string,
        }),
        during: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcellularLocalization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    probably: PropTypes.arrayOf(
      PropTypes.shape({
        probably: PropTypes.shape({
          name: PropTypes.string,
        }),
        during: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcellularLocalization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    not: PropTypes.arrayOf(
      PropTypes.shape({
        not: PropTypes.shape({
          name: PropTypes.string,
        }),
        during: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcellularLocalization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
  }),
  /** Same as data, but previous version. For diffs. */
  previousData: PropTypes.shape({
    certainly: PropTypes.arrayOf(
      PropTypes.shape({
        certainly: PropTypes.shape({
          name: PropTypes.string,
        }),
        during: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcellularLocalization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    possibly: PropTypes.arrayOf(
      PropTypes.shape({
        possibly: PropTypes.shape({
          name: PropTypes.string,
        }),
        during: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcellularLocalization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    probably: PropTypes.arrayOf(
      PropTypes.shape({
        probably: PropTypes.shape({
          name: PropTypes.string,
        }),
        during: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcellularLocalization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    not: PropTypes.arrayOf(
      PropTypes.shape({
        not: PropTypes.shape({
          name: PropTypes.string,
        }),
        during: PropTypes.shape({
          name: PropTypes.string,
        }),
        subcellularLocalization: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
  }),
  /** Whether to show diffs at all */
  showDiff: PropTypes.bool,
  /** Whether to show diff removals */
  showRemoved: PropTypes.bool,
}

ObserveExpression.defaultProps = {
  data: null,
  previousData: null,
  showDiff: true,
  showRemoved: true,
}

export default ObserveExpression
