/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'

import InfoModal from './InfoModal'

const ReviewerGuidelines = props => (
  <InfoModal
    headerText="reviewer guidelines"
    justifyText
    size="medium"
    {...props}
  >
    <div>
      <p>
        Written comments should explain acceptance, rejection, or detail
        modifications that should be made. If modifications are requested,
        enumerate each point the author(s) should address.
      </p>
      <p>
        It is not generally expected that the authors would need to perform
        additional experiments. However, on occasion important data/information
        that is necessary for solidifying a conclusion is missing from a
        submitted manuscript. In such a case, the reviewer should request the
        relevant information. Often, the authors have the data or will perform
        the relevant experiment. If the data/information is not available, the
        outcome depends on the nature of the missing data. If the data is viewed
        as essential for the authors to make the conclusion (e.g., control data
        missing) then the manuscript should be rejected. If the data is sound
        and a conclusion can be drawn, but the generality or extensibility of
        the result is not shown, then a qualification is required; for example,
        if a novel phenotype is observed in a single allele, but not examined in
        the reference allele, a qualification should be added to indicate that
        current data does not distinguish whether the result is specific for the
        allele tested or a general property of the gene. Here, microPublication
        Biology believes that getting findings rapidly out to the community
        assists in advancing the scientific mission, but that it is important to
        be transparent about limitations that make a conclusion preliminary.
      </p>
      <p>
        In addition to comments addressed to the author, there are “Confidential
        comments to the editor”.
      </p>
      <p>
        Comments could include concerns about the data quality, issues related
        to plagiarism, inappropriate figure manipulation, etc. These comments
        will not be shown to the author(s).
      </p>
    </div>
  </InfoModal>
)

export default ReviewerGuidelines
