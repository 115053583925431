/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid, th } from '../_helpers'
import { Button, TextEditor, Form } from '../common'

const Wrapper = styled.div`
  margin: ${grid(3)} ${grid(3)};
`

const Label = styled.span`
  color: ${th('colorPrimary')};
  text-transform: uppercase;
`

const ReviewerMessage = props => {
  const {
    className,
    reviewerMessage,
    showAddMessage,
    setShowAddMessage,
    saveReviewerMessage,
  } = props

  const handleSubmit = (formValues, formikBag) => {
    const { message } = formValues

    saveReviewerMessage(message).then(() => {
      formikBag.resetForm()
      setShowAddMessage(false)
    })
  }

  const deleteMessage = () =>
    saveReviewerMessage(null).then(() => setShowAddMessage(false))

  return (
    <Wrapper className={className}>
      {reviewerMessage && !showAddMessage && (
        <>
          <Label>Editor message to reviewer:</Label>

          <TextEditor readOnly value={reviewerMessage} />
          <Button onClick={() => setShowAddMessage(true)} primary>
            Edit
          </Button>
          <Button onClick={deleteMessage}>Delete</Button>
        </>
      )}
      {showAddMessage && (
        <>
          <Label>Editor message to reviewer:</Label>
          <Form
            initialValues={{ message: reviewerMessage }}
            onSubmit={handleSubmit}
          >
            {formProps => {
              const { values, ...rest } = formProps
              return (
                <>
                  <TextEditor name="message" value={values.message} {...rest} />
                  <Button primary type="submit">
                    Save
                  </Button>
                  <Button onClick={() => setShowAddMessage(false)}>
                    Cancel
                  </Button>
                </>
              )
            }}
          </Form>
        </>
      )}
    </Wrapper>
  )
}

ReviewerMessage.propTypes = {
  /** Editor message to reviewer */
  reviewerMessage: PropTypes.string,
  /** Show the add message text box */
  showAddMessage: PropTypes.bool,
  setShowAddMessage: PropTypes.func,
  saveReviewerMessage: PropTypes.func,
}

ReviewerMessage.defaultProps = {
  reviewerMessage: '',
  showAddMessage: false,
  setShowAddMessage: null,
  saveReviewerMessage: null,
}

export default ReviewerMessage
