import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { serverUrl } from '@coko/client'

import { Action, ActionGroup, Loader, PageHeader } from '../common'

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const DataReleases = props => {
  const { loading, dataReleases } = props

  if (loading) return <Loader />

  const newDataReleases = dataReleases && [...dataReleases.dataReleases]

  return (
    <PageWrapper>
      <PageHeader>Data Release Download</PageHeader>
      <ActionGroup>
        <Action key="all" to={`${serverUrl}/api/export/summary.csv`}>
          Summary (CSV)
        </Action>
        {newDataReleases &&
          newDataReleases.sort().map(d => (
            <Action key={d} to={`${serverUrl}/api/export/${d}/figures`}>
              {d} (ZIP)
            </Action>
          ))}
        <Action
          key="missing-metadata"
          to={`${serverUrl}/api/export/missing-metadata.csv`}
        >
          Missing Metadata
        </Action>
      </ActionGroup>
    </PageWrapper>
  )
}

DataReleases.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The data to display */
  dataReleases: PropTypes.arrayOf(PropTypes.string).isRequired,
}

DataReleases.defaultProps = {
  loading: false,
}

export default DataReleases
