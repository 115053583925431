/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { Action, List, TextEditor } from '../common'
import { th } from '../_helpers'

const StyledTextEditor = styled(TextEditor)`
  background: white;
  border: 0;
  margin: 0;
  padding: 0;

  del {
    color: ${th('colorError')};
    text-decoration: none;
  }
`

const StyledListItem = styled.div`
  border-bottom: 2px solid;
`

const ListItem = item => {
  const { id, name } = item
  return (
    <StyledListItem>
      <StyledTextEditor italic readOnly value={name} />
      <Action to={`/article/${id}`}>Go to Article</Action>
    </StyledListItem>
  )
}

const PreviousSubmissions = props => {
  const { authorArticles } = props

  if (!authorArticles || authorArticles.length === 0)
    return <>This author has not previously submitted any articles</>

  const items = authorArticles.map(a => {
    let name = a.title
    if (a.decision === 'reject') name = `<del>REJECTED:</del> ${name}`
    else if (a.decision === 'decline') name = `<del>DECLINED:</del> ${name}`

    return {
      id: a.manuscriptId,
      name: `${name} ${a.reviewers.join(', ')}. Number of reviewers invited: ${
        a.numInvites
      }`,
    }
  })

  return <List component={ListItem} items={items} />
}

export default PreviousSubmissions
