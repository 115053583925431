/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

const CsvPreview = props => {
  const { csv, header } = props

  if (!csv) return null

  return (
    <ReactTable
      columns={header.map(h => ({
        Header: h.Header,
        accessor: h.accessor,
        Cell: ({ row }) => (
          <div style={{ 'white-space': 'normal' }}>{row[h.accessor]}</div>
        ),
      }))}
      data={csv}
      minRows="0"
      showPagination={csv.length > 20}
    />
  )
}

export default CsvPreview
