/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import styled from 'styled-components'

import { uuid } from '@coko/client'
import { th } from '@pubsweet/ui-toolkit'
import { Button } from '../common'

import AuthorList from './AuthorList'
import AuthorInputModal from '../modals/AuthorInputModal'
import AuthorCsvUpload from './AuthorCsvUpload'

const Label = styled.label`
  display: block;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
`

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: calc(${th('gridUnit')} * 2);
`

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-left: ${th('gridUnit')};
  width: 600px;
`

const Info = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  margin-bottom: ${th('gridUnit')};
  width: 600px;
`

const AuthorSection = props => {
  const {
    dataTestId,
    coAuthors,
    errors,
    // getWBPerson,
    handleChange,
    name,
    readOnly,
    required,
    setFieldTouched,
    setFieldValue,
    touched,
    values,
  } = props

  const [currentAuthor, setCurrentAuthor] = useState(0)
  const [isInputOpen, setInputOpen] = useState(false)

  const handleAdd = () => {
    let data = get(values, name)

    if (!data) data = []

    const newItem = {
      email: '',
      firstName: '',
      id: uuid(),
      lastName: '',
      credit: [''],
      affiliations: [''],
      departments: [''],
      submittingAuthor: false,
      correspondingAuthor: false,
      equalContribution: false,
      academicStatus: '',
    }

    data.push(newItem)
    setCurrentAuthor(data.length - 1)
    setFieldValue(name, data)
    handleChange(name)
    set(values, name, data)
    setInputOpen(true)
    // setFieldTouched(name)
  }

  const handleRemove = async id => {
    const data = get(values, name)
    const changed = data.filter(val => val.id !== id)

    setFieldValue(name, changed)
    handleChange(name)
  }

  let data = get(values, name)

  if (!data) {
    data = []

    setFieldValue(name, data)
    handleChange(name)
    set(values, name, data)
  }

  const handleReorder = newList => {
    setFieldValue(name, newList)
    handleChange(name)
  }

  // const error = get(errors, name)
  // const authorTouched = get(touched, name)

  const onClickEditRow = id => {
    setCurrentAuthor(values.authors.findIndex(a => a.id === id))
    setInputOpen(true)
    // setFieldTouched(name)
  }

  const hasPI =
    values.authors.length === 0 ||
    values.authors.find(a => a.academicStatus === 'pi')

  return (
    <GroupWrapper data-test-id={dataTestId}>
      <Label>Authors</Label>

      {!hasPI && (
        <Error>
          Your submission does not have a Principal Investigator associated with
          the study. Please either include your PI as an author or add their
          email address and affiliation in the &#34;Comments to the editor&#34;
          section.
        </Error>
      )}

      <Info>
        Enter author names in the order they will appear in the article. If you
        have a middle initial, enter it after your first name.
      </Info>

      {!readOnly && (
        <GroupWrapper>
          <span>
            <Button
              data-test-id={`${dataTestId}-add-button`}
              onClick={handleAdd}
              primary
            >
              Add author
            </Button>
          </span>

          <AuthorInputModal
            coAuthors={
              coAuthors &&
              coAuthors.map(a => ({
                label: `${a.firstName} ${a.lastName}`,
                value: a,
              }))
            }
            currentAuthor={currentAuthor}
            errors={errors}
            handleAdd={handleAdd}
            handleChange={handleChange}
            isOpen={isInputOpen}
            name={name}
            onConfirm={() => setInputOpen(false)}
            onRequestClose={() => setInputOpen(false)}
            readOnly={readOnly}
            required={required}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            touched={touched}
            // {...passProps}
            values={values}
          />
        </GroupWrapper>
      )}
      <AuthorList
        authors={get(values, 'authors')}
        errors={get(errors, 'authors')}
        onClickEditRow={onClickEditRow}
        onClickRemoveRow={handleRemove}
        onReorder={handleReorder}
        setCurrentAuthor={setCurrentAuthor}
      />
      <AuthorCsvUpload
        authors={values.authors}
        errors={errors}
        name="authors"
        setFieldTouched={setFieldTouched}
        setFieldValue={setFieldValue}
        touched={touched}
      />
    </GroupWrapper>
  )
}

export default AuthorSection
