import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ActionGroup, Action } from '../common'
import ValueList from './ValueList'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`

const StyledList = styled(ValueList)`
  width: 60%;
`

const StyledGroup = styled(ActionGroup)`
  width: 40%;
`

const InfoSection = props => {
  const {
    authorEmail,
    authorName,
    canEdit,
    curatorName,
    editorName,
    ithenticate,
    ithenticateUpload,
    linkerLink,
    sectionEditorName,
    scienceOfficerName,
    onClickEdit,
    onClickTeamManager,
    // onClickShowLinker,
    setReportUrl,
    setScore,
    showTeamManager,
  } = props

  const [uploading, setUploading] = useState(false)
  const ithentId = ithenticate && ithenticate.id
  const [ithenticateId, setIthenticateId] = useState(ithentId)
  const [uploadError, setError] = useState(null)

  const teamValues = [
    {
      label: 'Main Editor',
      status: 'primary',
      value: editorName,
    },
    {
      label: 'Section Editor',
      status: 'primary',
      value: sectionEditorName,
    },
    {
      label: 'Science Officer',
      status: 'primary',
      value: scienceOfficerName,
    },
    {
      label: 'Curator',
      status: 'primary',
      value: curatorName ? curatorName.join(', ') : null,
    },
    {
      label: 'Submitting Author',
      status: 'primary',
      value: (
        <>
          {authorName}
          <br />( {authorEmail} )
        </>
      ),
    },
  ]

  const onClickUpload = () => {
    setUploading(true)
    ithenticateUpload()
      .then(async res => {
        const uploadId = await res.text()
        setIthenticateId(uploadId)
        setUploading(false)
      })
      .catch(async error => {
        setUploading(false)
        setError(await error.json())
      })
  }

  return (
    <Wrapper>
      <StyledList data={teamValues} missingValueText="not assigned" />

      <StyledGroup>
        {showTeamManager && (
          <Action onClick={onClickTeamManager}>Manage Team</Action>
        )}
        {canEdit && <Action to={linkerLink}>Linker</Action>}
        {canEdit && <Action onClick={onClickEdit}>Edit</Action>}
        {uploading && <Action>Uploading...</Action>}
        {uploadError && <Action>Error</Action>}
        {!uploading && ithenticate && !ithenticateId && (
          <Action onClick={onClickUpload}>Upload to iThenticate</Action>
        )}
        {ithenticate && ithenticateId && !ithenticate.score && (
          <Action onClick={setScore}>iThenticate Report</Action>
        )}
        {ithenticate && ithenticate.score && (
          <Action onClick={setReportUrl}>
            iThenticate Report ({ithenticate.score})
          </Action>
        )}
        {!uploading && ithenticate && ithenticateId && (
          <Action onClick={onClickUpload}>Re-Upload to iThenticate</Action>
        )}
      </StyledGroup>
      {uploadError && `${uploadError}`}
    </Wrapper>
  )
}

InfoSection.propTypes = {
  authorEmail: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  canEdit: PropTypes.bool,
  curatorName: PropTypes.arrayOf(PropTypes.string),
  editorName: PropTypes.string,
  ithenticate: PropTypes.shape({
    id: PropTypes.string,
    docId: PropTypes.string,
    score: PropTypes.string,
  }),
  ithenticateUpload: PropTypes.func,
  linkerLink: PropTypes.string,
  sectionEditorName: PropTypes.string,
  scienceOfficerName: PropTypes.string,
  setReportUrl: PropTypes.func,
  setScore: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickTeamManager: PropTypes.func,
  // onClickShowLinker: PropTypes.func,
  showTeamManager: PropTypes.bool,
}

InfoSection.defaultProps = {
  canEdit: false,
  curatorName: null,
  editorName: null,
  sectionEditorName: null,
  scienceOfficerName: null,
  setReportUrl: null,
  setScore: null,
  ithenticate: null,
  ithenticateUpload: null,
  linkerLink: null,
  onClickEdit: null,
  onClickTeamManager: null,
  // onClickShowLinker: null,
  showTeamManager: false,
}

export default InfoSection
