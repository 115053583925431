/* eslint-disable */
/* stylelint-disable */

import React from 'react'
import styled from 'styled-components'
import { th, override } from '@pubsweet/ui-toolkit'
import { Menu } from '@pubsweet/ui'

const Opener = styled.button.attrs(() => ({
  type: 'button',
}))`
  background: transparent;
  border: 0;
  border-radius: ${th('borderRadius')};
  color: #777;
  cursor: pointer;
  font-family: inherit;

  height: calc(${th('gridUnit')} * 6);

  display: flex;
  align-items: center;

  &:hover {
    border-bottom: 2px solid ${th('colorPrimary')};
    color: ${th('colorPrimary')};
    border-color: ${th('colorPrimary')};
  }
  &:after {
    content: '>';
    margin-left: 10px;
    transform: rotate(90deg);
  }

  &:focus {
    outline: none;
  }

  ${override('ui.Menu.Opener')};
`

const MenuStyled = styled(Menu)`
  display: ${props => (props.select ? 'inline-flex' : 'none')};
  button {
    height: 20px;
    width: 100px;
  }
`

const renderOpener = ({ placeholder, toggleMenu, open }) => (
  <Opener onClick={toggleMenu} open={open}>
    {placeholder}
  </Opener>
)

const dropDownOptions = [
  { value: 'α' },
  { value: 'β' },
  { value: 'γ' },
  { value: 'δ' },
  { value: 'ε' },
  { value: 'ζ' },
  { value: 'η' },
  { value: 'θ' },
  { value: 'ι' },
  { value: 'κ' },
  { value: 'λ' },
  { value: 'μ' },
  { value: 'ν' },
  { value: 'ξ' },
  { value: 'ο' },
  { value: 'π' },
  { value: 'ρ' },
  { value: 'σ' },
  { value: 'τ' },
  { value: 'υ' },
  { value: 'φ' },
  { value: 'χ' },
  { value: 'ψ' },
  { value: 'ω' },
  { value: 'Α' },
  { value: 'Β' },
  { value: 'Γ' },
  { value: 'Δ' },
  { value: 'Ε' },
  { value: 'Ζ' },
  { value: 'Η' },
  { value: 'Θ' },
  { value: 'Ι' },
  { value: 'Κ' },
  { value: 'Λ' },
  { value: 'Μ' },
  { value: 'Ν' },
  { value: 'Ξ' },
  { value: 'Ο' },
  { value: 'Π' },
  { value: 'Ρ' },
  { value: 'Σ' },
  { value: 'Τ' },
  { value: 'Υ' },
  { value: 'Φ' },
  { value: 'Χ' },
  { value: 'Ψ' },
  { value: 'Ω' },
]

const GreekDropdown = ({ item, state, dispatch, ...rest }) => (
  <MenuStyled
    onChange={value => {
      const { parent } = state.selection.$from
      const isInline = parent.inlineContent

      if (!isInline) return false

      const { from } = state.selection
      const { tr } = state

      tr.insertText(value, from)
      dispatch(tr)
      return true
    }}
    options={dropDownOptions}
    placeholder="Insert Greek"
    renderOpener={renderOpener}
    select={item.select && item.select(state)}
    {...rest}
  />
)

export default GreekDropdown
