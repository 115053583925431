/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import { Status, ActionGroup, Action, Icon } from '../common'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
`

const RowLine = styled(Row)`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')};
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-left: 5px;
`

const UserSectionItem = props => {
  const {
    displayName,
    species,
    isActive,
    notReviewer,
    email,
    editUser,
    id,
    isEditor,
    openReviewData,
    openArticleData,
    openRemindersData,
    numArticles,
    numReviews,
    numReminders,
    hasBadReview,
    hasGoodReview,
    currentlyReviewing,
  } = props

  return (
    <RowLine>
      <Col>
        <Row>
          <Col>
            <Status>{displayName}</Status>
          </Col>
          <Col>
            <Status>{email}</Status>
          </Col>
        </Row>
        <Row>
          <Status reverseColors>
            {species && species.length > 0
              ? species.join(', ')
              : 'missing species'}
          </Status>
          {hasGoodReview && <Icon>smile</Icon>}
          {hasBadReview && <Icon>frown</Icon>}
          {currentlyReviewing && (
            <Status reverseColors status="warn">
              Currently Reviewing
            </Status>
          )}{' '}
          {notReviewer && (
            <Status reverseColors status="error">
              Do Not Ask
            </Status>
          )}
          {!isActive && (
            <Status reverseColors status="error">
              Disabled
            </Status>
          )}
        </Row>
      </Col>
      <Col>
        <ActionGroup>
          <Action onClick={() => editUser(id)}>Profile</Action>
          {numReviews > 0 && (
            <Action onClick={() => openReviewData(id)}>
              Reviews ({numReviews})
            </Action>
          )}
          {numReviews === 0 && 'No Reviews'}
          {isEditor && numArticles > 0 && (
            <Action onClick={() => openArticleData(id)}>
              Articles ({numArticles})
            </Action>
          )}
          {isEditor && numArticles === 0 && 'No Articles'}
          {isEditor && numReminders > 0 && (
            <Action onClick={() => openRemindersData(id)}>
              Reminders ({numReminders})
            </Action>
          )}
          {isEditor && numReminders === 0 && 'No Reminders'}
        </ActionGroup>
      </Col>
    </RowLine>
  )
}

export default UserSectionItem
