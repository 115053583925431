import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { grid } from '../_helpers'
import ReinviteReviewer from './ReinviteReviewer'

const Header = styled.div`
  font-weight: bold;
  margin-bottom: ${grid(1)};
`

const ReinviteReviewerList = props => {
  const { onClickInvite, reviewers, showChat, onClickChat } = props

  return (
    <div>
      <Header>Reviewers from older versions</Header>

      {(!reviewers || reviewers.length === 0) && (
        <span>There are no reviewers from previous versions.</span>
      )}

      {reviewers.map(reviewer => (
        <ReinviteReviewer
          displayName={reviewer.displayName}
          key={reviewer.id}
          onClickChat={() => onClickChat(reviewer.id)}
          onClickInvite={() => onClickInvite(reviewer.id)}
          recommendation={reviewer.recommendation}
          showChat={showChat}
          showReinviteButton={!reviewer.invited}
        />
      ))}
    </div>
  )
}

ReinviteReviewerList.propTypes = {
  onClickInvite: PropTypes.func.isRequired,
  reviewers: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string,
      id: PropTypes.string,
      recommendation: PropTypes.oneOf(['accept', 'reject', 'revise']),
    }),
  ),
  showChat: PropTypes.bool,
  onClickChat: PropTypes.func,
}

ReinviteReviewerList.defaultProps = {
  reviewers: [],
  showChat: true,
  onClickChat: null,
}

export default ReinviteReviewerList
