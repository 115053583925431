const stripHTML = html => {
  // if (!html) return null
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

const isHTMLEmpty = html => stripHTML(html).length === 0

const grid = value => props => `calc(${props.theme.gridUnit} * ${value})`

export { grid, isHTMLEmpty, stripHTML }
export * from '@pubsweet/ui-toolkit'
