/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import { components } from 'react-select'

import { grid, th } from '../_helpers'
import { Form, Ribbon, Select, Status } from '../common'

import Modal from './Modal'
import ModalHeader from './ModalHeader'
import ModalFooterDialog from './ModalFooterDialog'

const StyledModal = styled(Modal)`
  .Modal {
    /* height: 750px; */
    height: 80vh;
    left: 50%;
    margin-left: -400px;
    /* margin-top: -400px; */
    margin-top: -40vh;
    top: 50%;
    width: 800px;
  }
`

const SelectWrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;

  > div:not(:last-child) {
    margin-bottom: ${grid(3)};
  }
`

const Label = styled.div`
  align-self: flex-end;
  color: ${th('colorPrimary')};
  padding: 10px 0 6px 0;
  text-transform: uppercase;
`

const RoleWrapper = styled.div``

const StyledStatus = styled(Status)`
  font-size: 12px;
  margin: 0 4px;
`

const warningMessage = `
  Clicking on "Update Team" will affect who has access to this article.
`

const Header = <ModalHeader text="Article Team Manager" />

const Footer = props => {
  const { onConfirm, onRequestClose } = props
  return (
    <ModalFooterDialog
      onConfirm={onConfirm}
      onRequestClose={onRequestClose}
      textSuccess="Update Team"
    />
  )
}

const userToValue = user => {
  if (Array.isArray(user)) {
    return user.map(u => ({
      label: u.displayName,
      isDisabled: u.isDisabled,
      value: u.id,
      species: u.species,
      numGlobalTeamArticles: u.numTeamArticles,
    }))
  }

  return {
    label: user.displayName,
    isDisabled: user.isDisabled,
    value: user.id,
    species: user.species,
    numGlobalTeamArticles: user.numTeamArticles,
  }
}

const Option = props => {
  const { data, children, ...rest } = props
  const { species, numGlobalTeamArticles } = data

  return (
    <components.Option {...rest}>
      {children}
      <StyledStatus reverseColors status="success">
        # Articles: {numGlobalTeamArticles}
      </StyledStatus>
      {species && <StyledStatus reverseColors>{species}</StyledStatus>}
    </components.Option>
  )
}

const Role = props => {
  const { isMulti, label, name, options, setFieldValue, value } = props

  const selectOptions = options ? options.map(userToValue) : []

  const handleChange = selected => {
    if (!selected || selected.length === 0) {
      setFieldValue(name, null)
    } else if (Array.isArray(selected)) {
      const selectedOptions = selected.map(user =>
        options.find(item => item.id === user.value),
      )

      setFieldValue(name, selectedOptions)
    } else {
      const option = options.find(item => item.id === selected.value)

      setFieldValue(name, option)
    }
  }

  return (
    <RoleWrapper>
      <Label>{label}:</Label>
      <Select
        // menuIsOpen
        components={{ Option }}
        isClearable
        isMulti={isMulti}
        onChange={handleChange}
        options={selectOptions}
        value={value && userToValue(value)}
      />
    </RoleWrapper>
  )
}

const ManuscriptTeamManager = props => {
  const {
    assignedCurator,
    assignedEditor,
    assignedSectionEditor,
    assignedScienceOfficer,
    curators,
    editors,
    sectionEditors,
    scienceOfficers,
    showEditorAssignment,
    updateTeams,
    ...rest
  } = props

  const initialValues = {
    curator: assignedCurator || null,
    editor: assignedEditor || null,
    sectionEditor: assignedSectionEditor || null,
    scienceOfficer: assignedScienceOfficer || null,
  }

  const handleSubmit = values => {
    updateTeams(values).then(() => {
      props.onRequestClose()
    })
  }

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {formProps => {
        const { setFieldValue, submitForm, values } = formProps

        const ModalFooter = (
          <Footer
            onConfirm={submitForm}
            onRequestClose={props.onRequestClose}
          />
        )

        return (
          <StyledModal
            footerComponent={ModalFooter}
            headerComponent={Header}
            {...rest}
          >
            <Ribbon message={warningMessage} status="warning" />

            <SelectWrapper>
              {showEditorAssignment && (
                <Role
                  label="main editor"
                  name="editor"
                  options={editors}
                  setFieldValue={setFieldValue}
                  value={values.editor}
                />
              )}

              <Role
                label="section editor"
                name="sectionEditor"
                options={sectionEditors}
                setFieldValue={setFieldValue}
                value={values.sectionEditor}
              />

              <Role
                label="science officer"
                name="scienceOfficer"
                options={scienceOfficers}
                setFieldValue={setFieldValue}
                value={values.scienceOfficer}
              />

              <Role
                isMulti
                label="curator"
                name="curator"
                options={curators}
                setFieldValue={setFieldValue}
                value={values.curator}
              />
            </SelectWrapper>
          </StyledModal>
        )
      }}
    </Form>
  )
}

// ManuscriptTeamManager.propTypes = {}

// ManuscriptTeamManager.defaultProps = {}

export default ManuscriptTeamManager
