import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import clone from 'lodash/clone'

import { NEWS, ADD_NEWS, EDIT_NEWS, DELETE_NEWS, UPLOAD_FILE } from '../graphql'
import { NewsManager } from '../../ui'

const NewsPage = () => {
  const { data, loading } = useQuery(NEWS)

  const queryRefetch = {
    refetchQueries: [
      {
        query: NEWS,
      },
    ],
  }

  const [deleteNews] = useMutation(DELETE_NEWS, queryRefetch)
  const [addNews] = useMutation(ADD_NEWS, queryRefetch)
  const [editNews] = useMutation(EDIT_NEWS, queryRefetch)
  const [uploadFile] = useMutation(UPLOAD_FILE)
  const upload = file => uploadFile({ variables: { file } })

  const deleteNewsFn = id => deleteNews({ variables: { id } })

  const addNewsFn = news => {
    const newNews = clone(news)
    delete newNews.image.preview
    return addNews({ variables: { newNews } })
  }

  const editNewsFn = (id, news) => {
    const newNews = clone(news)
    delete newNews.id
    delete newNews.image.preview

    if (
      (newNews.image.url && newNews.image.url.match(/(https?:\/\/[^\s]+)/g)) ||
      !newNews.image.url
    ) {
      delete newNews.image
    }

    return editNews({ variables: { id, newNews } })
  }

  const news =
    data &&
    data.news.map(newsItem => {
      const newsWithFunctions = clone(newsItem)
      newsWithFunctions.editNews = newNews => editNewsFn(newsItem.id, newNews)
      newsWithFunctions.deleteNews = () => deleteNewsFn(newsItem.id)
      newsWithFunctions.upload = file => upload(file)
      return newsWithFunctions
    })

  return (
    <NewsManager
      addNews={addNewsFn}
      loading={loading}
      news={news}
      upload={upload}
    />
  )
}

export default NewsPage
