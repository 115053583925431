/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { Action, ActionGroup, Icon, Status } from '../common'
import { grid, th } from '../_helpers'

const draggingStyles = css`
  box-shadow: 0 0 0 2px ${th('colorPrimary')};
`

const Wrapper = styled.div`
  background: ${th('colorBackground')};
  box-shadow: 0 0 0 1px ${th('colorPrimary')};
  display: flex;
  flex-direction: column;
  padding: ${grid(1)};
  transition: box-shadow 0.1s ease-in;

  &:hover {
    ${draggingStyles}
  }

  /* stylelint-disable-next-line order/order, order/properties-alphabetical-order */
  ${props => props.isDragging && draggingStyles}
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftSide = styled.div``

const RightSide = styled.div`
  display: flex;
`

const StatusWrapper = styled.div`
  display: inline-block;
  margin-left: ${grid(2)};
`

const StyledStatus = styled(Status)`
  font-size: 12px;
  margin: 0 4px;
`

const IconButton = styled(Icon)`
  &:hover {
    background: ${th('colorSecondary')};
    cursor: pointer;
  }
`

const EmailWrapper = styled.div`
  display: inline-block;
  font-size: ${th('fontSizeBaseSmall')};
  margin-left: ${grid(2)};
  /* padding-top: ${grid(0.5)}; */
`

const EmailLabel = styled.span`
  color: ${th('colorPrimary')};
  margin-right: ${grid(1)};
  text-transform: uppercase;
`

const ReviewerRow = props => {
  const {
    canAddMore,
    className,
    isDragging,
    innerRef,

    item,
    ...rest
  } = props

  const {
    acceptedInvitation,
    declinedInvitation,
    displayName,
    email,
    invitationRevoked,
    invited,
    isSignedUp,
    isActive,
    rejectedInvitation,
    reasonForDecline,
    reviewSubmitted,
    suggestedReviewer,
    onClickInvite,
    onClickRemove,
    onClickRevokeInvitation,
    showEmail,
  } = item

  const reviewPending = invited && acceptedInvitation && !reviewSubmitted
  const submitted = invited && acceptedInvitation && reviewSubmitted
  const rejected = invited && rejectedInvitation
  const revoked = invited && invitationRevoked
  const declined = invited && declinedInvitation

  const responsePending =
    invited &&
    !invitationRevoked &&
    !acceptedInvitation &&
    !rejectedInvitation &&
    !declinedInvitation

  const notInvited = !invited && !invitationRevoked

  const makeStatusText = () => {
    if (reviewPending) return 'accepted invitation - pending review'
    if (submitted) return 'review submitted'
    if (rejected) return 'rejected invitation'
    if (revoked) return 'invitation revoked'
    if (responsePending) return 'invited'
    if (notInvited) return 'not invited'
    if (declined) return 'declined invitation'
    return null
  }

  const makeStatus = () => {
    if (reviewPending) return 'success'
    if (submitted) return 'primary'
    if (rejected) return 'error'
    if (revoked) return 'error'
    if (declined) return 'error'
    if (responsePending) return 'success'
    if (notInvited) return null
    return null
  }

  return (
    <Wrapper
      className={className}
      isDragging={isDragging}
      ref={innerRef}
      {...rest}
    >
      <TopRow>
        <LeftSide>
          {displayName}
          <StatusWrapper>
            <StyledStatus reverseColors status={makeStatus()}>
              {makeStatusText()} {reasonForDecline && `- ${reasonForDecline}`}
            </StyledStatus>

            {!isSignedUp && (
              <StyledStatus reverseColors>Not signed up</StyledStatus>
            )}
            {!isActive && <StyledStatus reverseColors>Disabled</StyledStatus>}
          </StatusWrapper>

          {showEmail && (
            <EmailWrapper>
              <EmailLabel>Email:</EmailLabel>
              {email}
            </EmailWrapper>
          )}
        </LeftSide>

        <RightSide>
          {suggestedReviewer && (
            <EmailWrapper>
              <EmailLabel>Suggested Reviewer:</EmailLabel>
              {suggestedReviewer}
            </EmailWrapper>
          )}
          {notInvited && (
            <>
              <ActionGroup>
                <Action disabled={!canAddMore} onClick={onClickInvite}>
                  Invite
                </Action>
              </ActionGroup>
              <IconButton onClick={onClickRemove} size={2}>
                x
              </IconButton>
            </>
          )}

          {(revoked || declined) && (
            <ActionGroup>
              <Action disabled={!canAddMore} onClick={onClickInvite}>
                Reinvite
              </Action>
            </ActionGroup>
          )}

          {responsePending && (
            <ActionGroup>
              <Action onClick={onClickRevokeInvitation}>
                Revoke invitation
              </Action>
            </ActionGroup>
          )}
        </RightSide>
      </TopRow>
    </Wrapper>
  )
}

ReviewerRow.propTypes = {
  /** Whether this reviewer can be invited */
  canAddMore: PropTypes.bool.isRequired,
  /** Row's innerRef. For use by dnd */
  innerRef: PropTypes.func.isRequired,
  /** Whether this row is being dragged */
  isDragging: PropTypes.bool.isRequired,
  /** The item to be displayed in the row */
  item: PropTypes.shape({
    displayName: PropTypes.string,
    acceptedInvitation: PropTypes.bool.isRequired,
    declinedInvitation: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    invitationRevoked: PropTypes.bool.isRequired,
    invited: PropTypes.bool.isRequired,
    isSignedUp: PropTypes.bool.isRequired,
    isActive: PropTypes.bool,
    reasonForDecline: PropTypes.bool,
    rejectedInvitation: PropTypes.bool.isRequired,
    reviewSubmitted: PropTypes.bool.isRequired,
    suggestedReviewer: PropTypes.string,
    onClickInvite: PropTypes.func.isRequired,
    onClickRemove: PropTypes.func.isRequired,
    onClickRevokeInvitation: PropTypes.func.isRequired,
    showEmail: PropTypes.bool,
  }).isRequired,
}

export default ReviewerRow
