/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Loader } from '../common'
import { Section } from '../../../app/components/ui'
import ManuscriptItem from './ManuscriptItem'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`

const PublishedManuscripts = props => {
  const { loading, manuscripts, onClickOpenEntityTable } = props

  if (loading) return <Loader />

  return (
    <Wrapper>
      <Section
        component={ManuscriptItem}
        items={manuscripts}
        label="Published Manuscripts"
        onClickOpenEntityTable={onClickOpenEntityTable}
      />
    </Wrapper>
  )
}

PublishedManuscripts.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The data to display */
  manuscripts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      versions: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
    }),
  ).isRequired,
  onClickOpenEntityTable: PropTypes.func,
}

PublishedManuscripts.defaultProps = {
  loading: false,
  onClickOpenEntityTable: null,
}

export default PublishedManuscripts
