import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/export`

const pdfUrl =
  process.env.CLIENT_PDF_URL ||
  'https://www.micropublication.org/convert?auth=arachnys-weaver&url='

const exportManuscriptToHTML = articleId => {
  window.location.assign(`${apiUrl}/${articleId}/html`)
}

const exportManuscriptToPrint = versionId => {
  window.open(`${apiUrl}/${versionId}/print`)
}

const exportManuscriptToPDF = versionId => {
  window.open(`${pdfUrl}${apiUrl}/${versionId}/print`)
}

const exportManuscriptEntities = articleId => {
  window.open(`${apiUrl}/${articleId}/entities`)
}

/* eslint-disable import/prefer-default-export */
export {
  exportManuscriptToHTML,
  exportManuscriptToPDF,
  exportManuscriptToPrint,
  exportManuscriptEntities,
}
