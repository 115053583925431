import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { grid, th } from '../_helpers'

// From https://tobiasahlin.com/spinkit/
const animation = keyframes`
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  
  50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  } 
  
  100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
`

const Spinner = styled.div`
  animation: ${animation} 1.2s infinite ease-in-out;
  background-color: ${th('colorPrimary')};
  height: ${grid(6)};
  width: ${grid(6)};
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: calc(${th('gridUnit')} * 2);
`

const Loader = ({ className }) => (
  <Wrapper className={className}>
    <Spinner />
  </Wrapper>
)

Loader.propTypes = {
  className: PropTypes.string,
}

Loader.defaultProps = {
  className: null,
}

export default Loader
