/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Form, TextField } from '../common'
import DialogModal from './DialogModal'
import ValueList from '../editors/ValueList'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`

const urlConstructors = {
  WormBase: {
    GENE: 'https://wormbase.org/species/c_elegans/gene/',
    PROTEIN: 'https://wormbase.org/species/c_elegans/protein/',
    VARIANT: 'https://wormbase.org/species/c_elegans/variation/',
    TRANSGENE: 'https://wormbase.org/species/c_elegans/transgene/',
    STRAIN: 'https://wormbase.org/species/c_elegans/strain/',
    ANTIBODY: 'https://wormbase.org/species/c_elegans/antibody',
    DISEASE: 'https://wormbase.org/species/c_elegans/disease/',
    GENOTYPE: 'https://wormbase.org/species/c_elegans/genotype/',
    MOLECULE: 'https://wormbase.org/species/c_elegans/molecule/',
    PHENOTYPE: 'https://wormbase.org/species/c_elegans/phenotype/',
    CONSTRUCT: 'https://wormbase.org/species/c_elegans/construct/',
    REARRANGEMENT: 'https://wormbase.org/species/c_elegans/rearrangement/',
  },
  NCBI: {
    SPECIES:
      'https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?mode=Info&id=',
  },
  PomBase: {
    GENE: 'https://www.pombase.org/spombe/result/',
    PROTEIN: 'https://www.pombase.org/spombe/result/',
  },
}

const LinkInfoModal = props => {
  const {
    isAuthor,
    onRequestClose,
    entity,
    deleteLinks,
    // lookupAllele,
    // lookupStrain,
    // lookupTransgene,
    deleteLink,
    updateEntity,
  } = props

  if (!entity) return null

  const [genotype, setGenotype] = useState(null)
  const [showForm, setShowForm] = useState(false)

  const { match, count, method, source, type, sourceId, url, id } = entity

  const close = () => {
    setGenotype(null)
    setShowForm(false)
    onRequestClose()
  }

  const deleteEntity = () => {
    deleteLinks([entity])
    onRequestClose()
  }

  const deleteLinkById = () => {
    deleteLink(id)
    onRequestClose()
  }

  /*
  if (type === 'VARIANT' && !genotype) {
    lookupAllele(sourceId)
      .then(res => res.json())
      .then(result => {
        setGenotype(result.corresponding_gene.data[0][0].label)
      })
  }

  if (type === 'STRAIN' && !genotype) {
    lookupStrain(sourceId)
      .then(res => res.json())
      .then(result => setGenotype(result.genotype.data.str))
  }

  if (type === 'TRANSGENE' && !genotype) {
    lookupTransgene(sourceId)
      .then(res => res.json())
      .then(result => setGenotype(result.summary.data))
  }
  */
  const truncatedUrl = url.length > 30 ? `${url.substring(0, 30)}...` : url

  const data = [
    { label: 'Entity', status: 'primary', value: match },
    { label: 'Type', status: 'primary', value: type },
    { label: '# found', status: 'primary', value: count },
    { label: 'Database ID', status: 'primary', value: sourceId },
    {
      label: 'Database page',
      status: 'primary',
      value: (
        <a href={url} rel="noreferrer" target="_blank">
          {truncatedUrl}
        </a>
      ),
    },
  ]

  if (!isAuthor) {
    data.push({ label: 'Source', status: 'primary', value: source })
    data.push({ label: 'Method', status: 'primary', value: method })

    data.push({
      label: 'Genotype',
      status: 'primary',
      value: (type === 'STRAIN' || type === 'TRANSGENE') && genotype,
    })
    data.push({
      label: 'Corresponding Gene',
      status: 'primary',
      value: type === 'VARIANT' && genotype,
    })
  }

  const handleSubmit = (values, formikBag) => {
    updateEntity(values).then(() => setShowForm(false))
  }

  const initialValues = {
    source: source || '',
    sourceId: sourceId || '',
    url: url || '',
    type: type || '',
  }

  return (
    <DialogModal
      onConfirm={close}
      showCancelButton={false}
      size="medium"
      {...props}
      onRequestClose={close}
    >
      {entity && !showForm && (
        <Wrapper>
          <ValueList data={data} />
          <Row>
            {!isAuthor && (
              <Button onClick={() => setShowForm(true)} outline>
                Edit
              </Button>
            )}
            <Button onClick={deleteLinkById} outline>
              Remove hightlighting for this instance?
            </Button>
            <Button onClick={deleteEntity} outline>
              Remove hightlighting for all instances?
            </Button>
          </Row>
        </Wrapper>
      )}

      {entity && showForm && (
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          {formProps => {
            const {
              handleBlur,
              handleChange,
              setFieldValue,
              touched,
              values,
            } = formProps

            const handleChangeSourceId = input => {
              if (
                values.source in urlConstructors &&
                values.type in urlConstructors[values.source]
              )
                setFieldValue(
                  'url',
                  `${urlConstructors[values.source][values.type]}${
                    input.target.value
                  }`,
                )
              handleChange(input)
            }

            return (
              <Wrapper>
                <TextField
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Source"
                  name="source"
                  touched={touched}
                  value={values.source}
                />
                <TextField
                  handleBlur={handleBlur}
                  handleChange={handleChangeSourceId}
                  label="Source ID"
                  name="sourceId"
                  touched={touched}
                  value={values.sourceId}
                />
                <TextField
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Database Link"
                  name="url"
                  touched={touched}
                  value={values.url}
                />

                <Button onClick={() => setShowForm(false)}>Cancel</Button>

                <Button primary type="submit">
                  Save
                </Button>
              </Wrapper>
            )
          }}
        </Form>
      )}
    </DialogModal>
  )
}

export default LinkInfoModal
