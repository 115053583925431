import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/caltechdata`

const uploadData = async (versionId, dataFile) => {
  const url = `${apiUrl}/create?id=${versionId}&file=${encodeURIComponent(
    dataFile,
  )}`

  return fetch(url)
}

const publishData = async versionId => {
  const url = `${apiUrl}/publish?id=${versionId}`
  return fetch(url)
}

export { uploadData, publishData }
