/* eslint-disable sort-keys */

const bold = {
  parseDOM: [
    { tag: 'strong' },
    {
      getAttrs: node => node.style.fontWeight !== 'normal' && null,
      tag: 'b',
    },
    {
      getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null,
      style: 'font-weight',
    },
  ],
  toDOM: () => ['b'],
}

const italic = {
  parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
  toDOM: () => ['i'],
}

const underline = {
  parseDOM: [{ tag: 'u' }, { style: 'font-decoration=underline' }],
  toDOM: () => ['u'],
}

const added = {
  attrs: {
    'data-diff-added': true,
  },
  parseDOM: [
    {
      tag: 'span[data-diff-added]',
      getAttrs: dom => ({
        'data-diff-added': dom.getAttribute('data-diff-added'),
      }),
    },
  ],
  toDOM: node => ['span', node.attrs],
}

const removed = {
  attrs: {
    'data-diff-removed': true,
  },
  parseDOM: [
    {
      tag: 'span[data-diff-removed]',
      getAttrs: dom => ({
        'data-diff-removed': dom.getAttribute('data-diff-removed'),
      }),
    },
  ],
  toDOM: node => ['span', node.attrs],
}

const ins = {
  parseDOM: [{ tag: 'ins' }],
  toDOM: () => ['ins'],
}

const del = {
  parseDOM: [{ tag: 'del' }],
  toDOM: () => ['del'],
}

const link = {
  attrs: {
    href: { default: null },
    title: { default: null },
    id: { default: null },
  },
  inclusive: false,
  parseDOM: [
    {
      getAttrs: dom => ({
        href: dom.getAttribute('href'),
        title: dom.getAttribute('title'),
        id: dom.getAttribute('id'),
      }),
      tag: 'a',
    },
  ],
  toDOM: node => ['a', node.attrs],
}

const smallcaps = {
  parseDOM: [{ style: 'font-variant=small-caps' }],
  toDOM: () => ['span', { style: 'font-variant:small-caps' }],
}

const subscript = {
  parseDOM: [{ tag: 'sub' }, { style: 'vertical-align=sub' }],
  toDOM: () => ['sub'],
}

const superscript = {
  parseDOM: [{ tag: 'sup' }, { style: 'vertical-align=super' }],
  toDOM: () => ['sup'],
}

const mathml = {
  parseDOM: [{ tag: 'math' }],
  toDOM: () => ['span', { style: 'display:none' }],
}

export default {
  bold,
  italic,
  underline,
  link,
  subscript,
  superscript,
  smallcaps,
  added,
  removed,
  ins,
  del,
  mathml,
}
