/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

import { Link } from '../common'
import InfoModal from './InfoModal'
import Editor from '../preview/Editor'
import Split from '../split/Split'

const Table = styled(ReactTable)`
  width: 100%;

  .rt-tbody {
    text-align: center;
  }

  .rt-th {
    &:focus {
      outline: none;
    }
  }

  div.rt-noData {
    display: none;
  }
`

const columns = [
  {
    accessor: 'symbol',
    Header: 'Symbol',
  },
  {
    accessor: 'source',
    Header: 'Source',
    width: 200,
  },
  {
    accessor: 'type',
    Header: 'Type',
  },
  {
    accessor: 'sourceId',
    Header: 'Source Id',
    Cell: context => {
      const { original } = context
      const { sourceUrl, sourceId } = original
      return (
        <Link target="_blank" to={sourceUrl}>
          {sourceId}
        </Link>
      )
    },
    width: 200,
  },
]

const ExtendedDataEntityTable = props => {
  const { extendedDataEntities } = props

  if (!extendedDataEntities) return null

  const { text, entities } = extendedDataEntities

  let highlightText = text

  const rows = entities
    .filter(r => !r.deleted)
    .map((entity, index) => {
      const { match, source, type, sourceId, url } = entity

      highlightText = highlightText.replace(
        new RegExp(match, 'g'),
        '<ins>$&</ins>',
      )

      return {
        symbol: match,
        source: source && source.replace('_', ' '),
        type,
        sourceId,
        sourceUrl: url,
      }
    })

  const leftSide = <Editor value={highlightText} />

  const rightSide = rows && rows.length > 0 && (
    <Table
      className="-striped -highlight"
      columns={columns}
      data={rows}
      defaultPageSize={entities.length}
      minRows={0}
      resizable={false}
      showPagination={false}
    />
  )

  return (
    <InfoModal
      headerText="Entity Table"
      justifyText
      size="large"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Split left={leftSide} right={rightSide} splitOn={60} />
    </InfoModal>
  )
}

export default ExtendedDataEntityTable
