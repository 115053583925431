import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/export/graph`

const yearData = async () => {
  const url = `${apiUrl}/year`
  return fetch(url)
}

const speciesData = async () => {
  const url = `${apiUrl}/species`
  return fetch(url)
}

const categoriesData = async () => {
  const url = `${apiUrl}/categories`
  return fetch(url)
}

const submissionTypesData = async () => {
  const url = `${apiUrl}/submissionTypes`
  return fetch(url)
}

const speciesYearData = async () => {
  const url = `${apiUrl}/speciesYears`
  return fetch(url)
}

export {
  yearData,
  speciesData,
  categoriesData,
  submissionTypesData,
  speciesYearData,
}
