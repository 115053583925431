/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'

import { Checkbox as UICheckBox } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

// TO DO -- extract Labels from TextField
const Label = styled.label`
  display: block;
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  /* text-transform: uppercase; */
`

const Check = styled(UICheckBox)`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};

  input {
    display: none;
    margin: 0;
    position: unset;
  }

  span {
    cursor: pointer;
    font-family: ${th('fontInterface')};
    font-size: ${th('fontSizeBaseSmall')};
    font-style: normal;
    letter-spacing: unset;
    transition: none;

    &:before {
      margin-left: 1px;
    }

    &:hover {
      color: ${th('colorText')};
    }

    &:hover:before {
      animation: none;
      background: ${props => {
        if (props.disabled) return 'currentColor'
        if (props.checked) return th('colorPrimary')
        return 'transparent'
      }};
      box-shadow: ${props =>
        props.disabled
          ? '0 0 0 1px currentColor'
          : `0 0 0 1px ${th('colorPrimary')}`};
    }
  }
`

const Description = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  text-align: justify;
  /* text-transform: uppercase; */

  > p {
    /* margin-bottom: 0; */
    margin: ${th('gridUnit')} 0 0 0;
  }
`

const Wrapper = styled.div`
  margin-bottom: calc(${th('gridUnit')} * 2);
  width: 100%;

  /* stylelint-disable-next-line order/properties-alphabetical-order */
  ${props => props.inline && `display: inline;`}

  label:last-of-type {
    margin-top: ${th('gridUnit')};
  }
`

const BoxWithError = styled.div`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  justify-content: column;
`

const Error = styled.div`
  align-self: flex-end;
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  margin-left: ${th('gridUnit')};
`

const Checkbox = props => {
  const {
    checkBoxText,
    checked,
    className,
    'data-test-id': dataTestId,
    description,
    errors,
    inline,
    label,
    name,
    onBlur,
    onChange,
    readOnly,
    required,
    setFieldTouched,
    touched,
    value,
  } = props

  const touchedThis = name && touched[name]
  const error = typeof value !== 'undefined' && errors && errors[name]

  const handleChange = e => {
    if (setFieldTouched) {
      setFieldTouched(name)
    }

    onChange(e)
  }

  return (
    <Wrapper data-test-id={dataTestId} inline={inline}>
      {label && <Label>{`${label}${required ? ' *' : ''}`}</Label>}

      {description && <Description>{description}</Description>}

      <BoxWithError inline={inline}>
        <Check
          checked={checked}
          className={className}
          data-test-id={`${dataTestId}-checkbox`}
          disabled={readOnly}
          label={checkBoxText}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          value={value}
        />
        {touchedThis && error && <Error>{error}</Error>}
      </BoxWithError>
    </Wrapper>
  )
}

export default Checkbox
