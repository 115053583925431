import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { MANUSCRIPT_STATUS } from '../graphql'
// import { Loader } from '../../ui'
import CurrentUserContext from '../userContext'

import {
  CuratorView,
  EditorView,
  Preview,
  ReviewerView,
  ScienceOfficerView,
  SubmissionForm,
} from './article'

const Article = props => {
  const { id: manuscriptId } = useParams()

  /**
   * Permissions
   */
  const { currentUser } = useContext(CurrentUserContext)

  const isAdmin = currentUser.admin

  // const { isAcceptedReviewerForVersion, isGlobal } = currentUser.auth
  const { isGlobalEditor, isAssignedSectionEditor } = currentUser.auth
  const isAuthor = currentUser.auth.isAuthor.includes(manuscriptId)

  const isEditor =
    isAdmin || isGlobalEditor || isAssignedSectionEditor.includes(manuscriptId)

  const isReviewer = currentUser.auth.isAcceptedReviewerForManuscript.includes(
    manuscriptId,
  )

  const isScienceOfficer = currentUser.auth.isAssignedScienceOfficer.includes(
    manuscriptId,
  )

  const isCurator = currentUser.auth.isAssignedCurator.includes(manuscriptId)

  // const showSplitScreen = isEditor

  /**
   * Query & mutation handling
   */

  const { data, loading } = useQuery(MANUSCRIPT_STATUS, {
    variables: { id: manuscriptId },
  })

  let initiallySubmitted, underRevision, editorEdit, proofs

  if (data && data.manuscript) {
    // eslint-disable-next-line prefer-destructuring
    const { isInitiallySubmitted, versions } = data.manuscript
    const currentVersion = versions[versions.length - 1]
    const previousVersion = versions[versions.length - 2]

    initiallySubmitted = isInitiallySubmitted
    proofs = previousVersion && previousVersion.decision === 'accept'
    underRevision =
      previousVersion &&
      (previousVersion.decision === 'revise' ||
        previousVersion.decision === 'accept' ||
        previousVersion.decision === 'publish' ||
        previousVersion.decision === 'edit') &&
      !currentVersion.submitted
    ;({ editorEdit } = currentVersion)
  }

  /**
   * if author of manuscript
   * (
   * [ ] if not submitted
   * OR
   * [ ] under revision
   * show form
   * )
   * (
   * [ ] else
   * show preview
   * )
   *
   * if not author
   * (
   * if global editor or assigned editor
   * show SPLIT SCREEN
   * with editor panel
   * )
   * (
   * if science officer
   * show SPLIT SCREEN
   * with science officer panel
   * )
   * (
   * if reviewer
   * show split screen
   * with reviewer panel
   * )
   * (
   * if curator
   * show split screen
   * with curator panel
   * )
   *
   */

  // if (loading) return <Loader />
  if (loading) return null

  /**
   * ORDER MATTERS!
   *
   * eg. if the user is both an author and an editor, we should show the author
   *     ui, as editors are not allowed to edit their own manuscripts
   *
   * the order of the if statements will catch that
   */

  if (isAuthor || (isEditor && editorEdit)) {
    const showForm = initiallySubmitted === false || underRevision

    let submissionType
    if (initiallySubmitted === false) submissionType = 'initial'
    else if (proofs) submissionType = 'proofs'
    else if (underRevision) submissionType = 'revision'

    if (showForm)
      return (
        <SubmissionForm
          manuscriptId={manuscriptId}
          submissionType={submissionType}
          user={currentUser}
        />
      )

    return (
      <Preview
        isAuthor={isAuthor}
        isEditor={isEditor}
        manuscriptId={manuscriptId}
      />
    )
  }

  if (isEditor) {
    return (
      <EditorView
        manuscriptId={manuscriptId}
        showEditorAssignment={isGlobalEditor || isAdmin}
      />
    )
  }

  if (isReviewer) {
    return <ReviewerView manuscriptId={manuscriptId} />
  }

  if (isScienceOfficer) {
    return <ScienceOfficerView manuscriptId={manuscriptId} />
  }

  if (isCurator) {
    return <CuratorView manuscriptId={manuscriptId} />
  }

  return null
}

export default Article
