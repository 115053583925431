import { gql } from '@apollo/client'

const SIGNUP_USER = gql`
  mutation Signup($input: UserInput) {
    signUp(input: $input) {
      id
      type
      username
      email
      orcid
      reviewer
      specialty
      species
      researchArea
    }
  }
`

export default SIGNUP_USER
