/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import WarningModal from './WarningModal'

const EditLockModal = props => (
  <WarningModal
    headerText="Edit Manuscript"
    justifyText
    textSuccess="Yes"
    {...props}
  >
    This manuscript is currently marked as being copyedited by a different user.
    Do you want to continue?
  </WarningModal>
)

export default EditLockModal
