import React from 'react'
import PropTypes from 'prop-types'

import ReviewList from './ReviewList'

const CuratorSection = props => {
  const { curatorName, onClickChat, reviews, showChat } = props

  if (!curatorName) return 'No curator is currently assigned to this manuscript'

  return (
    <ReviewList
      label="Curator feedback"
      onClickChat={onClickChat}
      reviews={reviews}
      showChat={showChat}
      showRequestToSeeRevision={false}
    />
  )
}

CuratorSection.propTypes = {
  /** Curator's display name */
  curatorName: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Function to run on clicking the chat button */
  onClickChat: PropTypes.func,
  /** The curator assessments */
  reviews: PropTypes.arrayOf(
    PropTypes.shape({
      askedToSeeRevision: PropTypes.bool,
      content: PropTypes.string,
      openAcknowledgement: PropTypes.bool,
      pending: PropTypes.bool,
      recommendation: PropTypes.oneOf(['accept', 'reject', 'revise']),
      reviewerId: PropTypes.string,
      reviewerName: PropTypes.string,
    }),
  ),
  /** Whether to show the chat button */
  showChat: PropTypes.bool,
}

CuratorSection.defaultProps = {
  onClickChat: null,
  reviews: [],
  showChat: false,
}

export default CuratorSection
