import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/datacite`

const getDoi = doi => {
  const url = `${apiUrl}/fetch?doi=${doi}`
  return fetch(url)
}

const createDoi = async values => {
  // Retrieve the last DOI and increment
  const lastSubmission = await fetch(`${apiUrl}/fetchLast`)

  let errorMessage = ''

  const result = await lastSubmission.json()

  if (result.data.length > 0) {
    const doi = result.data[0].id
    const newDoi = incrementDoi(doi)
    const createResult = await fetch(`${apiUrl}/new?doi=${newDoi}`)

    if (createResult.status === 200) {
      return newDoi
    }

    const error = await createResult.text()
    errorMessage = error
  } else {
    errorMessage = 'No DOI returned'
  }

  throw Error(errorMessage)
}

const publishDoi = async manuscriptId => {
  const url = `${apiUrl}/publish?id=${manuscriptId}`
  const result = await fetch(url)

  if (result.status === 200) {
    return result
  }

  const error = await result.text()
  throw Error(error)
}

const incrementDoi = doi => {
  const doiSplit = doi.split('.')
  const doiNumber = parseInt(doiSplit[3], 10) + 1
  const paddedNumber = `${doiNumber}`.padStart(6, '0')

  return `${doiSplit[0]}.${doiSplit[1]}.${doiSplit[2]}.${paddedNumber}`
}

const publishCorrectionDoi = async (manuscriptId, doi) => {
  const url = `${apiUrl}/publishCorrection?id=${manuscriptId}&doi=${doi}`
  const result = await fetch(url)

  if (result.status === 200) {
    return result
  }

  const error = await result.text()
  throw Error(error)
}

export { getDoi, createDoi, publishDoi, publishCorrectionDoi }
