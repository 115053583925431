/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

import { Link } from '../common'
import InfoModal from './InfoModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Table = styled(ReactTable)`
  width: 100%;

  .rt-tbody {
    text-align: center;
  }

  .rt-th {
    &:focus {
      outline: none;
    }
  }

  div.rt-noData {
    display: none;
  }
`

const columns = [
  {
    accessor: 'symbol',
    Header: 'Symbol',
  },
  {
    accessor: 'source',
    Header: 'Source',
    width: 200,
  },
  {
    accessor: 'type',
    Header: 'Type',
  },
  {
    accessor: 'sourceId',
    Header: 'Source Id',
    Cell: context => {
      const { original } = context
      const { sourceUrl, sourceId } = original
      return (
        <Link target="_blank" to={sourceUrl}>
          {sourceId}
        </Link>
      )
    },
    width: 200,
  },
]

const DashboardEntityTable = props => {
  const { entities } = props

  if (!entities) return null

  const rows = entities
    .filter(r => !r.deleted)
    .map((entity, index) => {
      const { match, source, type, sourceId, url } = entity

      return {
        symbol: match,
        source: source && source.replace('_', ' '),
        type,
        sourceId,
        sourceUrl: url,
      }
    })

  return (
    <InfoModal
      headerText="Entity Table"
      justifyText
      size="medium"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Wrapper>
        {rows && rows.length > 0 && (
          <Table
            className="-striped -highlight"
            columns={columns}
            data={rows}
            defaultPageSize={entities.length}
            minRows={0}
            resizable={false}
            showPagination={false}
          />
        )}
      </Wrapper>
    </InfoModal>
  )
}

export default DashboardEntityTable
