import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import {
  Button,
  Form,
  TextField,
  Loader,
  PageHeader,
  Response,
} from '../common'
import { grid, th } from '../_helpers'

const Wrapper = styled.div``

const Link = styled(RouterLink)`
  color: ${th('colorPrimary')};
`

const VerifyingWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    text-align: center;
  }
`

const StyledLoader = styled(Loader)`
  margin: ${grid(3)} 0;
`

const Info = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  margin-bottom: ${grid(2)};
`

const StyledTextField = styled(TextField)`
  margin-bottom: ${grid(3)};

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > div:first-child {
      margin-bottom: 0;
      width: 100%;
    }

    > div[role='alert'] {
      align-self: flex-start;
      height: ${grid(2)};
      margin: ${grid(1)} 0;
    }
  }
`

const initialValues = {
  password: '',
  passwordRepeat: '',
}

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('New password is required'),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Please enter your new password twice'),
})

const NewPasswordForm = props => {
  const { handleSubmit } = props

  const submit = values => {
    const { password } = values
    handleSubmit(password)
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={submit}
      validationSchema={validationSchema}
    >
      {formProps => {
        const { errors, handleChange, handleBlur, touched, values } = formProps

        return (
          <>
            <Info>Please provide your new password in the fields below.</Info>

            <StyledTextField
              error={errors.password}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="New password"
              name="password"
              placeholder="Enter your new password"
              required
              touched={touched}
              type="password"
              value={values.password}
            />

            <StyledTextField
              error={errors.passwordRepeat}
              handleBlur={handleBlur}
              handleChange={handleChange}
              label="Repeat new password"
              name="passwordRepeat"
              placeholder="Enter your new password again"
              required
              touched={touched}
              type="password"
              value={values.passwordRepeat}
            />

            <Button primary type="submit">
              Submit new password
            </Button>
          </>
        )
      }}
    </Form>
  )
}

NewPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

const ResetPassword = props => {
  const {
    className,
    error,
    expired,
    verifyingToken,
    submitNewPassword,
    success,
  } = props

  return (
    <Wrapper className={className}>
      <PageHeader>Reset password</PageHeader>

      {!verifyingToken && error && (
        <Response icon="alert_circle" status="error">
          Something went wrong.
          <br />
          <br />
          <Info>
            The most likely scenario is that you have already used this link to
            reset your password. If that is not the case, please contact your
            system administrator.
          </Info>
        </Response>
      )}

      {!verifyingToken && !error && !success && expired && (
        <Response icon="alert_circle" status="error">
          This link has expired.
          <br />
          Password reset links are valid for 24 hours.
          <br />
          <Link to="/request-password-reset">
            Request a new password reset link
          </Link>
        </Response>
      )}

      {verifyingToken && (
        <VerifyingWrapper>
          <StyledLoader />
          <span>Verifying...</span>
        </VerifyingWrapper>
      )}

      {!verifyingToken && !error && !success && !expired && (
        <NewPasswordForm handleSubmit={submitNewPassword} />
      )}

      {!verifyingToken && !error && success && (
        <Response icon="check_circle" status="success">
          Password successfully changed!
          <br />
          Redirecting you to login page...
        </Response>
      )}
    </Wrapper>
  )
}

ResetPassword.propTypes = {
  /** Whether there's been an error */
  error: PropTypes.bool,
  /** Whether the token has expired */
  expired: PropTypes.bool,
  /** Function to run when the form is submitted */
  submitNewPassword: PropTypes.func.isRequired,
  /** Whether the password was successfully changed */
  success: PropTypes.bool,
  /** Loading state while token's expiration is being verified */
  verifyingToken: PropTypes.bool,
}

ResetPassword.defaultProps = {
  error: false,
  expired: false,
  success: false,
  verifyingToken: false,
}

export default ResetPassword
