import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { diff, fontReading } from './_cssFragments'

const StyledAuthor = styled.span`
  ${fontReading}
  ${diff}
`

const Author = props => {
  const {
    affiliations,
    hasEqualContribution,
    isAdded,
    isCorresponding,
    isLast,
    isRemoved,
    name,
    showDiff,
    hasSingleItem,
    hasOneAffiliation,
  } = props

  const sortedAffiliations = affiliations.sort().join(',')

  return (
    <StyledAuthor isAdded={isAdded} isRemoved={isRemoved} showDiff={showDiff}>
      {name}{' '}
      {!hasSingleItem && !hasOneAffiliation && <sup>{sortedAffiliations}</sup>}{' '}
      {isCorresponding && !hasSingleItem && <sup>&sect;</sup>}{' '}
      {hasEqualContribution && <sup>*</sup>}
      {!isLast && ', '}
    </StyledAuthor>
  )
}

Author.propTypes = {
  /** A list of numbers that correspond to the separate list of affiliations. */
  affiliations: PropTypes.arrayOf(PropTypes.number).isRequired,
  /** Whether this author shares equal contribution with other authors */
  hasEqualContribution: PropTypes.bool,
  /** Whether this was an addition for this version. For diffs. */
  isAdded: PropTypes.bool,
  /** Whether the author is the corresponding author. */
  isCorresponding: PropTypes.bool,
  /** Whether this is the last author of the list. Handles trailing comma. */
  isLast: PropTypes.bool,
  /** Whether this was a removal for this version. For diffs. */
  isRemoved: PropTypes.bool,
  /** The author's full name. */
  name: PropTypes.string.isRequired,
  /** Whether to show the diff at all. */
  showDiff: PropTypes.bool,
  /** Whether the array has one item */
  hasSingleItem: PropTypes.bool,
  /** Whether there is one affiliation */
  hasOneAffiliation: PropTypes.bool,
}

Author.defaultProps = {
  hasEqualContribution: false,
  isAdded: false,
  isCorresponding: false,
  isLast: false,
  isRemoved: false,
  showDiff: true,
  hasSingleItem: false,
  hasOneAffiliation: false,
}

export default Author
