import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

const SplitScreen = styled.div`
  align-items: stretch;
  display: flex;
  width: 100%;

  > div {
    border: ${th('borderWidth')} ${th('borderStyle')}
      ${th('colorBackgroundHue')};
    border-radius: ${th('borderRadius')};
    overflow-y: auto;
    padding: ${th('gridUnit')};
  }

  > div:first-child {
    width: ${props => props.splitOn}%;
  }

  > div:last-child {
    margin-left: ${th('gridUnit')};
    width: calc(100% - ${props => props.splitOn}%);
  }
`

const Split = props => {
  const { className, left, right, splitOn } = props

  return (
    <SplitScreen className={className} splitOn={splitOn}>
      <div>{left}</div>
      <div>{right}</div>
    </SplitScreen>
  )
}

Split.propTypes = {
  /** Component to render on the left side */
  left: PropTypes.node.isRequired,
  /** Component to render on the right side */
  right: PropTypes.node.isRequired,
  /** Percentage of width to split on */
  splitOn: PropTypes.number,
}

Split.defaultProps = {
  splitOn: 50,
}

export default Split
