/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
import { Query, Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'
import { Adopt } from 'react-adopt'
import get from 'lodash/get'

import { CURRENT_USER } from '../Private'
import { withCurrentUser } from '../../userContext'
import { UserProfile } from '../../../ui'

const GET_CURRENT_USER_PROFILE = gql`
  query CurrentUser {
    currentUser {
      id
      givenNames
      surname
      username
      orcid
      reviewer
      species
      researchArea
      specialty
    }
  }
`

const UPDATE_PERSONAL_INFORMATION = gql`
  mutation UpdatePersonalInformation($input: UpdatePersonalInformationInput!) {
    updatePersonalInformation(input: $input) {
      id
    }
  }
`

const UPDATE_USERNAME = gql`
  mutation UpdateUsername($input: UpdateUsernameInput!) {
    updateUsername(input: $input) {
      id
    }
  }
`

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input)
  }
`

const GetCurrentUserProfileQuery = props => {
  const { render } = props
  return <Query query={GET_CURRENT_USER_PROFILE}>{render}</Query>
}

const UpdatePersonalInformationMutation = props => {
  const { render } = props

  const refetchQueries = [
    { query: CURRENT_USER },
    { query: GET_CURRENT_USER_PROFILE },
  ]

  return (
    <Mutation
      mutation={UPDATE_PERSONAL_INFORMATION}
      refetchQueries={refetchQueries}
    >
      {(updatePersonalInformation, updatePersonalInformationResponse) =>
        render({ updatePersonalInformation, updatePersonalInformationResponse })
      }
    </Mutation>
  )
}

const UpdateUsernameMutation = props => {
  const { render } = props

  const refetchQueries = [
    { query: CURRENT_USER },
    { query: GET_CURRENT_USER_PROFILE },
  ]

  return (
    <Mutation mutation={UPDATE_USERNAME} refetchQueries={refetchQueries}>
      {(updateUsername, updateUsernameResponse) =>
        render({ updateUsername, updateUsernameResponse })
      }
    </Mutation>
  )
}

const UpdatePasswordMutation = props => {
  const { render } = props

  return (
    <Mutation mutation={UPDATE_PASSWORD}>
      {(updatePassword, updatePasswordResponse) =>
        render({ updatePassword, updatePasswordResponse })
      }
    </Mutation>
  )
}

const mapper = {
  // queries
  GetCurrentUserProfileQuery,

  // mutations
  UpdatePasswordMutation,
  UpdatePersonalInformationMutation,
  UpdateUsernameMutation,
}

const mapProps = args => {
  const updatePassword = input => {
    const { currentPassword, newPassword } = input

    return args.UpdatePasswordMutation.updatePassword({
      variables: {
        input: {
          currentPassword,
          newPassword,
        },
      },
    })
  }

  const updatePersonalInformation = input =>
    args.UpdatePersonalInformationMutation.updatePersonalInformation({
      variables: {
        input,
      },
    })

  const updateUsername = input =>
    args.UpdateUsernameMutation.updateUsername({
      variables: {
        input,
      },
    })

  return {
    givenNames: get(
      args.GetCurrentUserProfileQuery,
      'data.currentUser.givenNames',
    ),
    surname: get(args.GetCurrentUserProfileQuery, 'data.currentUser.surname'),
    username: get(args.GetCurrentUserProfileQuery, 'data.currentUser.username'),
    orcid: get(args.GetCurrentUserProfileQuery, 'data.currentUser.orcid'),
    reviewer: get(args.GetCurrentUserProfileQuery, 'data.currentUser.reviewer'),
    species: get(args.GetCurrentUserProfileQuery, 'data.currentUser.species'),
    researchArea: get(
      args.GetCurrentUserProfileQuery,
      'data.currentUser.researchArea',
    ),
    specialty: get(
      args.GetCurrentUserProfileQuery,
      'data.currentUser.specialty',
    ),
    loading: args.GetCurrentUserProfileQuery.loading,
    updatePassword,
    updatePersonalInformation,
    updateUsername,
  }
}

const ConnectedUserProfile = props => (
  <Adopt mapper={mapper} mapProps={mapProps}>
    {mappedProps => <UserProfile {...mappedProps} />}
  </Adopt>
)

export default withCurrentUser(ConnectedUserProfile)
