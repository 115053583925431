/* eslint-disable react/prop-types */

import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { DASHBOARD_MANUSCRIPTS } from './dashboardManuscripts'

const WITHDRAW_ARTICLE = gql`
  mutation SubmitDecision(
    $manuscriptVersionId: ID!
    $input: SubmitDecisionInput!
  ) {
    submitDecision(manuscriptVersionId: $manuscriptVersionId, input: $input)
  }
`

const WithdrawArticleMutation = props => {
  const { render } = props

  const refetch = [
    {
      query: DASHBOARD_MANUSCRIPTS,
    },
  ]

  return (
    <Mutation mutation={WITHDRAW_ARTICLE} refetchQueries={refetch}>
      {(withdrawArticle, withdrawArticleResponse) =>
        render({ withdrawArticle, withdrawArticleResponse })
      }
    </Mutation>
  )
}

export default WithdrawArticleMutation
