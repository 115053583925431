/* eslint-disable react/prop-types */
import React from 'react'
import { Interweave, Markup } from 'interweave'
import styled from 'styled-components'

import { th, darken } from '@pubsweet/ui-toolkit'

import { Loader, PageHeader, Button } from '../common'

const StyledButton = styled(Button)`
  background-color: ${props => props.background};
  font-style: ${props => props.fontStyle};
  min-width: 0;
  text-transform: none;
`

const DeleteLinkButton = styled(Button)`
  color: black;
  font-style: ${props => props.fontStyle};
  min-width: 0;
  text-transform: none;
`

const Pink = styled.i`
  color: ${darken('colorError', 0.6)};
`

/*
const Box = styled.div`
  border: 0.01rem solid;
  border-radius: 5px;
  padding: 5px;
`
*/

const Preview = props => {
  const {
    onClickShowLinkInfo,
    version,
    highlightEntity,
    savedEntities,
    onClickRemoveLink,
  } = props

  if (!version) return <Loader />

  // eslint-disable-next-line consistent-return
  const convertHTML = (node, children) => {
    const fontStyle =
      node.parentNode.tagName === 'I' || node.parentNode.tagName === 'EM'
        ? 'italic'
        : 'none'

    if (node.tagName === 'A') {
      if (!node.hasAttribute('id') && !node.hasAttribute('href'))
        return (
          <DeleteLinkButton
            fontStyle={fontStyle}
            onClick={() => onClickRemoveLink(children[0])}
            title={`Highlighting removed. Click to restore highlighting for all instances ${children[0]}.`}
          >
            {children}
          </DeleteLinkButton>
        )

      const background =
        highlightEntity === children[0]
          ? th('colorWarning')
          : th('colorBackground')

      const entity =
        savedEntities &&
        savedEntities.find(e => {
          if (
            e.match.includes('<sup>') &&
            children.length > 1 &&
            typeof children[1] === 'object'
          ) {
            return (
              e.match ===
              `${children[0]}<sup>${children[1].props.children[0]}</sup>`
            )
          }

          return e.match === children[0]
        })

      const color =
        entity && entity.method === 'prediction'
          ? th('colorSuccess')
          : th('colorPrimary')

      return (
        <StyledButton
          background={background}
          color={color}
          fontStyle={fontStyle}
          onClick={() =>
            onClickShowLinkInfo(entity.match, node.getAttribute('id'))
          }
          size="small"
        >
          {children}
        </StyledButton>
      )
    }

    if (node.tagName === 'I' || node.tagName === 'EM') {
      return <Pink>{children}</Pink>
    }
  }

  const {
    abstract,
    title,
    imageTitle,
    imageCaption,
    patternDescription,
    methods,
    reagents,
    references,
  } = version

  return (
    <>
      <PageHeader>Title</PageHeader>
      <Interweave content={title} />
      <PageHeader>abstract</PageHeader>
      <Interweave content={abstract} transform={convertHTML} />
      {imageTitle && imageTitle !== '' && imageTitle !== '<p></p>' && (
        <>
          <PageHeader>figure title</PageHeader>
          <Interweave content={imageTitle} transform={convertHTML} />
        </>
      )}
      <PageHeader>Figure Caption</PageHeader>
      <Interweave content={imageCaption} transform={convertHTML} />
      <PageHeader>Description</PageHeader>
      <Interweave content={patternDescription} transform={convertHTML} />
      {methods && methods !== '' && methods !== '<p></p>' && (
        <>
          <PageHeader>methods</PageHeader>
          <Interweave content={methods} transform={convertHTML} />
        </>
      )}
      {reagents && reagents !== '' && reagents !== '<p></p>' && (
        <>
          <PageHeader>reagents</PageHeader>
          <Interweave content={reagents} transform={convertHTML} />
        </>
      )}
      <PageHeader>References</PageHeader>
      {references.map(reference => {
        const ref = reference.reference.includes('<p>')
          ? reference.reference
          : `<p>${reference.reference}</p>`

        return <Markup content={ref} key={reference.pmId || reference.doi} />
      })}
    </>
  )
}

export default Preview
