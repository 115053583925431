/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Accordion as UIAccordion } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

const StyledAccordion = styled(UIAccordion)`
  box-shadow: 0 1px ${th('colorPrimary')};

  height: calc(${th('gridUnit')} * 3);
  margin: ${th('gridUnit')} 0;
  transition: box-shadow 0.1s ease-in;

  &:hover {
    box-shadow: 0 2px ${th('colorPrimary')};
  }

  > span {
    color: ${props => props.color};
    font-variant-ligatures: none;
    font-weight: bold;
  }
`

const Accordion = props => <StyledAccordion {...props} />

Accordion.propTypes = {
  /** Text to display as a header */
  label: PropTypes.string.isRequired,
  /** Whether the element should start expanded or not */
  startExpanded: PropTypes.bool,
}

Accordion.defaultProps = {
  startExpanded: false,
}

export default Accordion
