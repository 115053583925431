import { gql } from '@apollo/client'

const MANUSCRIPT_FOR_ASSIGN_REVIEWERS = gql`
  query ManuscriptForAssignReviewers($id: ID!) {
    manuscript(id: $id) {
      id
      versions {
        id
        created
        amountOfReviewers
        isReviewerAutomationOn
        reviewerPool {
          id
          reason
          status
          suggestedReviewer
          user {
            id
            displayName
            email
            agreedTc
            isActive
          }
        }
        reviewerMessage
        reviews {
          id
          reviewerId
          status {
            pending
            submitted
          }
        }
        suggestedReviewer {
          name
        }
      }
    }
  }
`

const SEARCH_FOR_REVIEWERS = gql`
  query SearchForReviewers($searchQuery: String!, $manuscriptVersionId: ID!) {
    searchForReviewer(
      searchQuery: $searchQuery
      manuscriptVersionId: $manuscriptVersionId
      limit: 10
    ) {
      user {
        id
        isActive
        displayName
        givenNames
        surname
        notReviewer
      }
      meta {
        isAuthorOfItem
        isEditorOfItem
        isCuratorOfItem
        isSectionEditorOfItem
        isScienceOfficerOfItem
        isReviewerOfItem
        currentlyReviewing
        numInvitations
        lastReview
        lastInvitation
      }
    }
  }
`

const UPDATE_REVIEWER_POOL = gql`
  mutation UpdateReviewerPool($manuscriptVersionId: ID!, $reviewerIds: [ID!]!) {
    updateReviewerPool(
      manuscriptVersionId: $manuscriptVersionId
      reviewerIds: $reviewerIds
    ) {
      id
      reviewerPool {
        id
        status
        reason
        user {
          id
          displayName
        }
      }
    }
  }
`

const INVITE_REVIEWER = gql`
  mutation InviteReviewer(
    $manuscriptVersionId: ID!
    $input: InviteReviewerInput!
  ) {
    inviteReviewer(manuscriptVersionId: $manuscriptVersionId, input: $input) {
      id
      status
    }
  }
`

const REVOKE_INVITATION = gql`
  mutation Revoke($manuscriptVersionId: ID!, $reviewerId: ID!) {
    revokeInvitation(
      manuscriptVersionId: $manuscriptVersionId
      reviewerId: $reviewerId
    ) {
      id
      reviewerPool {
        id
        status
        user {
          id
          displayName
        }
      }
    }
  }
`

const CHANGE_REVIEWER_AUTOMATION_STATUS = gql`
  mutation UpdateAutomation($manuscriptVersionId: ID!, $value: Boolean!) {
    changeReviewerAutomationStatus(
      manuscriptVersionId: $manuscriptVersionId
      value: $value
    ) {
      id
      isReviewerAutomationOn
      reviewerPool {
        id
        status
        user {
          id
          displayName
        }
      }
    }
  }
`

const ADD_EXTERNAL_REVIEWER = gql`
  mutation AddExternalReviewer(
    $manuscriptVersionId: ID!
    $input: AddExternalReviewerInput!
  ) {
    addExternalReviewer(
      manuscriptVersionId: $manuscriptVersionId
      input: $input
    )
  }
`

const CHANGE_AMOUNT_OF_REVIEWERS = gql`
  mutation ChangeAmountOfReviewers($manuscriptVersionId: ID!, $amount: Int!) {
    changeAmountOfReviewers(
      manuscriptVersionId: $manuscriptVersionId
      amount: $amount
    ) {
      id
      amountOfReviewers
    }
  }
`

const SAVE_REVIEWER_MESSAGE = gql`
  mutation SaveReviewerMessage($versionId: ID!, $message: String) {
    saveReviewerMessage(versionId: $versionId, message: $message) {
      id
      reviewerMessage
    }
  }
`

export {
  ADD_EXTERNAL_REVIEWER,
  CHANGE_AMOUNT_OF_REVIEWERS,
  CHANGE_REVIEWER_AUTOMATION_STATUS,
  INVITE_REVIEWER,
  MANUSCRIPT_FOR_ASSIGN_REVIEWERS,
  REVOKE_INVITATION,
  SEARCH_FOR_REVIEWERS,
  UPDATE_REVIEWER_POOL,
  SAVE_REVIEWER_MESSAGE,
}
