import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import styled from 'styled-components'
import clone from 'lodash/clone'

import { grid, th } from '@pubsweet/ui-toolkit'

import { Button, Form, TextField, Icon, Select } from '../common'

const validations = yup.object().shape({
  value: yup.string().required('Value cannot be empty'),
  label: yup.string().required('Label cannot be empty'),
  imageSrc: yup.string(),
  imageAlt: yup.string(),
  modLink: yup.string(),
  mod: yup.string(),
  linkVariable: yup.string(),
  externalId: yup.string(),
})

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 10px 10px 0 0;
`

const StyledSelect = styled(Select)`
  margin-bottom: ${grid(2)};
  width: ${grid(50)};
`

const TaxonomySectionItem = props => {
  const {
    id,
    value,
    label,
    imageSrc,
    imageAlt,
    modLink,
    mod,
    linkVariable,
    deleteTaxonomy,
    updateTaxonomy,
    description,
    externalId,
  } = props

  const [showForm, setShowForm] = useState(false)
  const [showDelete, setShowDelete] = useState(false)

  const initialValues = {
    id: id || '',
    value: value || '',
    label: label || '',
    imageSrc: imageSrc || '',
    imageAlt: imageAlt || '',
    modLink: modLink || '',
    mod: mod || '',
    linkVariable: linkVariable || '',
    description: description || '',
    externalId: externalId || '',
  }

  const linkVariableOptions = [
    { label: 'DB Reference ID', value: 'dbReferenceId' },
    { label: 'DOI', value: 'doi' },
    { label: 'PMC ID', value: 'pmcId' },
    { label: 'PubMed ID', value: 'pmId' },
  ]

  const handleSubmit = (values, formikBag) => {
    const newValues = clone(values)
    newValues.value = values.value.toLowerCase()
    return updateTaxonomy(id, newValues).then(() => setShowForm(false))
  }

  return (
    <>
      {!showForm && !showDelete && (
        <Wrapper>
          {label}
          <Wrapper>
            <Button onClick={() => setShowForm(true)}>
              <Icon>edit</Icon>
            </Button>
            <Button onClick={() => setShowDelete(true)}>
              <Icon>x-square</Icon>
            </Button>
          </Wrapper>
        </Wrapper>
      )}
      {showDelete && (
        <Wrapper>
          Confirm Delete {label}?
          <Button onClick={() => deleteTaxonomy(id)} primary>
            Yes
          </Button>
          <Button onClick={() => setShowDelete(false)} primary>
            No
          </Button>
        </Wrapper>
      )}

      {showForm && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => {
            const {
              errors,
              handleBlur,
              handleChange,
              setFieldValue,
              touched,
              values,
            } = formProps

            const currentLinkVariable =
              values.linkVariable &&
              linkVariableOptions.find(
                option => option.value === values.linkVariable,
              )

            const handleLinkVariable = variable =>
              setFieldValue('linkVariable', variable.value)

            return (
              <>
                <TextField
                  error={errors.value}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Value"
                  name="value"
                  touched={touched}
                  value={values.value}
                />

                <TextField
                  error={errors.label}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Label"
                  name="label"
                  touched={touched}
                  value={values.label}
                />

                <TextField
                  error={errors.imageSrc}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Image Source"
                  name="imageSrc"
                  touched={touched}
                  value={values.imageSrc}
                />

                <TextField
                  error={errors.imageAlt}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Image Alt Tag"
                  name="imageAlt"
                  touched={touched}
                  value={values.imageAlt}
                />

                <TextField
                  error={errors.mod}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Representative Database"
                  name="mod"
                  touched={touched}
                  value={values.mod}
                />

                <TextField
                  error={errors.modLink}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Database Link"
                  name="modLink"
                  touched={touched}
                  value={values.modLink}
                />

                <Label>Link Variable (Optional)</Label>
                <StyledSelect
                  name="linkVariable"
                  onBlur={handleBlur}
                  onChange={handleLinkVariable}
                  options={linkVariableOptions}
                  value={currentLinkVariable}
                />

                <TextField
                  error={errors.description}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Description (Optional)"
                  name="description"
                  touched={touched}
                  value={values.description}
                />

                <TextField
                  error={errors.externalId}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Taxon ID (Optional)"
                  name="externalId"
                  touched={touched}
                  value={values.externalId}
                />

                <Button onClick={() => setShowForm(false)}>Cancel</Button>
                <Button primary type="submit">
                  Save
                </Button>
              </>
            )
          }}
        </Form>
      )}
    </>
  )
}

TaxonomySectionItem.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  modLink: PropTypes.string,
  mod: PropTypes.string,
  linkVariable: PropTypes.string,
  updateTaxonomy: PropTypes.func,
  deleteTaxonomy: PropTypes.func,
  description: PropTypes.string,
  externalId: PropTypes.string,
}

TaxonomySectionItem.defaultProps = {
  id: null,
  value: null,
  label: null,
  imageSrc: null,
  imageAlt: null,
  modLink: null,
  mod: null,
  linkVariable: null,
  updateTaxonomy: null,
  deleteTaxonomy: null,
  description: null,
  externalId: null,
}

export default TaxonomySectionItem
