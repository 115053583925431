import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import * as yup from 'yup'

import { th } from '@pubsweet/ui-toolkit'

import { Button, Checkbox, Form, Radio, TextEditor } from '../common'
import { stripHTML } from '../_helpers'

const Wrapper = styled.div``

const Editor = styled(TextEditor)`
  width: 100%;
`

const StyledRadio = styled(Radio)`
  width: 100%;
`

const radioOptions = [
  {
    color: th('colorSuccess'),
    label: 'ACCEPT version as is',
    value: 'accept version as is',
  },
  {
    color: th('colorText'),
    label: 'ACCEPT, with MINOR modifications to figure and/or text',
    value: 'accept, with minor modifications to figure and/or text',
  },
  {
    color: th('colorText'),
    label: 'ACCEPT, with MAJOR modifications to figure and/or text',
    value: 'accept, with major modifications to figure and/or text',
  },
  {
    color: th('colorText'),
    label:
      'ACCEPT, with ADDITIONAL DATA/experiment OR a CAVEAT concerning the conclusion given the missing information, as well as any additional minor or major modifications',
    value:
      'accept, with additional data/experiment or a caveat concerning the conclusion given the missing information, as well as any additional minor or major modifications',
  },
  {
    color: th('colorText'),
    label:
      'ACCEPT, with the addition of MISSING ESSENTIAL DATA; in the absence of these data, then REJECT',
    value:
      'accept, with the addition of missing essential data; in the absence of these data, then reject',
  },
  {
    color: th('colorError'),
    label: 'REJECT',
    value: 'reject',
  },
]

const validations = yup.object().shape({
  content: yup
    .string()
    .test('content-not-empty', 'Cannot leave review empty', val => {
      if (!val) return false
      return stripHTML(val).length > 0
    }),
  recommendation: yup.string().required('You need to make a recommendation'),
  openAcknowledgement: yup.boolean(),
  askedToSeeRevision: yup.boolean(),
  confidentialComments: yup.string(),
})

/* eslint-disable react/prop-types */
class AutoSave extends React.Component {
  save = debounce(() => {
    const { onSave, values } = this.props
    Promise.resolve(onSave(values))
  }, 500)

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { values } = this.props

    if (!isEqual(nextProps.values, values)) {
      this.save()
    }
  }

  render() {
    return null
  }
}
/* eslint-enable react/prop-types */

const SubmitReviewSection = props => {
  const { review, save, submit, submitted, createSOReview } = props

  const recommendation =
    get(review, 'recommendation') === 'revise'
      ? get(review, 'reviseQualifier')
      : get(review, 'recommendation')

  const initialValues = {
    content: get(review, 'content') || '',
    confidentialComments: '',
    recommendation: recommendation || '',
    openAcknowledgement: get(review, 'openAcknowledgement') || false,
  }

  const handleSubmit = values => submit(values)

  return (
    <Wrapper>
      {!review && (
        <Button onClick={createSOReview} primary>
          Write Review
        </Button>
      )}
      {review && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => {
            const {
              errors,
              handleChange,
              setFieldTouched,
              setFieldValue,
              touched,
              values,
            } = formProps

            const setRecommendation = (_, value) => {
              if (!['reject', 'accept'].includes(value)) {
                setFieldValue('reviseQualifier', value)
                setFieldValue('recommendation', 'revise')
              } else {
                setFieldValue('reviseQualifier', '')
                setFieldValue('recommendation', value)
              }
            }

            return (
              <>
                <StyledRadio
                  error={errors.recommendation}
                  label="Recommendation to the Editors"
                  name="recommendation"
                  options={radioOptions}
                  readOnly={submitted}
                  required
                  setFieldValue={setRecommendation}
                  touched={touched}
                  value={
                    get(values, 'reviseQualifier') || values.recommendation
                  }
                />

                <Editor
                  bold
                  error={errors.content}
                  italic
                  key={submitted}
                  label="In the space below please explain acceptance, rejection, or detail the modifications that should be made. If modifications are requested, enumerate each point the author(s) should address."
                  name="content"
                  placeholder="Write your review"
                  readOnly={submitted}
                  required
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  subscript
                  superscript
                  touched={touched}
                  value={values.content}
                />

                <Checkbox
                  checkBoxText="I would like to be openly acknowledged as the reviewer"
                  checked={values.openAcknowledgement}
                  name="openAcknowledgement"
                  onChange={handleChange}
                  readOnly={submitted}
                  setFieldTouched={setFieldTouched}
                  touched={touched}
                />

                {!submitted && (
                  <Button primary type="submit">
                    Submit
                  </Button>
                )}

                <AutoSave onSave={save} values={values} />
              </>
            )
          }}
        </Form>
      )}
    </Wrapper>
  )
}

const validDecisions = ['accept', 'reject', 'revise']

SubmitReviewSection.propTypes = {
  review: PropTypes.shape({
    content: PropTypes.string,
    recommendation: PropTypes.oneOf(validDecisions),
    reviseQualifier: PropTypes.string,
    openAcknowledgement: PropTypes.bool,
    askedToSeeRevision: PropTypes.bool,
  }),
  save: PropTypes.func,
  submit: PropTypes.func,
  submitted: PropTypes.bool,
  createSOReview: PropTypes.func,
}

SubmitReviewSection.defaultProps = {
  review: null,
  save: null,
  submit: null,
  submitted: false,
  createSOReview: null,
}

export default SubmitReviewSection
