/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import startCase from 'lodash/startCase'
import get from 'lodash/get'

import { Action, ActionGroup, Icon, Status } from '../common'
import { grid, th } from '../_helpers'

const draggingStyles = css`
  box-shadow: 0 0 0 2px ${th('colorPrimary')};
`

const Wrapper = styled.div`
  background: ${th('colorBackground')};
  box-shadow: 0 0 0 1px ${th('colorPrimary')};
  display: flex;
  flex-direction: column;
  padding: ${grid(1)};
  transition: box-shadow 0.1s ease-in;

  &:hover {
    ${draggingStyles}
  }

  /* stylelint-disable-next-line order/order, order/properties-alphabetical-order */
  ${props => props.isDragging && draggingStyles}
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftSide = styled.div`
  width: 85%;
`

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
`

const StatusWrapper = styled.div`
  display: inline-block;
  margin-left: ${grid(2)};
`

const StyledStatus = styled(Status)`
  font-size: 12px;
  margin: 0 4px;
`

const LineWrapper = styled.div`
  display: inline-block;
  font-size: ${th('fontSizeBaseSmall')};
  margin-left: ${grid(2)};
`

const LineLabel = styled.span`
  color: ${th('colorPrimary')};
  margin-right: ${grid(1)};
  text-transform: uppercase;
`

const ErrorWrapper = styled.span`
  color: ${th('colorError')};
  display: inline-block;
  font-size: ${th('fontSizeBaseSmall')};
  margin-left: ${grid(2)};
`

const AuthorRow = props => {
  const {
    className,
    isDragging,
    innerRef,
    item,
    onClickRemoveRow,
    onClickEditRow,
    ...rest
  } = props

  const {
    id,
    firstName,
    lastName,
    credit,
    affiliations,
    email,
    orcid,
    correspondingAuthor,
    submittingAuthor,
    equalContribution,
    error,
  } = item

  const err =
    typeof error === 'string'
      ? error
      : get(error, 'firstName') ||
        get(error, 'lastName') ||
        get(error, 'credit') ||
        get(error, 'affiliations') ||
        get(error, 'email')

  return (
    <Wrapper
      className={className}
      isDragging={isDragging}
      ref={innerRef}
      {...rest}
    >
      <TopRow>
        <LeftSide>
          {error && <ErrorWrapper>Error: {err}</ErrorWrapper>}
          <LineWrapper>
            <LineLabel>Name:</LineLabel>
            {firstName} {lastName}
          </LineWrapper>
          <LineWrapper>
            <LineLabel>Affiliations:</LineLabel>
            {affiliations.join(', ')}{' '}
          </LineWrapper>
          <LineWrapper>
            <LineLabel>Email:</LineLabel>
            {email}
          </LineWrapper>
          {orcid && (
            <LineWrapper>
              {orcid && (
                <>
                  <LineLabel>ORCiD:</LineLabel>
                  {orcid}
                </>
              )}
            </LineWrapper>
          )}
          <LineWrapper>
            <LineLabel>CRediT:</LineLabel>
            {credit && credit.map(c => startCase(c)).join(', ')}
          </LineWrapper>
          <StatusWrapper>
            {correspondingAuthor ? (
              <StyledStatus>Corresponding</StyledStatus>
            ) : (
              ''
            )}
            {submittingAuthor ? <StyledStatus>Submitting</StyledStatus> : ''}
            {equalContribution ? (
              <StyledStatus>Equal Contribution</StyledStatus>
            ) : (
              ''
            )}
          </StatusWrapper>
        </LeftSide>

        <RightSide>
          <ActionGroup>
            <Action onClick={() => onClickEditRow(id)}>
              <Icon>edit-2</Icon>
            </Action>
            <Action onClick={() => onClickRemoveRow(id)}>
              <Icon>trash-2</Icon>
            </Action>
          </ActionGroup>
        </RightSide>
      </TopRow>
    </Wrapper>
  )
}

AuthorRow.propTypes = {
  onClickRemoveRow: PropTypes.func,
  onClickEditRow: PropTypes.func,
  /** Row's innerRef. For use by dnd */
  innerRef: PropTypes.func.isRequired,
  /** Whether this row is being dragged */
  isDragging: PropTypes.bool.isRequired,
  /** The item to be displayed in the row */
  item: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    credit: PropTypes.arrayOf(PropTypes.string),
    affiliations: PropTypes.arrayOf(PropTypes.string),
    email: PropTypes.string,
    orcid: PropTypes.string,
    correspondingAuthor: PropTypes.bool,
    submittingAuthor: PropTypes.bool,
    equalContribution: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
}

AuthorRow.defaultProps = {
  onClickRemoveRow: null,
  onClickEditRow: null,
}

export default AuthorRow
