import React from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation } from '@apollo/client'

import {
  SCIENCE_OFFICER_PANEL,
  SCIENCE_OFFICER_PREVIEW,
  SEND_CHAT,
  SAVE_REVIEW,
  SUBMIT_REVIEW,
  CREATE_SO_REVIEW,
} from '../../graphql'
import { DateParser, EditorPanel, SyncedTabs } from '../../../ui'

import {
  getFromStorage,
  getManuscriptTeam,
  getReviewerCounts,
  getSubmittingAuthor,
  saveToStorage,
  transformChatMessages,
  transformReviews,
} from '../_helpers/common'

import { ArticlePreview } from '../../components/ui'
import { exportManuscriptToPDF } from '../../fetch/exportManuscript'

/* eslint-disable-next-line react/prop-types */
const Label = ({ created, index }) => (
  <DateParser dateFormat="MM.DD.YY HH:mm" timestamp={new Date(Number(created))}>
    {timestamp => (
      <span>
        {index === 0
          ? `Original: ${timestamp}`
          : `Revision ${index}: ${timestamp}`}
      </span>
    )}
  </DateParser>
)

const ScienceOfficerView = props => {
  const { manuscriptId } = props

  /**
   * Queries & mutations
   */

  const { data: previewData, loading: previewLoading } = useQuery(
    SCIENCE_OFFICER_PREVIEW,
    {
      variables: { id: manuscriptId },
    },
  )

  const { data: panelData, loading: panelLoading } = useQuery(
    SCIENCE_OFFICER_PANEL,
    {
      variables: { id: manuscriptId },
    },
  )

  const refetchQuery = {
    refetchQueries: [
      {
        query: SCIENCE_OFFICER_PANEL,
        variables: { id: manuscriptId },
      },
    ],
  }

  const [saveReviewMutation] = useMutation(SAVE_REVIEW, refetchQuery)
  const [submitReviewMutation] = useMutation(SUBMIT_REVIEW, refetchQuery)

  const [sendChatMutation] = useMutation(SEND_CHAT, refetchQuery)
  const [createSOReviewMutation] = useMutation(CREATE_SO_REVIEW, refetchQuery)

  /**
   * Left side: preview
   */

  let leftSections

  if (
    previewData &&
    previewData.manuscript &&
    previewData.manuscript.versions
  ) {
    const filteredVersions = previewData.manuscript.versions.filter(
      v =>
        (!v.submitted &&
          previewData.manuscript.isInitiallySubmitted &&
          !previewData.manuscript.isDataTypeSelected) ||
        v.submitted,
    )

    leftSections = filteredVersions.map((version, index) => ({
      key: version.id,
      label: <Label created={version.created} index={index} />,
      content: (
        <ArticlePreview
          article={version}
          categories={previewData.manuscript.categories}
          exportManuscript={exportManuscriptToPDF}
          manuscriptId={manuscriptId}
          previousVersion={filteredVersions[index - 1]}
          showAdditionalData
          showHeader={false}
        />
      ),
    }))
  }

  /**
   * Right side: science officer panel
   */

  let rightSections

  if (!panelLoading && panelData) {
    const { manuscript } = panelData
    const { chatThreads, dataType, versions } = panelData.manuscript

    const scienceOfficerChat = chatThreads.find(
      t => t.chatType === 'scienceOfficer',
    )

    const scienceOfficerChatMessages = transformChatMessages(
      scienceOfficerChat.messages,
    )

    const sendChat = content =>
      sendChatMutation({
        variables: {
          input: {
            chatThreadId: scienceOfficerChat.id,
            content,
          },
        },
      })

    const saveChat = message =>
      saveToStorage(message, `chat_${scienceOfficerChat.id}`)

    const getSavedChat = () => getFromStorage(`chat_${scienceOfficerChat.id}`)

    const filteredVersions = versions.filter(
      v =>
        (!v.submitted &&
          previewData.manuscript.isInitiallySubmitted &&
          !previewData.manuscript.isDataTypeSelected) ||
        v.submitted,
    )

    const {
      curator: assignedCurator,
      editor: assignedEditor,
      scienceOfficer: assignedScienceOfficer,
      sectionEditor: assignedSectionEditor,
    } = getManuscriptTeam(manuscript.teams)

    rightSections = filteredVersions.map((version, index) => {
      const {
        authors,
        created,
        decision,
        decisionLetter,
        id,
        reviews,
        teams,
      } = version

      const latest = index === filteredVersions.length - 1

      const { authorEmail, authorName } = getSubmittingAuthor(authors)
      const reviewerCounts = getReviewerCounts(teams)
      const versionReviews = transformReviews(reviews)

      const curatorNames =
        assignedCurator && assignedCurator.map(c => c.displayName)

      const editorName = assignedEditor && assignedEditor.displayName

      const sectionEditorName =
        assignedSectionEditor && assignedSectionEditor.displayName

      const scienceOfficerName =
        assignedScienceOfficer && assignedScienceOfficer.displayName

      let saveReview, submitReview, submitted, review

      if (reviews && reviews.length > 0) {
        const thisReview = reviews[0]
        submitted = thisReview.status.submitted

        review = {
          content: thisReview.content,
          recommendation: thisReview.recommendation,
          openAcknowledgement: thisReview.openAcknowledgement,
          askedToSeeRevision: thisReview.askedToSeeRevision,
          confidentialComments: thisReview.confidentialComments,
          reviseQualifier: thisReview.reviseQualifier,
        }

        if (latest) {
          saveReview = input =>
            saveReviewMutation({
              variables: {
                reviewId: thisReview.id,
                input,
              },
            })

          submitReview = input => {
            submitReviewMutation({
              variables: {
                reviewId: thisReview.id,
                input,
              },
            })
          }
        }
      }

      const createSOReview = () =>
        createSOReviewMutation({ variables: { versionId: version.id } })

      return {
        key: id,
        label: <Label created={created} index={index} />,
        content: (
          <EditorPanel
            acceptedReviewersCount={reviewerCounts.accepted}
            authorEmail={authorEmail}
            authorName={authorName}
            createSOReview={createSOReview}
            curatorName={curatorNames}
            dataType={dataType}
            decision={decision}
            decisionLetter={decisionLetter}
            decisionSubmitted={!!decision}
            editorName={editorName}
            getSavedSOChat={getSavedChat}
            invitedReviewersCount={reviewerCounts.invited}
            key={version.id}
            rejectedReviewersCount={reviewerCounts.rejected}
            reviews={versionReviews}
            saveSOChat={saveChat}
            saveSOReview={saveReview}
            scienceOfficerChatMessages={scienceOfficerChatMessages}
            scienceOfficerName={scienceOfficerName}
            sectionEditorName={sectionEditorName}
            sendScienceOfficerChatMessage={sendChat}
            showDecision={!!decision}
            showInfo={latest}
            showReviews
            showScienceOfficerChat={latest}
            // startDataTypeExpanded
            // startDecisionExpanded
            // startChatExpanded
            // startReviewsExpanded
            showSubmitReview={latest}
            soReview={review}
            soReviewSubmitted={submitted}
            submitSOReview={submitReview}
          />
        ),
      }
    })
  }

  /**
   * Render
   */

  return (
    <SyncedTabs
      leftHeader="Article Preview"
      leftLoading={previewLoading}
      leftSections={leftSections}
      rightHeader="Science Officer Panel"
      rightLoading={panelLoading}
      rightSections={rightSections}
    />
  )
}

ScienceOfficerView.propTypes = {
  manuscriptId: PropTypes.string.isRequired,
}

export default ScienceOfficerView
