import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import { H4, Select } from '../common'

const Wrapper = styled.div`
  padding-bottom: calc(${th('gridUnit')} * 2);
`

const Header = styled(H4)`
  font-size: ${th('fontSizeHeading4')};
  line-height: ${th('lineHeightHeading3')};
  margin: 0 auto ${th('gridUnit')};
`

const mapToSelectValues = items =>
  items &&
  items.map(item => ({
    label: item.displayName,
    value: item.id,
  }))

const TeamSection = props => {
  const { displayName, handleChange, name, users, value } = props

  const options = mapToSelectValues(users)
  const selectValue = mapToSelectValues(value)

  const onChange = vals => {
    const transform = vals.map(val => ({
      id: val.value,
      displayName: val.label,
    }))

    handleChange(transform)
  }

  return (
    <Wrapper>
      <Header>{displayName}</Header>
      <Select
        closeMenuOnSelect={false}
        isMulti
        name={name}
        onChange={onChange}
        options={options}
        value={selectValue}
      />
    </Wrapper>
  )
}

TeamSection.propTypes = {
  /** Name of team to display above select */
  displayName: PropTypes.string.isRequired,
  /** Function to run on change of values (send data to form) */
  handleChange: PropTypes.func.isRequired,
  /** Name that will be used by form to identify input */
  name: PropTypes.string.isRequired,
  /** List of users to choose from */
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /** Value of select input */
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }),
  ),
}

TeamSection.defaultProps = {
  value: [],
}

export default TeamSection
