import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import uniqueId from 'lodash/uniqueId'

import { H5 } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import { transformGeneExpressionData, unCamelCase } from './_helpers'

import ObserveExpression from './ObserveExpression'
import Separator from './Separator'
import TextBlock from './TextBlock'
import TextBlockList from './TextBlockList'

const Wrapper = styled.div`
  > div {
    margin-top: ${th('gridUnit')};
  }
`

const Header = styled(H5)`
  color: ${th('colorText')};
  margin: 0;
`

const GeneExpression = props => {
  const { data, previousData, showDiff, showRemoved } = props
  if (!data) return null

  const {
    detectionMethod,
    expressionPattern,
    observeExpression,
    species,
  } = data

  const geneExpressionData = transformGeneExpressionData(data, previousData)

  return (
    <Wrapper>
      <Header>Gene Expression Data</Header>

      <TextBlock
        label="species"
        previousValue={get(previousData, 'species.name')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={get(species, 'name')}
        variant="metadata"
      />

      <TextBlock
        label="expression pattern for gene"
        previousValue={get(previousData, 'expressionPattern.name')}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={get(expressionPattern, 'name')}
        variant="metadata"
      />

      <TextBlock
        label="detection method"
        previousValue={unCamelCase(get(previousData, 'detectionMethod'))}
        showDiff={showDiff}
        showRemoved={showRemoved}
        value={unCamelCase(detectionMethod)}
        variant="metadata"
      />

      {geneExpressionData.map(item => {
        if (Array.isArray(item.displayValue))
          return (
            <TextBlockList
              key={uniqueId()}
              label={item.label}
              previousValues={item.previousDisplayValue}
              showDiff={showDiff}
              showRemoved={showRemoved}
              values={item.displayValue}
              variant="metadata"
            />
          )

        return (
          <TextBlock
            key={uniqueId()}
            label={item.label}
            previousValue={item.previousDisplayValue}
            showDiff={showDiff}
            showRemoved={showRemoved}
            value={item.displayValue}
            variant="metadata"
          />
        )
      })}

      {observeExpression && (
        <>
          <Separator />
          <ObserveExpression
            data={observeExpression}
            previousData={get(previousData, 'observeExpression')}
            showDiff={showDiff}
            showRemoved={showRemoved}
          />
        </>
      )}
    </Wrapper>
  )
}

GeneExpression.propTypes = {
  /** Gene expression form data */
  data: PropTypes.shape({
    antibodyUsed: PropTypes.string,
    backboneVector: PropTypes.shape({
      name: PropTypes.string,
    }),
    coinjected: PropTypes.string,
    constructComments: PropTypes.string,
    detectionMethod: PropTypes.string,
    dnaSequence: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    expressionPattern: PropTypes.shape({
      name: PropTypes.string,
    }),
    fusionType: PropTypes.shape({
      name: PropTypes.string,
    }),
    genotype: PropTypes.string,
    injectionConcentration: PropTypes.string,
    inSituDetails: PropTypes.string,
    integratedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    reporter: PropTypes.shape({
      name: PropTypes.string,
    }),
    species: PropTypes.shape({
      name: PropTypes.string,
    }),
    strain: PropTypes.string,
    transgeneName: PropTypes.string,
    transgeneUsed: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    utr: PropTypes.shape({
      name: PropTypes.string,
    }),
    variation: PropTypes.shape({
      name: PropTypes.string,
    }),
    observeExpression: PropTypes.shape({
      certainly: PropTypes.arrayOf(
        PropTypes.shape({
          certainly: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      possibly: PropTypes.arrayOf(
        PropTypes.shape({
          possibly: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      probably: PropTypes.arrayOf(
        PropTypes.shape({
          probably: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      not: PropTypes.arrayOf(
        PropTypes.shape({
          not: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  /** Same as data, but previous version. For diffs. */
  previousData: PropTypes.shape({
    antibodyUsed: PropTypes.string,
    backboneVector: PropTypes.shape({
      name: PropTypes.string,
    }),
    coinjected: PropTypes.string,
    constructComments: PropTypes.string,
    detectionMethod: PropTypes.string,
    dnaSequence: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    expressionPattern: PropTypes.shape({
      name: PropTypes.string,
    }),
    fusionType: PropTypes.shape({
      name: PropTypes.string,
    }),
    genotype: PropTypes.string,
    injectionConcentration: PropTypes.string,
    inSituDetails: PropTypes.string,
    integratedBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    reporter: PropTypes.shape({
      name: PropTypes.string,
    }),
    species: PropTypes.shape({
      name: PropTypes.string,
    }),
    strain: PropTypes.string,
    transgeneName: PropTypes.string,
    transgeneUsed: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    utr: PropTypes.shape({
      name: PropTypes.string,
    }),
    variation: PropTypes.shape({
      name: PropTypes.string,
    }),
    observeExpression: PropTypes.shape({
      certainly: PropTypes.arrayOf(
        PropTypes.shape({
          certainly: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      possibly: PropTypes.arrayOf(
        PropTypes.shape({
          possibly: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      probably: PropTypes.arrayOf(
        PropTypes.shape({
          probably: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
      not: PropTypes.arrayOf(
        PropTypes.shape({
          not: PropTypes.shape({
            name: PropTypes.string,
          }),
          during: PropTypes.shape({
            name: PropTypes.string,
          }),
          subcellularLocalization: PropTypes.shape({
            name: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  /** Whether to show diffs at all */
  showDiff: PropTypes.bool,
  /** Whether to show diff removals */
  showRemoved: PropTypes.bool,
}

GeneExpression.defaultProps = {
  data: null,
  previousData: null,
  showDiff: true,
  showRemoved: true,
}

export default GeneExpression
