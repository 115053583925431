/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

import React from 'react'

import DialogModal from './DialogModal'

const InfoModal = props => (
  <DialogModal
    onConfirm={props.onRequestClose}
    showCancelButton={false}
    {...props}
  />
)

export default InfoModal
