/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import startCase from 'lodash/startCase'

import { Action, ActionGroup, Icon } from '@pubsweet/ui'
import { th, grid } from '../_helpers'

const portal = document.createElement('div')
if (!document.body) throw new Error('body not ready for portal creation!')
document.body.appendChild(portal)

const Wrapper = styled.div`
  border: 1px solid gray;
  margin: 10px;
  padding: 8px;

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`

const EmptyMessage = styled.div`
  color: ${th('colorTextPlaceholder')};
  font-size: ${th('fontSizeBaseSmall')};
  font-style: italic;
`

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftSide = styled.div`
  width: 85%;
`

const RightSide = styled.div`
  display: flex;
  flex-direction: row;
`

const LineWrapper = styled.div`
  display: inline-block;
  font-size: ${th('fontSizeBaseSmall')};
  margin-left: ${grid(2)};
`

const LineLabel = styled.span`
  color: ${th('colorPrimary')};
  margin-right: ${grid(1)};
  text-transform: uppercase;
`

const DefaultComponent = props => {
  const {
    className,
    isDragging,
    innerRef,
    item,
    onClickRemoveRow,
    onClickEditRow,
    ...rest
  } = props

  return (
    <Wrapper
      className={className}
      isDragging={isDragging}
      ref={innerRef}
      {...rest}
    >
      <TopRow>
        <LeftSide>
          {Object.keys(item).map(key => {
            if (key === '__typename') return null
            if (key === 'id') return null
            return (
              <LineWrapper key={`${key}-${item.id}`}>
                <LineLabel>{startCase(key)}:</LineLabel> {item[key]}
              </LineWrapper>
            )
          })}
        </LeftSide>

        <RightSide>
          <ActionGroup>
            {onClickEditRow && (
              <Action onClick={() => onClickEditRow(item.id)}>
                <Icon>edit-2</Icon>
              </Action>
            )}
            {onClickRemoveRow && (
              <Action onClick={() => onClickRemoveRow(item.id)}>
                <Icon>trash-2</Icon>
              </Action>
            )}
          </ActionGroup>
        </RightSide>
      </TopRow>
    </Wrapper>
  )
}

const Row = props => {
  const {
    canAddMore,
    item,
    provided,
    snapshot,
    component: Component,
    ...rest
  } = props

  const { innerRef, draggableProps, dragHandleProps } = provided
  const { isDragging } = snapshot

  const child = (
    <Component
      canAddMore={canAddMore}
      innerRef={innerRef}
      isDragging={isDragging}
      item={item}
      {...draggableProps}
      {...dragHandleProps}
      {...rest}
    />
  )

  if (!isDragging) return child
  return ReactDOM.createPortal(child, portal)
}

const DndList = props => {
  const {
    canAddMore,
    onReorder,
    items,
    component,
    onClickRemoveRow,
    onClickEditRow,
    emptyMessage,
  } = props

  const onDragEnd = result => {
    const { source, destination } = result
    if (!destination) return
    const list = items.slice(0) // shallow clone

    const [removed] = list.splice(source.index, 1)
    list.splice(destination.index, 0, removed)

    onReorder(list)
  }

  if (items.length === 0)
    return (
      <Wrapper>
        <EmptyMessage>{emptyMessage}</EmptyMessage>
      </Wrapper>
    )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="item-list">
        {providedDroppable => (
          <Wrapper
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
          >
            {items.map((item, index) => (
              <Draggable draggableId={item.id} index={index} key={item.id}>
                {(providedDraggable, snapshotDraggable) => (
                  <Row
                    canAddMore={canAddMore}
                    component={component}
                    item={item}
                    onClickEditRow={onClickEditRow}
                    onClickRemoveRow={onClickRemoveRow}
                    provided={providedDraggable}
                    snapshot={snapshotDraggable}
                  />
                )}
              </Draggable>
            ))}
            {providedDroppable.placeholder}
          </Wrapper>
        )}
      </Droppable>
    </DragDropContext>
  )
}

DefaultComponent.propTypes = {
  isDragging: PropTypes.bool,
  innerRef: PropTypes.func,
  item: PropTypes.shape({ id: PropTypes.string }),
  onClickRemoveRow: PropTypes.func,
  onClickEditRow: PropTypes.func,
}

DefaultComponent.defaultProps = {
  isDragging: false,
  innerRef: null,
  item: null,
  onClickEditRow: null,
  onClickRemoveRow: null,
}

DndList.propTypes = {
  canAddMore: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
  onReorder: PropTypes.func.isRequired,
  component: PropTypes.node,
  onClickRemoveRow: PropTypes.func,
  onClickEditRow: PropTypes.func,
  emptyMessage: PropTypes.string,
}

DndList.defaultProps = {
  canAddMore: true,
  items: [],
  onClickRemoveRow: null,
  onClickEditRow: null,
  component: DefaultComponent,
  emptyMessage: 'No items have been added to the list',
}

export default DndList
