/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import uniqBy from 'lodash/uniqBy'

import { th, darken } from '@pubsweet/ui-toolkit'

import {
  Dropdown,
  Select,
  TextField,
  Icon,
  Button,
  Accordion,
  TextEditor,
} from '../common'
import EntityList from './EntityList'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1rem;
`

const HelpButton = styled.div`
  cursor: pointer;

  svg {
    height: calc(${th('gridUnit')} * 2);
    stroke: ${th('colorFurniture')};
  }
`

const StyledButton = styled(Button)`
  background-color: ${props => props.background};
  font-style: ${props => props.fontStyle};
  min-width: 0;
  text-transform: none;
`

const Pink = styled.i`
  color: ${darken('colorError', 0.6)};
`

const StyledAccordion = styled(Accordion)`
  margin: calc(${th('gridUnit')} * 2) 0;
`

const LinkerMenu = props => {
  const {
    authorEntitiesComments,
    revert,
    exportEntities,
    fixGeneOntology,
    formLink,
    // getKeywordSets,
    isAuthor,
    keywordSets,
    linkHTML,
    getEntities,
    saveLinks,
    setEntities,
    entities,
    updatePredictionTable,
    searchAlliance,
    newEntity,
    onClickShowKwsDescriptions,
    dbReferenceId,
    setNewEntity,
    setShowPreviewModal,
    // tableUpdated,
    setEntitiesComments,
    setShowResetLinks,
  } = props

  const [entityType, setEntityType] = useState()
  const [sourceId, setSourceId] = useState()
  const [source, setEntitySource] = useState()
  const [sourceUrl, setSourceUrl] = useState()

  const menu = [
    { id: '1', onClick: () => revert(), title: 'Revert' },
    { id: '2', onClick: () => exportEntities(), title: 'Download' },
    {
      id: '3',
      onClick: () => saveLinks(),
      title: 'Save to version',
    },
  ]

  // const getOptionValue = option => option.uuid
  // const getOptionLabel = option => option.name.replace('_', ' ')

  /*
  const loadOptions = async () =>
    getKeywordSets()
      .then(res => res.json())
      .then(
        result => result,
        error => {
          // eslint-disable-next-line no-console
          console.log(error)
        },
      )
      */

  const changeKWS = kws => {
    // setLoading(true)
    getEntities(kws.value)
      .then(res => res.json())
      .then(async result => {
        const sortedEntities = result.sort((a, b) => {
          if (a.match.length < b.match.length) return 1
          if (a.match.length > b.match.length) return -1
          return 0
        })

        const fixedEntities = fixGeneOntology(sortedEntities)
        const predictions = await updatePredictionTable(fixedEntities)

        const filteredPredictions = predictions.filter(
          p => fixedEntities.findIndex(e => e.match === p.match) === -1,
        )

        const combinedEntities = await linkHTML(
          uniqBy(
            [...fixedEntities, ...entities, ...filteredPredictions],
            e => e.match,
          ),
        )

        setEntities(combinedEntities)

        // setLoading(false)
      })
  }

  const sources = [
    { label: 'WormBase', value: 'WormBase' },
    { label: 'FlyBase', value: 'FlyBase' },
    { label: 'SGD', value: 'SGD' },
    { label: 'TAIR', value: 'TAIR' },
    { label: 'PomBase', value: 'PomBase' },
    { label: 'ZFIN', value: 'ZFIN' },
    { label: 'MGI', value: 'MGI' },
    { label: 'RGD', value: 'RGD' },
    { label: 'HUGO', value: 'HUGO' },
    { label: 'NCBI', value: 'NCBI' },
  ]

  const urlConstructors = {
    WormBase: {
      GENE: 'https://wormbase.org/species/c_elegans/gene/',
      PROTEIN: 'https://wormbase.org/species/c_elegans/protein/',
      VARIANT: 'https://wormbase.org/species/c_elegans/variation/',
      TRANSGENE: 'https://wormbase.org/species/c_elegans/transgene/',
      STRAIN: 'https://wormbase.org/species/c_elegans/strain/',
      ANTIBODY: 'https://wormbase.org/species/c_elegans/antibody',
      DISEASE: 'https://wormbase.org/species/c_elegans/disease/',
      GENOTYPE: 'https://wormbase.org/species/c_elegans/genotype/',
      MOLECULE: 'https://wormbase.org/species/c_elegans/molecule/',
      PHENOTYPE: 'https://wormbase.org/species/c_elegans/phenotype/',
      CONSTRUCT: 'https://wormbase.org/species/c_elegans/construct/',
      REARRANGEMENT: 'https://wormbase.org/species/c_elegans/rearrangement/',
    },
    NCBI: {
      SPECIES:
        'https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?mode=Info&id=',
    },
    PomBase: {
      GENE: 'https://www.pombase.org/spombe/result/',
      PROTEIN: 'https://www.pombase.org/spombe/result/',
    },
    RGD: {
      GENE: 'https://rgd.mcw.edu/rgdweb/report/gene/main.html?id=',
      STRAIN: 'https://rgd.mcw.edu/rgdweb/report/strain/main.html?id=',
    },
  }

  const entityTypes = [
    {
      label: 'GENE',
      value: 'GENE',
    },
    {
      label: 'PROTEIN',
      value: 'PROTEIN',
    },
    {
      label: 'VARIANT',
      value: 'VARIANT',
    },
    {
      label: 'TRANSGENE',
      value: 'TRANSGENE',
    },
    {
      label: 'STRAIN',
      value: 'STRAIN',
    },
    {
      label: 'ANATOMY',
      value: 'ANATOMY',
    },
    {
      label: 'LIFESTAGE',
      value: 'LIFESTAGE',
    },
    {
      label: 'ANTIBODY',
      value: 'ANTIBODY',
    },
    {
      label: 'DISEASE',
      value: 'DISEASE',
    },
    {
      label: 'FISH',
      value: 'FISH',
    },
    {
      label: 'GENOTYPE',
      value: 'GENOTYPE',
    },
    {
      label: 'METHOD',
      value: 'METHOD',
    },
    {
      label: 'MOLECULE',
      value: 'MOLECULE',
    },
    {
      label: 'PHENOTYPE',
      value: 'PHENOTYPE',
    },
    {
      label: 'ABBERATION',
      value: 'ABBERATION',
    },
    {
      label: 'CONSTRUCT',
      value: 'CONSTRUCT',
    },
    {
      label: 'REARRANGEMENT',
      value: 'REARRANGEMENT',
    },
    {
      label: 'BALANCER',
      value: 'BALANCER',
    },
    {
      label: 'SPECIES',
      value: 'SPECIES',
    },
  ]

  const setNewEntitySource = selectedSource => {
    setEntitySource(selectedSource.value)

    if (
      selectedSource.value in urlConstructors &&
      entityType in urlConstructors[selectedSource.value]
    ) {
      setSourceUrl(urlConstructors[selectedSource.value][entityType])
    }
  }

  const setNewEntityType = async selectedType => {
    const { value } = selectedType
    setEntityType(value)

    if (source in urlConstructors && value in urlConstructors[source]) {
      setSourceUrl(urlConstructors[source][value])
    }

    if (newEntity) {
      const query = value === 'PROTEIN' ? newEntity.toLowerCase() : newEntity

      const category = {
        GENE: 'gene',
        VARIANT: 'allele',
        PROTEIN: 'gene',
        STRAIN: 'model',
        TRANSGENE: 'allele',
      }

      if (category[value]) {
        await searchAlliance(query, category[value])
          .then(res => res.json())
          .then(results => {
            if (
              results.results.length > 0 &&
              results.results[0].name === newEntity.trim()
            ) {
              const newSourceId = results.results[0].primaryKey.split(':').pop()
              setSourceId(newSourceId)

              if (
                source in urlConstructors &&
                value in urlConstructors[source]
              ) {
                setSourceUrl(`${urlConstructors[source][value]}${newSourceId}`)
              }
            }
          })
      }
    }
  }

  const setNewEntityUrl = event => {
    setSourceUrl(event.target.value)
  }

  const setNewEntitySourceId = event => {
    setSourceId(event.target.value)

    if (source in urlConstructors && entityType in urlConstructors[source]) {
      setSourceUrl(
        `${urlConstructors[source][entityType]}${event.target.value}`,
      )
    }
  }

  const saveNewEntity = () => {
    const entity = {
      match: newEntity,
      type: entityType,
      method: 'added',
      sourceId,
      source,
      url: sourceUrl,
    }

    linkHTML([entity, ...entities])
    setEntities([entity, ...entities])

    setEntityType(null)
    setEntitySource(null)
    setSourceId('')
    setSourceUrl('')
    setNewEntity('')
  }

  const backToForm = () => {
    // if (tableUpdated) {
    // confirm save
    // }
    formLink()
  }

  return (
    <>
      <Wrapper>
        <Row>
          {isAuthor && (
            <>
              <Button onClick={() => setShowPreviewModal(true)} primary>
                preview
              </Button>
              <Button onClick={backToForm} primary>
                Go Back to Submission Form
              </Button>
              <Button onClick={() => setShowResetLinks(true)} primary>
                Start From Scratch
              </Button>
            </>
          )}
          {!isAuthor && (
            <>
              <Dropdown itemsList={menu}>Menu</Dropdown>{' '}
              <Select
                // async
                defaultOptions
                //  getOptionLabel={getOptionLabel}
                //  getOptionValue={getOptionValue}
                // loadOptions={loadOptions}
                name="kws"
                onChange={changeKWS}
                options={keywordSets}
                placeholder="Select To Link..."
              />{' '}
              <HelpButton onClick={onClickShowKwsDescriptions}>
                <Icon title="Click for entity set descriptions...">
                  help_circle
                </Icon>
              </HelpButton>
            </>
          )}
          {dbReferenceId && `DB Reference ID: ${dbReferenceId}`}
        </Row>
        <StyledAccordion
          color={darken('colorError', 0.6)}
          label="Instructions - read me first"
        >
          <p>
            The Bioentity Spell-Checker has highlighted known entities (
            <StyledButton color={th('colorPrimary')}>blue</StyledButton>),
            predicted entities (
            <StyledButton color={th('colorSuccess')}>green</StyledButton>) and
            italicized entities (<Pink>magenta</Pink>). Please check these
            highlighted entities following the instructions below.
          </p>
          <p>
            <StyledButton color={th('colorPrimary')}>Blue</StyledButton>: Known
            bioentities in the database linked to their database page. If
            correct, no action is needed. If there are typos, return to the
            submission form to correct them and run the Bioentity Spell-checker
            again. If a bioentity shouldn&#39;t be highlighted, click on it and
            choose &#34;Remove highlighting for this instance?&#34; or
            &#34;Remove highlighting for all instances?&#34; if there are many.
            The bioentity should now be black and underlined, to restore the
            original highlighting and database hyperlink, click on it again.
          </p>
          <p>
            <StyledButton color={th('colorSuccess')}>Green</StyledButton>:{' '}
            Potential bioentity that should be added to the database. If
            correctly identified, leave it as is for the database curator. If
            there are typos, return to the submission form to correct them and
            run the Bioentity Spell-checker again. If a bioentity shouldn&#39;t
            be highlighted, click on it and choose &#34;Remove highlighting for
            this instance?&#34; or &#34;Remove highlighting for all
            instances?&#34; if there are many. The bioentity should now be black
            and underlined, to restore the original highlighting, click on it
            again.
          </p>
          <p>
            <Pink>Magenta</Pink>: Terms that are italicized and not linked to
            any database page. If a term should be highlighted in blue or green
            instead, return to the submission form to correct it and run the
            Bioentity Spell-checker again. If there are no issues then no action
            is needed.
          </p>
          <p>
            If a bioentity was not identified or linked but you want it to be,
            enter it in the &#34;
            <strong>Missed entities and additional feedback</strong>&#34;
            free-text box.
          </p>
          <p>
            NOTE: Your work will be autosaved. This tool can be used anytime you
            have editing access to your article.
          </p>
        </StyledAccordion>

        <StyledAccordion label="Missed entities and additional feedback">
          <TextEditor
            name="authorEntitiesComments"
            placeholder="Enter bioentities that were missed by our spell-checker along with any feedback you would like to give."
            readOnly={!isAuthor}
            setFieldTouched={() => undefined}
            setFieldValue={setEntitiesComments}
            value={authorEntitiesComments}
          />
        </StyledAccordion>
      </Wrapper>
      {!isAuthor && (
        <Accordion label="Add New">
          <TextField
            placeholder="Highlight the entity in the manuscript"
            readOnly
            value={newEntity && newEntity.trim()}
          />
          <Select
            onChange={setNewEntitySource}
            options={sources}
            placeholder="Select Source..."
            value={source ? { value: source, label: source } : null}
          />
          <Select
            onChange={setNewEntityType}
            options={entityTypes}
            placeholder="Select Type..."
            value={entityType ? { value: entityType, label: entityType } : null}
          />
          <TextField
            onChange={setNewEntitySourceId}
            placeholder="Source ID"
            value={sourceId}
          />
          <TextField
            onChange={setNewEntityUrl}
            placeholder="Source URL"
            value={sourceUrl}
          />
          <Button
            disabled={!newEntity || !entityType}
            ghost
            icon="plus"
            onClick={saveNewEntity}
            size="small"
          >
            <Icon primary size={2}>
              plus
            </Icon>
            Add
          </Button>
        </Accordion>
      )}
    </>
  )
}

const LinkerDetails = props => {
  const {
    authorEntitiesComments,
    savedEntities,
    deleteLinks,
    exportEntities,
    // getKeywordSets,
    isAuthor,
    keywordSets,
    linkHTML,
    getEntities,
    setEntities,
    predictEntities,
    saveLinks,
    searchAlliance,
    setEntitiesComments,
    setHighlightEntity,
    setShowPreviewModal,
    newEntity,
    fixGeneOntology,
    updatePredictionTable,
    onClickShowKwsDescriptions,
    dbReferenceId,
    setShowResetLinks,
    setNewEntity,
    ncbiAccessionLookup,
    highlightEntity,
    tableUpdated,
    formLink,
    revert,
  } = props

  return (
    <>
      <LinkerMenu
        authorEntitiesComments={authorEntitiesComments}
        dbReferenceId={dbReferenceId}
        entities={savedEntities}
        exportEntities={exportEntities}
        fixGeneOntology={fixGeneOntology}
        formLink={formLink}
        // getKeywordSets={getKeywordSets}
        getEntities={getEntities}
        isAuthor={isAuthor}
        keywordSets={keywordSets}
        linkHTML={linkHTML}
        newEntity={newEntity}
        onClickShowKwsDescriptions={onClickShowKwsDescriptions}
        predictEntities={predictEntities}
        revert={revert}
        saveLinks={saveLinks}
        searchAlliance={searchAlliance}
        setEntities={setEntities}
        setEntitiesComments={setEntitiesComments}
        setNewEntity={setNewEntity}
        setShowPreviewModal={setShowPreviewModal}
        setShowResetLinks={setShowResetLinks}
        tableUpdated={tableUpdated}
        updatePredictionTable={updatePredictionTable}
      />
      <StyledAccordion
        label="Table of Entities highlighted in your paper"
        title="The table lists known entities (with an ID), and predicted entities (no ID)."
      >
        <p>
          Bioentities in blue exist in your community database with the
          associated ID. Bioentities in green have been predicted based on
          community nomenclature rules. Please check all for accuracy. See
          Instructions for more information.
        </p>
        <EntityList
          deleteLinks={deleteLinks}
          entities={savedEntities}
          highlightEntity={highlightEntity}
          ncbiAccessionLookup={ncbiAccessionLookup}
          setHighlightEntity={setHighlightEntity}
        />
      </StyledAccordion>
    </>
  )
}

export default LinkerDetails
