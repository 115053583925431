import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'

import { grid } from '../_helpers'
import { Button, Form } from '../common'

const Wrapper = styled.div`
  max-width: ${grid(35)};
`

const SubmitButton = styled(Button)`
  margin-top: ${grid(2)};
`

const options = [
  {
    label: 'Gene expression results',
    value: 'geneExpression',
    isDisabled: true,
  },
  {
    label: 'No Datatype',
    value: 'noDatatype',
  },
]

const initialValues = {
  dataType: 'noDatatype',
}

const validations = yup.object().shape({
  dataType: yup.string().required('You need to select a datatype'),
})

const findSelected = value => options.find(option => option.value === value)

const Datatype = props => {
  const {
    className,
    dataType,
    onClickSetDataType,
    showDataTypeSelection,
  } = props

  const handleSubmit = values => {
    const selected = findSelected(values.dataType)
    onClickSetDataType(selected)
  }

  return (
    <Wrapper className={className}>
      {!dataType && showDataTypeSelection && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => (
            <>
              <input name="dataType" type="hidden" value="noDatatype" />
              <SubmitButton primary type="submit">
                Initiate Editorial Review
              </SubmitButton>
              <ul>
                <li>Notify author it is in the hands of an editor</li>
                <li>Upload to iThenticate</li>
                <li>Set DOI</li>
                <li>Create entity table</li>
              </ul>
            </>
          )}
        </Form>
      )}
    </Wrapper>
  )
}

Datatype.propTypes = {
  dataType: PropTypes.string,
  onClickSetDataType: PropTypes.func,
  showDataTypeSelection: PropTypes.bool,
}

Datatype.defaultProps = {
  dataType: null,
  onClickSetDataType: null,
  showDataTypeSelection: false,
}

export default Datatype
