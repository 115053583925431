/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

import { SectionItem } from '../../../app/components/ui'
import { Action, ActionGroup } from '../common'

const StatusRowWrapper = styled.div`
  display: flex;
`

const dotAfter = css`
  &:after {
    color: ${th('colorBorder')};
    content: '\\2022';
    padding: 0 ${th('gridUnit')};
  }
`

const NameWrapper = styled.div`
  font-size: ${th('fontSizeBaseSmall')};
  ${props => !props.last && dotAfter}
`

const Label = styled.span`
  color: ${th('colorBorder')};
  margin-right: calc(${th('gridUnit')} / 2);
  text-transform: capitalize;

  &:after {
    content: ':';
  }
`

const RowWrapper = styled.div`
  border-bottom: ${th('borderWidth')} ${th('borderStyle')}
    ${th('colorFurniture')};
  padding: 4px 0;
`

const DashboardName = props => {
  const { last, name, label } = props

  return (
    name && (
      <NameWrapper last={last}>
        <Label>{label}</Label>
        {name}
      </NameWrapper>
    )
  )
}

const StatusRow = props => {
  const { dueDate, submittingAuthor, correspondingAuthor } = props

  const submittingAuthorValue = submittingAuthor
    ? `${submittingAuthor.firstName} ${submittingAuthor.lastName} ${submittingAuthor.email}`
    : ''

  const correspondingAuthorValue = correspondingAuthor
    ? `${correspondingAuthor.firstName} ${correspondingAuthor.lastName} ${correspondingAuthor.email}`
    : ''

  return (
    <StatusRowWrapper>
      {dueDate && <DashboardName label="due date" name={dueDate} />}
      {submittingAuthor && (
        <DashboardName label="submitting author" name={submittingAuthorValue} />
      )}
      {correspondingAuthor && (
        <DashboardName
          label="corresponding author"
          name={correspondingAuthorValue}
        />
      )}
    </StatusRowWrapper>
  )
}

const BillingItem = item => {
  const {
    articleId,
    dueDate,
    title,
    submittingAuthor,
    correspondingAuthor,
    invoice,
  } = item

  const EditorPanel = (
    <ActionGroup>
      <Action to={invoice}>Invoice</Action>
      <Action to={`/article/${articleId}`}>Editor View</Action>
    </ActionGroup>
  )

  return (
    <RowWrapper>
      <StatusRow
        correspondingAuthor={correspondingAuthor}
        dueDate={dueDate}
        submittingAuthor={submittingAuthor}
      />
      <SectionItem rightComponent={EditorPanel} title={title} />
    </RowWrapper>
  )
}

export default BillingItem
