/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import styled, { css } from 'styled-components'

import { grid, th, stripHTML } from '../_helpers'
import SearchBox from '../assignReviewers/SearchBox'
import { Action, Icon, DndList } from '../common'

const draggingStyles = css`
  box-shadow: 0 0 0 2px ${th('colorPrimary')};
`

const Wrapper = styled.div`
  background: ${th('colorBackground')};
  box-shadow: 0 0 0 1px ${th('colorPrimary')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${grid(1)};
  transition: box-shadow 0.1s ease-in;

  &:hover {
    ${draggingStyles}
  }

  /* stylelint-disable-next-line order/order, order/properties-alphabetical-order */
  ${props => props.isDragging && draggingStyles}
`

const IconButton = styled(Icon)`
  &:hover {
    background: ${th('colorSecondary')};
    cursor: pointer;
  }
`

const IntegrationRow = props => {
  const {
    canAddMore,
    className,
    isDragging,
    innerRef,

    item,
    ...rest
  } = props

  const { label, onClickRemove, value } = item

  return (
    <Wrapper
      className={className}
      isDragging={isDragging}
      ref={innerRef}
      {...rest}
    >
      <>
        <Action target="_blank" to={`/article/${value}`}>
          {label}
        </Action>
      </>
      <>
        <IconButton onClick={onClickRemove} size={2}>
          x
        </IconButton>
      </>
    </Wrapper>
  )
}

const IntegrationsSection = props => {
  const {
    onClickIntegrationArticle,
    articleSearch,
    integrations,
    reorderIntegrations,
    removeIntegration,
  } = props

  const items = integrations
    ? integrations.map(i => {
        const { manuscriptId, title } = i
        return {
          id: manuscriptId,
          label: stripHTML(title),
          value: manuscriptId,
          onClickRemove: () => removeIntegration(manuscriptId),
        }
      })
    : []

  return (
    <>
      <SearchBox
        onClickOption={onClickIntegrationArticle}
        placeholder="Add an article to the list"
        search={articleSearch}
      />
      <DndList
        component={IntegrationRow}
        items={items}
        onReorder={reorderIntegrations}
      />
    </>
  )
}

export default IntegrationsSection
