import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'
import { usePagination } from '@pubsweet/ui/dist/molecules/Pagination'
import { IconButton } from '@pubsweet/ui'

import {
  Loader,
  PageHeader as DefaultPageHeader,
  List,
  TextField,
  Button,
  Select,
} from '../common'
import NewUserSectionItem from './UserSectionItem'

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const PageHeader = styled(DefaultPageHeader)`
  margin-block-end: ${th('gridUnit')};
`

const Line = styled.div`
  display: flex;
  justify-content: space-between;
`

const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

const UserManager = props => {
  const {
    loading,
    users,
    editUser,
    isEditor,
    openReviewData,
    nameSearch,
    setNameSearch,
    openArticleData,
    openRemindersData,
  } = props

  if (loading) return <Loader />

  const [filteredUsers, setFilteredUsers] = useState(users)
  const [selectedSpecies, setSelectedSpecies] = useState(null)
  const pagination = usePagination(filteredUsers, 10)

  const handleSearch = event => {
    const { value } = event.target
    pagination.toFirst()

    if (nameSearch && nameSearch.length > value.length) {
      setFilteredUsers(
        users.filter(
          u =>
            u.displayName &&
            u.displayName.toLowerCase().includes(value.toLowerCase()),
        ),
      )
    } else {
      setFilteredUsers(
        filteredUsers.filter(
          u =>
            u.displayName &&
            u.displayName.toLowerCase().includes(value.toLowerCase()),
        ),
      )
    }

    setNameSearch(value)
  }

  const resetSearch = () => {
    pagination.toFirst()
    setFilteredUsers(users)
    setNameSearch('')
    setSelectedSpecies(null)
  }

  const getSpeciesOptions = () => {
    const species = []

    users.forEach(user => {
      user.species.forEach(s => {
        if (!species.includes(s)) species.push(s)
      })
    })

    return species.map(s => ({ label: s, value: s }))
  }

  const handleSelect = value => {
    setSelectedSpecies(value)

    setFilteredUsers(
      filteredUsers.filter(u => u.species && u.species.includes(value.value)),
    )
  }

  const handleNext = () => pagination.nextPage()
  const handleFirst = () => pagination.toFirst()
  const handlePrev = () => pagination.prevPage()
  const handleLast = () => pagination.toLast()

  return (
    <PageWrapper>
      <PageHeader>User Manager</PageHeader>
      <Line>
        <TextField
          handleChange={handleSearch}
          name="search"
          placeholder="Name Search"
          value={nameSearch}
        />
        <Select
          onChange={handleSelect}
          options={getSpeciesOptions()}
          placeholder="Species Search..."
          value={selectedSpecies}
        />
        <Button onClick={resetSearch} primary>
          Reset Search
        </Button>
      </Line>

      <Controls>
        <IconButton
          disabled={pagination.page === 0}
          icon="chevrons-left"
          onClick={handleFirst}
          title="To First"
        />
        <IconButton
          disabled={pagination.page === 0}
          icon="chevron-left"
          onClick={handlePrev}
          title="Previous"
        />

        {`${pagination.page * pagination.itemsPerPage + 1} to ${
          pagination.hasMore
            ? pagination.itemsPerPage * (pagination.page + 1)
            : pagination.maxItems
        } out of ${filteredUsers.length}`}

        <IconButton
          disabled={!pagination.hasMore}
          icon="chevron-right"
          onClick={handleNext}
          title="Next"
        />
        <IconButton
          disabled={!pagination.hasMore}
          icon="chevrons-right"
          onClick={handleLast}
          title="To Last"
        />
      </Controls>
      <List
        component={NewUserSectionItem}
        editUser={editUser}
        isEditor={isEditor}
        items={pagination.paginatedItems}
        openArticleData={openArticleData}
        openRemindersData={openRemindersData}
        openReviewData={openReviewData}
      />
    </PageWrapper>
  )
}

UserManager.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The list of users to display */
  users: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  editUser: PropTypes.func,
  openReviewData: PropTypes.func,
  nameSearch: PropTypes.string,
  setNameSearch: PropTypes.func,
  openArticleData: PropTypes.func,
  openRemindersData: PropTypes.func,
  isEditor: PropTypes.bool,
}

UserManager.defaultProps = {
  loading: false,
  editUser: null,
  openReviewData: null,
  nameSearch: null,
  setNameSearch: null,
  openArticleData: null,
  openRemindersData: null,
  isEditor: false,
}

export default UserManager
