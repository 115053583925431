/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import WarningModal from './WarningModal'
import { Button } from '../common'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

const ConfirmResetLinks = props => {
  const { resetLinks, revertAndCancel, ...rest } = props

  if (!resetLinks) return null

  const [isSubmitted, setSubmitted] = useState(false)

  const handleConfirm = async e => {
    setSubmitted(true)
    resetLinks().then(() => setSubmitted(false))
  }

  return (
    <WarningModal headerText="Start from Scratch" justifyText {...rest}>
      <Col>
        Start over with the Bioentity-Checker on your article. Any edits you
        made to the links will be lost.
        <Row>
          <Button onClick={() => revertAndCancel()} outline>
            remove highlighting and go back to submission form
          </Button>
          <Button loading={isSubmitted} onClick={() => handleConfirm()} outline>
            start from scratch
          </Button>
        </Row>
      </Col>
    </WarningModal>
  )
}

ConfirmResetLinks.propTypes = {
  resetLinks: PropTypes.func.isRequired,
  revertAndCancel: PropTypes.func.isRequired,
}

export default ConfirmResetLinks
