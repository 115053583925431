/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'

import { th } from '@pubsweet/ui-toolkit'

import {
  PageHeader,
  Form,
  Radio,
  TextField,
  Button,
  Loader,
  H4,
} from '../common'

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
`

const Error = styled.span`
  color: ${th('colorError')};
  padding-left: ${th('gridUnit')};
`

const options = [
  {
    value: 'I currently do not have time to review the article',
    label: 'I currently do not have time to review the article',
  },
  {
    value: 'The topic is not within my area of expertise',
    label: 'The topic is not within my area of expertise',
  },
  { value: 'Conflict of interest', label: 'Conflict of interest' },
  {
    value: 'I am not interested in reviewing this article',
    label: 'I am not interested in reviewing this article',
  },
  {
    value: 'Retired',
    label: 'Retired',
  },
  {
    value: 'Don’t know much about microPublication Biology',
    label: 'Don’t know much about microPublication Biology',
  },
]

const validations = yup.object().shape({
  radioReason: yup.string(),
  otherReason: yup.string(),
  suggestedReviewer: yup.string(),
})

const DeclineInvitation = props => {
  const { declineInvitation, reviewer, loading, title } = props

  if (loading) return <Loader />

  const showForm = reviewer.getReviewer.status === 'invited'
  const showSuccess = reviewer.getReviewer.status === 'declinedInvitation'

  const [submitting, setSubmitting] = useState(false)

  const initialValues = {
    radioReason: '',
    otherReason: '',
    suggestedReviewer: '',
  }

  const handleSubmit = async (values, actions) => {
    const { otherReason, radioReason, suggestedReviewer } = values
    const { setFieldError } = actions

    if (radioReason === '' && otherReason === '') {
      setFieldError('radioReason', 'A reason must be provided')
      return null
    }

    setSubmitting(true)

    return declineInvitation(`${radioReason} ${otherReason}`, suggestedReviewer)
      .then(() => setSubmitting(false))
      .error(() => {
        setFieldError('radioReason', 'There was a problem.')
        setSubmitting(false)
      })
  }

  return (
    <Wrapper>
      <PageHeader>Decline Invitation to Review</PageHeader>
      <H4>{title}</H4>
      {showForm && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => {
            const { errors, handleChange, setFieldValue, values } = formProps

            return (
              <>
                <Radio
                  handleChange={handleChange}
                  name="radioReason"
                  options={options}
                  setFieldValue={setFieldValue}
                  values={values}
                />
                <TextField
                  handleChange={handleChange}
                  name="otherReason"
                  placeholder="Other"
                  setFieldValue={setFieldValue}
                  value={values.otherReason}
                />
                Would you recommend other reviewers for this article?
                <TextField
                  handleChange={handleChange}
                  name="suggestedReviewer"
                  placeholder=" Please list them here, with a current email address, if possible."
                  setFieldValue={setFieldValue}
                  value={values.suggestedReviewer}
                />
                <Button loading={submitting} primary type="submit">
                  Decline
                </Button>
                {errors.radioReason && (
                  <Error>Error: {errors.radioReason}</Error>
                )}
              </>
            )
          }}
        </Form>
      )}
      {showSuccess && <>Success</>}
      {!showForm && !showSuccess && <Error>There was a problem</Error>}
    </Wrapper>
  )
}

export default DeclineInvitation
