import { gql } from '@apollo/client'

const STATISTICS = gql`
  query Statistics {
    statistics {
      numPublished
      numRejected
      numDeclined
      numWithdrawn
      numUnpaid
      numPaid
      numWaived
      speciesCounts {
        label
        value
      }
      categoriesCounts {
        label
        value
      }
      yearCounts {
        label
        value
      }
      averageTimeFromSubmitToAccept
      averageTimeFromSubmitToPublish
      averageNumChats
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { STATISTICS }
