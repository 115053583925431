import { gql } from '@apollo/client'

const NEWS = gql`
  query News {
    news {
      id
      created
      updated
      title
      image {
        name
        url
      }
      content
      publish
    }
  }
`

const NEWS_ITEM = gql`
  query NewsItem($id: ID!) {
    newsItem {
      id
      created
      updated
      title
      image {
        name
        url
      }
      content
      publish
    }
  }
`

const ADD_NEWS = gql`
  mutation AddNews($newNews: NewsInput!) {
    addNews(newNews: $newNews)
  }
`

const EDIT_NEWS = gql`
  mutation EditNews($id: ID!, $newNews: NewsInput!) {
    editNews(id: $id, newNews: $newNews)
  }
`

const DELETE_NEWS = gql`
  mutation DeleteNews($id: ID!) {
    deleteNews(id: $id)
  }
`

export { NEWS, NEWS_ITEM, ADD_NEWS, EDIT_NEWS, DELETE_NEWS }
