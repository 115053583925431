import { gql } from '@apollo/client'

const PAYMENT = gql`
  query Payment($id: ID!) {
    manuscript(id: $id) {
      history {
        published
      }
      versions {
        authors {
          firstName
          lastName
          email
          submittingAuthor
          correspondingAuthor
        }
        title
        teams {
          role
          members {
            user {
              email
            }
          }
        }
      }
      doi
      paymentStatus
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { PAYMENT }
