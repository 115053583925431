import React from 'react'

import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { DECLINE_INVITATION, GET_REVIEWER } from '../graphql'
import { DeclineInvitation } from '../../ui'

const DeclineInvitationPage = props => {
  const { id } = useParams()

  const { data, loading } = useQuery(GET_REVIEWER, {
    variables: { teamMemberId: id },
  })

  const queryRefetch = {
    refetchQueries: [
      {
        query: GET_REVIEWER,
        variables: { teamMemberId: id },
      },
    ],
  }

  const [declineInvitation] = useMutation(DECLINE_INVITATION, queryRefetch)

  const declineInvitationFn = (reason, suggestedReviewer) =>
    declineInvitation({
      variables: { teamMemberId: id, reason, suggestedReviewer },
    })

  return (
    <DeclineInvitation
      declineInvitation={declineInvitationFn}
      loading={loading}
      reviewer={data}
    />
  )
}

export default DeclineInvitationPage
