import clone from 'lodash/clone'
import get from 'lodash/get'
import last from 'lodash/last'
import set from 'lodash/set'

/*
  CLEAN STATES
*/

const cleanDecision = {
  accepted: false,
  declined: false,
  rejected: false,
  revise: false,
}

const cleanScienceOfficer = {
  approved: null,
  pending: false,
}

const cleanSubmission = {
  datatypeSelected: false,
  full: false,
  initial: false,
}

const createNewStatus = () => ({
  decision: clone(cleanDecision),
  scienceOfficer: clone(cleanScienceOfficer),
  submission: clone(cleanSubmission),
})

/*
  STATUS INFO
*/

/* Decision */

const isRevise = status => {
  if (get(status, 'decision.revise')) return true
  return false
}

/* Reviewers */
const isReviewerInvited = status => {
  if (get(status, 'reviewers.invited')) return true
  return false
}

const isReviewerAccepted = status => {
  if (get(status, 'reviewers.accepted')) return true
  return false
}

const isReviewerSubmitted = status => {
  if (get(status, 'reviewers.submitted')) return true
  return false
}

/* Submission */
const isFullSubmissionReady = status => {
  if (get(status, 'submission.full')) return true
  return false
}

const isInitialSubmissionReady = status => {
  if (get(status, 'submission.initial')) return true
  return false
}

const isDatatypeSelected = status => {
  if (get(status, 'submission.datatypeSelected')) return true
  return false
}

const isEditableByAuthor = status =>
  isInitialSubmissionReady(status) === false ||
  (isDatatypeSelected(status) && isFullSubmissionReady(status) === false) ||
  isUnderRevision(status)

/*
  DECISION
*/

const isUnderRevision = status => status.decision === 'revise'

/*
  SUBMISSION
*/

const setInitialSubmissionReady = status => {
  set(status, 'submission.initial', true)
  return status
}

const setDatatypeSelected = status => {
  set(status, 'submission.datatypeSelected', true)
  return status
}

const setDatatypeSelectedAndFullSubmissionReady = status => {
  set(status, 'submission.datatypeSelected', true)
  set(status, 'submission.full', true)
  return status
}

const setFullSubmissionReady = status => {
  set(status, 'submission.full', true)
  return status
}

// Sets submission status to its next logical state
// initial -> datatype selected -> full
const updateSubmissionStatus = (status, newDatatype) => {
  if (!status)
    throw new Error('Cannot update submission status without a status object')

  const newStatus = clone(status)
  if (!newStatus.submission) newStatus.submission = clone(cleanSubmission)

  const initial = isInitialSubmissionReady(newStatus)
  const datatype = isDatatypeSelected(newStatus)
  const full = isFullSubmissionReady(newStatus)
  const underRevision = isUnderRevision(newStatus)

  if (!initial) return setInitialSubmissionReady(newStatus)

  if (initial && !datatype) {
    if (newDatatype === 'noDatatype') {
      return setDatatypeSelectedAndFullSubmissionReady(status)
    }

    return setDatatypeSelected(newStatus)
  }

  if (datatype && !full) return setFullSubmissionReady(newStatus)

  if (full && underRevision) {
    newStatus.decision = cleanDecision
    return newStatus
  }

  throw new Error(
    'Cannot update submission status, as it has been fully submitted already',
  )
}

// Returns boolean to decide whether to show a link to assign reviewers page
const shouldShowAssignReviewersLink = manuscript => {
  if (!manuscript || !manuscript.versions) return false
  const lastVersion = last(manuscript.versions.filter(v => v.submitted))

  if (!lastVersion) return false

  const { decision, submitted } = lastVersion
  const full = submitted

  if (!full || decision) return false
  return true
}

/*
  EXPORT
*/

export {
  createNewStatus,
  isDatatypeSelected,
  isEditableByAuthor,
  isFullSubmissionReady,
  isInitialSubmissionReady,
  isReviewerAccepted,
  isReviewerInvited,
  isReviewerSubmitted,
  isRevise,
  isUnderRevision,
  shouldShowAssignReviewersLink,
  setDatatypeSelected,
  setFullSubmissionReady,
  setInitialSubmissionReady,
  updateSubmissionStatus,
}
