/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as yup from 'yup'

import { Button, Form, TextField } from '../common'
import { grid, th } from '../_helpers'

const Wrapper = styled.div`
  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

const FormLabel = styled.div`
  color: ${th('colorPrimary')};
  margin-bottom: ${grid(2)};
  text-transform: uppercase;
`

const FormWarning = styled.div`
  color: ${th('colorError')};
  margin-bottom: ${grid(2)};
  text-transform: uppercase;
`

const initialValues = {
  email: '',
  givenNames: '',
  surname: '',
}

const validations = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
  givenNames: yup.string().trim().required('Given names are required'),
  surname: yup.string().trim().required('Surname is required'),
})

const InviteReviewer = props => {
  const { className, disabled, sendInvitation } = props

  const handleSubmit = (formValues, formikBag) => {
    const { email, givenNames, surname } = formValues

    const input = {
      email: email.trim(),
      givenNames: givenNames.trim(),
      surname: surname.trim(),
    }

    sendInvitation(input).then(() => formikBag.resetForm())
  }

  return (
    <Wrapper className={className} disabled={disabled}>
      <FormLabel>Invite a reviewer that is not a user yet</FormLabel>

      {disabled && <FormWarning>No available invitation slots</FormWarning>}

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validations}
      >
        {formProps => {
          const { errors, values, setFieldValue, ...rest } = formProps

          return (
            <>
              <TextField
                error={errors.givenNames}
                label="Given names"
                name="givenNames"
                required
                value={values.givenNames}
                {...rest}
              />
              <TextField
                error={errors.surname}
                label="Surname"
                name="surname"
                required
                value={values.surname}
                {...rest}
              />
              <TextField
                error={errors.email}
                label="Email"
                name="email"
                required
                value={values.email}
                {...rest}
              />
              <Button primary type="submit">
                Add to List
              </Button>
            </>
          )
        }}
      </Form>
    </Wrapper>
  )
}

InviteReviewer.propTypes = {
  /** Controls whether form is disabled */
  disabled: PropTypes.bool,
  /** Function to run on submit */
  sendInvitation: PropTypes.func.isRequired,
  searchSpecies: PropTypes.func,
}

InviteReviewer.defaultProps = {
  disabled: false,
  searchSpecies: null,
}

export default InviteReviewer
