import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useMutation, useSubscription } from '@apollo/client'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import keys from 'lodash/keys'
import last from 'lodash/last'
import omit from 'lodash/omit'
import union from 'lodash/union'

import styled from 'styled-components'

import { uuid } from '@coko/client'

import {
  MANUSCRIPT_STATUS,
  MANUSCRIPT_SUBMISSION_FORM,
  SAVE_FORM,
  SUBMIT_MANUSCRIPT,
  UPLOAD_FILE,
  SPECIES,
  CATEGORIES,
  SUBMISSION_TYPES,
  UPLOAD_PROGRESS,
  USER_COAUTHORS,
  CANCEL_EDIT,
} from '../../graphql'
import { parseCsv } from '../_helpers/common'

import { Loader, Split, SubmissionFormFeedback } from '../../../ui'
import SubmissionForm from '../../components/SubmissionForm'

const FullHeightSplit = styled(Split)`
  height: 100%;
`

const isUrl = str => {
  if (!str) return false

  // this is a very basic check for http in the url, but it allows for localhost urls
  const expression = /(https?:\/\/[^\s]+)/g
  const regex = new RegExp(expression)

  const match = str.match(regex)
  return match !== null
}

const dataToFormValues = data => {
  const vals = cloneDeep(data)
  const { authors, awards, image, references } = data

  if (authors) {
    const modAuthors = authors.map(item => {
      const modAuthor = cloneDeep(item)
      modAuthor.id = uuid()

      if (isEmpty(modAuthor.affiliations)) {
        modAuthor.affiliations = ['']
      }

      if (isEmpty(modAuthor.departments)) {
        modAuthor.departments = modAuthor.affiliations.map(() => '')
      }

      if (!modAuthor.email) modAuthor.email = ''
      return omit(modAuthor, '__typename')
    })

    vals.authors = modAuthors
  }

  if (references) {
    vals.references = references.map(item => {
      const modReference = cloneDeep(item)
      modReference.id = uuid()
      return modReference
    })
  }

  if (awards) {
    vals.awards = awards.map(award => {
      const modAward = cloneDeep(award)
      modAward.id = uuid()
      return modAward
    })
  }

  delete vals.__typename // eslint-disable-line no-underscore-dangle

  // eslint-disable-next-line no-underscore-dangle
  if (image && image.__typename) {
    vals.image = omit(image, '__typename')
  }

  if (image && image.data) {
    const parsedCsv = parseCsv(image.data)
    vals.image.data = parsedCsv.csvData
    vals.image.header = parsedCsv.csvHeader
  }

  return vals
}

/* eslint-disable no-underscore-dangle, no-param-reassign */
// TODO -- write data cleanup functions (eg. remove __typename)
const formValuesToData = values => {
  const data = cloneDeep(values)
  const { authors, status, references, awards } = data

  if (authors) {
    data.authors = union([], authors)
    // author.submittingAuthor = true
    // data.authors.push(author)

    data.authors = data.authors
      .map(item => {
        const modAuthor = cloneDeep(item)
        delete modAuthor.id
        delete modAuthor.__typename
        delete modAuthor.error
        // if (!modAuthor.submittingAuthor) modAuthor.submittingAuthor = null
        if (!modAuthor.email) modAuthor.email = null
        else modAuthor.email = modAuthor.email.trim()

        modAuthor.affiliations = modAuthor.affiliations.filter(
          aff => aff !== '',
        )
        return modAuthor
      })
      .filter(item => {
        if (
          isEmpty(item.firstName) &&
          (isEmpty(item.credit) || isEqual(item.credit, [''])) &&
          isEmpty(item.affiliations) &&
          !item.submittingAuthor &&
          isEmpty(item.orcid)
        ) {
          return false
        }

        return true
      })
  }

  if (status) {
    delete status.__typename
    if (status.decision) delete status.decision.__typename
    if (status.scienceOfficer) delete status.scienceOfficer.__typename
    if (status.submission) delete status.submission.__typename
  }

  if (references) {
    data.references = references
      .map(item => {
        const modReference = cloneDeep(item)
        delete modReference.__typename
        delete modReference.id
        delete modReference.error
        return modReference
      })
      .filter(item => {
        if (
          isEmpty(item.pubmedId) &&
          isEmpty(item.doi) &&
          (isEmpty(item.reference) || isEqual(item.reference, '<p></p>'))
        ) {
          return false
        }

        return true
      })
  }

  if (awards) {
    data.awards = awards.map(award => {
      const modAward = cloneDeep(award)
      delete modAward.id
      return modAward
    })
  }

  if (data.laboratory) delete data.laboratory.__typename

  const autocompleteKeys = keys(data).filter(key => {
    const match = key.match(/react-autowhatever*/)
    return match !== null
  })

  autocompleteKeys.forEach(key => delete data[key])

  if (data.image) delete data.image.__typename
  if (data.suggestedReviewer) delete data.suggestedReviewer.__typename

  delete data.__typename

  delete data.active
  delete data.dataType
  delete data.submitted
  delete data.created
  delete data.isApprovedByScienceOfficer
  delete data.decisionLetter
  delete data.authorCsv
  delete data.referenceUpload
  delete data.confidentialComments
  delete data.editorEdit
  delete data.articleTitle

  if (data.image && isUrl(data.image.url)) {
    delete data.image
  }

  if (data.image && data.image.preview) {
    delete data.image.preview
  }

  if (data.image && data.image.data) {
    delete data.image.data
  }

  if (data.image && data.image.header) {
    delete data.image.header
  }

  if (data.extendedData && data.extendedData.length > 0) {
    const newExtendedData = data.extendedData.map(file => {
      if (isUrl(file.url)) {
        file.url = `/${file.url.split('/').pop()}`
      }

      delete file.preview
      return file
    })

    data.extendedData = newExtendedData
  }

  return data
}

/* eslint-enable no-underscore-dangle, no-param-reassign */

const SubmissionFormPage = props => {
  const { manuscriptId, submissionType, user } = props

  const [fileProgress, setFileProgress] = useState(null)

  /**
   * Fetch data
   */
  const { data, loading } = useQuery(MANUSCRIPT_SUBMISSION_FORM, {
    variables: {
      id: manuscriptId,
    },
  })

  const { data: speciesQuery } = useQuery(SPECIES)
  const { data: categoriesQuery } = useQuery(CATEGORIES)
  const { data: submissionTypesQuery } = useQuery(SUBMISSION_TYPES)

  const { data: userCoauthors } = useQuery(USER_COAUTHORS, {
    variables: { id: user.id },
  })

  const speciesOptions = speciesQuery && speciesQuery.species
  const categoryOptions = categoriesQuery && categoriesQuery.categories
  const coAuthors = userCoauthors && userCoauthors.userCoauthors

  const submissionTypeOptions =
    submissionTypesQuery && submissionTypesQuery.submissionTypes

  let formData, feedbackData

  if (data && data.manuscript) {
    formData = dataToFormValues(last(data.manuscript.versions))
    formData.categories = data.manuscript.categories
    formData.species = data.manuscript.species
    formData.submissionTypes = data.manuscript.submissionTypes

    feedbackData = data.manuscript.versions
      .filter(v => v.submitted)
      .map((v, index) => ({
        decisionLetter: v.decisionLetter,
        label: index === 0 ? `Original` : `Revision ${index}`,
      }))
  }

  /**
   * Autosave form
   */
  const [saveForm] = useMutation(SAVE_FORM, {
    refetchQueries: [
      {
        query: MANUSCRIPT_SUBMISSION_FORM,
        variables: { id: manuscriptId },
      },
      // ALSO DASHBOARD MANUSCRIPTS?
      // BECAUSE OF TITLE
    ],
  })

  const saveFormFn = saveData =>
    saveForm({
      variables: {
        input: formValuesToData(saveData),
      },
    })

  /**
   * Upload image
   */
  const [uploadFile] = useMutation(UPLOAD_FILE)
  const upload = file => uploadFile({ variables: { file } })

  const { data: progressData, loading: progressLoading } = useSubscription(
    UPLOAD_PROGRESS,
  )

  useEffect(() => {
    if (!progressLoading) {
      setFileProgress(progressData.uploadProgress)
    }
  })

  /**
   * Submit manuscript
   */
  const [submitManuscript] = useMutation(SUBMIT_MANUSCRIPT, {
    refetchQueries: [
      {
        query: MANUSCRIPT_STATUS,
        variables: { id: manuscriptId },
      },
      // {
      //   query: MANUSCRIPT_SUBMISSION_FORM,
      //   variables: { id: manuscriptId },
      // },
    ],
  })

  const submitManuscriptFn = values => {
    localStorage.removeItem('edit')
    return submitManuscript({
      variables: {
        input: formValuesToData(values),
      },
    })
  }

  const [cancelEdit] = useMutation(CANCEL_EDIT, {
    refetchQueries: [
      {
        query: MANUSCRIPT_STATUS,
        variables: { id: manuscriptId },
      },
    ],
  })

  const cancelEditFn = () => {
    localStorage.removeItem('edit')
    return cancelEdit({ variables: { manuscriptId } })
  }

  /**
   * UI
   */
  if (loading) return <Loader />

  const form = (
    <SubmissionForm
      cancelEdit={cancelEditFn}
      categoryOptions={categoryOptions}
      coAuthors={coAuthors}
      data={formData}
      fileProgress={fileProgress}
      manuscriptId={manuscriptId}
      saveForm={saveFormFn}
      speciesOptions={speciesOptions}
      submissionType={submissionType}
      submissionTypeOptions={submissionTypeOptions}
      submitManuscript={submitManuscriptFn}
      upload={upload}
    />
  )

  if (submissionType !== 'initial') {
    const feedback = <SubmissionFormFeedback data={feedbackData} />
    return <FullHeightSplit left={form} right={feedback} splitOn={70} />
  }

  return form
}

SubmissionFormPage.propTypes = {
  manuscriptId: PropTypes.string.isRequired,
  submissionType: PropTypes.oneOf(['initial', 'full', 'revision']).isRequired,
}

export default SubmissionFormPage
