import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import last from 'lodash/last'

import { th } from '@pubsweet/ui-toolkit'

import { grid } from '../_helpers'

import Tabs from './Tabs'
import TabsLock from './TabsLock'
import Split from './Split'
import { Loader, PageHeader } from '../common'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledTabsLock = styled(TabsLock)`
  margin-bottom: ${th('gridUnit')};
`

// Grow to fill all height minus the height of the lock row
const StyledSplit = styled(Split)`
  flex-grow: 1;
  max-height: calc(100% - ${grid(4)});
`

const SideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.loading && `height: 100%;`}
`

const Header = styled(PageHeader)`
  margin: 0;
`

/* eslint-disable react/prop-types */
const Side = ({
  activeKey,
  alwaysUseActiveKeyFromProps,
  headerText,
  loading,
  onChange,
  sections,
}) => (
  <SideWrapper loading={loading}>
    <Header>{headerText}</Header>

    {loading && <Loader />}

    {!loading && (
      <Tabs
        activeKey={activeKey}
        alwaysUseActiveKeyFromProps={alwaysUseActiveKeyFromProps}
        onChange={onChange}
        sections={sections}
      />
    )}
  </SideWrapper>
)
/* eslint-enable react/prop-types */

const SyncedTabs = props => {
  const {
    className,
    leftHeader,
    leftLoading,
    leftSections,
    rightHeader,
    rightLoading,
    rightSections,
  } = props

  const defaultActiveKey =
    leftSections && leftSections.length > 0 && last(leftSections).key

  const [activeKey, setActiveKey] = useState(defaultActiveKey)
  const [locked, setLocked] = useState(true)

  // HACK -- we shouldn't really update state while rendering
  // this solves (for now) the scenario where initial state was set to undefined
  // because leftSections hadn't arrived yet
  if (!activeKey && defaultActiveKey) setActiveKey(defaultActiveKey)

  const Left = (
    <Side
      activeKey={activeKey}
      alwaysUseActiveKeyFromProps={locked}
      headerText={leftHeader}
      loading={leftLoading}
      onChange={key => setActiveKey(key)}
      sections={leftSections}
    />
  )

  const Right = (
    <Side
      activeKey={activeKey}
      alwaysUseActiveKeyFromProps={locked}
      headerText={rightHeader}
      loading={rightLoading}
      onChange={key => setActiveKey(key)}
      sections={rightSections}
    />
  )

  return (
    <Wrapper className={className}>
      <StyledTabsLock locked={locked} onClick={() => setLocked(!locked)} />
      <StyledSplit left={Left} right={Right} />
    </Wrapper>
  )
}

SyncedTabs.propTypes = {
  /** Header text to display on the left */
  leftHeader: PropTypes.string.isRequired,
  /** Whether the left side of the split pane is in loading state */
  leftLoading: PropTypes.bool,
  /** Left tab sections (same API as Tabs component) */
  leftSections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ),
  /** Same as leftHeader, but for right side */
  rightHeader: PropTypes.string.isRequired,
  /** Whether the right side of the split pane is in loading state */
  rightLoading: PropTypes.bool,
  /** Same as leftSections, but for right side */
  rightSections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ),
}

SyncedTabs.defaultProps = {
  leftLoading: false,
  rightLoading: false,
  leftSections: null,
  rightSections: null,
}

export default SyncedTabs
