/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'

import WarningModal from './WarningModal'
import { stripHTML } from '../_helpers'

const ConfirmManuscriptWithdraw = props => {
  const { title, ...rest } = props

  const formattedTitle = title ? stripHTML(title) : 'Untitled'

  return (
    <WarningModal
      headerText="confirm withdraw"
      textSuccess="withdraw"
      {...rest}
    >
      {`You are about to withdraw the article "${formattedTitle}". Are you sure?`}
    </WarningModal>
  )
}

ConfirmManuscriptWithdraw.propTypes = {
  title: PropTypes.string,
}

ConfirmManuscriptWithdraw.defaultProps = {
  title: 'Untitled',
}

export default ConfirmManuscriptWithdraw
