import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'

const Chat = styled.span`
  border-radius: 2px;
  color: ${th('colorPrimary')};
  cursor: pointer;
  font-family: ${th('fontInterface')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: calc(${th('gridUnit')} / 2) ${th('gridUnit')} 0;
  text-transform: uppercase;
  transition: background 0.1s ease-in;

  &:hover {
    background: ${th('colorBackgroundHue')};
  }
`

export default Chat
