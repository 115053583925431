/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import { th } from '@pubsweet/ui-toolkit'
import { ActionGroup, H4, List } from '@pubsweet/ui'

const Wrapper = styled.div`
  margin-bottom: calc(${th('gridUnit')} * 3);
`

const Title = styled(H4)`
  align-self: flex-end;
  color: ${th('colorPrimary')};
  font-weight: 400;
  /* line-height: 20px; */
  margin: 0 ${th('gridUnit')} 0 0;
  text-transform: uppercase;
`

const Header = styled.div`
  align-items: flex-end;
  border-bottom: 2px solid ${th('colorPrimary')};
  display: flex;
  flex: 0 1 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  /* margin: calc(${th('gridUnit')} * 2) auto; */
  margin: 0 0 ${th('gridUnit')} 0;
`

//  /* border-bottom: ${th('borderWidth')} ${th('borderStyle')} ${th('colorBorder')}; */
const HeaderActions = styled(ActionGroup)`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`

const NoItems = styled.div`
  color: ${th('colorTextPlaceholder')};
  font-style: italic;
`

const Section = props => {
  const { actions, itemComponent, items, label, ...rest } = props

  const hasItems = items && items.length > 0
  const emptyMessage = 'There are no articles to display'

  return (
    <Wrapper data-test-id="dashboard-section">
      <Header>
        <Title>{label}</Title>
        <HeaderActions>{actions && actions.map(a => a)}</HeaderActions>
      </Header>
      {hasItems && <List component={itemComponent} items={items} {...rest} />}

      {!hasItems && <NoItems>{emptyMessage}</NoItems>}
    </Wrapper>
  )
}

export default Section
