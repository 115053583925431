/* eslint-disable react/prop-types */

import React, { useState } from 'react'
import styled from 'styled-components'

import { Button } from '../common'
import ModalFooter from './ModalFooter'

const Wrapper = styled(ModalFooter)`
  display: flex;
  justify-content: flex-end;
`

const ModalFooterDialog = props => {
  const {
    className,
    onConfirm,
    onRequestClose,
    showCancelButton = true,
    textCancel = 'Cancel',
    textSuccess = 'OK',
  } = props

  const [isSubmitted, setSubmitted] = useState(false)

  const handleConfirm = e => {
    setSubmitted(true)
    onConfirm()
  }

  return (
    <Wrapper className={className}>
      {showCancelButton && (
        <Button onClick={onRequestClose}>{textCancel}</Button>
      )}

      <Button
        disabled={isSubmitted}
        loading={isSubmitted}
        onClick={handleConfirm}
        primary
      >
        {textSuccess}
      </Button>
    </Wrapper>
  )
}

export default ModalFooterDialog
