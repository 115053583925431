/* eslint-disable react/prop-types */

import styled, { createGlobalStyle } from 'styled-components'
import React, { Fragment } from 'react'

// HACK -- figure out why this is needed
const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }

  body {
    font-family: ${props => props.theme.fontInterface};
    height: 100vh;
    line-height: ${props => props.theme.lineHeightBase};
    overflow: hidden;
  }

  #root,
  #root > div,
  #root > div > div {
    height: 100%;
  }

 
`

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Layout = ({ children }) => (
  <>
    <GlobalStyle />
    <PageLayout>{children}</PageLayout>
  </>
)

export default Layout
