/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// import { StateList } from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import WarningModal from './WarningModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${th('fontSizeBaseSmall')};
  text-align: left;
`

const AutoLinkModal = props => {
  const { autoLink, current, linkMessage, ...rest } = props

  if (!autoLink) return null

  /* const stateValues = {
    link: ['To link', 'Linking', 'Linked'],
    predict: ['To predict', 'Predicting', 'Predicted', 'No prediction rules'],
  }
  */

  return (
    <WarningModal
      headerText="Check Bioentities"
      onConfirm={autoLink}
      textSuccess="continue"
      {...rest}
    >
      <Wrapper>{linkMessage}</Wrapper>
    </WarningModal>
  )
}

AutoLinkModal.propTypes = {
  autoLink: PropTypes.func.isRequired,
  linkMessage: PropTypes.string,
  current: PropTypes.objectOf(),
}

AutoLinkModal.defaultProps = {
  linkMessage: 'Link article?',
  current: { link: 0, predict: 0 },
}

export default AutoLinkModal
