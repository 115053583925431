/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */

import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import omit from 'lodash/omit'

import {
  Icon as UIIcon,
  Button,
  IconButton as UIIconButton,
} from '@pubsweet/ui'
import { th } from '@pubsweet/ui-toolkit'

import Credit from './Credit'
import AutoComplete from '../../../app/components/formElements/AutoComplete'
import { lookupAffiliation } from '../../../app/fetch/RorApi'
import { TextField, Checkbox, Select } from '../common'

const Wrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: calc(${th('gridUnit')} * 2);
`

const StyledAutoComplete = styled(AutoComplete)`
  margin-bottom: 0;
  margin-right: ${th('gridUnit')};
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0;
  margin-right: ${th('gridUnit')};
`

const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 0;
`

const Error = styled.span`
  color: ${th('colorError')};
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding-left: ${th('gridUnit')};
`

const Icon = styled(UIIcon)`
  svg {
    height: calc(${th('gridUnit')} * 2);
    stroke: ${th('colorFurniture')};
  }
`

const AffiliationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-bottom: ${th('gridUnit')};
`

const AffiliationLine = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledSelect = styled(Select)`
  max-width: 675px;
  /* stylelint-disable-next-line declaration-no-important */
  min-width: 350px !important; /* it was overriden by select */
`

const QuestionMark = () => (
  <a
    href="https://credit.niso.org"
    rel="noopener noreferrer"
    target="_blank"
    title="See CRediT for definition of roles."
  >
    <Icon>help_circle</Icon>
  </a>
)

const IconButton = styled(Button)`
  min-width: calc(${th('gridUnit')} * 6);
`

const AuthorInput = props => {
  const {
    coAuthors,
    currentAuthor,
    errors,
    handleChange,
    name,
    readOnly,
    setFieldTouched,
    setFieldValue,
    touched,
    // useAutoComplete,
    values,
  } = props

  if (currentAuthor < 0) return null

  const authorFirstName = `authors[${currentAuthor}].firstName`
  const authorLastName = `authors[${currentAuthor}].lastName`
  const creditName = `authors[${currentAuthor}].credit`
  const affiliationsName = `authors[${currentAuthor}].affiliations`
  const departmentsName = `authors[${currentAuthor}].departments`
  const emailName = `authors[${currentAuthor}].email`
  const isCorrespondingName = `authors[${currentAuthor}].correspondingAuthor`
  const isSubmittingName = `authors[${currentAuthor}].submittingAuthor`
  const equalContributionName = `authors[${currentAuthor}].equalContribution`
  const orcidName = `authors[${currentAuthor}].orcid`
  const academicStatusName = `authors[${currentAuthor}].academicStatus`

  const affiliations = get(values, affiliationsName)
  const departments = get(values, departmentsName)

  const err =
    get(errors, authorFirstName) ||
    get(errors, authorLastName) ||
    get(errors, creditName) ||
    get(errors, affiliationsName) ||
    get(errors, emailName) ||
    get(errors, isCorrespondingName) ||
    get(errors, isSubmittingName) ||
    get(errors, academicStatusName)

  const touchedThis = get(touched, name)

  const autoCompleteProps = omit(props, [
    'label',
    'name',
    'value',
    'placeholder',
    'required',
  ])

  const handleAddAffiliations = () => {
    affiliations.push('')
    departments.push('')
    setFieldValue(affiliationsName, affiliations)
  }

  const handleRemoveAffiliation = i => {
    const affiliationsRemoved = get(values, affiliationsName).filter(
      (_, index) => index !== i,
    )

    setFieldValue(affiliationsName, affiliationsRemoved)
    handleChange(affiliationsName)

    const departmentsRemoved = get(values, departmentsName).filter(
      (_, index) => index !== i,
    )

    setFieldValue(departmentsName, departmentsRemoved)
    handleChange(departmentsName)
  }

  const submittingAuthorText = (
    <>
      Submitting author
      <Icon title="The submitting author is responsible for all correspondences during the submission, review, and revision stages of the article.">
        help_circle
      </Icon>
    </>
  )

  const correspondingAuthorText = (
    <>
      Corresponding author
      <Icon title="The corresponding author is responsible for all correspondences concerning the article after publication.">
        help_circle
      </Icon>
    </>
  )

  const authorStatusOptions = [
    {
      label: 'High School',
      value: 'high school',
    },
    {
      label: 'Undergraduate',
      value: 'undergraduate',
    },
    {
      label: 'Graduate',
      value: 'graduate',
    },
    {
      label: 'Postdoc',
      value: 'postdoc',
    },
    {
      label: 'Postbac',
      value: 'postbac',
    },
    { label: 'PI', value: 'pi' },
    {
      label: 'Other',
      value: 'other',
    },
  ]

  const handleAcademicStatusChange = newValue =>
    setFieldValue(academicStatusName, newValue.value)

  const handleAcademicStatusBlur = e => {
    if (!touched) setFieldTouched(name)
  }

  const selectAuthor = author =>
    setFieldValue(`authors[${currentAuthor}]`, author.value)

  return (
    <>
      <Label>{err && <Error>{err}</Error>}</Label>
      {coAuthors && coAuthors.length > 0 && (
        <StyledSelect
          onChange={selectAuthor}
          options={coAuthors}
          placeholder="Select author from previous microPublications"
        />
      )}
      <Wrapper>
        <StyledTextField
          data-test-id={`authorinput-${authorFirstName}`}
          error={get(errors, authorFirstName)}
          hideErrorMessage
          name={authorFirstName}
          placeholder="First name"
          required
          value={get(values, authorFirstName)}
          {...autoCompleteProps}
        />
        <StyledTextField
          data-test-id={`authorinput-${authorLastName}`}
          error={get(errors, authorLastName)}
          hideErrorMessage
          name={authorLastName}
          placeholder="Last name"
          required
          value={get(values, authorLastName)}
          {...autoCompleteProps}
        />
      </Wrapper>

      <Wrapper>
        <Credit
          data-test-id="credit"
          name={creditName}
          readOnly={readOnly}
          setFieldTouched={setFieldTouched}
          setFieldValue={setFieldValue}
          touched={touchedThis}
          values={get(values, creditName)}
        />

        <QuestionMark />

        <StyledSelect
          isDisabled={readOnly}
          onBlur={handleAcademicStatusBlur}
          onChange={handleAcademicStatusChange}
          options={authorStatusOptions}
          placeholder="Academic Status"
          value={authorStatusOptions.find(
            option => option.value === get(values, academicStatusName),
          )}
        />

        <Icon title="This information is planned to be used to collect demographic information to seek US Government funding for microPublication">
          help_circle
        </Icon>
      </Wrapper>
      <AffiliationsWrapper>
        <Label>Affiliations *</Label>
        {affiliations &&
          affiliations.length > 0 &&
          affiliations.map((item, i) => (
            /* eslint-disable-next-line react/no-array-index-key */
            <AffiliationLine key={`${affiliationsName}-${i}`}>
              <StyledAutoComplete
                fetchData={lookupAffiliation}
                hideErrorMessage
                name={`${affiliationsName}[${i}]`}
                onChange={handleChange}
                // onSuggestionSelected={onSuggestionSelected}
                placeholder="Enter Affiliation"
                required
                value={item || ''}
                {...autoCompleteProps}
              />
              <StyledTextField
                hideErrorMessage
                name={`${departmentsName}[${i}]`}
                placeholder="Enter Department (Optional)"
                {...autoCompleteProps}
                value={departments[i]}
              />
              {affiliations.length > 1 && !readOnly && (
                <IconButton
                  onClick={() => handleRemoveAffiliation(i)}
                  title="Remove affiliation"
                  value={i}
                >
                  <Icon>minus_circle</Icon>
                </IconButton>
              )}
            </AffiliationLine>
          ))}
        {!readOnly && (
          <AffiliationLine>
            <UIIconButton icon="plus_circle" onClick={handleAddAffiliations}>
              Add Affiliation
            </UIIconButton>
          </AffiliationLine>
        )}
      </AffiliationsWrapper>
      <Wrapper>
        <StyledTextField
          data-test-id="author-email"
          error={get(errors, emailName)}
          hideErrorMessage
          label="Email address"
          name={emailName}
          placeholder="Please type in the email"
          required
          {...autoCompleteProps}
          value={get(values, emailName)}
        />

        <StyledTextField
          data-test-id="author-orcid"
          error={get(errors, orcidName)}
          hideErrorMessage
          label="ORCID iD"
          name={orcidName}
          placeholder="Optional"
          {...autoCompleteProps}
          value={get(values, orcidName)}
        />
      </Wrapper>
      <Wrapper>
        <Checkbox
          checkBoxText={correspondingAuthorText}
          checked={get(values, isCorrespondingName)}
          data-test-id={`author-input-${isCorrespondingName}`}
          name={isCorrespondingName}
          onChange={handleChange}
          value={get(values, isCorrespondingName)}
          {...autoCompleteProps}
        />

        <Checkbox
          checkBoxText={submittingAuthorText}
          checked={get(values, isSubmittingName)}
          data-test-id={`author-input-${isSubmittingName}`}
          name={isSubmittingName}
          onChange={handleChange}
          value={get(values, isSubmittingName)}
          {...autoCompleteProps}
        />

        <Checkbox
          checkBoxText="Equal contribution"
          checked={get(values, equalContributionName)}
          data-test-id={`author-input-${equalContributionName}`}
          name={equalContributionName}
          onChange={handleChange}
          value={get(values, equalContributionName)}
          {...autoCompleteProps}
        />
      </Wrapper>
    </>
  )
}

export default AuthorInput
