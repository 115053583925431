import { serverUrl } from '@coko/client'

const apiUrl = `${serverUrl}/api/crossref`

const crossrefLookup = async value => {
  const url = `${apiUrl}/fetch/${value}`
  return fetch(url)
}

const funderLookup = async value => {
  const url = `${apiUrl}/funder/${value}`
  return fetch(url)
}

export { crossrefLookup, funderLookup }
