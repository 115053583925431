/* eslint-disable react/prop-types */

import React from 'react'
import { Mutation } from '@apollo/client/react/components'
import { gql } from '@apollo/client'

import { DASHBOARD_MANUSCRIPTS } from './dashboardManuscripts'
import { withCurrentUser } from '../../../userContext'

const UPDATE_ASSIGNED_SCIENCE_OFFICER = gql`
  mutation UpdateAssignedScienceOfficer($input: UpdateAssignedInput!) {
    updateAssignedScienceOfficer(input: $input)
  }
`

const UpdateAssignedScienceOfficerMutation = props => {
  const { currentUser, render } = props

  const refetchQueries = [
    {
      query: DASHBOARD_MANUSCRIPTS,
      variables: { reviewerId: currentUser.id },
    },
  ]

  return (
    <Mutation
      mutation={UPDATE_ASSIGNED_SCIENCE_OFFICER}
      refetchQueries={refetchQueries}
    >
      {(updateAssignedScienceOfficer, updateAssignedScienceOfficerResponse) =>
        render({
          updateAssignedScienceOfficer,
          updateAssignedScienceOfficerResponse,
        })
      }
    </Mutation>
  )
}

export default withCurrentUser(UpdateAssignedScienceOfficerMutation)
