/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'

import Modal from './Modal'
import ModalHeader from './ModalHeader'

const StyledImg = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 2000px;
`

const ImageModal = props => {
  const { headerText, image, ...rest } = props

  const Header = <ModalHeader text={headerText || 'Figure 1.'} />

  return (
    <Modal headerComponent={Header} size="large" {...rest}>
      <StyledImg alt="Figure 1." src={image} />
    </Modal>
  )
}

export default ImageModal
