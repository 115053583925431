import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

import { grid, th } from '../_helpers'

const animation = keyframes`
  0% {
    transform: scale(0.0);
  } 
  
  100% {
    opacity: 0;
    transform: scale(1.0);
  }
`

const Wrapper = styled.span`
  align-items: center;
  animation: ${animation} 0.8s infinite ease-in-out;
  background: ${props => th(props.color)};
  border-radius: 100%;
  display: inline-flex;
  height: ${props => grid(props.size)};
  justify-content: center;
  width: ${props => grid(props.size)};
`

const LoaderInline = props => {
  const { className, color, size } = props
  return <Wrapper className={className} color={color} size={size} />
}

LoaderInline.propTypes = {
  /** Any color theme variable is valid */
  color: PropTypes.string,
  /** Will multiply this number by the grid size */
  size: PropTypes.number,
}

LoaderInline.defaultProps = {
  color: 'colorText',
  size: 6,
}

export default LoaderInline
