import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as yup from 'yup'
import clone from 'lodash/clone'

import { grid, th } from '@pubsweet/ui-toolkit'

import {
  Accordion,
  Loader,
  PageHeader as DefaultPageHeader,
  Button,
  Form,
  TextField,
  List,
  Select,
} from '../common'
import TaxonomySectionItem from './TaxonomySectionItem'

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 800px;
`

const PageHeader = styled(DefaultPageHeader)`
  margin-block-end: ${th('gridUnit')};
`

const Label = styled.label`
  font-size: ${th('fontSizeBaseSmall')};
  line-height: ${th('lineHeightBaseSmall')};
  padding: 10px 10px 0 0;
`

const StyledSelect = styled(Select)`
  margin-bottom: ${grid(2)};
  width: ${grid(50)};
`

const validations = yup.object().shape({
  value: yup.string().required('Value cannot be empty'),
  label: yup.string().required('Label cannot be empty'),
  imageSrc: yup.string(),
  imageAlt: yup.string(),
  modLink: yup.string(),
  mod: yup.string(),
  linkVariable: yup.string(),
  description: yup.string(),
  externalId: yup.string(),
})

const linkVariableOptions = [
  { label: 'DB Reference ID', value: 'dbReferenceId' },
  { label: 'DOI', value: 'doi' },
  { label: 'pmcId', value: 'PMC ID' },
  { label: 'PubMed ID', value: 'pmId' },
]

const TaxonomyManager = props => {
  const { loading, species, categories, submissionTypes, addTaxonomy } = props

  const [showAddTaxon, setShowAddTaxon] = useState(false)
  const [submitType, setSubmitType] = useState(null)

  const initialValues = {
    type: '',
    value: '',
    label: '',
    imageSrc: '',
    imageAlt: '',
    modLink: '',
    mod: '',
    linkVariable: '',
    description: '',
    externalId: '',
  }

  const handleSubmit = (values, formikBag) => {
    const newValues = clone(values)
    newValues.type = submitType
    newValues.value = values.value.toLowerCase()
    addTaxonomy(newValues).then(() => setShowAddTaxon(false))
  }

  const openAddSpecies = () => {
    setSubmitType('species')
    setShowAddTaxon(true)
  }

  const openAddCategory = () => {
    setSubmitType('categories')
    setShowAddTaxon(true)
  }

  const openAddType = () => {
    setSubmitType('submissionTypes')
    setShowAddTaxon(true)
  }

  if (loading) return <Loader />

  return (
    <PageWrapper>
      <PageHeader>Taxonomy Manager</PageHeader>
      <Button onClick={openAddSpecies}>Add Species</Button>
      <Button onClick={openAddCategory}>Add Category</Button>
      <Button onClick={openAddType}>Add Submission Type</Button>
      {showAddTaxon && (
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validations}
        >
          {formProps => {
            const {
              errors,
              handleBlur,
              handleChange,
              setFieldValue,
              touched,
              values,
            } = formProps

            const currentLinkVariable =
              values.linkVariable &&
              linkVariableOptions.find(
                option => option.value === values.linkVariable,
              )

            const handleLinkVariable = value =>
              setFieldValue('linkVariable', value.value)

            return (
              <>
                <TextField
                  error={errors.value}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Value"
                  name="value"
                  touched={touched}
                  value={values.value}
                />

                <TextField
                  error={errors.label}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Label"
                  name="label"
                  touched={touched}
                  value={values.label}
                />

                <TextField
                  error={errors.imageSrc}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Image Source (Optional}"
                  name="imageSrc"
                  touched={touched}
                  value={values.imageSrc}
                />

                <TextField
                  error={errors.imageAlt}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Image Alt Tag {Optional)"
                  name="imageAlt"
                  touched={touched}
                  value={values.imageAlt}
                />

                <TextField
                  error={errors.mod}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Representative Database (Optional)"
                  name="mod"
                  touched={touched}
                  value={values.mod}
                />

                <TextField
                  error={errors.modLink}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Database Link (Optional)"
                  name="modLink"
                  touched={touched}
                  value={values.modLink}
                />

                <Label>Link Variable (Optional)</Label>
                <StyledSelect
                  name="linkVariable"
                  onBlur={handleBlur}
                  onChange={handleLinkVariable}
                  options={linkVariableOptions}
                  value={currentLinkVariable}
                />

                <TextField
                  error={errors.description}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Description (Optional)"
                  name="description"
                  touched={touched}
                  value={values.description}
                />

                <TextField
                  error={errors.externalId}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  label="Taxon ID (Optional)"
                  name="externalId"
                  touched={touched}
                  value={values.externalId}
                />

                <Button onClick={() => setShowAddTaxon(false)}>Cancel</Button>
                <Button primary type="submit">
                  Save
                </Button>
              </>
            )
          }}
        </Form>
      )}
      {species && (
        <Accordion label="Species">
          <List component={TaxonomySectionItem} items={species} />
        </Accordion>
      )}
      {categories && (
        <Accordion label="Categories">
          <List component={TaxonomySectionItem} items={categories} />
        </Accordion>
      )}
      {submissionTypes && (
        <Accordion label="Submission Types">
          <List component={TaxonomySectionItem} items={submissionTypes} />
        </Accordion>
      )}
    </PageWrapper>
  )
}

TaxonomyManager.propTypes = {
  /** Whether the data is still being fetched */
  loading: PropTypes.bool,
  /** The lists of items to display */
  species: PropTypes.arrayOf(PropTypes.object.isRequired),
  categories: PropTypes.arrayOf(PropTypes.object.isRequired),
  submissionTypes: PropTypes.arrayOf(PropTypes.object.isRequired),
  addTaxonomy: PropTypes.func,
}

TaxonomyManager.defaultProps = {
  loading: false,
  addTaxonomy: null,
  species: [],
  categories: [],
  submissionTypes: [],
}

export default TaxonomyManager
