import { gql } from '@apollo/client'

const PUBLISHED_MANUSCRIPTS = gql`
  query PublishedManuscripts($role: String!) {
    publishedManuscripts(role: $role) {
      history {
        published
      }
      entities {
        match
        deleted
        source
        sourceId
        url
        type
      }
      id
      versions {
        authors {
          firstName
          lastName
          submittingAuthor
        }
        curatorReviews {
          curator {
            id
          }
          submitted
          curated
        }
        title
      }
    }
  }
`

// eslint-disable-next-line import/prefer-default-export
export { PUBLISHED_MANUSCRIPTS }
